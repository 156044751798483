import { DataService } from '../services/data.service';
import { Injectable, Inject } from '@angular/core';

import { Resolve } from '@angular/router';

import { Observable, empty } from 'rxjs';



import { Title } from '@angular/platform-browser';
import { ConfigurationService } from '../services/configuration.service';

@Injectable()
export class ConfigResolver implements Resolve<Observable<any>> {
  constructor(
    private dataService: DataService,
    private title: Title,
    private configurationService: ConfigurationService,
    @Inject('Window') private window: Window) { }

  async resolve() {
    if (this.dataService.configSettings) {
      return this.dataService.configSettings;
    }

    const result = this.configurationService.getSetupConfig().catch(reason => {
      console.log('Config file not available:', reason);
      return empty();
    });

    this.dataService.configSettings = await result;

    const isValidTab = this.dataService.configSettings.navigation.order.some(i => i === this.dataService.currentRoute);
    const isValidRoute = ['fhir/app', 'integration/app'].some(i => i === this.dataService.currentRoute);
    const defaultRoute = this.dataService.configSettings.navigation.order[0];
    const params = this.dataService.setupData({});

    if (isValidTab) {
      this.title.setTitle(this.dataService.configSettings[this.dataService.currentRoute].title);
    } else if (isValidRoute && params.app) {
      this.dataService.navigate(params.app);
    } else {
      this.dataService.navigate(defaultRoute);
    }

    return result;
  }

}
