import fecha from 'fecha';
import * as _ from 'lodash';

export class Patient {
  constructor(data) {
    this.name = data.name ? data.name : '---';
    this.dob = data.dob;
    this.mrn = data.mrn;

    this.lastName = data.lastName;
    this.alternateLastName = '';
    this.firstName = data.firstName;
    this.middleName = data.middleName;
    this.dobFormat = data.dobFormat;
    this.title = data.title;
    this.suffix = data.suffix;
    this.gender = data.gender;
    this.studyDescription = data.studyDescription;
    this.sendToPACS = data.sendToPACS;
    this.sendToNuance = data.sendToNuance;
    this.sendToXDSb = data.sendToXDSb;
  }

  name: string;
  dob: string;
  sex: string;
  mrn: string;

  lastName: string;
  alternateLastName: string;
  firstName: string;
  middleName: string;
  dobFormat: string;
  title: string;
  suffix: string;
  gender: string;
  studyDescription: string;
  studyUid: string;
  sendToPACS: any;
  sendToNuance: any;
  sendToXDSb: any;

  static parseDate(stringDate: string): string {
    const year = parseInt(stringDate.substring(0, 4), 10);
    const month = parseInt(stringDate.substring(4, 6), 10) - 1;
    const day = parseInt(stringDate.substring(6, 8), 10);
    const dateObject = new Date(Date.UTC(year, month, day, 12, 0, 0));
    const dobFormat = fecha.format(dateObject, 'MMM D, YYYY');

    return dobFormat;
  }

  static parseName(stringName: string) {
    if (_.includes(stringName, '^')) {
      return this.split(stringName, '^');
    }

    if (_.includes(stringName, ',')) {
      return this.split(stringName, ',');
    }

    if (_.includes(stringName, '|')) {
      return this.split(stringName, '|');
    }

    return {
      lastName: stringName === 'undefined' ? undefined : stringName
    };
  }

  private static split(name: string, delimiter: string) {
    let splitName = (decodeURIComponent(name).split(delimiter));
    splitName = splitName.map(i => i.trim());

    return {
      lastName: splitName.shift(),
      firstName: splitName.shift() || '',
      middleName: splitName.shift() || '',
      title: splitName.shift() || '',
      suffix: splitName.shift() || ''
    };
  }
}
