<div>
  <h3>
    {{translate.instant('phrase.navigation')}}
  </h3>
  <p>
    {{translate.instant('instruction.navigation')}}
  </p>
</div>
<div class="form-footer">
  <button mat-button color='primary' (click)="cancel()">
    {{translate.instant('button.nevermind')}}
  </button>
  <button mat-raised-button color='accent' (click)="submit()">
    {{translate.instant('button.continue')}}
  </button>
</div>