import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dicom } from '../classes/dicom-file';
import { ArrayResponse, Study, Patient, ObjectResponse, StudyExists, ExistingStudy } from '../classes/image-exchange-types';
import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { UrlParameterService } from './url-parameter.service';

@Injectable({
  providedIn: 'root'
})
export class DicomQueryRetrieveService {

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private urlParameterService: UrlParameterService
  ) { }

  enableMocks(): Boolean {
    return !environment.production || this.urlParameterService.getUrlParameter('demo') === 'true';
  }

  enableMocksLocally(): Boolean {
    return !environment.production && this.urlParameterService.getUrlParameter('unittest') !== 'y';
  }

  patients(query): Promise<ArrayResponse<ArrayResponse<Patient>>> {
    if (this.enableMocksLocally()) {
      const data: ArrayResponse<ArrayResponse<Patient>> = {
        status: 200,
        message: '',
        data: [
          {
            status: 200,
            message: '',
            data: [
              { birthDate: '03/10/1950', patientId: '123456', patientNameDisplay: 'Smith, Alexandra', sex: 'F', site: 'Saint Luke\'s', archive: 'ohif pacs', } as any
            ]
          },
          {
            status: 200,
            message: '',
            data: [
              { birthDate: '03/10/1950', patientId: '987654', patientNameDisplay: 'Smith, Alex', sex: 'F', site: 'Saint John\'s', archive: 'ohif pacs', } as any
            ]
          },
          {
            status: 200,
            message: 'No patients found at University Clinic',
            data: []
          }
        ]
      };
      return Promise.resolve(data);
    }
    return this.http.get(`${this.sessionService.baseUrl}dicom/patients?${this.toQueryString(query)}`).toPromise() as Promise<ArrayResponse<ArrayResponse<Patient>>>;
  }

  studies(query): Promise<ArrayResponse<Study>> {
    if (this.enableMocksLocally()) {
      let data: ArrayResponse<Study>;
      if (query.site.includes('John')) {
        data = {
          status: 200,
          message: '',
          data: [
            { birthDate: '19500310', patientId: '123457', patientNameDisplay: 'Smith, Alex', sex: 'F', studyDate: '05/11/2018', studyDescription: 'Chest Xray & Chest Xray & Chest Xray & Chest Xray & Chest Xray', studyInstanceUID: '1.2.826.0.1.3680043.11.124', modalitiesInStudy: 'CR', referringPhysicianName: 'Dr Erik Johnson', numberOfStudyRelatedInstances: 2, error: '', archive: '', mrn: '', searchPatientName: '', siteName: '' } as any
          ]
        };
      } else {
        data = {
          status: 200,
          message: '',
          data: [
            { birthDate: '19500310', patientId: '123457', patientNameDisplay: 'Smith, Alexandra', sex: 'F', studyDate: '08/26/2009', studyDescription: 'CT of Chest', studyInstanceUID: '1.2.826.0.1.3680043.11.124', modalitiesInStudy: 'CT', referringPhysicianName: 'Dr Beth Miller', numberOfStudyRelatedInstances: 55, error: '', archive: '', mrn: '', searchPatientName: '', siteName: '' } as any
          ]
        };
      }

      return Promise.resolve(data);
    }
    return this.http.post(`${this.sessionService.baseUrl}dicom/studies?${this.toQueryString(query)}`, query).toPromise() as Promise<ArrayResponse<Study>>;
  }

  queryExistingStudies(query): Promise<ArrayResponse<ExistingStudy>> {
    if (this.enableMocksLocally()) {
      let data: ArrayResponse<ExistingStudy>;

      data = {
        status: 200,
        message: '',
        data: [
          {
            'accessionNumber': 'HSA666000000010',
            'archive': 'STENTOR_QRP',
            'birthDate': '19610929',
            'error': '',
            'firstName': 'Alex',
            'instanceNumber': '',
            'lastName': 'Smith',
            'middleName': '',
            'modalitiesInStudy': 'VL',
            'modalitiesInStudyDisplay': 'VL, CR, MR, HR, PR, SS, JK',
            'modality': '',
            'numberOfSeriesRelatedInstances': '',
            'numberOfStudyRelatedInstances': '1',
            'numberOfStudyRelatedSeries': '1',
            'patientId': '6789abc',
            'referringPhysician': 'Thompson^Sam^^^',
            'seriesInstanceUID': '',
            'seriesNumber': '2',
            'sex': 'M',
            'sopClassUID': '',
            'sopInstanceUID': '',
            'studyDate': '20190507',
            'studyDateDisplay': 'Jun 20 2005',
            'studyDescription': 'NM TUMOR IMAGING - OUTSTANDING ISSUES',
            'studyInstanceUID': '1.2.826.0.1.3680043.9.3574.666.2.19071506'
          },
          {
            'accessionNumber': 'HSA666000000010',
            'archive': 'STENTOR_QRP',
            'birthDate': '19610929',
            'error': '',
            'firstName': 'Alex',
            'instanceNumber': '',
            'lastName': 'Smith',
            'middleName': '',
            'modalitiesInStudy': 'VL',
            'modalitiesInStudyDisplay': 'VL',
            'modality': '',
            'numberOfSeriesRelatedInstances': '',
            'numberOfStudyRelatedInstances': '1',
            'numberOfStudyRelatedSeries': '1',
            'patientId': '6789abc',
            'referringPhysician': 'Thompson^Sammy^^^',
            'seriesInstanceUID': '',
            'seriesNumber': '2',
            'sex': 'M',
            'sopClassUID': '',
            'sopInstanceUID': '',
            'studyDate': '20190507',
            'studyDateDisplay': 'Jun 20 2005',
            'studyDescription': 'Ophthalmology Photo',
            'studyInstanceUID': '1.2.826.0.1.3680043.9.3574.666.2.19071504'
          }
        ]
      };

      return Promise.resolve(data);
    }
    return this.http.get(`${this.sessionService.baseUrl}dicom/queryStudies?${this.toQueryString(query)}`).toPromise() as Promise<ArrayResponse<ExistingStudy>>;

  }

  studyExists(query): Promise<ObjectResponse<StudyExists>> {
    if (this.enableMocksLocally()) {
      return Promise.resolve({ data: { studyExists: query.modalitiesInStudy === 'CT', aeTitles: []}, message: '', status: 200 } as any);
    }
    return this.http.get(`${this.sessionService.baseUrl}dicom/studyExists?${this.toQueryString(query)}`).toPromise() as Promise<any>;
  }

  retrieve(studyUidsList: any[], query: any): Promise<ArrayResponse<Study>> {
    if (this.enableMocksLocally()) {
      return Promise.resolve({ 'data': [{ 'patientSex': 'M', 'modalitiesInStudy': 'VL', 'patientID': '6789abc', 'studyDescription': 'Ophthalmology Photo', 'studyInstanceUID': '1.2.826.0.1.3680043.11.124', 'mrn': '123', 'siteName': 'Staging', 'archive': 'ohif', 'referringPhysicianName': 'Thompson^Sam^^Dr.', 'error': '', 'birthDate': '19610929', 'numberOfStudyRelatedInstances': '1', 'destinationServer': 'https://dev.imagemovermd.com', 'ehrPatientName': 'Smith, Alex', 'studyDate': '05/07/2019', 'searchPatientName': 'Smith^Alex', 'manifestId': 11, 'selected': true, 'manifest': { id: 11 } }], 'message': '', 'status': 200 } as any);
    }
    return this.http.post(`${this.sessionService.baseUrl}dicom/move?${this.toQueryString(query)}`, {
      studies: studyUidsList,
    }).toPromise() as Promise<ArrayResponse<Study>>;
  }

  status(manifestId: number): Promise<ObjectResponse<Dicom.Counts>> {
    if (this.enableMocksLocally()) {
      return Promise.resolve({
        status: 200,
        message: '',
        data: { SUCCESS: 1, ERROR: 0, PROCESSING: 3, CANCELLED: 0 }
      } as any);
    }
    return this.http.get(`${this.sessionService.baseUrl}dicom/uploadStatus/${manifestId}`).toPromise() as Promise<ObjectResponse<Dicom.Counts>>;
  }

  sites(): Promise<ArrayResponse<string>> {
    if (this.enableMocksLocally()) {
      const data: ArrayResponse<string> = {
        status: 200,
        message: '',
        data: ['Saint Mary\'s', 'Mercy General', 'Saint Luke\'s', 'Saint John\'s', 'University Hospital', 'University Clinic']
      };
      return Promise.resolve(data);
    }
    return this.http.get(`${this.sessionService.baseUrl}dicom/sitesLabel`).toPromise() as Promise<ArrayResponse<string>>;
  }

  private toQueryString(paramsObject) {
    return Object
      .keys(paramsObject)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
      .join('&');
  }
}
