<div mat-dialog-title class="dialog-title">
  <h2>{{translate.instant('phrase.selectExistingStudy')}}</h2>
  <button mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="filters">
  <mat-form-field>
    <mat-label>{{translate.instant('table.modalities')}}</mat-label>
    <mat-select [formControl]="modalitySelect" [multiple]="true" #select>
      <mat-option class="search-sites">
        <ngx-mat-select-search [formControl]="modalityFilter"
          [placeholderLabel]="translate.instant('button.search')" noEntriesFoundLabel=""></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let modality of filteredModalities | async" [value]="modality" class="">
        {{modality}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{translate.instant('table.date')}}</mat-label>
    <mat-select [formControl]="timeSelect">
      <mat-option *ngFor="let time of times" [value]="time" class="">
        {{time.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<table mat-table matSort [dataSource]="studiesTable" class="table">

  <ng-container matColumnDef="studyDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="date-column"> {{translate.instant('table.date')}} </th>
    <td mat-cell *matCellDef="let element" class="date-column"> {{ element.studyDateDisplay}} </td>
  </ng-container>

  <ng-container matColumnDef="studyDescription">
    <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.study')}} </th>
    <td mat-cell *matCellDef="let element"> {{element.studyDescription}} </td>
  </ng-container>

  <ng-container matColumnDef="modalities">
    <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.modalities')}} </th>
    <td mat-cell *matCellDef="let element"> {{element.type === 'study' ? element.modalitiesInStudyDisplay : element.modality}} </td>
  </ng-container>

  <ng-container matColumnDef="referringPhysician">
    <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.referringphysician')}} </th>
    <td mat-cell *matCellDef="let element"> {{element.type === 'study' ? element.referringPhysician : element.performingPhysician}} </td>
  </ng-container>

  <ng-container matColumnDef="accessionNumber">
    <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.accessionNumber')}} </th>
    <td mat-cell *matCellDef="let element" class="text-ellipsis"> <span>{{element.accessionNumber}}</span> </td>
  </ng-container>

  <ng-container matColumnDef="studySource">
    <th mat-header-cell *matHeaderCellDef class="type--header" class="source-column"> {{translate.instant('table.studySource')}} </th>
    <td mat-cell *matCellDef="let element" [class.source-column]="element.type">
      <span *ngIf="element.type === 'study'" class="color-theme--primary" [matTooltip]="translate.instant('phrase.pacs')" [matTooltipPosition]="'after'">PACS</span>
      <span *ngIf="element.type === 'exam'" class="color-theme--ternary" [matTooltip]="translate.instant('phrase.mwl')" [matTooltipPosition]="'after'">MWL</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectStudy(row)" class="row" [class.selected]="row?.studyInstanceUID === selectedStudy?.studyInstanceUID"></tr>
</table>

<div class="error">
  <span class="no-modalities" *ngIf="selectedModalities.length === 0">
    {{translate.instant('validation.noModalities')}}
  </span>
  <span class="no-studies" *ngIf="state === VIEW_STATES.Ready && studiesTable.data.length === 0 && selectedModalities.length > 0">
    {{translate.instant('patient.studyNotFound')}}
  </span>
  <span class="no-studies" *ngIf="state === VIEW_STATES.Error">
    There was an issue loading the patients studies....
  </span>
</div>

<div *ngIf="state === VIEW_STATES.Loading" class="loading">
  <p>Retrieving patient studies...</p>
  <mat-spinner></mat-spinner>
</div>

<mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10]"></mat-paginator>

<mat-dialog-actions class="actions">
  <button mat-raised-button color="primary" (click)="updateAvailableStudies(params)">
    <mat-icon>refresh</mat-icon>
    <span>Refresh List</span>
  </button>
</mat-dialog-actions>