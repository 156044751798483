"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var snack_bar_1 = require("@angular/material/snack-bar");
var translate_service_1 = require("../../translation/translate.service");
var SCRIPT_REGEX = /<\/?script>/g;
var PIN_REGEX = /\$PIN/g;
var URL_REGEX = /\$URL/g;
var TransferConfirmModalComponent = /** @class */ (function () {
    function TransferConfirmModalComponent(data, _snackBar, translate) {
        this.data = data;
        this._snackBar = _snackBar;
        this.translate = translate;
        this.userMessage = '';
        this.tokenExpired = false;
        var _message = data.message || translate.instant('dicom.syncDesc') + "\n\nSync URL: " + this.data.path + "\n\nDirectory Upload Sync Code: " + this.data.pin;
        this.tokenExpired = data.tokenExpired;
        this.userMessage = this.generateMessage(_message);
    }
    TransferConfirmModalComponent.prototype.generateMessage = function (message) {
        var output = message;
        // Replace script tags with emtpy strings
        output = output.replace(SCRIPT_REGEX, '');
        output = output.replace(PIN_REGEX, this.data.pin);
        output = output.replace(URL_REGEX, this.data.path);
        return output;
    };
    TransferConfirmModalComponent.prototype.copyText = function () {
        var el = document.createElement('textarea');
        el.value = this.data.path;
        el.setAttribute('readonly', '');
        el.style.opacity = '0';
        el.style.transform = 'scale(0)';
        el.style.pointerEvents = 'none';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this._snackBar.open(this.translate.instant('dicom.copyURLConfirm'), null, {
            duration: 2000
        });
    };
    return TransferConfirmModalComponent;
}());
exports.TransferConfirmModalComponent = TransferConfirmModalComponent;
