"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ModalityWorklistService = /** @class */ (function () {
    function ModalityWorklistService(http) {
        this.http = http;
        this.baseUrl = (environment_1.environment.production ? '' : 'http://localhost:3000') + "/pronghorn/v1/dicom/queryMwl";
        this.mwlAvailable = false;
        this.checkForMWL();
    }
    ModalityWorklistService.prototype.checkForMWL = function () {
        var _this = this;
        this
            .queryModalityWorklist({})
            .then(function () { return _this.mwlAvailable = true; })
            .catch(function () { return _this.mwlAvailable = false; });
    };
    ModalityWorklistService.prototype.queryModalityWorklist = function (query) {
        var queryParams = new URLSearchParams(Object.entries(query));
        return this.http.get(this.baseUrl + "?" + queryParams.toString()).toPromise();
    };
    ModalityWorklistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalityWorklistService_Factory() { return new ModalityWorklistService(i0.ɵɵinject(i1.HttpClient)); }, token: ModalityWorklistService, providedIn: "root" });
    return ModalityWorklistService;
}());
exports.ModalityWorklistService = ModalityWorklistService;
