"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["mat-form-field[_ngcontent-%COMP%]{display:block}.form-footer[_ngcontent-%COMP%]{text-align:right}"];
exports.styles = styles;
