"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var abstract_uploader_page_component_1 = require("../shared/abstract-uploader-page.component");
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var paginator_1 = require("@angular/material/paginator");
var table_1 = require("@angular/material/table");
var data_service_1 = require("../services/data.service");
var file_parse_service_1 = require("../services/file-parse.service");
var translate_service_1 = require("../translation/translate.service");
var media_file_1 = require("../classes/media-file");
var nuance_service_1 = require("../services/nuance.service");
var pdf_preview_modal_component_1 = require("../modals/pdf-preview-modal/pdf-preview-modal.component");
var file_service_service_1 = require("./services/file-service.service");
var date_format_service_1 = require("../services/date-format.service");
var manifest_service_1 = require("../services/manifest.service");
var session_service_1 = require("../services/session.service");
var url_parameter_service_1 = require("../services/url-parameter.service");
var select_study_dialog_component_1 = require("../modals/select-study-dialog/select-study-dialog.component");
var body_map_dialog_component_1 = require("../modals/body-map-dialog/body-map-dialog.component");
var configuration_service_1 = require("../services/configuration.service");
var lodash_1 = require("lodash");
var inter_app_communication_util_1 = require("../utilities/inter-app-communication.util");
var MediaComponent = /** @class */ (function (_super) {
    __extends(MediaComponent, _super);
    function MediaComponent(dateFormateService, fileService, dialogService, manifestService, materialDialog, nuanceService, sessionService, urlParamService, dataService, fileParseService, translate, configService, document, window) {
        var _this = _super.call(this) || this;
        _this.dateFormateService = dateFormateService;
        _this.fileService = fileService;
        _this.dialogService = dialogService;
        _this.manifestService = manifestService;
        _this.materialDialog = materialDialog;
        _this.nuanceService = nuanceService;
        _this.sessionService = sessionService;
        _this.urlParamService = urlParamService;
        _this.dataService = dataService;
        _this.fileParseService = fileParseService;
        _this.translate = translate;
        _this.configService = configService;
        _this.document = document;
        _this.window = window;
        _this.NotAnatomical = {
            name: 'Not Anatomical',
            modifiers: [],
            view: '0',
            orientation: 'ANTERIOR',
            relatedList: [],
            bodyPart: {
                bpe: 'NA',
                code: 'NOT ANATOMICAL',
                id: '-1',
                modifierTables: [],
                localizedStrings: {
                    en: { label: 'Not Anatomical' },
                    de: { label: 'Not Anatomical' },
                    fr: { label: 'Not Anatomical' },
                    it: { label: 'Not Anatomical' },
                    nl: { label: 'Not Anatomical' },
                    fr_ca: { label: 'Not Anatomical' },
                    es: { label: 'Not Anatomical' },
                }
            }
        };
        _this.NotSpecified = { name: _this.translate.instant('table.notSpecified'), code: 'NOT SPECIFIED' };
        _this.NewStudy = {
            new: true,
            studyDescription: 'New Study',
            modalities: '',
            time: ''
        };
        _this.localization = 'en';
        _this.allFilesSelected = true;
        _this.errorStatus = '';
        _this.files = [];
        _this.isPdfModalOpen = false;
        _this.logColumns = ['name', 'message'];
        _this.logData = new table_1.MatTableDataSource();
        _this.over = false;
        _this.uploaderDisabled = true;
        _this.cancelDisabled = true;
        _this.resetDisabled = true;
        _this.bodyPartEnabled = false;
        _this.bodyPartRequired = false;
        // UI flag that sets "[Not Specified]" body parts to red if the body part is required and the user attempted to submit.
        //  This emulates traditional form controls and the transition from black to red draws the users eyes
        _this.invalidFields = false;
        _this.nuanceDestinations = [];
        _this.nuanceStudyTagIds = '';
        _this.exams = [];
        _this.existingStudy = _this.NewStudy;
        _this.selectionType = 'study';
        _this.isSensitive = false;
        _this.CURRENT_TAB = 'media';
        _this.BATCH_SIZE = 1;
        _this.WARNING_TOOLTIP_POSITION = 'above';
        _this.continueChain = true;
        _this.currentlyUpdating = false;
        _this.manifestFetchRetryCounter = 0;
        _this.manifestList = [];
        _this.params = {};
        _this.promiseList = [];
        _this.bodyPartList = '';
        return _this;
    }
    // -- PUBLIC -- //
    MediaComponent.prototype.ngOnInit = function () {
        this.params = this.dataService.setupData(this.params);
        this.translate.use(this.params.lang);
        this.localization = this.params.lang.slice(0, 2) || this.localization;
        this.requestSource = inter_app_communication_util_1.RequestSource.Integrated;
        if (this.params.requestSource) {
            this.requestSource = this.params.requestSource;
        }
        this.setup();
        this.cleanup();
    };
    MediaComponent.prototype.ngOnDestroy = function () {
        return __awaiter(this, void 0, void 0, function () {
            var thumbnailFiles;
            return __generator(this, function (_a) {
                thumbnailFiles = media_file_1.Media.getMediaChildren(this.files)
                    .map(function (child) { return child.thumbnail.getValue() || ''; })
                    .filter(Boolean);
                this.fileService.deleteRemoteThumbnailSource(thumbnailFiles);
                this.resetPage();
                this.document.body.classList.remove(this.customerConfigs.theme);
                return [2 /*return*/];
            });
        });
    };
    MediaComponent.prototype.beforeunloadHandler = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var thumbnailFiles;
            return __generator(this, function (_a) {
                thumbnailFiles = [];
                this.files.forEach(function (file) {
                    file.children.forEach(function (child) {
                        thumbnailFiles.push(child.thumbnail.getValue());
                    });
                });
                this.window.localStorage.setItem('old_thumbnails', thumbnailFiles.join(','));
                return [2 /*return*/];
            });
        });
    };
    MediaComponent.prototype.trackByFile = function (__index, file) {
        return file.patientID;
    };
    MediaComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.logData.filter = filterValue;
    };
    MediaComponent.prototype.openPdf = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isPdfModalOpen) {
                            return [2 /*return*/];
                        }
                        this.isPdfModalOpen = true;
                        return [4 /*yield*/, this.materialDialog.open(pdf_preview_modal_component_1.PdfPreviewModalComponent, {
                                minWidth: '55vw',
                                maxWidth: '100vw',
                                maxHeight: '80vh',
                                panelClass: 'pdf-dialog',
                                data: {
                                    file: file
                                }
                            }).afterClosed().toPromise()];
                    case 1:
                        _a.sent();
                        this.isPdfModalOpen = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.selectFile = function ($event, file, child) {
        child.selected = $event.checked;
        this.updateSelectChildrenStatus(file);
        this.updateSelectAllStatus();
        this.setUploaderEnabledStatus();
    };
    MediaComponent.prototype.selectAllChildren = function ($event, file) {
        file.selected = $event.checked;
        file.children.forEach(function (i) { return i.selected = $event.checked; });
        this.updateSelectAllStatus();
        this.setUploaderEnabledStatus();
    };
    /**
     * Mark the global sensitivity property.  This can be used to mark an entire study as sensitive
     * @param {MatCheckboxChange} $event Event emitted by the checkbox
     */
    MediaComponent.prototype.updateSensitive = function ($event) {
        this.isSensitive = $event.checked;
    };
    /**
     * Mark all files of a specific type as sensitive, should be used with a material checkbox.  This can be used to mark an individual manifest as sensitive
     * @param {MatCheckboxChange} $event Event emitted by the checkbox
     * @param {MediaFile} file
     */
    MediaComponent.prototype.markChildrenAsSensitive = function ($event, file) {
        file.sensitive = $event.checked;
    };
    MediaComponent.prototype.selectAllFiles = function ($event) {
        var _this = this;
        this.allFilesSelected = $event.checked;
        this.files.forEach(function (file) {
            _this.selectAllChildren($event, file);
        });
    };
    MediaComponent.prototype.fileChange = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // run a destroy of old state
                        this.finished = false;
                        this.dataService.showNavigationWarning = true;
                        return [4 /*yield*/, this.fileParseService.parseMediaFolder($event)];
                    case 1:
                        _a.sent();
                        this.files = this.fileParseService.parsedFiles.files;
                        this.fileService.setFileIcons(this.files);
                        this.files.forEach(function (file) {
                            return file.children.forEach(function (child) {
                                return _this.fileService.setBodyPart(child, _this.NotSpecified);
                            });
                        });
                        this.setUploaderEnabledStatus();
                        this.resetDisabled = false;
                        this.logData = new table_1.MatTableDataSource(this.fileParseService.parsedFiles.log);
                        this.logData.paginator = this.paginator;
                        this.fileInput.nativeElement.value = '';
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.dragover = function ($event) {
        this.over = true;
        this.stopAndPrevent($event);
    };
    MediaComponent.prototype.dragleave = function ($event) {
        this.over = false;
        this.stopAndPrevent($event);
    };
    MediaComponent.prototype.drop = function ($event) {
        this.over = false;
        this.fileChange($event.dataTransfer.files);
        this.stopAndPrevent($event);
    };
    MediaComponent.prototype.cancelUpload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var updateObject, len, j, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.continueChain = false;
                        this.cancelDisabled = true;
                        this.inputDisabled = true;
                        this.resetDisabled = false;
                        // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
                        window.parent.postMessage('enable-inputs', location.protocol + "//" + location.hostname + ":8443");
                        // Only allow user to reset page via the reset btn IF there is no error.  Otherwise error will instruct user on how to proceed
                        //  Ex: Unable to connect to pronghorn will instruct user to refresh page/relaunch from EHR
                        if (!this.errorStatus) {
                            this.resetDisabled = false;
                        }
                        updateObject = { expectedFiles: 0 };
                        len = this.manifestList.length;
                        j = 0;
                        _a.label = 1;
                    case 1:
                        if (!(j < len)) return [3 /*break*/, 7];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, 5, 6]);
                        return [4 /*yield*/, this.manifestService.updateManifestByRoute(this.manifestList[j].self, updateObject)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 6];
                    case 5:
                        this.stopStatus();
                        return [7 /*endfinally*/];
                    case 6:
                        j++;
                        return [3 /*break*/, 1];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.selectStudy = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, study, list, type, cancel;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.selectStudyDialog = this.dialogService.open(select_study_dialog_component_1.SelectStudyDialogComponent, {
                            minWidth: '75vw',
                            maxWidth: '600px',
                            position: { top: '0' },
                            panelClass: 'select-study-modal',
                            disableClose: true,
                            data: {
                                allowNew: this.requestSource === inter_app_communication_util_1.RequestSource.Integrated,
                                mode: this.selectionType,
                                selection: this.existingStudy,
                                exams: this.exams
                            }
                        });
                        return [4 /*yield*/, this.selectStudyDialog.afterClosed().toPromise()];
                    case 1:
                        _a = _b.sent(), study = _a.study, list = _a.list, type = _a.type, cancel = _a.cancel;
                        // User selected to close the modal
                        if (cancel) {
                            return [2 /*return*/];
                        }
                        this.studyInstanceUID = '';
                        this.accessionNumber = '';
                        this.selectionType = type;
                        if (list) {
                            this.exams = list;
                        }
                        if (study) {
                            this.studyInstanceUID = study.studyInstanceUID;
                            this.accessionNumber = study.accessionNumber;
                            this.selectedStudyObject = this.dataService.findStudy(this.studies, study.studyDescription, 'label');
                            if (!this.selectedStudyObject.studyDescription) {
                                this.selectedStudyObject = { studyDescription: study.studyDescription };
                            }
                            this.existingStudy = {
                                studyDescription: study.studyDescription,
                                modalities: study.studyModalities,
                                time: study.studyTime
                            };
                        }
                        if (!study) {
                            this.existingStudy = this.NewStudy;
                            this.selectionType = 'study';
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.submitTrigger = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // Check for Disabled
                if (this.uploaderDisabled) {
                    return [2 /*return*/];
                }
                if (!this.validateBodyPartRequired()) {
                    this.errorStatus = this.translate.instant('media.body-part-required');
                    this.invalidFields = true;
                    setTimeout(function () { return _this.errorStatus = ''; }, 5000);
                    return [2 /*return*/];
                }
                if (this.requestSource == inter_app_communication_util_1.RequestSource.Standalone && !this.accessionNumber) {
                    this.errorStatus = this.translate.instant('media.study-required');
                    this.invalidFields = true;
                    setTimeout(function () { return _this.errorStatus = ''; }, 5000);
                    return [2 /*return*/];
                }
                this.manifestList = [];
                try {
                    this.files
                        .filter(function (file) { return file.selected !== false; })
                        .forEach(function (file) {
                        _this.manifestList.push({
                            'studyid': file.UID,
                            'patientid': file.patientID,
                            'imageModifiers': _this.isSensitive ? 'S' : '',
                            accessionNumber: _this.accessionNumber ? _this.accessionNumber : '',
                            useAccessionNumberProvided: _this.selectionType === 'exam'
                        });
                        _this.promiseList[file.UID] = [];
                        file.children
                            .filter(function (child) { return child.selected !== false; })
                            .forEach(function (child) {
                            var _child = child;
                            // Check if body part is Not specified or Not anatomical and send empty string if it is, else send body part name
                            _child.bodyPart =
                                _child.bodyPart.code === _this.NotSpecified.code ||
                                    _child.bodyPart.bodyPart.code === _this.NotAnatomical.bodyPart.code ?
                                    { name: '' } :
                                    _child.bodyPart;
                            _this.promiseList[_child.studyUID].push(_child);
                            if (_this.bodyPartList) {
                                _this.bodyPartList += _child.bodyPart.name ? ", " + _child.bodyPart.name : ''; // Append body parts to list for Session study description
                            }
                            else {
                                _this.bodyPartList = _child.bodyPart.name;
                            }
                        });
                    });
                    this.folderUpload();
                }
                catch (error) {
                    this.currentlyUpdating = false;
                    this.clearAndShowError('error.general', error.message);
                    throw error;
                }
                return [2 /*return*/];
            });
        });
    };
    MediaComponent.prototype.changeSelectedStudy = function ($event) {
        this.selectedStudyObject = this.dataService.findStudy(this.studies, $event.value, 'value');
    };
    // -- PRIVATE -- //
    /**
     * Clean up any items leftover from previous sessions.  This includes but is not limited
     * to cleaning up thumbnail files that were not uploaded to pronghorn if the user refreshes the page
     */
    MediaComponent.prototype.cleanup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var oldThumbnails, thumbnails;
            return __generator(this, function (_a) {
                oldThumbnails = this.window.localStorage.getItem('old_thumbnails');
                if (oldThumbnails) {
                    this.window.localStorage.removeItem('old_thumbnails');
                    thumbnails = oldThumbnails.split(',');
                    this.fileService.deleteRemoteThumbnailSource(thumbnails);
                }
                return [2 /*return*/];
            });
        });
    };
    MediaComponent.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var photoSettings, result, bodyMappings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.params.batchSize = this.params.batchSize > 0 ? this.params.batchSize : 3;
                        this.patient = this.dataService.patient;
                        this.customerConfigs = this.dataService.configSettings.media;
                        this.configureFileParser();
                        photoSettings = this.dataService.configSettings.photo || { bodyMapEnabled: true, bodyPartRequired: false };
                        this.bodyPartEnabled = photoSettings.bodyMapEnabled;
                        this.bodyPartRequired = photoSettings.bodyPartRequired;
                        this.document.body.classList.add(this.customerConfigs.theme);
                        if (this.params.hideNavTabs) {
                            data_service_1.DataService.hideNavTabs.next(this.params.hideNavTabs);
                        }
                        else {
                            data_service_1.DataService.hideNavTabs.next(!this.customerConfigs.name);
                        }
                        if (!this.dataService.hasMinimumPatientInformation) {
                            this.clearAndShowError('error.session');
                            this.inputDisabled = true;
                            return [2 /*return*/];
                        }
                        this.setDefaultServerPostLocation();
                        this.studies = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, this.CURRENT_TAB);
                        result = this.dataService.searchStudies(this.studies, this.params, this.CURRENT_TAB);
                        this.studies = result.studiesFilter;
                        this.suggestedStudies = result.suggested;
                        this.notSuggestedStudies = result.notSuggested;
                        this.setupInitialStudyConfig();
                        this.selectedDestination = this.nuanceDestinations.length > 0 ? this.nuanceDestinations[0].id : undefined;
                        return [4 /*yield*/, this.configService.getBodyParts()];
                    case 1:
                        bodyMappings = (_a.sent()).bodyMappings;
                        this.bodyParts = bodyMappings;
                        this.rollups = bodyMappings.rollups || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.setupInitialStudyConfig = function () {
        var foundStudy = this.dataService.findStudy(this.studies, this.params.studyDescription);
        var suggestedStudy = this.suggestedStudies.length ? this.suggestedStudies[0] : null;
        var defaultStudy = suggestedStudy ? suggestedStudy : this.dataService.findStudy(this.studies, this.customerConfigs.defaultStudyDescription);
        var fallbackStudy = defaultStudy.value ? defaultStudy : this.studies[0];
        var study = foundStudy.value ? foundStudy : fallbackStudy;
        if (this.params.studyDescription) {
            this.selectedStudy = this.params.studyDescription;
            this.selectedStudyObject = {
                code: '-1',
                studyDescription: this.params.studyDescription
            };
        }
        else {
            this.selectedStudy = this.selectedStudyObject && this.selectedStudyObject.value ? this.selectedStudyObject.value : study.value;
            this.selectedStudyObject = study;
        }
    };
    MediaComponent.prototype.configureFileParser = function () {
        this.fileParseService.params = this.params;
        this.fileParseService.customerConfigs = this.dataService.configSettings.media;
        this.fileParseService.parsedUpdate.progressPercentage = 0;
        this.fileParseService.parsedFiles = {
            logType: [0, 0, 0],
            log: [],
            files: []
        };
    };
    MediaComponent.prototype.resetPage = function () {
        // Capture attempt to reset when reset is disabled
        if (this.resetDisabled) {
            return;
        }
        this.errorStatus = '';
        this.cancelDisabled = true;
        this.finished = false;
        this.uploaderDisabled = true;
        this.inputDisabled = false;
        this.resetDisabled = true;
        this.invalidFields = false;
        this.dataService.showNavigationWarning = false;
        this.configureFileParser();
        this.files = [];
        this.exams = [];
        this.studyInstanceUID = '';
        this.accessionNumber = '';
        this.existingStudy = this.NewStudy;
        this.bodyPartList = '';
        this.stopStatus();
        // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
        window.parent.postMessage('enable-inputs', location.protocol + "//" + location.hostname + ":8443");
    };
    MediaComponent.prototype.setUploaderEnabledStatus = function () {
        // strict compare to false because null denotes indeterminate
        var selectedFiles = this.files.filter(function (file) { return file.selected !== false; });
        this.uploaderDisabled = !(selectedFiles.length > 0);
        selectedFiles = [];
    };
    MediaComponent.prototype.updateSelectChildrenStatus = function (file) {
        var allSelected = file.children.every(function (i) { return i.selected; });
        var anySelected = file.children.some(function (i) { return i.selected; });
        var isIndeterminate = !allSelected && anySelected;
        file.selected = isIndeterminate ? null : allSelected;
    };
    MediaComponent.prototype.updateSelectAllStatus = function () {
        var allSelected = this.files.every(function (i) { return i.selected; });
        var anySelected = this.files.some(function (i) { return i.selected !== false; });
        var isIndeterminate = !allSelected && anySelected;
        this.allFilesSelected = isIndeterminate ? null : allSelected;
    };
    MediaComponent.prototype.stopAndPrevent = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
    };
    MediaComponent.prototype.batchTransmit = function (tasks, batch_size, files) {
        return __awaiter(this, void 0, void 0, function () {
            var promise_array, i, task, seriesDescription, promise_batch, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.continueChain) return [3 /*break*/, 6];
                        this.currentlyUpdating = false;
                        // Finished?
                        if (!tasks || tasks.length === 0) {
                            return [2 /*return*/];
                        }
                        promise_array = [];
                        for (i = 0; (i < batch_size) && (i < tasks.length); i++) {
                            try {
                                task = tasks[i];
                                seriesDescription = this.seriesDescriptionArray.shift();
                                promise_array.push(this.fileService.uploadSingleFile(task, files, this.isSensitive, seriesDescription));
                            }
                            catch (error) {
                                console.log(error);
                            }
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 6]);
                        promise_batch = Promise.all(promise_array);
                        return [4 /*yield*/, promise_batch];
                    case 2:
                        _a.sent();
                        tasks.splice(0, batch_size);
                        return [4 /*yield*/, this.batchTransmit(tasks, batch_size, files)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_2 = _a.sent();
                        tasks.splice(0, batch_size);
                        return [4 /*yield*/, this.batchTransmit(tasks, batch_size, files)];
                    case 5:
                        _a.sent();
                        console.log(error_2);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Upload selected files to the current session.
     */
    MediaComponent.prototype.folderUpload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var allowCoerce, sessionSettings, session, nuanceRepoId, _a, studyDescriptionOptions, studyBlock, seriesBlock, isSensitive, i, token, tag, prefix, suffix, literal, ifSensitive, ifEqual, ifExists, tagString, numFileTypes, m, filesOfCurrentType, j, curFile, i, token, tag, prefix, suffix, literal, ifSensitive, ifEqual, ifExists, tagString, numFileTypes, i, filesOfCurrentType, numChildFiles, j, curFile, seriesDescriptionStringForFile, modality, postData, error_3;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
                        window.parent.postMessage('disable-inputs', location.protocol + "//" + location.hostname + ":8443");
                        this.inputDisabled = true;
                        this.uploaderDisabled = true;
                        this.cancelDisabled = false;
                        this.resetDisabled = true;
                        allowCoerce = this.params.demo;
                        if (allowCoerce) {
                            this.params.coerceUIDs = true;
                        }
                        sessionSettings = {
                            appName: 'media',
                            coerce: allowCoerce,
                            coerceUIDs: this.params.coerceUIDs,
                            sourceSite: this.params.sourceSite,
                            prov: this.params.prov,
                            op: this.params.op,
                            demo: this.params.demo
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.sessionService.createSession(this.params, sessionSettings)];
                    case 2:
                        session = _b.sent();
                        this.manifestRoute = session.manifests + '/';
                        nuanceRepoId = this.selectedDestination && this.params.sendToNuance ? this.selectedDestination : undefined;
                        // studyDescriptionFormats.json file parsing
                        _a = this;
                        return [4 /*yield*/, this.configService.getStudyDescriptionFormats()];
                    case 3:
                        // studyDescriptionFormats.json file parsing
                        _a.studyDescriptionFormats = _b.sent();
                        if (this.studyDescriptionFormats) { // if there's a studyDescriptionFormats.json file
                            studyDescriptionOptions = this.studyDescriptionFormats.studyDescriptionOverrides.find(function (desc) { return desc.studyDescription === _this.selectedStudyObject.studyDescription; });
                            studyBlock = void 0;
                            seriesBlock = void 0;
                            if (studyDescriptionOptions) { // if there's an override
                                studyBlock = studyDescriptionOptions.studyDescriptionFormat;
                                seriesBlock = studyDescriptionOptions.seriesDescriptionFormat;
                            }
                            else { // default
                                studyBlock = this.studyDescriptionFormats.studyDescriptionFormat;
                                seriesBlock = this.studyDescriptionFormats.seriesDescriptionFormat;
                            }
                            isSensitive = this.isSensitive;
                            // Work through Study Descriptions tags
                            for (i = 0; i < studyBlock.tokens.length; i++) {
                                token = studyBlock.tokens[i];
                                tag = token.tag;
                                prefix = token.prefix || '';
                                suffix = token.suffix || '';
                                literal = token.literal;
                                ifSensitive = token.ifSensitive;
                                ifEqual = token.ifEqual;
                                ifExists = token.ifExists;
                                if (i === 0) {
                                    this.studyDescriptionString = ''; // initialize
                                }
                                if (literal) {
                                    if (ifSensitive && !isSensitive) {
                                        this.studyDescriptionString = ''; // ignore Sensitive string if not actually sensitive
                                    }
                                    else {
                                        this.studyDescriptionString += literal; // special case - no prefix, suffix, ifEqual, etc
                                    }
                                }
                                else { // tags instead of literals
                                    tagString = this.getTagString(tag);
                                    if (tagString && tagString !== '' && ((ifEqual === this.studyDescriptionString) || (ifExists === tag) || (ifEqual !== '' && !ifExists))) {
                                        this.studyDescriptionString += prefix + tagString + suffix;
                                        this.studyDescriptionString = this.studyDescriptionString.replace('  ', ' ');
                                    }
                                }
                            }
                            // Work through Series Descriptions tags
                            this.seriesDescriptionArray = [];
                            numFileTypes = this.files.length;
                            for (m = 0; m < numFileTypes; m++) {
                                filesOfCurrentType = this.files[m];
                                for (j = 0; j < filesOfCurrentType.children.length; j++) {
                                    this.seriesDescriptionString = ''; // initialize
                                    curFile = filesOfCurrentType.children[j];
                                    // WITS-4594: Ensure that we are only processing files the user has selected
                                    if (!curFile.selected) {
                                        continue;
                                    }
                                    for (i = 0; i < seriesBlock.tokens.length; i++) {
                                        token = seriesBlock.tokens[i];
                                        tag = token.tag;
                                        prefix = token.prefix || '';
                                        suffix = token.suffix || '';
                                        literal = token.literal;
                                        ifSensitive = token.ifSensitive;
                                        ifEqual = token.ifEqual;
                                        ifExists = token.ifExists;
                                        if (literal) {
                                            if (ifSensitive && !isSensitive) {
                                                this.seriesDescriptionString = ''; // ignore Sensitive string if not actually sensitive
                                            }
                                            else {
                                                this.seriesDescriptionString += literal; // special case - no prefix, suffix, ifEqual, etc
                                            }
                                        }
                                        else { // tags instead of literals
                                            tagString = '';
                                            this.currentBodyPart = curFile.bodyPart;
                                            tagString = this.getTagString(tag);
                                            if (tagString && tagString !== '' && ((ifEqual === this.seriesDescriptionString) || (ifExists === tag) || (ifEqual !== '' && !ifExists))) {
                                                this.seriesDescriptionString += prefix + tagString + suffix;
                                                this.seriesDescriptionString = this.seriesDescriptionString.replace('  ', ' ');
                                            }
                                        }
                                    }
                                    this.seriesDescriptionArray.push(this.seriesDescriptionString);
                                }
                            }
                        }
                        else { // default if there's no StudyDescriptionFormats.json file
                            this.seriesDescriptionArray = []; // initialize
                            this.studyDescriptionString = "" + (this.isSensitive ? 'SENSITIVE ' : '') + this.selectedStudyObject.studyDescription + " " + this.bodyPartList.trim();
                            this.studyDescriptionString = this.studyDescriptionString.replace('  ', ' ');
                            numFileTypes = this.files.length;
                            for (i = 0; i < numFileTypes; i++) {
                                filesOfCurrentType = this.files[i];
                                numChildFiles = filesOfCurrentType.children.length;
                                for (j = 0; j < numChildFiles; j++) {
                                    curFile = filesOfCurrentType.children[j];
                                    // WITS-4594: Ensure that we are only processing files the user has selected
                                    if (!curFile.selected) {
                                        continue;
                                    }
                                    seriesDescriptionStringForFile = (this.isSensitive ? 'SENSITIVE' : '') + (curFile.bodyPart.name ? curFile.bodyPart.name : '');
                                    seriesDescriptionStringForFile = seriesDescriptionStringForFile.replace('  ', ' ');
                                    this.seriesDescriptionArray.push(seriesDescriptionStringForFile);
                                }
                            }
                        }
                        modality = this.urlParamService.getDefaultModality(this.params);
                        if (this.selectedStudy.type === 'exam') {
                            modality = this.selectedStudy.studyModalities;
                        }
                        postData = {
                            'session': session.key,
                            'patient.lastName': this.params.lastName,
                            'patient.firstName': this.params.firstName,
                            'patient.middleName': this.params.middleName,
                            'patient.title': this.params.title,
                            'patient.suffix': this.params.suffix,
                            'patient.externalId': this.params.mrn,
                            'patient.dateOfBirth': this.dateFormateService.formatDate(this.params.dob),
                            'patient.gender': this.params.gender,
                            'batchSize': this.BATCH_SIZE,
                            'batch.size': this.BATCH_SIZE,
                            'sendToPACS': this.params.sendToPACS,
                            'sendToNuance': this.params.sendToNuance,
                            'sendToXDSb': this.params.sendToXDSb,
                            'examCode': this.selectedStudyObject.code === '-1' ? '' : this.selectedStudyObject.code,
                            'studyDescription': this.studyDescriptionString,
                            'expectedFiles': 0,
                            'studyStringID': '',
                            'isOverread': false,
                            'reasonForStudy': '',
                            'nuanceRepoId': nuanceRepoId,
                            'nuanceRepoStudyTagIds': this.nuanceStudyTagIds,
                            'xdsPatientId': this.params.xdsPatientId,
                            'xdsSourcePatientId': this.params.xdsSourcePatientId,
                            'imageModifiers': this.isSensitive ? 'S' : '',
                            'studyInstanceUID': this.studyInstanceUID,
                            'accessionNumber': this.accessionNumber,
                            modality: modality,
                        };
                        return [4 /*yield*/, this.createManifest(postData, this.promiseList, 0)];
                    case 4:
                        _b.sent();
                        this.startStatus();
                        return [3 /*break*/, 6];
                    case 5:
                        error_3 = _b.sent();
                        this.errorStatus = this.translate.instant('error.session');
                        throw error_3;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.getTagString = function (tag) {
        var tagString;
        var lang = this.localization;
        switch (tag) {
            case 'DEPARTMENT':
                var dept_1 = this.params.encDept;
                if (dept_1 && this.studyDescriptionFormats.departmentNameRemapping) {
                    var depMatch = this.studyDescriptionFormats.departmentNameRemapping.filter(function (group) { return group.names.find(function (match) { return match === dept_1; }); });
                    if (depMatch[0]) {
                        tagString = depMatch[0].mapTo; // use first match if for some reason there are multiple matches
                    }
                    else {
                        tagString = dept_1;
                    }
                }
                else {
                    tagString = dept_1;
                }
                break;
            case 'SPECIALTY':
                tagString = this.params.specialty; // not actually supported yet
                break;
            case 'STUDY_DESCRIPTION':
                tagString = this.selectedStudyObject.studyDescription;
                break;
            case 'BODY_PART': // this tag only applies to Series Description
                if (this.currentBodyPart && this.currentBodyPart.bodyPart) {
                    var tagStringPreText = '';
                    if (this.currentBodyPart.relatedList && this.currentBodyPart.relatedList.parent && this.currentBodyPart.relatedList.parent[0].prependLabelToSubParts === true) {
                        tagStringPreText = this.currentBodyPart.relatedList.parent[0].localizedStrings[lang].label + ' ';
                    }
                    tagString = tagStringPreText + this.currentBodyPart.bodyPart.localizedStrings[lang].label;
                }
                else {
                    tagString = '';
                }
                break;
            case 'MODIFIERS': // this tag only applies to Series Description
                if (this.currentBodyPart && this.currentBodyPart.modifiers && this.currentBodyPart.modifiers.length !== 0) {
                    var modifierArray = this.currentBodyPart.modifiers.filter(function (mod) { return mod.selected !== ''; });
                    if (modifierArray) {
                        var tagStringArray = [];
                        var _loop_1 = function (k) {
                            var modifier = modifierArray[k];
                            var selectedString = modifier.selected;
                            var selectedCode = modifier.value.find(function (line) { return line.code === selectedString; });
                            if (selectedCode) {
                                tagStringArray.push(selectedCode.localizedStrings[lang].label);
                            }
                        };
                        for (var k = 0; k < modifierArray.length; k++) {
                            _loop_1(k);
                        }
                        if (tagStringArray.length === 1) {
                            tagString = tagStringArray[0];
                        }
                        else {
                            tagString = tagStringArray.join(' ');
                        }
                    }
                }
                else {
                    tagString = '';
                }
                break;
            case 'BODY_PART_ROLLUP': // this tag only applies to Study Description
                var numFileTypes = this.files.length; // could be multiple file types, like PNGs and JPGs
                this.codeArray = [];
                this.nameArray = [];
                this.finalNameArray = [];
                for (var n = 0; n < numFileTypes; n++) {
                    var filesOfCurrentType = this.files[n];
                    var numChildFiles = filesOfCurrentType.children.length; // number of actual image files in current file type (e.g. PNG)
                    if (numFileTypes === 1 && numChildFiles === 1) { // no rollup logic needed if only one file
                        var tagStringPreText = '';
                        if (filesOfCurrentType.children[0].bodyPart.relatedList && filesOfCurrentType.children[0].bodyPart.relatedList.parent && filesOfCurrentType.children[0].bodyPart.relatedList.parent[0].prependLabelToSubParts === true) {
                            tagStringPreText = filesOfCurrentType.children[0].bodyPart.relatedList.parent[0].localizedStrings[lang].label + ' ';
                        }
                        if (filesOfCurrentType.children[0].bodyPart.name === '') {
                            tagString = '';
                        }
                        else {
                            tagString = tagStringPreText + filesOfCurrentType.children[0].bodyPart.bodyPart.localizedStrings[lang].label;
                        }
                        return tagString;
                    }
                    else {
                        for (var i = 0; i < numChildFiles; i++) {
                            if (filesOfCurrentType.children[i].bodyPart.name !== '' && filesOfCurrentType.children[i].selected) {
                                this.codeArray.push(filesOfCurrentType.children[i].bodyPart.bodyPart.code);
                                var tagStringPreText = '';
                                if (filesOfCurrentType.children[i].bodyPart.relatedList && filesOfCurrentType.children[i].bodyPart.relatedList.parent && filesOfCurrentType.children[i].bodyPart.relatedList.parent[0].prependLabelToSubParts === true) {
                                    tagStringPreText = filesOfCurrentType.children[i].bodyPart.relatedList.parent[0].localizedStrings[lang].label + ' ';
                                }
                                this.nameArray.push(tagStringPreText + filesOfCurrentType.children[i].bodyPart.bodyPart.localizedStrings[lang].label);
                            }
                        }
                    }
                }
                if (this.codeArray.length !== 0 && this.nameArray.length !== 0) {
                    for (var j = 0; j < this.rollups.length; j++) {
                        var codeBlock = this.rollups[j].codes;
                        this.matches = []; // clear between each set of rollups
                        var _loop_2 = function (k) {
                            var code = this_1.codeArray[k];
                            if (codeBlock.find(function (line) { return line === code; })) {
                                this_1.matches.push(code);
                            }
                        };
                        var this_1 = this;
                        for (var k = 0; k < this.codeArray.length; k++) {
                            _loop_2(k);
                        }
                        if (this.matches.length > 1 && this.rollups[j] && !(this.matches.every(function (val, i, arr) { return val === arr[0]; }))) {
                            var rollupLabel = this.rollups[j].localizedStrings[lang].label;
                            for (var m = 0; m < this.matches.length; m++) {
                                var match = this.matches[m];
                                var index = this.codeArray.indexOf(match);
                                this.nameArray.splice(index, 1);
                                this.codeArray.splice(index, 1);
                                if (m === this.matches.length - 1) {
                                    this.finalNameArray.push(rollupLabel);
                                }
                            }
                        }
                    }
                }
                // remove duplicates
                var nameArrayUnique = this.nameArray.filter(function (name, index, array) {
                    var firstIndex = array.findIndex(function (item) { return item === name; });
                    return index === firstIndex;
                });
                var finalNameArrayUnique = this.finalNameArray.filter(function (name, index, array) {
                    var firstIndex = array.findIndex(function (item) { return item === name; });
                    return index === firstIndex;
                });
                var allParts = finalNameArrayUnique.concat(nameArrayUnique).sort(); // merge any non-rolled up entries
                tagString = allParts.join(', ');
                break;
        }
        return tagString;
    };
    MediaComponent.prototype.createManifest = function (postData, promiseList, count) {
        return __awaiter(this, void 0, void 0, function () {
            var current, list, manifest, error_4, error_5, totalExpected, j, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        current = this.manifestList[count];
                        if (!(current && this.continueChain)) return [3 /*break*/, 10];
                        postData.expectedFiles = promiseList[current.studyid].length;
                        postData.studyStringID = current.studyid;
                        postData.modality = current.modality ? current.modality : postData.modality;
                        postData.reasonForStudy = current.reasonForStudy;
                        postData.imageModifiers = current.imageModifiers;
                        postData.useAccessionNumberProvided = current.useAccessionNumberProvided;
                        list = this.manifestList[count];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, this.manifestService.createManifest(this.manifestRoute, postData)];
                    case 2:
                        manifest = _a.sent();
                        list.manifestId = manifest.id;
                        list.self = manifest.self;
                        current.files = manifest.files;
                        current.expectedFiles = postData.expectedFiles;
                        current.count = {};
                        count++;
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 7]);
                        this.batchTransmit(promiseList[current.studyid], this.params.batchSize, current.files);
                        return [4 /*yield*/, this.createManifest(postData, promiseList, count)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 5:
                        error_4 = _a.sent();
                        console.log(error_4);
                        return [4 /*yield*/, this.createManifest(postData, promiseList, count)];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        error_5 = _a.sent();
                        this.errorStatus = this.translate.instant('error.manifest');
                        throw error_5;
                    case 9: return [3 /*break*/, 14];
                    case 10:
                        if (!this.continueChain) return [3 /*break*/, 14];
                        totalExpected = 0;
                        for (j = 0; j < this.manifestList.length; j++) {
                            totalExpected = totalExpected + this.manifestList[j].expectedFiles;
                        }
                        _a.label = 11;
                    case 11:
                        _a.trys.push([11, 13, , 14]);
                        return [4 /*yield*/, this.sessionService.updateSession(totalExpected)];
                    case 12:
                        _a.sent();
                        return [3 /*break*/, 14];
                    case 13:
                        error_6 = _a.sent();
                        this.errorStatus = this.translate.instant('error.session');
                        throw error_6;
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.parseResponse = function (response) {
        var _this = this;
        response.forEach(function (status) {
            var countObject = status.transmissionStatusSummary;
            var fileGroup = _this.files.find(function (file) { return file.UID === status.studyStringID; });
            countObject.totalFiles = status.expectedFiles > 0 ? status.expectedFiles : fileGroup.counts.totalFiles;
            countObject.filesReceived = status.filesReceived;
            fileGroup.counts = countObject;
            fileGroup.errors = status.transmissionStatusErrorReasons ? status.transmissionStatusErrorReasons : [];
            fileGroup.progressPercentage = Math.round(((countObject.filesReceived - countObject.PROCESSING) / countObject.totalFiles) * 100);
            fileGroup.bufferPercentage = Math.round((countObject.filesReceived / countObject.totalFiles) * 100);
            fileGroup.progressMode = fileGroup.bufferPercentage > 0 ? 'buffer' : 'determinate';
        });
        if (this.files.filter(function (file) { return file.selected !== false; }).every(function (file) { return file.progressPercentage === 100 && file.counts.SUCCESS === file.counts.totalFiles; })) {
            this.inputDisabled = true;
            this.uploaderDisabled = true;
            this.finished = true;
            this.cancelDisabled = true;
            this.resetDisabled = false;
            this.dataService.showNavigationWarning = false;
            this.stopStatus();
            // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
            window.parent.postMessage('enable-inputs', location.protocol + "//" + location.hostname + ":8443");
        }
        this.addErrorsToLogs();
    };
    MediaComponent.prototype.addErrorsToLogs = function () {
        var _this = this;
        if (!this.files) {
            return;
        }
        var logs = this.files.map(function (i) {
            if (!i.errors) {
                i.errors = [];
            }
            return i.errors.map(function (j) {
                return {
                    name: 'Error',
                    message: j
                };
            });
        }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (i) { return !_this.fileParseService.parsedFiles.log.includes(i); });
        this.logData = new table_1.MatTableDataSource(this.fileParseService.parsedFiles.log.concat(logs));
        this.logData.paginator = this.paginator;
    };
    /**
     * Attempts to fetch the thumbnail file again in the event that an error occurred
     * @param file
     */
    MediaComponent.prototype.retryThumbnailRequest = function (file) {
        var thumbnailFilename = file.thumbnail.getValue();
        console.dir(file);
        if (!thumbnailFilename || thumbnailFilename === 'data:image/png;base64,error') {
            var type = file.type.split('/');
            file.thumbnail.complete();
            file.thumbnail = this.fileParseService.saveImage(file, type[1]);
            this.fileService.getThumbnail(file);
        }
        else {
            file.thumbnail.next(thumbnailFilename);
        }
    };
    MediaComponent.prototype.clearAndShowError = function (error, details) {
        if (error === void 0) { error = 'error.general'; }
        if (details === void 0) { details = ''; }
        this.stopStatus();
        this.errorStatus = this.translate.instant(error) + " - " + details;
    };
    MediaComponent.prototype.startStatus = function () {
        // Don't start a new interval if we are already have one, this prevents memory leaks.
        this.checkStatus();
        var pollingInterval = 3000;
        this.stopInterval = setInterval(this.checkStatus.bind(this), pollingInterval);
    };
    MediaComponent.prototype.stopStatus = function () {
        clearInterval(this.stopInterval);
        this.stopInterval = undefined;
    };
    MediaComponent.prototype.checkStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.currentlyUpdating) {
                            return [2 /*return*/];
                        }
                        // Set to prevent queuing of calls.
                        this.currentlyUpdating = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.manifestService.getStatusManifest(this.manifestRoute)];
                    case 2:
                        response = _a.sent();
                        // Set to allow calls to resume.
                        this.currentlyUpdating = false;
                        if (response.status !== 404) {
                            this.parseResponse(response);
                        }
                        else {
                            // This handles the typical 404 error message after timeout or a hard failure somewhere like server reboot. Server should try 3 times to make this call before it gets caught in the catch below.
                            this.manifestFetchRetryCounter = this.manifestFetchRetryCounter + 1;
                            if (this.manifestFetchRetryCounter >= 3) {
                                this.clearAndShowError('error.timeout');
                            }
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_7 = _a.sent();
                        // If for some reason we get a JS error this handles stopping anything.
                        console.log(error_7);
                        this.currentlyUpdating = false;
                        this.clearAndShowError('error.timeout');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MediaComponent.prototype.setDefaultServerPostLocation = function () {
        this.serverPostLocation = this.customerConfigs.sendToServerDefault;
        this.setServerPostLocation({ value: this.serverPostLocation });
    };
    MediaComponent.prototype.openBodyMap = function (file, childIndex) {
        var _this = this;
        var bodyDialog = this.dialogService.open(body_map_dialog_component_1.BodyMapDialogComponent, {
            maxHeight: 'unset',
            maxWidth: 'unset',
            position: { top: '0' },
            panelClass: 'body-map-modal',
            data: { file: this.bodyParts, bodyPart: file.children[childIndex].bodyPart, fileCount: this.countFilesWithUnspecified(), lang: this.params.lang }
        });
        bodyDialog.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            }
            if (result.bodyPart) {
                file.children[childIndex].bodyPart = result.bodyPart;
            }
            if (result.applyToUnspecified) {
                _this.setUnspecified(file, childIndex);
            }
        });
    };
    MediaComponent.prototype.setNotAnatomical = function (file, childIndex) {
        file.children[childIndex].bodyPart = this.NotAnatomical;
        file.children[childIndex].bodyPart.name = this.translate.instant('media.not-anatomical');
    };
    MediaComponent.prototype.setUnspecified = function (inputFile, childIndex) {
        var bodyPart = inputFile.children[childIndex].bodyPart;
        // Loop over file types
        this.files.forEach(function (fileTypes) {
            // Loop over files
            fileTypes.children.forEach(function (file) {
                if (file.bodyPart.name === '[Not specified]') {
                    file.bodyPart = lodash_1.cloneDeep(bodyPart);
                }
            });
        });
    };
    /**
     * Validates that all images have a body part if the server requires it
     * @returns {Boolean} If the validation is successful
     */
    MediaComponent.prototype.validateBodyPartRequired = function () {
        var _this = this;
        if (!this.bodyPartRequired) {
            return true;
        }
        return this.files.every(function (fileTypes) {
            return fileTypes
                .children
                .filter(function (file) { return file.selected; })
                .every(function (file) {
                return file.bodyPart.code !== _this.NotSpecified.code;
            });
        });
    };
    MediaComponent.prototype.countFilesWithUnspecified = function () {
        var _this = this;
        return this.files
            .map(function (file) { return file.children.filter(function (child) { return child.bodyPart.code === _this.NotSpecified.code; }).length; })
            .reduce(function (acc, cur) { return acc + cur; }, 0);
    };
    MediaComponent.prototype.generateUnspecifiedTooltip = function (childBodyPart) {
        return "Apply " + childBodyPart + " to " + this.countFilesWithUnspecified() + " files";
    };
    return MediaComponent;
}(abstract_uploader_page_component_1.default));
exports.MediaComponent = MediaComponent;
