"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var session_service_1 = require("./session.service");
var url_parameter_service_1 = require("./url-parameter.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./session.service");
var i3 = require("./url-parameter.service");
var DicomQueryRetrieveService = /** @class */ (function () {
    function DicomQueryRetrieveService(http, sessionService, urlParameterService) {
        this.http = http;
        this.sessionService = sessionService;
        this.urlParameterService = urlParameterService;
    }
    DicomQueryRetrieveService.prototype.enableMocks = function () {
        return !environment_1.environment.production || this.urlParameterService.getUrlParameter('demo') === 'true';
    };
    DicomQueryRetrieveService.prototype.enableMocksLocally = function () {
        return !environment_1.environment.production && this.urlParameterService.getUrlParameter('unittest') !== 'y';
    };
    DicomQueryRetrieveService.prototype.patients = function (query) {
        if (this.enableMocksLocally()) {
            var data = {
                status: 200,
                message: '',
                data: [
                    {
                        status: 200,
                        message: '',
                        data: [
                            { birthDate: '03/10/1950', patientId: '123456', patientNameDisplay: 'Smith, Alexandra', sex: 'F', site: 'Saint Luke\'s', archive: 'ohif pacs', }
                        ]
                    },
                    {
                        status: 200,
                        message: '',
                        data: [
                            { birthDate: '03/10/1950', patientId: '987654', patientNameDisplay: 'Smith, Alex', sex: 'F', site: 'Saint John\'s', archive: 'ohif pacs', }
                        ]
                    },
                    {
                        status: 200,
                        message: 'No patients found at University Clinic',
                        data: []
                    }
                ]
            };
            return Promise.resolve(data);
        }
        return this.http.get(this.sessionService.baseUrl + "dicom/patients?" + this.toQueryString(query)).toPromise();
    };
    DicomQueryRetrieveService.prototype.studies = function (query) {
        if (this.enableMocksLocally()) {
            var data = void 0;
            if (query.site.includes('John')) {
                data = {
                    status: 200,
                    message: '',
                    data: [
                        { birthDate: '19500310', patientId: '123457', patientNameDisplay: 'Smith, Alex', sex: 'F', studyDate: '05/11/2018', studyDescription: 'Chest Xray & Chest Xray & Chest Xray & Chest Xray & Chest Xray', studyInstanceUID: '1.2.826.0.1.3680043.11.124', modalitiesInStudy: 'CR', referringPhysicianName: 'Dr Erik Johnson', numberOfStudyRelatedInstances: 2, error: '', archive: '', mrn: '', searchPatientName: '', siteName: '' }
                    ]
                };
            }
            else {
                data = {
                    status: 200,
                    message: '',
                    data: [
                        { birthDate: '19500310', patientId: '123457', patientNameDisplay: 'Smith, Alexandra', sex: 'F', studyDate: '08/26/2009', studyDescription: 'CT of Chest', studyInstanceUID: '1.2.826.0.1.3680043.11.124', modalitiesInStudy: 'CT', referringPhysicianName: 'Dr Beth Miller', numberOfStudyRelatedInstances: 55, error: '', archive: '', mrn: '', searchPatientName: '', siteName: '' }
                    ]
                };
            }
            return Promise.resolve(data);
        }
        return this.http.post(this.sessionService.baseUrl + "dicom/studies?" + this.toQueryString(query), query).toPromise();
    };
    DicomQueryRetrieveService.prototype.queryExistingStudies = function (query) {
        if (this.enableMocksLocally()) {
            var data = void 0;
            data = {
                status: 200,
                message: '',
                data: [
                    {
                        'accessionNumber': 'HSA666000000010',
                        'archive': 'STENTOR_QRP',
                        'birthDate': '19610929',
                        'error': '',
                        'firstName': 'Alex',
                        'instanceNumber': '',
                        'lastName': 'Smith',
                        'middleName': '',
                        'modalitiesInStudy': 'VL',
                        'modalitiesInStudyDisplay': 'VL, CR, MR, HR, PR, SS, JK',
                        'modality': '',
                        'numberOfSeriesRelatedInstances': '',
                        'numberOfStudyRelatedInstances': '1',
                        'numberOfStudyRelatedSeries': '1',
                        'patientId': '6789abc',
                        'referringPhysician': 'Thompson^Sam^^^',
                        'seriesInstanceUID': '',
                        'seriesNumber': '2',
                        'sex': 'M',
                        'sopClassUID': '',
                        'sopInstanceUID': '',
                        'studyDate': '20190507',
                        'studyDateDisplay': 'Jun 20 2005',
                        'studyDescription': 'NM TUMOR IMAGING - OUTSTANDING ISSUES',
                        'studyInstanceUID': '1.2.826.0.1.3680043.9.3574.666.2.19071506'
                    },
                    {
                        'accessionNumber': 'HSA666000000010',
                        'archive': 'STENTOR_QRP',
                        'birthDate': '19610929',
                        'error': '',
                        'firstName': 'Alex',
                        'instanceNumber': '',
                        'lastName': 'Smith',
                        'middleName': '',
                        'modalitiesInStudy': 'VL',
                        'modalitiesInStudyDisplay': 'VL',
                        'modality': '',
                        'numberOfSeriesRelatedInstances': '',
                        'numberOfStudyRelatedInstances': '1',
                        'numberOfStudyRelatedSeries': '1',
                        'patientId': '6789abc',
                        'referringPhysician': 'Thompson^Sammy^^^',
                        'seriesInstanceUID': '',
                        'seriesNumber': '2',
                        'sex': 'M',
                        'sopClassUID': '',
                        'sopInstanceUID': '',
                        'studyDate': '20190507',
                        'studyDateDisplay': 'Jun 20 2005',
                        'studyDescription': 'Ophthalmology Photo',
                        'studyInstanceUID': '1.2.826.0.1.3680043.9.3574.666.2.19071504'
                    }
                ]
            };
            return Promise.resolve(data);
        }
        return this.http.get(this.sessionService.baseUrl + "dicom/queryStudies?" + this.toQueryString(query)).toPromise();
    };
    DicomQueryRetrieveService.prototype.studyExists = function (query) {
        if (this.enableMocksLocally()) {
            return Promise.resolve({ data: { studyExists: query.modalitiesInStudy === 'CT', aeTitles: [] }, message: '', status: 200 });
        }
        return this.http.get(this.sessionService.baseUrl + "dicom/studyExists?" + this.toQueryString(query)).toPromise();
    };
    DicomQueryRetrieveService.prototype.retrieve = function (studyUidsList, query) {
        if (this.enableMocksLocally()) {
            return Promise.resolve({ 'data': [{ 'patientSex': 'M', 'modalitiesInStudy': 'VL', 'patientID': '6789abc', 'studyDescription': 'Ophthalmology Photo', 'studyInstanceUID': '1.2.826.0.1.3680043.11.124', 'mrn': '123', 'siteName': 'Staging', 'archive': 'ohif', 'referringPhysicianName': 'Thompson^Sam^^Dr.', 'error': '', 'birthDate': '19610929', 'numberOfStudyRelatedInstances': '1', 'destinationServer': 'https://dev.imagemovermd.com', 'ehrPatientName': 'Smith, Alex', 'studyDate': '05/07/2019', 'searchPatientName': 'Smith^Alex', 'manifestId': 11, 'selected': true, 'manifest': { id: 11 } }], 'message': '', 'status': 200 });
        }
        return this.http.post(this.sessionService.baseUrl + "dicom/move?" + this.toQueryString(query), {
            studies: studyUidsList,
        }).toPromise();
    };
    DicomQueryRetrieveService.prototype.status = function (manifestId) {
        if (this.enableMocksLocally()) {
            return Promise.resolve({
                status: 200,
                message: '',
                data: { SUCCESS: 1, ERROR: 0, PROCESSING: 3, CANCELLED: 0 }
            });
        }
        return this.http.get(this.sessionService.baseUrl + "dicom/uploadStatus/" + manifestId).toPromise();
    };
    DicomQueryRetrieveService.prototype.sites = function () {
        if (this.enableMocksLocally()) {
            var data = {
                status: 200,
                message: '',
                data: ['Saint Mary\'s', 'Mercy General', 'Saint Luke\'s', 'Saint John\'s', 'University Hospital', 'University Clinic']
            };
            return Promise.resolve(data);
        }
        return this.http.get(this.sessionService.baseUrl + "dicom/sitesLabel").toPromise();
    };
    DicomQueryRetrieveService.prototype.toQueryString = function (paramsObject) {
        return Object
            .keys(paramsObject)
            .map(function (key) { return encodeURIComponent(key) + "=" + encodeURIComponent(paramsObject[key]); })
            .join('&');
    };
    DicomQueryRetrieveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DicomQueryRetrieveService_Factory() { return new DicomQueryRetrieveService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.UrlParameterService)); }, token: DicomQueryRetrieveService, providedIn: "root" });
    return DicomQueryRetrieveService;
}());
exports.DicomQueryRetrieveService = DicomQueryRetrieveService;
