import { Dicom } from './dicom-file';

class Response {
    status: number;
    message: string;
}
export class ArrayResponse<T> extends Response {
    data: T[];
}

export class ObjectResponse<T> extends Response {
    data: T;
}

export class StudyExists {
    studyExists: boolean;
    aeTitles: string[];
}

export class Study {
    studyDescription: string;
    error: string;
    numberOfStudyRelatedInstances: number;
    mrn: string;
    patientId: string;
    birthDate: string;
    studyInstanceUID: string;
    referringPhysician: string;
    studyDate: string;
    studyDateDisplay: string;
    sex: string;
    modalitiesInStudy: string;
    modalitiesInStudyDisplay: string;
    archive: string;
    ehrPatientName: string;
    patientNameDisplay: string;
    searchPatientName: string;
    siteName: string;
    selected: boolean;
    // manifestId: number;
    manifest: Manifest;
    counts: Dicom.Counts;
    progressPercentage: number;
    progressColor = 'primary';
    bufferPercentage: number;
    progressMode: string;
    uploadFailed: boolean;
    studyExists: boolean;
    overread: any;
    reasonForStudy: any;
    loading: boolean;

    static getUID(study: Study): string {
        return `${study.siteName} ${study.studyInstanceUID} ${study.archive} ${study.manifest.id}`;
    }
}

export class Manifest {
    id: number;
}

export class Patient {
    birthDate: string;
    patientId: string;
    name: string;
    patientNameDisplay: string;
    sex: string;
    site: string;
    siteName: string;
    archive: string;
    studies: Study[];
    selected: boolean;
    loadingStudies: boolean;
    error?: string;
}

export class ExistingStudy {
    accessionNumber: string;
    archive: string;
    birthDate: string;
    error: string;
    firstName: string;
    instanceNumber: string;
    lastName: string;
    middleName: string;
    modalitiesInStudy: string;
    modalitiesInStudyDisplay: string;
    modality: string;
    numberOfSeriesRelatedInstances: string;
    numberOfStudyRelatedInstances: string;
    numberOfStudyRelatedSeries: string;
    patientId: string;
    referringPhysician: string;
    seriesInstanceUID: string;
    seriesNumber: string;
    sex: string;
    sopClassUID: string;
    sopInstanceUID: string;
    studyDate: string;
    studyDateDisplay: string;
    studyDescription: string;
    studyInstanceUID: string;
}
