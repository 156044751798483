"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var session_service_1 = require("../services/session.service");
var data_service_1 = require("../services/data.service");
var translate_service_1 = require("../translation/translate.service");
var TransferComponent = /** @class */ (function () {
    function TransferComponent(sessionService, dataService, window, document, router, formBuilder, translate) {
        this.sessionService = sessionService;
        this.dataService = dataService;
        this.window = window;
        this.document = document;
        this.router = router;
        this.formBuilder = formBuilder;
        this.translate = translate;
        this.params = {};
        this.displayInvalidMessage = false;
        this.displayLoading = false;
    }
    TransferComponent.prototype.ngOnInit = function () {
        this.params = this.dataService.setupData(this.params);
        this.createForm();
        this.setup();
    };
    TransferComponent.prototype.setup = function () {
        this.customerConfigs = this.dataService.configSettings.sync;
        this.document.body.classList.add(this.customerConfigs.theme);
    };
    TransferComponent.prototype.createForm = function () {
        this.form = this.formBuilder.group({
            pin1: ['', forms_1.Validators.required],
            pin2: ['', forms_1.Validators.required],
            pin3: ['', forms_1.Validators.required],
            pin4: ['', forms_1.Validators.required]
        });
    };
    TransferComponent.prototype.moveBack = function (e) {
        var input = e.target;
        var previous = input.previousSibling;
        if (e.keyCode === 8) {
            this.form.controls[previous.name].setValue(null);
            this.form.controls[input.name].markAsPristine();
            this.form.controls[previous.name].markAsPristine();
            previous.focus();
            this.displayInvalidMessage = false;
        }
    };
    TransferComponent.prototype.moveNext = function (e) {
        e.preventDefault();
        e.target.nextSibling.focus();
        this.displayInvalidMessage = false;
    };
    TransferComponent.prototype.updateFormInvalid = function (e) {
        var _this = this;
        this.displayInvalidMessage = true;
        Object.keys(this.form.controls).forEach(function (control) {
            _this.form.controls[control].setValue(null);
            _this.form.controls[control].markAsDirty();
        });
    };
    TransferComponent.prototype.submit = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var code, data, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.target.blur();
                        this.displayLoading = true;
                        if (!!this.form.valid) return [3 /*break*/, 1];
                        this.updateFormInvalid(e);
                        return [3 /*break*/, 5];
                    case 1:
                        this.form.disable();
                        code = Object.keys(this.form.value).reduce(function (acc, cur) { return acc += _this.form.value[cur]; }, '');
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.sessionService.retrieveTransfer(code)];
                    case 3:
                        data = _a.sent();
                        data.query.syncWorkflow = !!data.session.iePinCode;
                        data.query.hideNavTabs = this.params.hideNavTabs;
                        window.postData = data.query;
                        this.sessionService.setSession(data.session);
                        this.router.navigate([data.location]);
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        console.error('Error In Transfer', err_1);
                        this.updateFormInvalid(e);
                        return [3 /*break*/, 5];
                    case 5:
                        this.form.enable();
                        this.displayLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return TransferComponent;
}());
exports.TransferComponent = TransferComponent;
