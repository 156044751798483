import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '../../translation/translate.service';
import { Provider } from '../../classes/provider';
import { DataService } from '../../services/data.service';
import { StudyType } from '../../classes/study-type';

@Component({
  selector: 'app-patient-data-confirmation',
  templateUrl: './patient-data-confirmation.component.html',
  styleUrls: ['./patient-data-confirmation.component.scss']
})
export class PatientDataConfirmationComponent implements OnInit {

  studies: Array<any>;
  params;
  patient;
  patientInfoConfig;
  manifestList;
  validation;
  providers: Array<Provider>;
  selectedProvider: Provider;
  specialties: Array<string>;
  selectedSpecialty: string;
  showProviders: boolean;
  submitDisabled: boolean;
  constructor(
    private dialogRef: MatDialogRef<PatientDataConfirmationComponent>,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public translate: TranslateService) { }

  ngOnInit() {
    this.params = this.dataService.setupData(this.params);
    this.patientInfoConfig = [
      { label: 'patient.name', key: 'name', altKey: 'patientName' },
      { label: 'patient.dob', key: 'dobFormat', altKey: 'dob' },
      { label: 'patient.sex', key: 'gender', altKey: 'gender' },
      { label: 'patient.mrn', key: 'mrn', altKey: 'patientID' }
    ];

    this.studies = this.data.studies;
    this.params = this.data.params;
    this.patient = this.data.patient;
    this.manifestList = this.data.manifestList;
    this.validation = this.data.validation;
    this.providers = this.data.providers;

    this.specialties = this.data.specialties;
    this.showProviders = this.providers && this.providers.length > 0 && this.specialties && this.specialties.length > 0 && this.manifestList.some(i => i.overread);
    this.submitDisabled = this.manifestList.some(m => !m.studyDescriptionObject);
    if (this.providers) {
      this.setProvider();
    }
  }

  setProvider() {
    this.selectedProvider = this.providers.find(i => i.id === this.params.prov);
    if (this.selectedProvider) {
      this.filterProviders({ value: this.selectedProvider.specialties[0] } as MatSelectChange);
    }
    this.selectedSpecialty = this.selectedProvider ? this.selectedProvider.specialties[0] : undefined;
  }

  filterProviders($event: MatSelectChange) {
    this.providers = this.data.providers.filter(i => i.specialties.some(j => j === $event.value));
    this.selectedProvider = this.providers.length > 0 ? this.providers[0] : undefined;
  }

  getPatientTooltip(patientInfoConfig) {
    const patientMessage = this.validation[patientInfoConfig.key + 'Message'];
    const altPatientMessage = this.validation[patientInfoConfig.altKey + 'Message'];

    return patientMessage ? patientMessage : altPatientMessage;
  }

  getPatientClass(patientInfoConfig) {
    const patientClass = this.validation[patientInfoConfig.key];
    const altPatientClass = this.validation[patientInfoConfig.altKey];

    return patientClass ? patientClass : altPatientClass;
  }

  getOverreadTooltip(manifest) {
    return manifest.overread ? `${this.translate.instant('validation.overread')} "${manifest.reasonForStudy}"` : '';
  }

  isInPatientInfoConfig(patientInfoConfig) {
    return this.validation[patientInfoConfig.key] === 'validation-error' || this.validation[patientInfoConfig.altKey] === 'validation-error';
  }

  studyChange($event: StudyType, manifest) {
    manifest.studyDescription = $event.label;
    manifest.studyDescriptionValue = $event.value;
    manifest.studyDescriptionObject = $event;
    this.submitDisabled = this.manifestList.some(m => !m.studyDescriptionObject);
  }

  submit() {
    this.dialogRef.close({
      submit: true,
      manifestList: this.manifestList,
      provider: this.selectedProvider
    });
  }

  cancel() {
    this.dialogRef.close({
      submit: false
    });
  }

}
