import { Injectable, Inject } from '@angular/core';

import * as _ from 'lodash';
import { Patient } from '../classes/patient';
import { NameParseService } from './name-parse.service';

@Injectable({
  providedIn: 'root'
})
export class UrlParameterService {

  constructor(
    private nameParseService: NameParseService,
    @Inject('Window') private window: Window
  ) { }

  getUrlParameter(parameterName): string {
    const allParameters = this.getAllClientProvidedParameters();
    return _.get(allParameters, parameterName, '');
  }

  buildParameterContainer(configSettings) {
    let parameters = this.getAllClientProvidedParameters();

    parameters = _.assign(parameters, this.getNameInformation(parameters.name));
    parameters = _.assign(parameters, this.getDateOfBirthInformation(parameters.dob));
    parameters = _.assign(parameters, this.getLanguageInformation(parameters.lang, configSettings));
    parameters = _.assign(parameters, this.getRemappedParameters(parameters));

    return parameters;
  }

  getAllParametersViaUrl() {
    const searchParameters: string = this.window.location.search;

    if (!searchParameters) {
      return '';
    }

    return _.chain(searchParameters)
    .replace('?', '')
    .split('&') // separate query elements
    .map((variable) => _.split(variable, '=')) // break each element into key/value pairs
    .map((pair) => [ decodeURIComponent(pair[0]), decodeURIComponent(pair[1]) ]) // decode special characters
    .reduce((output, param) => { // Join array of objects together
      const [ key, value ] = param
      const [ primaryKey, ...secondaryKeys ] = this.convertKeyStringToArray(key);
      const paramAsObject = this.createParamObject(this.convertKeyStringToArray(key), value);

      // If the property does not exist yet within the returned object, just set it
      if (!output.hasOwnProperty(primaryKey)) {
        return Object.assign(output, paramAsObject);
      }

      // Lodash's setWith function takes an array of square bracketed strings to walk into the object
      const secondaryBracketPath = secondaryKeys.map( key => `[${key}]`).join('');
      // Basically a deep value setter
      _.setWith(output[primaryKey], secondaryBracketPath , value);
      
      return output;
    }, {})
    .value();
  }

  getDefaultModality(params) {
    return params.modality ? params.modality : '';
  }

  getRawParameters() {
    return this.getAllClientProvidedParameters();
  }

  /**
   * Removes square brackets from a string
   * @param string input string to be update
   * @returns string without square brackets
   */
  private rmBrackets(string: string): string {
    return _.replace(string, /(\[|\])/g, '');
  }


  private convertKeyStringToArray(key: string): string[] {
    return key.split('[').map(this.rmBrackets);
  }

  private createParamObject(array, value) {
    const _arr = Array.isArray(array) ? [...array] : [ array ];
    const _item = _arr.shift();
    
    if (_arr.length > 0) {
      return { [_item]: this.createParamObject(_arr, value) }
    }
    
    return { [_item]: value };
  }


  private getAllClientProvidedParameters() {
    const postData = (window as any).postData;
    const urlData = this.getAllParametersViaUrl();

    if (_.isUndefined(postData)) {
      return urlData;
    }

    return Object.assign(urlData, postData);
  }

  private getNameInformation(name) {
    if (_.isUndefined(name) || _.isNull(name)) {
      return {};
    }

    const parsedName = this.nameParseService.parseName(name);

    return {
      lastName: parsedName.lastName,
      firstName: parsedName.firstName,
      middleName: parsedName.middleName,
      title: parsedName.title,
      suffix: parsedName.suffix,
      name: `${parsedName.lastName}, ${parsedName.firstName}${parsedName.middleName ? `, ${parsedName.middleName}` : ''}`
    };
  }

  private getDateOfBirthInformation(dateOfBirth) {
    if (_.isUndefined(dateOfBirth) || _.isNull(dateOfBirth)) {
      return {
        dobFormat: '---'
      };
    }

    return {
      dobFormat: Patient.parseDate(dateOfBirth)
    };
  }

  private getLanguageInformation(language, configSettings) {
    return {
      lang: language || _.get(configSettings, 'language', 'enus')
    };
  }

  private getRemappedParameters(parameters) {
    return {
      gender: parameters.sex ? parameters.sex : '---'
    };
  }
}
