import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoundsRoutingModule } from './rounds-routing.module';
import { RoundsComponent } from './rounds.component';
import { LogoModule } from '../shared/logo/logo.module';
import { FooterModule } from '../shared/footer/footer.module';
import { MatProgressSpinnerModule, MatCardModule } from '@angular/material';
import { QRCodeModule } from 'angular2-qrcode';
import { PatientInformationModule } from '../shared/patient-information/patient-information.module';

@NgModule({
  declarations: [
    RoundsComponent
  ],
  imports: [
    CommonModule,
    RoundsRoutingModule,
    LogoModule,
    FooterModule,
    MatProgressSpinnerModule,
    MatCardModule,
    QRCodeModule,
    PatientInformationModule
  ]
})
export class RoundsModule { }
