"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var translate_service_1 = require("../../translation/translate.service");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
var StudySelectionComponent = /** @class */ (function () {
    function StudySelectionComponent(translate, cdr) {
        this.translate = translate;
        this.cdr = cdr;
        this.selectedStudyChange = new core_1.EventEmitter();
        this.selectionChange = new core_1.EventEmitter();
        this.selectionDisabled = new core_1.EventEmitter();
        this.studyFilter = new forms_1.FormControl();
        this.studySelect = new forms_1.FormControl(undefined, [forms_1.Validators.required]);
    }
    Object.defineProperty(StudySelectionComponent.prototype, "selectedStudy", {
        get: function () {
            return this._selectedStudy;
        },
        set: function (val) {
            this._selectedStudy = val;
            this.studySelect.setValue(this._selectedStudy, {
                emitEvent: false,
                onlySelf: true
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudySelectionComponent.prototype, "disabled", {
        get: function () {
            return this._disabled;
        },
        set: function (val) {
            this._disabled = val;
            if (this._disabled) {
                this.studySelect.disable({
                    emitEvent: false,
                    onlySelf: true
                });
            }
            else {
                this.studySelect.enable({
                    emitEvent: false,
                    onlySelf: true
                });
            }
            this.selectionDisabled.emit(this._disabled);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudySelectionComponent.prototype, "notSuggestedStudies", {
        get: function () {
            return this._notSuggestedStudies;
        },
        set: function (val) {
            this._notSuggestedStudies = val;
            this.filteredNotSuggestedStudies = new rxjs_1.BehaviorSubject(this._notSuggestedStudies);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StudySelectionComponent.prototype, "suggestedStudies", {
        get: function () {
            return this._suggestedStudies;
        },
        set: function (val) {
            this._suggestedStudies = val;
            this.filteredSuggestedStudies = new rxjs_1.BehaviorSubject(this._suggestedStudies);
        },
        enumerable: true,
        configurable: true
    });
    StudySelectionComponent.prototype.filterStudies = function (studies, search) {
        return studies.filter(function (study) { return ("(" + study.code + ") " + study.studyDescription).toLowerCase().includes(search.toLowerCase()); });
    };
    StudySelectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.studySelect.markAsTouched();
        this.filteredNotSuggestedStudies = new rxjs_1.BehaviorSubject(this.notSuggestedStudies);
        this.filteredSuggestedStudies = new rxjs_1.BehaviorSubject(this.suggestedStudies);
        this.suggestedStudies = this.suggestedStudies ? this.suggestedStudies : [];
        this.studyFilter.valueChanges
            .subscribe(function (search) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.filteredSuggestedStudies.next(this.filterStudies(this.suggestedStudies, search));
                this.filteredNotSuggestedStudies.next(this.filterStudies(this.notSuggestedStudies, search));
                this.cdr.markForCheck();
                return [2 /*return*/];
            });
        }); });
        this.studySelect.valueChanges
            .subscribe(function (study) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.selectionChange.emit(study);
                this.cdr.markForCheck();
                return [2 /*return*/];
            });
        }); });
        this.cdr.markForCheck();
    };
    return StudySelectionComponent;
}());
exports.StudySelectionComponent = StudySelectionComponent;
