<h2 mat-dialog-title>Select Body Part</h2>
<mat-dialog-content>
  <section class="direction-btns">
    <button *ngIf="view === Views.Anterior; else  anteriorBtnAlt" mat-raised-button color="primary" (click)="setView(Views.Anterior)">{{ translate.instant('word.anterior') }}</button>
    <button *ngIf="view === Views.Posterior; else  posteriorBtnAlt" mat-raised-button color="primary" (click)="setView(Views.Posterior)">{{ translate.instant('word.posterior') }}</button>
  </section>
  <section class="map">
    <div class="body-maps">
      <div *ngIf="view === Views.Anterior"><ng-container *ngTemplateOutlet="anterior"></ng-container></div>
      <div *ngIf="view === Views.Posterior"><ng-container *ngTemplateOutlet="posterior"></ng-container></div>
    </div>
    <div class="proximity-buttons" >
      <div class="body-parts">
        <button class="parent-body-part" mat-stroked-button color="primary" *ngFor="let parentPart of relatedList.parent" (click)="setBodyPart($event, parentPart)">{{ getLocalizedBodyPartString(parentPart) }}{{ parentPart?.children ? ' >' : '' }}</button>
        <button mat-stroked-button color="primary" *ngFor="let beforePart of relatedList.before" (click)="setBodyPart($event, beforePart)">{{ getLocalizedBodyPartString(beforePart) }}{{ beforePart?.children ? ' >' : '' }}</button>
        <button 
          *ngIf="selectedBodyPart"
          mat-raised-button 
          color="primary" 
          [attr.data-name]="selectedBodyPart.code"
          (click)="setBodyPart($event, selectedBodyPart)"
        >{{ getLocalizedBodyPartString(selectedBodyPart) }}{{ selectedBodyPart?.children ? ' >' : '' }}</button>
        <button mat-stroked-button color="primary" *ngFor="let afterPart of relatedList.after" (click)="setBodyPart($event, afterPart)">{{ getLocalizedBodyPartString(afterPart) }}{{ afterPart?.children ? ' >' : '' }}</button>
        <button class="child-body-part" mat-stroked-button color="primary" *ngFor="let childPart of relatedList.children" [attr.data-name]="childPart.code" (click)="setBodyPart($event, childPart)">{{ getLocalizedBodyPartString(childPart) }}{{ childPart?.children ? ' >' : '' }}</button>
      </div>
      <div class="modifier-tables">
        <form class="modifier-table" *ngFor="let mod of selectedModifiers; index as modIndex">
          <div class="radio-wrapper" *ngFor="let option of mod.value">
            <input type="checkbox" id="{{ option.code }}" name="{{ mod.name }}" [checked]="mod.selected === option.code" (change)="selectModifier($event, modIndex)"  />
            <label for="{{ option.code }}">{{ getLocalizedModifierString(option) }}</label> 
          </div>
        </form>
      </div>
    </div>
  </section>
  <section class="selection">
    <span>Selected:</span>
    <span><strong>{{ selectedBodyPart ? generateBodyPartString() : translate.instant('table.notSpecified') }}</strong></span>
  </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">{{ translate.instant('button.cancel') }}</button>
  <button mat-stroked-button (click)="submit(true)" *ngIf="showUnspecifiedButton">{{ translate.instant('media.apply-to-unspecified') }}</button>
  <button mat-raised-button color="primary" (click)="submit()">{{ translate.instant('word.apply') }}</button>
</mat-dialog-actions>

<ng-template #anteriorBtnAlt>
  <button mat-stroked-button color="primary" (click)="setView(Views.Anterior)">{{ translate.instant('word.anterior') }}</button>
</ng-template>

<ng-template #posteriorBtnAlt>
  <button mat-stroked-button color="primary" (click)="setView(Views.Posterior)">{{ translate.instant('word.posterior') }}</button>
</ng-template>

<ng-template #anterior>
  <svg id="ANTERIOR" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 702 1276" (click)="selectBodyPartFromMap($event)">
    <defs>
      <style>
        .cls-1 {
          fill: #a3cced;
        }
  
        .cls-1, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-2, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
          fill-rule: evenodd;
        }
  
        .cls-2 {
          fill: #a1cdee;
        }
  
        .cls-3 {
          fill: #17a1bf;
        }
  
        .cls-4 {
          fill: #15a1bc;
        }
  
        .cls-5 {
          fill: #bbdce0;
        }
  
        .cls-6 {
          fill: #b8dce0;
        }
  
        .cls-7 {
          fill: #26494f;
        }
  
        .cls-8 {
          fill: #24494f;
        }
  
        .cls-9 {
          fill: #cce7ea;
        }
  
        .cls-10 {
          fill: #cae7ea;
        }
  
        .cls-11 {
          fill: #7188c3;
        }
  
        .cls-12 {
          fill: #6f87c3;
        }
  
        .cls-13 {
          fill: #0b7c88;
        }
  
        .cls-14 {
          fill: #0a7b85;
        }
  
        .cls-15 {
          fill: #71cad2;
        }
  
        .cls-16 {
          fill: #6dcad2;
        }
  
        .cls-17 {
          fill: #d2eceb;
        }
  
        .cls-18 {
          fill: #cfebea;
        }
  
        .cls-19 {
          fill: #17597b;
        }
  
        .cls-20 {
          fill: #16587a;
        }
  
        .cls-21 {
          fill: #abd9e5;
        }
  
        .cls-22 {
          fill: #a7d9e4;
        }
  
        .cls-23 {
          fill: #284556;
        }
  
        .cls-24 {
          fill: #243f91;
        }
  
        .cls-25 {
          fill: #15a1bf;
        }
  
        .cls-26 {
          fill: #c2e8f3;
        }
  
        .cls-27 {
          fill: #075282;
        }
  
        .cls-28 {
          fill: #f0eaf3;
        }
      </style>
    </defs>
    <path id="RIGHT_HAND" data-name="RIGHT HAND" class="cls-1" d="M66,614c8.605,0.7,37.739,8.6,42,15,8.268,12.413-4.466,41.421-8,51-14.107,38.236-13.326,81.948-64,64-6.382-23.359,2.187-55.75,4-84-10.65,8.62-13.637,17.039-29,22-4.83-1.587-8.082-2.6-11-6v-3c22.6-11.6,25.493-35.382,44-49Z"/>
    <path id="LEFT_HAND" data-name="LEFT HAND" class="cls-2" d="M593,614c30.679,3,38.491,30.639,54,49l17,11-3,5-14,2c-11.169-5.511-15.046-14.8-24-22,1.713,27.959,21.7,83.436-8,91-37.485,9.546-66.9-89.245-60-121C565.312,622.44,582.6,620.845,593,614Z"/>
    <path id="RIGHT_WRIST" data-name="RIGHT WRIST" class="cls-3" d="M76,591c8.379,0,41.3,12.423,45,17-3.977,9.286-6.712,17.395-12,30h-1c-2.159-13.437-30.368-21.261-44-22C66.528,608.679,72.253,599.138,76,591Z"/>
    <path id="LEFT_WRIST" data-name="LEFT WRIST" class="cls-4" d="M579,591l10,2c1.424,9.442,5.453,15.85,10,22v1c-18.362,1.047-33.545,12.645-46,19-0.368-12.548-6.22-18.9-11-27C549.38,599.443,569.608,597.2,579,591Z"/>
    <path id="RIGHT_FOREARM" data-name="RIGHT FOREARM" class="cls-5" d="M127,454c6.236,18.577,14.036,46.715,45,37l18-10c-19.456,54.013-46.333,86.813-69,127-8.889-5.96-34.446-17.863-46-15C86.631,538.208,98.041,491.362,127,454Z"/>
    <path id="LEFT_FOREARM" data-name="LEFT FOREARM" class="cls-6" d="M535,454c32.153,24.776,44.891,91.921,53,139-7.463-2.709-45.067,15.263-46,13-28.629-25.679-49.9-80.838-69-125l1-2C497.065,500.746,536.474,489.781,535,454Z"/>
    <path id="RIGHT_ELBOW" data-name="RIGHT ELBOW" class="cls-7" d="M140,421c20.961,6.93,27.852,17.049,58,17l1,2c-3.347,9.38-8.727,45.659-17,47-10.53,15.012-40.864,3.479-48-6C121.548,464.459,130.547,439.037,140,421Z"/>
    <path id="LEFT_ELBOW" data-name="LEFT ELBOW" class="cls-8" d="M521,421l4,1c1.561,15.832,14.788,28.129,12,42-5.125,25.5-45.486,42.126-64,17,3.887-13.494-4.786-31.269-9-41l1-2C492.358,438.778,506.7,432.753,521,421Z"/>
    <path id="RIGHT_UPPER_ARM" data-name="RIGHT UPPER ARM" class="cls-9" d="M197,300c3.169,3.507,2.118,11.163,4,16,20.972,53.9,12.769,70.7-2,125-6.437-3.568-20.043-.673-28-3l-27-15c-3.674-1.308-3.29-.221-6,1-0.444-33.232,8.537-54.036,11-80C170.571,341.178,190.156,317.879,197,300Z"/>
    <path id="LEFT_UPPER_ARM" data-name="LEFT UPPER ARM" class="cls-10" d="M465,302c16.436,12.193,23.121,42.283,49,42,1.9,25.619,10.789,46.736,11,80-2.693-1.274-2.351-2.312-6-1l-27,15c-9.709,3.009-21.538-1.286-28,3-4.364-23.957-18.527-47.587-17-76C448.371,339.476,463.14,325.661,465,302Z"/>
    <path id="RIGHT_SHOULDER" data-name="RIGHT SHOULDER" class="cls-11" d="M253,205h3c-0.375,48.616-31.08,55.761-50,84-14.4,21.491-24.725,54.274-57,57v-1c13.77-45.984,5.631-91.848,41-115C209.679,217.119,237.3,221.108,253,205Z"/>
    <path id="LEFT_SHOULDER" data-name="LEFT SHOULDER" class="cls-12" d="M408,205l35,18,29,7c34.338,21.6,32.289,69.094,42,116h-6c-28.255-13.672-34.675-33.766-52-58C436.284,260.421,407.873,254.449,408,205Z"/>
    <path id="RIGHT_FOOT" data-name="RIGHT FOOT" class="cls-13" d="M314,1184h2q-3.5,11.505-7,23,1.5,17.505,3,35c-4.031,16.84-21.231,40.55-48,34-46.7-11.43-5.023-60.46,2-89h2C274.474,1204.9,308.926,1199.31,314,1184Z"/>
    <path id="LEFT_FOOT" data-name="LEFT FOOT" class="cls-14" d="M347,1186c16.187,6.73,32.664,24.17,49,0h1v2q3.5,11.505,7,23c8.719,20.47,37.821,55.07-5,65-26.763,6.2-44.479-17.4-48-35q1.5-10.995,3-22C356.436,1205.59,350.291,1194.87,347,1186Z"/>
    <path id="RIGHT_ANKLE" data-name="RIGHT ANKLE" class="cls-15" d="M300,1145c3,0.67,6,1.33,9,2,1.274,12.77,13.5,31.27,5,42-7.824,17.44-36.906,10.51-48,2,0.156-12.02.023-34.39,4-43C280.734,1148.36,293.126,1148.73,300,1145Z"/>
    <path id="LEFT_ANKLE" data-name="LEFT ANKLE" class="cls-16" d="M356,1145q19.5,1.995,39,4c0.34,16.5,3.987,37.01-2,46C345.187,1214.08,340.382,1179.32,356,1145Z"/>
    <path id="RIGHT_LOWER_LEG" data-name="RIGHT LOWER LEG" class="cls-17" d="M241,920c6.945,11.725,6.538,29.309,13,40,21.636,5.848,42.683-16,47-33h1q6,30.5,12,61c4.96,29.48-7.88,58.87-8,91q1,36.495,2,73c-5.114-6.26-28.894-3.84-40,0-0.009-24.82-9.7-39.35-16-58q-9.5-36.495-19-73c-3.892-18.28-8.167-48.392-3-69C232.946,940.252,240.454,934.866,241,920Z"/>
    <path id="LEFT_LOWER_LEG" data-name="LEFT LOWER LEG" class="cls-18" d="M421,921c29.218,61.912-.022,146.87-21,201l-6,30c-9.583-4.52-31.612-4.79-40-3,11.195-38.73,1.42-101.73-5-137-4.192-23.035,6.239-68.392,13-85v2c9.89,12.816,25.991,42.692,49,29C414.953,946.708,418.9,935,421,921Z"/>
    <path id="RIGHT_KNEE" data-name="RIGHT KNEE" class="cls-19" d="M266,889c63.33-2.605,32.01,80.778-11,72-6.344-8.64-22.285-46.12-12-59C247.549,892.212,257.881,893.422,266,889Z"/>
    <path id="LEFT_KNEE" data-name="LEFT KNEE" class="cls-20" d="M384,889c17.3,1.3,32.105.619,37,14,8.979,15.531-6.863,51.421-15,59-24.695.823-56.518-31.312-44-56C366.584,896.96,374.914,894.97,384,889Z"/>
    <path id="RIGHT_THIGH" data-name="RIGHT THIGH" class="cls-21" d="M255,646c9.461,3.613,13.569,15.97,21,22,18.239,14.8,29.4,26.011,53,37-5.35,13.97-1.288,33.385-4,51q-5.5,41.5-11,83c-5.267,33.324,5.312,64.553-12,84,0.622-40.206-61.149-42.833-60-7l-2-1-4-16V854c-9.523-34.4-43.413-110.443-23-160h1q-0.5,2.5-1,5C233.073,689.633,254.426,674.649,255,646Z"/>
    <path id="LEFT_THIGH" data-name="LEFT THIGH" class="cls-22" d="M406,643h3c-0.592,43.531,35.9,43.754,42,57q1,13,2,26c8.423,55.079-19.188,92.8-27,134-3.805,20.066,6.371,42.74-5,56,1.041-36.574-59.747-32.874-60,7l-2-1-10-27q0.5-21.5,1-43-6-46-12-92c-3.3-19.006,0-39.852-4-56C352,702.517,399.628,657.053,406,643Z"/>
    <path id="GROIN" data-name="GROIN" class="cls-23" d="M239,596H425c-7.006,37.7-54.052,102.352-90,110C296.824,714.122,242.8,629.421,239,596Z"/>
    <path id="RIGHT_HIP" data-name="RIGHT HIP" class="cls-24" d="M227,558l9,30,19,38c14.538,43.624-22.192,66.411-43,74q4-30,8-60C224.552,610.665,219.8,582.48,227,558Z"/>
    <path id="LEFT_HIP" data-name="LEFT HIP" class="cls-24" d="M435,559c4.657,4.434,15.742,125.156,16,141l-2-1c-7.259-.317-11.65-4.9-16-9-8.878-8.364-14.947-11.96-21-20C387.636,637.639,434.642,592.32,435,559Z"/>
    <path id="WAIST" data-name="WAIST" class="cls-25" d="M435,544c1.9,19.726-.229,41.494-12,53H239c-6.114-18.974-12.244-29.758-10-53H435Z"/>
    <path id="ABDOMEN" data-name="ABDOMEN" class="cls-26" d="M327,365c28.6,0.053,81.011,63.832,106,81-11.808,28.782-8.577,68.577,2,99H229c12.785-39.811,12.247-61.2,2-99C263.273,434.354,293.009,384.515,327,365Z"/>
    <path id="CHEST" data-name="CHEST" class="cls-27" d="M260,201c37.469,18.283,61.106,44.206,115,17,8.652-4.367,19.749-8.36,24-17l11,5c-9.513,42.69,37.5,62.093,52,87,9.408,16.161-6.342,35.734-11,51-5.191,17.014,3.887,37.567,0,52l-19,50c-27.129-11.435-76.285-87.6-107-80-30.013,7.421-61.012,71.293-94,80-5.841-15.258-15.556-36.115-19-50-3.208-12.934,6.1-31.8,2-47-3.813-14.145-19.4-32.948-16-50,4.2-21.075,31.523-33.68,43-49,9.566-12.769,7.545-30.826,15-47Z"/>
    <path id="NECK" data-name="NECK" class="cls-28" d="M371,168h3c1.378,19.309,15.1,23.437,26,33l-5,6c-38.184,17.564-68.865,35.362-114,8-7.319-4.437-15.24-7.062-19-15,12.955-4.089,28.485-12.94,29-30l18,14C335.524,193.9,361.837,180.482,371,168Z"/>
    <path id="HEAD" data-name="HEAD" class="cls-26" d="M324,1c35.566-.595,64.279,15.442,70,44V87c3.659-.026,4.472-0.6,7,3,10.835,14.357-3.579,40.485-7,54-4.44.266-7.565-.245-10,2-12.219,11.654-8.994,35.485-31,41-38.386,9.619-67.379-12.057-77-32q-0.5-6.5-1-13l-9-3c-6.024-5.9-12.722-34.343-11-43,1.732-8.711,8.566-8.742,12-15q0.5-16.5,1-33C274.754,18.128,297.326,12,324,1Z"/>
  </svg>
</ng-template>

<ng-template #posterior>
  <svg id="POSTERIOR" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 702 1276" (click)="selectBodyPartFromMap($event)">
  <defs>
    <style>
      .cls-1 {
        fill: #117692;
      }

      .cls-1, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-17, .cls-18, .cls-19, .cls-2, .cls-20, .cls-21, .cls-22, .cls-23, .cls-24, .cls-25, .cls-26, .cls-27, .cls-28, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        fill-rule: evenodd;
      }

      .cls-2 {
        fill: #c5dced;
      }

      .cls-3 {
        fill: #c8dcec;
      }

      .cls-4 {
        fill: #1591a6;
      }

      .cls-5 {
        fill: #1591a8;
      }

      .cls-6 {
        fill: #cfe7e7;
      }

      .cls-7 {
        fill: #cfe9e9;
      }

      .cls-8 {
        fill: #247861;
      }

      .cls-9 {
        fill: #17616e;
      }

      .cls-10 {
        fill: #185d6d;
      }

      .cls-11 {
        fill: #b1dae0;
      }

      .cls-12 {
        fill: #b0dae0;
      }

      .cls-13 {
        fill: #0b6d6c;
      }

      .cls-14 {
        fill: #0c706c;
      }

      .cls-15 {
        fill: #c9e9ed;
      }

      .cls-16 {
        fill: #caeaee;
      }

      .cls-17 {
        fill: #2b2a53;
      }

      .cls-18 {
        fill: #2e2a53;
      }

      .cls-19 {
        fill: #cedfe7;
      }

      .cls-20 {
        fill: #d1e9e7;
      }

      .cls-21 {
        fill: #165a81;
      }

      .cls-22 {
        fill: #a8dde0;
      }

      .cls-23 {
        fill: #a9dddf;
      }

      .cls-24 {
        fill: #1aa1be;
      }

      .cls-25 {
        fill: #334556;
      }

      .cls-26 {
        fill: #0a785e;
      }

      .cls-27 {
        fill: #12b5b4;
      }

      .cls-28 {
        fill: #1d6d81;
      }
    </style>
  </defs>
  <path id="POSTERIOR_RIGHT_FOOT" data-orientation="POSTERIOR" data-name="RIGHT FOOT" class="cls-1" d="M341,1186c9.821,16.75,40.8,30.22,49,2q3.5,10.995,7,22c9.353,21.92,34.756,55.59-8,66-25.617,6.24-43.549-17.3-47-34q1.5-17.505,3-35Q343,1196.5,341,1186Z"/>
  <path id="POSTERIOR_LEFT_FOOD" data-orientation="POSTERIOR" data-name="LEFT FOOD" class="cls-1" d="M310,1186c4.493,5.12-4.389,20.3-3,32,2.9,24.43-4.941,67.6-44,58-48.442-11.9-9.041-59.75-2-88C271.379,1205.23,304.205,1213.81,310,1186Z"/>
  <path id="RIGHT_ANKLE" data-name="RIGHT ANKLE" class="cls-2" d="M351,1146c14.645-.26,25.177,2.27,37,5,1.048,25.55,8.259,57.97-22,55C331.538,1202.62,342.976,1168.63,351,1146Z"/>
  <path id="LEFT_ANKLE" data-name="LEFT ANKLE" class="cls-3" d="M287,1146c6.209-.23,11.4.24,15,2,2.653,21.34,21.093,49.64-10,57-28.139,6.66-31.171-15.37-28-34,1.187-6.97-1.844-15.93,2-20Q276.5,1148.495,287,1146Z"/>
  <path id="RIGHT_LOWER_LEG" data-name="RIGHT LOWER LEG" class="cls-4" d="M364,957c18.616,0.157,43.24,8.631,56,3v2c30.747,62.2-31.4,130.06-32,190-10.738-3.43-25.234-6.25-37-5v-6c12.193-43.08-5.835-96.2-12-133q2-24.5,4-49C348.1,960.42,358.937,959.422,364,957Z"/>
  <path id="LEFT_LOWER_LEG" data-name="LEFT LOWER LEG" class="cls-5" d="M269,957l40,2q2,25.5,4,51c-7.5,40.99-15.4,82.78-12,137-11.468-1.25-26.506,1.5-37,5-0.128-25.16-9.881-41.49-17-60-15.208-39.54-29.035-86.42-15-132C239.063,963.441,260.789,960.8,269,957Z"/>
  <path id="RIGHT_KNEE" data-name="RIGHT KNEE" class="cls-6" d="M381,884c10.919,1.838,27.754,2.014,36,7-0.438,24.856-.443,47.942,3,70l-3,1-30-4c-15.6-2.523-30.419,1.8-43,3-1.891-10.044,4.247-20.555,6-30,3.019-16.263-4.788-29.3-9-40l2-3Z"/>
  <path id="LEFT_KNEE" data-name="LEFT KNEE" class="cls-7" d="M262,885c15.477-.35,37.363-0.491,49,5l-1,1-9,32c-2.439,12.23,9.262,24.268,7,38-12.562-1.27-27.413-5.5-43-3-10.123,1.622-22.264,5.314-32,4-0.279-1.163,5-73,5-73Z"/>
  <path id="RIGHT_THIGH" data-name="RIGHT THIGH" class="cls-8" d="M327,706v10l1,3v9l1,8,1,4v7l1,3v5l1,3v4l1,3v3l1,3v3l1,3v3l1,3v3l1,3v56l1,7v11l1,5v6l1,4v6s0.688,3.1,1,3,0,3,0,3h1l1-1h3l2-1h5l12-1,7-1,6-1h7l5,1,7,1,9,1,7,1h2l1,1,1-6V860l1-3v-4l1-2v-2l1-2v-2l1-2v-2l1-2v-3l1-1v-3l1-1v-2l1-2v-1l1-2v-2l1-1v-2l1-2v-2l1-2v-2l1-2v-2l1-2v-3l1-2v-3l1-2v-4l1-2v-3l1-2v-4l1-2v-4l1-2v-5l1-3v-5l1-4v-7l1-5V711l-1-3v-3l-1,2-2,2-2,2-1,1-2,1-1,1-1,1-4,2-6,3s-3,.9-3,1-6,2-6,2l-3,1-4,1-6,1-7,1H373l-8-1-5-1-4-1-3-1-3-1s-2.729-.992-3-1a14.537,14.537,0,0,1-2-1s-1.6-.812-2-1-5-2-5-2l-2-2-3-2-3-3-1-2Z"/>
  <path id="LEFT_THIGH" data-name="LEFT THIGH" class="cls-8" d="M325,708v6l-1,3s0.038,5.8,0,6-1,4-1,4,0.092,6.015,0,6-1,4-1,4v7l-1,4v5l-1,4v4l-1,2v4l-1,2v4l-1,2v4l-1,2v4l-1,2v6l-1,5v15l1,9v18l-1,8v11l-1,6v7l-1,4v5l-1,4v4h-3l-6-1h-4l-9-1h-4l-5-1-5-1h-8l-5,1-7,1s-9.211,1.033-9,1-7,1-7,1l-2,1a3.458,3.458,0,0,1-1,1c-0.289.017-1,0-1,0V863l-1-5v-4l-1-1-1-4-1-5-1-4-1-4-1-4-1-4-1-3-1-4-1-3-1-4-1-4-1-4-1-5-1-5s-1.152-5.012-1-5-1-6-1-6l-1-6-1-6-1-7-1-8-1-11V705l1-2h1l1,2,1,2,2,2,2,2,1,1,2,2,2,1,3,2,4,2,4,2,4,1,6,2,6,1,6,1,5,1,13,1,7-1,8-1,5-1s2.815-1.2,3-1,6-2,6-2l3-1,2-1,2-2,3-3,3-2a12.38,12.38,0,0,1,2-2C320.4,710.9,325,708,325,708Z"/>
  <path id="POSTERIOR_RIGHT_HAND" data-orientation="POSTERIOR" data-name="RIGHT HAND" class="cls-9" d="M584,618c31.147,0.86,35.267,23.841,49,42,4.58,6.056,12.76,8.706,15,17l-3,2c-15.773,6.289-19.871-16.065-30-21v2c-3.515,9.444,1.564,20.522,3,28,6.807,35.452,4.424,55.542-22,64-24.12-8.981-56.469-84.128-48-119C559.326,625.235,574.627,625.957,584,618Z"/>
  <path id="POSTERIOR_LEFT_HAND" data-orientation="POSTERIOR" data-name="LEFT HAND" class="cls-10" d="M59,618c11.876-.328,15.761,3.849,22,7,7.786,3.932,18.931-.75,24,10,14.928,31.66-32.634,100.066-42,115-53.032,11.933-17.969-65.007-27-92l-2,1c-5.441,10.278-11.623,21.026-28,21l-2-3-1-4c17.064-7.888,20.367-25.664,31-40C39.935,625,52.458,625.164,59,618Z"/>
  <path id="RIGHT_WRIST" data-name="RIGHT WRIST" class="cls-11" d="M574,594l7,1c3.566,9.626,8.3,16.943,12,25-15.681-1.764-38.327,8.494-46,22h-1c-1.672-13.735-7.583-21.123-10-32l3-2C549.173,601.287,564.07,599.778,574,594Z"/>
  <path id="LEFT_WRIST" data-name="LEFT WRIST" class="cls-12" d="M72,594c7.246,0.234,40.668,12.887,44,17-2.98,8.557-6.4,21.129-11,31-12.779-19.747-28.258-15.725-46-23l5-5Z"/>
  <path id="RIGHT_FOREARM" data-name="RIGHT FOREARM" class="cls-13" d="M528,458c25.389,12.838,46.531,102.777,53,138-6.694-1.816-40.768,11.334-45,16q-0.5-1-1-2l-16-22-34-38c-8.909-17.51-13.016-39.689-19-62,26.691,13.46,63.459,14.65,64-26Q529,460,528,458Z"/>
  <path id="LEFT_FOREARM" data-name="LEFT FOREARM" class="cls-14" d="M122,458c8.376,36.9,26.563,53.431,64,29-7.456,30.9-11.991,55.451-29,76l-20,20-22,29c-2.465-4.529-38.159-17.484-44-16C75.848,548.65,98.552,488.607,122,458Z"/>
  <path id="RIGHT_ELBOW" data-name="RIGHT ELBOW" class="cls-15" d="M515,427h3c1.261,15.5,7.834,24.794,13,36-2.276,26.753-35.155,49.339-61,29-8.306-6.537-12.155-37.1-16-47C481.812,445.808,500.815,439.71,515,427Z"/>
  <path id="LEFT_ELBOW" data-name="LEFT ELBOW" class="cls-16" d="M134,427c21.741,5.868,30.922,17.927,64,18-4.853,11.629-8.273,42.674-19,49-22.772,13.43-64.441-3.508-58-32C123.432,451.241,131.394,441.455,134,427Z"/>
  <path id="RIGHT_UPPER_ARM" data-name="RIGHT UPPER ARM" class="cls-17" d="M425,273c24.042,11.064,54.435,80.78,79,78q4.5,17,9,34,2.5,21.5,5,43l-34,16-30,2q-9-36-18-72,0.5-28,1-56Q431,295.5,425,273Z"/>
  <path id="LEFT_UPPER_ARM" data-name="LEFT UPPER ARM" class="cls-18" d="M225,273c4.212,5.693-8.193,31.628-10,42v68l-17,63-30-2-34-16c3.889-28.023,8.176-53.354,14-77C163.273,352.738,212.684,286.622,225,273Z"/>
  <path id="RIGHT_SHOULDER" data-name="RIGHT SHOULDER" class="cls-19" d="M400,206l27,16c69.331,30.988,64.346,45.551,77,130h-4c-26.619-14.305-38.87-42.03-57-65-10.914-13.828-31.513-19.681-41-35C394.9,240.528,392.086,218.457,400,206Z"/>
  <path id="LEFT_SHOULDER" data-name="LEFT SHOULDER" class="cls-20" d="M250,206l4,2c2.759,10.522,4.349,25.1,0,36-7.071,17.715-26.084,26.875-40,38-19.784,15.816-36.454,66.551-66,70v-3c3.758-11.846,1.5-25.452,4-40,3.865-22.5,11.076-45.01,23-60C191.984,227.649,231.012,225.61,250,206Z"/>
  <path id="BUTTOCKS" data-name="BUTTOCKS" class="cls-21" d="M275,566c32.086-.166,32.923,9.439,51,17l3-2c8-4.8,15.367-10.287,26-13,23.9-6.1,46.066,4.036,62,9-3.766,25.033-3.971,51.908,5,74,5.278,13,25.514,34.42,17,53-13.186,28.776-98.707,32.275-112,1l-1,2-17,14c-31.069,13.721-91.481,5.876-97-23-3.517-18.4,15.283-36.851,20-50,8.026-22.374,6.65-46.71,4-71C246.669,573.727,262.575,571.275,275,566Z"/>
  <path id="RIGHT_HIP" data-name="RIGHT HIP" class="cls-22" d="M419,540c10.56,11.642,18.242,89.585,22,113,1.694,10.554,3.03,33.2-3,37-4.55-25.9-19.625-42.6-25-71C407.381,589.309,420.932,569.265,419,540Z"/>
  <path id="LEFT_HIP" data-name="LEFT HIP" class="cls-23" d="M231,542c4.785,5.336,2.164,23.426,4,31,10.366,42.76.089,64.747-14,97l-8,21h-1C206.347,635.483,226.546,589.651,231,542Z"/>
  <path id="WAIST" data-name="WAIST" class="cls-24" d="M233,541H420c1.494,14.117-.872,26.875-3,39h-1c-17.845-8.913-48.923-16.141-72-6l-19,13c-13.532-29.223-65.8-16.524-89-7C233.99,568.122,231.131,555.062,233,541Z"/>
  <path id="LOWER_BACK" data-name="LOWER BACK" class="cls-25" d="M224,419c9.771,4.38,15.858,15.4,27,20,14.533,6,116.365,11.279,139,5,15.929-4.418,20.886-18.151,33-24-11.817,34.873-7.354,84.433-2,122l-1,1-188-1v-6C242.556,499.651,234.923,450.036,224,419Z"/>
  <path id="UPPER_BACK" data-name="UPPER BACK" class="cls-26" d="M261,201c11,6.913,16.763,19.262,30,25,31.112,13.486,85.575,2.323,98-23l11,3c-21.192,36.844,14.243,50.368,27,67,17.3,22.559,5.116,54.591,10,87q1,9.5,2,19c-7.073,19.735-17.034,52-35,62-10.714,5.963-113.54,9.3-133,6-12.509-2.119-27.014-7.568-36-14-24.39-17.457-30.095-131.189-10-160l24-20c6.018-8.438,13.983-38.061,2-46Z"/>
  <path id="NECK" data-name="NECK" class="cls-27" d="M279,133c17.945,12.466,18.109,27.085,51,27,6.7-.017,13.9.625,18-2l21-23c-5.671,51.6-11.331,43.075,23,69-22.376,30.663-119.483,47.561-131-3h2C288.852,175.953,281.823,174.28,279,133Z"/>
  <path id="HEAD" data-name="HEAD" class="cls-28" d="M320,1c49.619-1.069,61.342,26.272,60,75,4.346-.006,5.82.135,8,5,5.939,11.209-3.108,34.621-8,43-4.854.572-6.422-.579-9,3-10.609,22.8-12,35.007-46,35l-22-2-24-23q-0.5-7-1-14l-10-5c-6.946-11.384-14.645-41.439,3-45C270.093,22.472,285.558,15.69,320,1Z"/>
</svg>
</ng-template>