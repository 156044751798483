<div>
  <h2>{{translate.instant('phrase.search')}}</h2>

  <div class="field">
    <mat-form-field appearance="standard">
      <mat-label>{{translate.instant('patient.firstName')}}</mat-label>
      <input matInput [value]="patient?.firstName" (input)="updateFormData($event, 'firstName')" />
      <mat-checkbox matSuffix color="primary" [checked]="formState.hasFirstName"
        (change)="updateFormState($event, 'hasFirstName')"></mat-checkbox>
    </mat-form-field>
  </div>
  <div class="field">
    <mat-form-field appearance="standard">
      <mat-label>{{translate.instant('patient.lastName')}}</mat-label>
      <input matInput [value]="patient?.lastName" (input)="updateFormData($event, 'lastName')" />
      <mat-checkbox matSuffix color="primary" [checked]="formState.hasLastName"
        (change)="updateFormState($event, 'hasLastName')"></mat-checkbox>
    </mat-form-field>
  </div>
  <div class="field">
    <mat-form-field appearance="standard">
      <mat-label>{{translate.instant('patient.mrn')}}</mat-label>
      <input matInput [value]="patient?.mrn" (input)="updateFormData($event, 'mrn')" />
      <mat-checkbox matSuffix color="primary" [checked]="formState.hasMRN" (change)="updateFormState($event, 'hasMRN')">
      </mat-checkbox>
    </mat-form-field>
  </div>
  <div class="field">
    <mat-form-field appearance="standard">
      <mat-label>{{translate.instant('patient.sex')}}</mat-label>
      <input matInput [value]="patient?.gender" (input)="updateFormData($event, 'gender')" />
      <mat-checkbox matSuffix color="primary" [checked]="formState.hasSex" (change)="updateFormState($event, 'hasSex')">
      </mat-checkbox>
    </mat-form-field>
  </div>
  <div class="field">
    <mat-form-field appearance="standard">
      <mat-label>{{translate.instant('patient.dob')}}</mat-label>
      <input matInput [value]="patient?.dob" (input)="updateFormData($event, 'dob')" />
      <mat-checkbox matSuffix color="primary" [checked]="formState.hasBirthDate"
        (change)="updateFormState($event, 'hasBirthDate')">
      </mat-checkbox>
    </mat-form-field>
  </div>

  <div class="form-footer">
    <button mat-button (click)="cancel()">{{translate.instant('button.close')}}</button>
    <button mat-raised-button color="accent" (click)="close()">{{translate.instant('button.search')}}</button>
  </div>
</div>