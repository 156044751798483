<mat-card>
  <header>
    <app-logo [logo]="customerConfigs.logo"></app-logo>
  </header>
  <section>
    <aside>
      <qr-code class="unresolved" [class.unresolved]="!qrCodeData" [value]="qrCodeData" [size]="280" [level]="'M'"></qr-code>

      <div class="code-container unresolved" [class.unresolved]="!(qrCodeData && customerConfigs.hasPinCode)">
        <h3>OR</h3>
        <div class="code">
          <div class="title">
            {{translate.instant('phrase.pinCode')}}
          </div>
          <div *ngIf="pin; else generatePinCode">
            <div class="pincode">{{server}}{{pin}}</div>
            <input type="text" value="{{cloudServer}}{{server}}{{pin}}" #pinCodeInput>
            <button mat-raised-button color="accent" (click)="copyPinUrl($event, pinCodeInput)">
              {{translate.instant('button.copyPinUrl')}}
            </button>
          </div>
          <ng-template #generatePinCode>
            <div>
              <button mat-raised-button color="accent" (click)="getPinCode($event)">
                {{translate.instant('button.generate')}}
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </aside>

    <main>

      <mat-progress-spinner *ngIf="loading; else finishedLoading" color="accent" mode="indeterminate">
      </mat-progress-spinner>
      <ng-template #finishedLoading>
        <div class="instructions">
          {{translate.instant('instruction.capturePartOne')}}&nbsp;{{customerConfigs.appName}}&nbsp;{{translate.instant('instruction.capturePartTwo')}}
        </div>
        <div class="ehr-information" *ngIf="dataService.hasMinimumPatientInformation">
          <app-patient-information [patient]="patient">

            <app-destination-selector [inputDisabled]="loading" [(serverPostLocation)]="serverPostLocation"
              [(selectedDestination)]="selectedDestination" [(nuanceStudyTagIds)]="nuanceStudyTagIds"
              [nuanceDestinations]="nuanceDestinations" [config]="customerConfigs"
              (selectedDestinationChange)="updateNuancePostData($event)"
              (nuanceStudyTagIdsChange)="updateNuancePostData($event)" (change)="setServerPostLocation($event)">
            </app-destination-selector>

          </app-patient-information>
        </div>

        <div *ngIf="errorStatus" class="error">
          {{errorStatus}}
        </div>

        <div class="device-status-list" *ngIf="manifestStatus; else displayHelpText">

          <div class="device-status" *ngFor="let device of deviceStatusList; trackBy: trackDeviceStatusBy">

            <div *ngIf="device.connectionNotes" class="notes">
              {{device.connectionNotes}}

              <div class="counts" [class.active]="device.counts?.totalFiles > 0">
                {{translate.instant('phrase.transferstatus')}}&nbsp;
                <span class="total"
                  [class.active]="device.counts?.totalFiles > 0 && device.counts?.totalFiles != device.counts?.filesReceived">
                  {{translate.instant('word.processing')}}&nbsp;{{device.counts?.filesReceived}}&nbsp;/&nbsp;{{device.counts?.totalFiles}}
                </span>
                <span class="cancel" [class.active]="device.counts?.canceledTotal > 0">
                  {{translate.instant('word.cancelled')}}&nbsp;{{device.counts?.canceledTotal}}
                </span>
                <span class="failure" [class.active]="device.counts?.failureTotal > 0">
                  {{translate.instant('word.failure')}}&nbsp;{{device.counts?.failureTotal}}
                </span>
                <span class="success" [class.active]="device.counts?.successTotal > 0">
                  {{translate.instant('word.success')}}&nbsp;{{device.counts?.successTotal}}
                </span>
              </div>
              <mat-progress-bar color="primary" [mode]="device.progressMode" [value]="device.progressUpload">
              </mat-progress-bar>
            </div>
          </div>
        </div>
        <ng-template #displayHelpText>
          <footer *ngIf="customerConfigs.showFooterHelpText">
            {{ translate.instant('phrase.install') }}
            <br /> {{ translate.instant('phrase.scanPartOne') }}&nbsp;{{ customerConfigs.appName }}&nbsp;{{
            translate.instant('phrase.scanPartTwo') }}
            <a href="https://www.imagemovermd.com/install" target="_blank"
              rel="noopener noreferrer">imagemovermd.com/install</a>
            {{ translate.instant('phrase.scanPartThree') }}
          </footer>
        </ng-template>
      </ng-template>
    </main>
  </section>
</mat-card>

<small *ngIf="customerConfigs.disclaimer">
  {{ customerConfigs.disclaimer }}
</small>