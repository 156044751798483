"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Response = /** @class */ (function () {
    function Response() {
    }
    return Response;
}());
var ArrayResponse = /** @class */ (function (_super) {
    __extends(ArrayResponse, _super);
    function ArrayResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ArrayResponse;
}(Response));
exports.ArrayResponse = ArrayResponse;
var ObjectResponse = /** @class */ (function (_super) {
    __extends(ObjectResponse, _super);
    function ObjectResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ObjectResponse;
}(Response));
exports.ObjectResponse = ObjectResponse;
var StudyExists = /** @class */ (function () {
    function StudyExists() {
    }
    return StudyExists;
}());
exports.StudyExists = StudyExists;
var Study = /** @class */ (function () {
    function Study() {
        this.progressColor = 'primary';
    }
    Study.getUID = function (study) {
        return study.siteName + " " + study.studyInstanceUID + " " + study.archive + " " + study.manifest.id;
    };
    return Study;
}());
exports.Study = Study;
var Manifest = /** @class */ (function () {
    function Manifest() {
    }
    return Manifest;
}());
exports.Manifest = Manifest;
var Patient = /** @class */ (function () {
    function Patient() {
    }
    return Patient;
}());
exports.Patient = Patient;
var ExistingStudy = /** @class */ (function () {
    function ExistingStudy() {
    }
    return ExistingStudy;
}());
exports.ExistingStudy = ExistingStudy;
