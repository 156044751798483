import { catchError, map, tap, take, switchMap } from 'rxjs/operators';
import { Injectable, Inject, NgZone } from '@angular/core';
import { TranslateService } from '../translation/translate.service';
import { DataService } from './data.service';
import fecha from 'fecha';
import { Media } from '../classes/media-file';
import { Dicom } from '../classes/dicom-file';
import { Patient } from '../classes/patient';
import * as dicomParser from 'dicom-parser/dist/dicomParser.js';
import { NameParseService } from './name-parse.service';
import { DicomUtils } from '../utilities/dicom.utils';
import { HttpClient } from '@angular/common/http';
import { of, BehaviorSubject, Subject } from 'rxjs';

// import * as cornerstone from 'cornerstone-core';

// export const MediaFormats = {
//   const images = ['jpeg', 'jpg'];
//   const videos = ['mpeg', 'mpg', 'quicktime', 'mov', 'mp4', 'x-msvideo', 'avi'];
//   const others = ['png', 'tiff', 'gif', 'pdf'];
// }

enum ParsedFileCountType {
  Parsed,       // DICOM files available for import
  Failed,       // DICOM files that do not conform to the standard
  Skipped,      // Non-DICOM files that were skipped
  Media,        // Media files available for import
  OtherSkipped  // File skipped for other reason
}

@Injectable()
export class FileParseService {

  filesParsed = [0, 0, 0, 0, 0];
  patientCount = 0;
  MAX_SIZE = 49182;
  dicom;
  params;
  customerConfigs;
  folderLength: number;
  parsedUpdate = {
    progressPercentage: 0
  };
  patientFailedValidation: boolean;
  parsedFiles: {
    logType: Array<number>;
    files: Array<any>;
    log: Array<any>;
  };
  patientFileMap = new Map<string, any>();



  constructor(
    @Inject('Window') private window: Window,
    private dataService: DataService,
    private nameParseService: NameParseService,
    private translate: TranslateService,
    private zone: NgZone,
    private http: HttpClient
  ) {
    const params = this.dataService.setupData({});
  }

  createPatientObject(file) {
    this.patientCount++;
    let patientId = {} as any;
    let validateLastName = {} as any;
    let validateGender = {} as any;
    let validateDateOfBirth = {} as any;

    patientId = this.handlePatientId(file);

    // Name Validation
    if (this.params.lastName) {
      validateLastName = this.validateLastName(file);
    }

    // Gender Validation
    if (this.params.gender) {
      validateGender = this.validateGender(file);
    }

    // Dob Validation
    if (this.params.dobFormat) {
      validateDateOfBirth = this.validateDateOfBirth(file);
    }

    const validation = {
      'name': validateLastName.validationName,
      'nameMessage': validateLastName.validationNameMessage,
      'gender': validateGender.validationGender,
      'genderMessage': validateGender.validationGenderMessage,
      'dob': validateDateOfBirth.validationDob,
      'dobMessage': validateDateOfBirth.validationDobMessage
    };

    const patient = {
      'patient': true,
      'selected': true,
      'id': patientId.id,
      'patientID': patientId.patientID,
      'patientName': file.patientName,
      'gender': file.gender,
      'dob': file.dob,
      'age': file.age,
      'patientValidated': validateLastName.patientValidated && validateGender.patientValidated && validateDateOfBirth.patientValidated,
      'validation': validation,
      'children': []
    };

    return patient;
  }

  handlePatientId(file) {
    let id, patientID;
    if (typeof file.patientID !== 'object') {
      if (file.patientID && (file.patientID.indexOf('.') > 0)) {
        id = 'u' + file.patientID.split('.').join('');
      } else {
        id = 'u' + file.patientID;
      }
      patientID = file.patientID;
    } else {
      patientID = 'Unknown.';
    }

    return {
      id,
      patientID
    };
  }

  validateDateOfBirth(file) {
    let validationDob, validationDobMessage, patientValidated = true;

    if (file.dob === this.params.dobFormat) {
      validationDob = 'validation-success';
      validationDobMessage = this.translate.instant('validation.dobmatch');
    } else if (file.dob === '' || file.dob === '00010101') {
      validationDob = 'validation-partial';
      validationDobMessage = this.translate.instant('validation.dobpartial');
    } else if (file.patientNameObject.lastName === 'Media Files') {
      validationDob = '';
    } else {
      validationDob = 'validation-error';
      validationDobMessage = this.translate.instant('validation.dobmismatch');
      patientValidated = false;
      this.patientFailedValidation = true;
    }

    return {
      validationDob,
      validationDobMessage,
      patientValidated
    };
  }

  validateGender(file) {
    let validationGender, validationGenderMessage, patientValidated = true;

    if (file.gender.toLowerCase() === this.params.gender.toLowerCase()) {
      validationGender = 'validation-success';
      validationGenderMessage = this.translate.instant('validation.gendermatch');
    } else if (file.gender === ' ' || file.gender === 'O') {
      validationGender = 'validation-partial';
      validationGenderMessage = this.translate.instant('validation.genderpartial');
    } else if (file.patientNameObject.lastName === 'Media Files') {
      validationGender = '';
    } else {
      validationGender = 'validation-error';
      validationGenderMessage = this.translate.instant('validation.gendermismatch');
      patientValidated = false;
      this.patientFailedValidation = true;
    }
    return {
      validationGender,
      validationGenderMessage,
      patientValidated
    };
  }

  validateLastName(file) {
    let validationName, validationNameMessage, patientValidated = true;

    const name = this.nameParseService.parseName(file.patientName);

    if (file.patientNameObject.lastName.toLowerCase() === this.params.lastName.toLowerCase() && name.firstName.toLowerCase() === this.params.firstName.toLowerCase()) {
      validationName = 'validation-success';
      validationNameMessage = this.translate.instant('validation.namematch');
    } else if (file.patientNameObject.lastName.toLowerCase().indexOf(this.params.lastName.toLowerCase()) > -1) {
      validationName = 'validation-partial';
      validationNameMessage = this.translate.instant('validation.namepartial');
    } else if (file.patientNameObject.lastName === 'Media Files') {
      validationName = '';
    } else {
      validationName = 'validation-error';
      validationNameMessage = this.translate.instant('validation.namemismatch');
      patientValidated = false;
      this.patientFailedValidation = true;
    }

    return {
      validationName,
      validationNameMessage,
      patientValidated
    };
  }

  createStudyObject(file) {
    let id;

    if (file.studyUID && (file.studyUID.indexOf('.') > 0)) {
      id = 'u' + file.studyUID.split('.').join('');
    } else {
      id = 'u' + file.studyUID;
    }


    const study = {
      'study': true,
      'selected': true,
      'id': id,
      'UID': file.studyUID,
      'studyProgress': '',
      'studyDescription': file.studyDescription,
      'isModalityAllowed': this.customerConfigs.disabledModalitiesForOverread ? this.customerConfigs.disabledModalitiesForOverread.every(c => c !== file.modality) : true,
      'tooOld': Dicom.Study.diffDate(file.studyDate) > this.customerConfigs.maxStudyAgeInDays,
      'modality': file.modality,
      'studyDate': file.studyDate,
      'reasonForStudy': ''
    };

    return study;
  }

  saveImage(file: File, type: string) {
    const thumbnailSubject = new BehaviorSubject<string>('');
    let body = new FormData();
    body.append('fileContent', file)
    body.append('type', type)
    this
      .http
      .post('/pronghorn/v1/image/save', body)
      .pipe(
        map((result: any) => result.data.newFileName),
        catchError((error) => {
          this.appendLogEntry(file.name, error.message);
          console.dir(error);
          return of('data:image/png;base64,error');
        })
      ).subscribe(thumbnailFilename => {
        thumbnailSubject.next(thumbnailFilename)
      });

    return thumbnailSubject;
  }

  async parseMediaFolder(folder: FileList) {
    this.patientCount = 0;
    this.patientFailedValidation = false;
    this.filesParsed = [0, 0, 0, 0, 0];

    const folderArray = Array.from(folder);
    folder = null;
    folderArray.forEach(async (file) => {
      const [ group, type ] = file.type.split('/');

      if(!file.type.startsWith('video')) {
        (file as any).thumbnail = this.saveImage(file, type);
        (file as any).imageUrl = 'data:image/png;base64,pending';
      }

      this.parseMediaFile(file);
    });

    this.parseUpdateBatchToUI(this.parseUpdate());
    this.parsedUpdate.progressPercentage = 0;
  }

  parseMediaFile = (file) => {
    const images = ['jpeg', 'jpg'];
    const videos = ['mpeg', 'mpg', 'quicktime', 'mov', 'mp4', 'x-msvideo', 'avi'];
    const others = ['png', 'tiff', 'gif', 'pdf'];

    if (images.some(type => file.type.indexOf(type) > -1)) {
      if (this.customerConfigs.jpeg !== true) {
        this.filesParsed[2] = this.filesParsed[2] + 1;
        this.parsedFiles.log.push({ name: file.name, message: 'JPG ' + this.translate.instant('dicom.enabled'), class: 'validation-partial' });
        this.parsedFiles.logType[1] = this.parsedFiles.logType[1] + 1;
      } else {
        this.parsedFiles.files = this.setFilePropertiesForType(this.parsedFiles.files, file, 'JPEG', 'JPEG');
      }

      // Handle Video
    } else if (videos.some(type => file.type.indexOf(type) > -1)) {
      if (this.customerConfigs.mpeg !== true) {
        this.filesParsed[2] = this.filesParsed[2] + 1;
        this.parsedFiles.log.push({ name: file.name, message: 'MPEG ' + this.translate.instant('dicom.enabled'), class: 'validation-partial' });
        this.parsedFiles.logType[1] = this.parsedFiles.logType[1] + 1;
      } else {
        this.parsedFiles.files = this.setFilePropertiesForType(this.parsedFiles.files, file, 'MPEG', 'MPEG');
      }

      // Handle Other Media
    } else if (others.some(type => file.type.indexOf(type) > -1)) {
      if (this.customerConfigs.other !== true) {
        this.filesParsed[2] = this.filesParsed[2] + 1;
        this.parsedFiles.log.push({ name: file.name, message: file.type + ' ' + this.translate.instant('dicom.enabled'), class: 'validation-partial' });
        this.parsedFiles.logType[1] = this.parsedFiles.logType[1] + 1;
      } else if (file.type.indexOf('png') > 0) {
        this.parsedFiles.files = this.setFilePropertiesForType(this.parsedFiles.files, file, 'PNG', 'PNG', false);
      } else if (file.type.indexOf('tiff') > 0) {
        this.parsedFiles.files = this.setFilePropertiesForType(this.parsedFiles.files, file, 'TIFF', 'TIF', false);
      } else if (file.type.indexOf('gif') > 0) {
        this.parsedFiles.files = this.setFilePropertiesForType(this.parsedFiles.files, file, 'GIF', 'GIF', false);
      } else if (file.type.indexOf('pdf') > 0) {
        this.parsedFiles.files = this.setFilePropertiesForType(this.parsedFiles.files, file, 'PDF', 'PDF', false);
      }
    } else {
      this.parsedFiles.logType[0] = this.parsedFiles.logType[0] + 1;
      this.filesParsed[4] = this.filesParsed[4] + 1;
      this.parsedFiles.log.push({ name: file.name, message: this.translate.instant('dicom.notmedia'), class: 'IMblack' });
    }

    this.parseUpdateBatchToUI(this.parseUpdate());
  }

  setFilePropertiesForType(files, file: any, type: string, mediaType: string, isMedia = true) {
    file.patientName = `${type} ${this.translate.instant('word.files')}`;
    file.patientID = `${type} ${this.translate.instant('word.files')}`;
    file.patientNameObject = { 'firstName': '', 'lastName': `${type} ` + this.translate.instant('word.files') };
    file.studyUID = `${type} ${this.translate.instant('word.files')}`;
    file.UID = `${type} ${this.translate.instant('word.files')}`;
    file.mediaType = `${mediaType}`;
    file.gender = '';
    file.isMedia = isMedia;

    if (!file.lastModified && file.lastModifiedDate) {
      file.lastModified = new Date(file.lastModifiedDate);
    }

    // Another IE Support Item.  In EI (aka. IE) File classes do not have the
    //  lastModified property.  See: https://developer.mozilla.org/en-US/docs/Web/API/File/lastModified#browser_compatibility
    //  So when there is not a last-modified property, we will set it to today
    if (file.lastModified) {
      file.date = fecha.format(file.lastModified, 'MMM DD, YYYY');
    } else {
      file.date = fecha.format(Date.now(), 'MMM DD, YYYY');
    }

    file.id = file.name;
    file.label = file.name;
    file.selected = true;
    file.file = true;
    this.filesParsed[3] = this.filesParsed[3] + 1;
    return this.mediaMatch(files, file);
  }
  async parseDicomFolder(folder: FileList) {
    this.patientCount = 0;
    this.folderLength = folder.length;
    this.patientFailedValidation = false;
    this.filesParsed = [0, 0, 0, 0, 0];

    const folderArray: Array<File> = Array.from(folder);
    folder = null;

    for (const each of folderArray) {

      await (async (file: File) => {
        const name = file.name.toLowerCase();
        const skipTypes = ['DS_Store', '.oly', '.xmd', '.inf', '.ico', '.exe', '.zip',
          '.csv', '.doc', '.htaccess', '.jar', '.bfc', '.src', '.html', '.txt', '.bat',
          '.properties', '.pf', '.dll', '.xml', '.iso', '.bmp', '.chm', '.db', '.xsl',
          '.config', '.htm', '.ttf', '.cpl', '.jsa'];

        const mediaTypes = ['jpeg', 'jpg', 'mpeg', 'mpg', 'mov', 'mp4', 'png', 'tiff', 'gif', 'pdf'];

        if (skipTypes.some(type => name.indexOf(type) > -1)) {
          this.filesParsed[4] = this.filesParsed[4] + 1;
          this.parsedFiles.log.push({ name: file.name, message: file.type + ' ' + this.translate.instant('dicom.skipped') });
          this.parsedFiles.logType[0] = this.parsedFiles.logType[0] + 1;

        } else if (file.name.indexOf('DICOMDIR') > -1) {
          this.filesParsed[4] = this.filesParsed[4] + 1;
          this.parsedFiles.log.push({ name: file.name, message: file.type + ' ' + this.translate.instant('dicom.skipped') });
          this.parsedFiles.logType[0] = this.parsedFiles.logType[0] + 1;

        } else if (mediaTypes.some(type => file.type.indexOf(type) > -1)) {
          this.filesParsed[2] = this.filesParsed[2] + 1;
          this.parsedFiles.log.push({ name: file.name, message: this.translate.instant('dicom.nomedia') });
          this.parsedFiles.logType[1] = this.parsedFiles.logType[1] + 1;

        } else {
          await this.parseDicomFile(file);
        }
      })(each);
    }

    this.parseUpdateBatchToUI(this.parseUpdate());
    this.parsedUpdate.progressPercentage = 0;
  }

  async parseDicomFile(file) {
    try {
      // assuming this is a dicom file
      const response = await this.parseFile(file);

      DicomUtils.parseDicomFile(file, response, ParsedFileCountType.Failed);

      this.dicomMatch(file);
      this.filesParsed[0] = this.filesParsed[0] + 1;

      this.parseUpdateBatchToUI(this.parseUpdate());
    } catch (error) {
      console.dir({
        error: error.message,
        stack: error.stack,
        code: error.code || ParsedFileCountType.OtherSkipped
      })

      const code = error.code || ParsedFileCountType.OtherSkipped;

      if (code === ParsedFileCountType.Failed) {
        // Not a Valid Dicom File Does not Contain Object
        this.parsedFiles.log.push({ name: file.name, message: this.translate.instant('dicom.invalidresponse'), class: 'validation-error' });
        this.parsedFiles.logType[ParsedFileCountType.Failed]++;
      } else {
        // Not a valid file type count as failure and move on.
        this.parsedFiles.log.push({ name: file.name, message: error.message, class: error.class });
        this.parsedFiles.logType[ParsedFileCountType.Parsed]++;
        this.filesParsed[code]++;
      }
  
      this.parseUpdateBatchToUI(this.parseUpdate());
    }
  }

  isInSopList(file: Dicom.Child, destination): boolean {
    return destination.sopList.some(i => i === file.sopClass);
  }

  parseUpdateBatchToUI(parsedUpdate) {
    this.zone.run(() => {
      const oldProgress = this.parsedUpdate.progressPercentage;
      const newProgress = parsedUpdate.progressPercentage;
      this.parsedUpdate = parsedUpdate;
      this.parsedUpdate.progressPercentage = newProgress > oldProgress ? newProgress : oldProgress;
    });
  }


  parseFile(file): Promise<any> {
    let reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        this.readLoadedFile(reader, resolve, reject);
      };

      reader.onerror = (error) => {
        reader = undefined;
        return resolve(error);
      };

      reader.readAsArrayBuffer(file.size < this.MAX_SIZE ? file : file.slice(0, this.MAX_SIZE));
    });
  }

  readLoadedFile(reader, resolve, reject) {
    const arrayBuffer = reader.result;
    reader = undefined;
    // Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
    // Uint8Array so we create that here
    const byteArray = new Uint8Array(arrayBuffer);
    try {
      const options = {
        omitPrivateAttibutes: true,
        untilTag: 'x0020000e',
        maxElementLength: 128
      };
      // const dataSet = this.window['dicomParser'].parseDicom(byteArray, options);

      const dataSet = dicomParser.parseDicom(byteArray, options);


      return resolve(DicomUtils.extractDicomData(dataSet));
    } catch (error) {
      if (error === 'dicomParser.readPart10Header: DICM prefix not found at location 132 - this is not a valid DICOM P10 file.') {
        return reject(new Error(this.translate.instant('dicom.location132')));
      } else {
        let errorMessage = null;
        if (typeof error.exception === 'object') {
          const tempObject = error.exception;
          errorMessage = tempObject.exception;
        } else if (typeof error.exception === 'string') {
          errorMessage = error.exception;
        } else {
          errorMessage = 'Unknown Error';
          console.error(error);
        }
        return reject(new Error(errorMessage));
      }
    }
  }

  isASCII(str) {
    return /^[\x00-\x7F]*$/.test(str);
  }

  convertDataSet(dataSet) {
    // tags array restricts header set allowed for consumption
    const tagsArray = ['x00100020', 'x00100010', 'x00100030', 'x00100040', 'x00101010', 'x0020000d', 'x0020000e', 'x00080018', 'x00080016', 'x00080020', 'x00080060', 'x00081030', 'x00080050'];
    const output = {};

    console.dir(dataSet)

    for (const propertyName in dataSet.elements) {
      // These are all the elements we care about
      if (tagsArray.indexOf(propertyName) > -1) {
        const element = dataSet.elements[propertyName];

        if (!(element.items && element.fragments) && element.length < 256) {
          const str = dataSet.string(propertyName);
          const stringIsAscii = this.isASCII(str);

          if (stringIsAscii) {
            if (str !== undefined) {
              output[propertyName] = str;
            }
          }
        }
      } // End if

    } // End For

    return output;
  }

  dicomMatch(file: Dicom.Child) {
    let study;
    let lastPatient;
    let lastStudy;

    if (this.parsedFiles.files.length >= 1) {
      // Set last patient since there is at least 1 patient
      lastPatient = this.parsedFiles.files[(this.parsedFiles.files.length - 1)];

      // Alternative Loop through all values, slight performance hit but checks against all patients
      for (let j = 0; j < this.parsedFiles.files.length; j++) {
        const lp = this.parsedFiles.files[j] as Dicom.Child;
        if (lp.patientID === file.patientID) {
          lastPatient = lp;
          break;
        }
      }

      if (lastPatient.patientID === file.patientID) {

        // Set last study since there is at least 1 study
        lastStudy = lastPatient.children[(lastPatient.children.length - 1)];

        // Alternative Loop through all values, slight performance hit but checks against all studies for that patient
        for (let k = 0; k < lastPatient.children.length; k++) {
          const ls = lastPatient.children[k];
          if (ls.UID === file.studyUID) {
            lastStudy = ls;
            break;
          }
        }

        if (lastStudy.UID === file.studyUID) {

          // Bind to existing study and patient
          lastStudy.children.push(file);

        } else {

          // Bind to existing patient but make new study
          study = this.createStudyObject(file);
          study.children = [file];
          lastPatient.children.push(study);

        }

      } else {
        this.newStudyAndPatient(study, file);
      }

    } else {
      this.newStudyAndPatient(study, file);
    }


  }

  newStudyAndPatient(study, file) {
    // Make New Study And Patient
    study = this.createStudyObject(file);
    const patient = this.createPatientObject(file);
    study.children = [file];
    patient.children = [study];
    this.parsedFiles.files.push(patient);
  }

  mediaMatch(files: Array<Media.MediaFile>, file: Media.Child) {
    let patient;
    let lastPatient;
    if (files.length >= 1) {
      // Set last patient since there is at least 1 patient
      lastPatient = files[(files.length - 1)];

      // Alternative Loop through all values, slight performance hit but checks against all patients
      for (let j = 0; j < files.length; j++) {
        const lp = files[j];
        if (lp.patientID === file.patientID) {
          lastPatient = lp;
          break;
        }
      }

      if (lastPatient.patientID === file.patientID) {

        // Set last study since there is at least 1 study
        lastPatient.children.push(file);

      } else {

        // Make New Study And Patient
        patient = this.createPatientObject(file);
        file.first = true;
        patient.children = [file];
        patient.media = true;
        patient.UID = file.UID;
        patient.patient = false;
        files.push(patient);

      }

    } else {

      // Make New Study And Patient
      patient = this.createPatientObject(file);
      file.first = true;
      patient.children = [file];
      patient.media = true;
      patient.UID = file.UID;
      patient.patient = false;
      files.push(patient);

    }

    return files;
  }

  parseUpdate(): any {
    let parsed, failed, skipped, media, system;
    if (this.filesParsed[0] > 0) {
      parsed = this.filesParsed[0] + ' ' + this.translate.instant('dicom.available');
    } else {
      parsed = '';
    }
    if (this.filesParsed[1] > 0) {
      failed = this.filesParsed[1] + ' ' + this.translate.instant('dicom.nonconformant');
    } else {
      failed = '';
    }
    if (this.filesParsed[2] > 0) {
      skipped = this.filesParsed[2] + ' ' + this.translate.instant('dicom.mediaskipped');
    } else {
      skipped = '';
    }
    if (this.filesParsed[3] > 0) {
      media = this.filesParsed[3] + ' ' + this.translate.instant('dicom.mediaready');
    } else {
      media = '';
    }
    if (this.filesParsed[4] > 0) {
      system = this.filesParsed[4] + ' ' + this.translate.instant('dicom.fileskipped');
    } else {
      system = '';
    }
    const totalFilesParsed = (this.filesParsed[0] + this.filesParsed[1] + this.filesParsed[2] + this.filesParsed[3] + this.filesParsed[4]);
    return {
      parsed,
      failed,
      skipped,
      media,
      system,
      totalFilesParsed,
      progressPercentage: Math.round((totalFilesParsed / this.folderLength) * 100)
    };
  }

  private appendLogEntry(filename, message ) {
    this.parsedFiles.log.push({ name: filename, message });
  }
}
