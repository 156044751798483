import { BehaviorSubject, Observable, Subject } from "rxjs";

export namespace Media {

  export interface Validation {
    name: string;
    nameMessage: string;
    gender: string;
    genderMessage: string;
    dob: string;
    dobMessage: string;
  }

  export interface PatientNameObject {
    firstName: string;
    lastName: string;
  }

  export interface BodyPart {
    [x: string]: any;
    name: string;
    bodyPart?: any;
    modifiers?: any;
    view?: string;
    orientation?: string;
  }

  export interface Child {
    patientName: string;
    patientID: string;
    patientNameObject: PatientNameObject;
    studyUID: string;
    UID: string;
    mediaType: string;
    gender: string;
    date: string;
    id: string;
    label: string;
    selected: boolean;
    file: boolean;
    first: boolean;
    type: string;
    lastModified: number;
    lastModifiedDate: Date;
    webkitRelativePath: string;
    // Image URLs for image files.  The $dataUrl can be subscribed to and will hold the status of the loading
    // image while the imageUrl will hold a base64 string once loaded.
    $dataUrl: Subject<string>;
    imageUrl: string;
    size: string;
    icon: string;
    bodyPart: BodyPart;
    thumbnail: BehaviorSubject<string>;
    // isMedia defines if the file is identified by the file-parse.service as being a media file.
    //   This is identified in the parseMediaFile method.  Will be true if the file is an `image`
    //   or `video` and false if it is an `other`
    isMedia: boolean;
  }

  export class Counts {
    SUCCESS = 0;
    ERROR = 0;
    PROCESSING = 0;
    CANCELLED = 0;
    totalFiles = 0;
    filesReceived = 0;
  }

  export interface MediaFile {
    patient: boolean;
    selected: boolean;
    sensitive: boolean;
    id: string;
    patientID: string;
    patientName: string;
    gender: string;
    patientValidated: boolean;
    validation: Validation;
    children: Child[];
    media: boolean;
    UID: string;
    progressPercentage: number;
    bufferPercentage: number;
    progressMode: string;
    counts: Counts;
    errors: string[];
  }

  export function getMediaChildren(fileArray: Media.MediaFile[]): Media.Child[] {
    const files = []
  
    // Extract all children from the file array into one master array
    fileArray.forEach(file => files.push(...file.children));

    return files;
  }
}
