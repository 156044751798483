"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var media_component_1 = require("./media/media.component");
var dicom_component_1 = require("./dicom/dicom.component");
var modality_component_1 = require("./modality/modality.component");
var qrcode_component_1 = require("./qrcode/qrcode.component");
var studyDescription_resolver_1 = require("./resolvers/studyDescription.resolver");
var config_resolver_1 = require("./resolvers/config.resolver");
var router_1 = require("@angular/router");
var patient_history_component_1 = require("./patient-history/patient-history.component");
var transfer_component_1 = require("./transfer/transfer.component");
var ɵ0 = function () { return Promise.resolve().then(function () { return require("./rounds/rounds.module.ngfactory"); }).then(function (m) { return m.RoundsModuleNgFactory; }); };
exports.ɵ0 = ɵ0;
var routes = [
    {
        path: '',
        resolve: { config: config_resolver_1.ConfigResolver, studies: studyDescription_resolver_1.StudyDescriptionResolver, },
        children: [
            {
                path: 'qrcode',
                component: qrcode_component_1.QrcodeComponent,
            },
            {
                path: 'dicom',
                component: dicom_component_1.DicomComponent
            },
            {
                path: 'media',
                component: media_component_1.MediaComponent
            },
            {
                path: 'modality',
                component: modality_component_1.ModalityComponent
            },
            {
                path: 'dicom-image-exchange',
                component: patient_history_component_1.PatientHistoryComponent
            },
            {
                path: 'sync',
                component: transfer_component_1.TransferComponent
            },
            {
                path: 'rounds',
                loadChildren: ɵ0
            },
            {
                path: 'fhir/app',
                redirectTo: '/'
            },
            {
                path: 'integration/app',
                redirectTo: '/'
            }
        ]
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
exports.AppRoutingModule = AppRoutingModule;
