<div>
  <mat-form-field>
    <input matInput [(ngModel)]="text" [placeholder]="translate.instant('phrase.reasonOverread')" autocomplete="off">
  </mat-form-field>
</div>
<div class="form-footer">
  <button mat-button color='primary' (click)="cancel()">
    {{translate.instant('button.cancel')}}
  </button>
  <button mat-raised-button color='accent' [disabled]="!text" (click)="submit()">
    {{translate.instant('button.confirmOverread')}}
  </button>
</div>