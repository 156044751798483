<mat-card [class.drag-over]="over" (dragover)="dragover($event)" (dragleave)="dragleave($event)" (drop)="drop($event)">
  <header>
    <app-logo [logo]="customerConfigs.logo"></app-logo>
  </header>
  <section>
    <div class="head-display">
      <aside class="column-left">
        <div>
          <div>
            <strong>
              {{ translate.instant('phrase.file') }}
            </strong>
          </div>
          <div class="file-selection">
            <input hidden type="file" #mediaFileSelect [disabled]="inputDisabled"
              (change)="fileChange($event.target['files'])" multiple />
            <button mat-raised-button color='primary'
              [disabled]="(fileParseService.parsedUpdate?.progressPercentage > 0 && 100 < fileParseService.parsedUpdate?.progressPercentage) || inputDisabled"
              (click)="mediaFileSelect.click()">
              {{translate.instant('phrase.selectfiles')}}
            </button>

            <mat-progress-bar color='primary' mode='determinate'
              [value]="fileParseService.parsedUpdate?.progressPercentage"></mat-progress-bar>
          </div>
          <div *ngIf="dataService.configSettings?.modalities?.options?.length > 0 && dataService.configSettings?.timeMapping?.times?.length > 0"  class="add-to-existing">
            <header>
              <strong>{{ selectionType === 'exam' ? translate.instant('phrase.exam') : translate.instant('phrase.study') }}</strong>
            </header>
            <div class="study">
              <section class="study-details">
                <p class="study-title">{{ translate.instant('word.selected') }}:</p>
                <p class="study-name" [matTooltip]="existingStudy.studyDescription">{{ existingStudy.studyDescription }}</p>
                <div class="selected-study">
                <p class="study-time">{{ existingStudy.time }}</p>
                <p class="study-modalities" [matTooltip]="existingStudy.modalities">{{ existingStudy.modalities }}</p>
                </div>
              </section>
            </div>
            <section class="select-btn-group">
              <button mat-raised-button color='primary' (click)="selectStudy()" [disabled]="inputDisabled" >{{  translate.instant('phrase.addToExisting') }}</button>
            </section>
          </div>
          <div>
            <app-destination-selector [inputDisabled]="inputDisabled" [(serverPostLocation)]="serverPostLocation"
              [(selectedDestination)]="selectedDestination" [(nuanceStudyTagIds)]="nuanceStudyTagIds"
              [nuanceDestinations]="nuanceDestinations" [config]="customerConfigs"
              (change)="setServerPostLocation($event)">
            </app-destination-selector>
          </div>
        </div>
      </aside>
      <div class="column-right">
        <div class="ehr-information">
          <app-patient-information [patient]="patient">
            <div *ngIf="selectedStudyObject?.code === '-1'; else studyDescriptionSelector" class="display">
              <span>{{translate.instant('table.study')}}:</span>{{selectedStudyObject.studyDescription}}
            </div>

            <ng-template #studyDescriptionSelector>
              <app-study-selection [suggestedStudies]="suggestedStudies" [notSuggestedStudies]="notSuggestedStudies"
                [(selectedStudy)]="selectedStudyObject" (selectionChange)="changeSelectedStudy($event)"
                [suggestedLabel]="translate.instant('word.study-match')" [disabled]="inputDisabled || studyInstanceUID">
              </app-study-selection>
            </ng-template>

            <mat-checkbox [disabled]="inputDisabled" (change)="updateSensitive($event)"
              [checked]="isSensitive" color="warn">{{ translate.instant('media.sensitiveStudy')}}</mat-checkbox>
          </app-patient-information>
        </div>
      </div>

    </div>
    <div>
      <mat-tab-group>
        <mat-tab label="Media">
          <div class="file-header-row" *ngIf="errorStatus">
            <div class="error">
              {{ errorStatus }}
            </div>
          </div>

          <div [class.finished]="finished" id="finished">
            <span>
              {{ translate.instant('word.success') }}
            </span>
          </div>

          <div *ngIf="files?.length > 0" class="select-all-files">
            <mat-checkbox [disabled]="inputDisabled" (change)="selectAllFiles($event)"
              [indeterminate]="allFilesSelected == null" [checked]="allFilesSelected" color="primary">
              {{ translate.instant('phrase.selectall') }}
            </mat-checkbox>
          </div>

          <mat-accordion [multi]="true">
            <mat-expansion-panel #panel [expanded]="true" *ngFor="let file of files; trackByFile">

              <mat-expansion-panel-header>

                <mat-panel-title>
                  {{ file.patientName }}
                </mat-panel-title>

                <mat-panel-description>
                  <mat-progress-bar color='primary' [mode]='file.progressMode' [value]="file.progressPercentage"
                    [bufferValue]="file.bufferPercentage">
                  </mat-progress-bar>
                </mat-panel-description>

              </mat-expansion-panel-header>

              <main>
                <div class="above-table">
                  <div class="media-type-checkbox-container">
                    <mat-checkbox [disabled]="inputDisabled" (change)="selectAllChildren($event, file)"
                      [indeterminate]="file.selected == null" [checked]="file.selected" color="primary"
                      class="select-all">
                      {{ translate.instant('phrase.selectall') }}
                    </mat-checkbox>
                    <!-- Leaving this code here - in the future we would like to be able to mark manifests as sensitive -->
                    <!-- <mat-checkbox [disabled]="inputDisabled" (change)="markChildrenAsSensitive($event, file)"
                      [checked]="isSensitive" color="warn">{{ translate.instant('media.allSensitive')}}</mat-checkbox> -->
                  </div>

                  <div class="counts" *ngIf="file.counts">
                    <span class="file-success" [matTooltip]="translate.instant('phrase.filesendsuccess')">
                      {{ file.counts?.SUCCESS }}
                    </span>
                    <span class="file-processing" [matTooltip]="translate.instant('phrase.fileprocessing')">
                      {{ file.counts?.PROCESSING }}
                    </span>
                    <span class="file-error" [matTooltip]="translate.instant('phrase.filesenderror')">
                      {{ file.counts?.ERROR }}
                    </span>
                    <span class="file-total" [matTooltip]="translate.instant('phrase.filesprocessed')">
                      {{ file.counts?.filesReceived }}&nbsp;/&nbsp;{{ file.counts?.totalFiles }}
                    </span>
                  </div>
                </div>
                <div class="row top">
                  <div class="checkbox-column"></div>
                  <div class="name-column">{{ translate.instant('word.name') }}</div>
                  <div class="file-size-column">{{ translate.instant('word.size') }} ({{ translate.instant('word.bytes') }})</div>
                  <div class="date-column">{{ translate.instant('word.date') }}</div>
                  <div class="body-column" [class.hideColumn]="!bodyPartEnabled">{{ translate.instant('table.bodyPart') }}
                    <sup *ngIf="bodyPartRequired" class="required">*</sup>
                    <mat-icon [matTooltip]="translate.instant('media.not-applied-study-description')" [matTooltipPosition]="WARNING_TOOLTIP_POSITION" *ngIf="!this.existingStudy.new && this.selectionType === 'study'" class="icon-warn">report_problem</mat-icon>
                  </div>
                </div>

                <div *ngFor="let child of file?.children; index as i; trackBy: trackByFile" class="select">
                  <div class="row">

                    <div class="checkbox-column">
                      <mat-checkbox [disabled]="inputDisabled" (change)="selectFile($event, file, child)"
                        [checked]="child.selected" color="primary">
                      </mat-checkbox>
                    </div>

                    <div class="name-column">{{ child.name }}</div>
                    <div class="file-size-column">{{ child.size }}</div>
                    <div class="date-column">{{ child.date }}</div>
                    <div 
                      class="body-column" 
                      [class.disabled]="uploaderDisabled"
                      [class.hideColumn]="!bodyPartEnabled" 
                    >
                      <span [class.disabled]="child.bodyPart?.bodyPart?.code === 'NOT ANATOMICAL'" (click)="openBodyMap(file, i)" [class.invalid]="child.bodyPart?.code === 'NOT SPECIFIED' && invalidFields">
                        {{child.bodyPart.name}}
                      </span>
                      <div class="body-column__action-btns">
                        <a *ngIf="child.bodyPart?.bodyPart?.code !== 'NOT ANATOMICAL'" (click)="setNotAnatomical(file, i)" [matTooltip]="translate.instant('media.not-anatomical')">na</a>
                        <a *ngIf="child.bodyPart?.bodyPart?.code === 'NOT ANATOMICAL'" (click)="openBodyMap(file, i)" [matTooltip]="translate.instant('media.select-different-body-part')">{{translate.instant('media.change')}}</a>
                        <a *ngIf="child.bodyPart?.code !== 'NOT SPECIFIED' && countFilesWithUnspecified() > 0" (click)="setUnspecified(file, i)" [matTooltip]="generateUnspecifiedTooltip(child.bodyPart.name)">{{translate.instant('media.apply-to-unspecified')}}</a>
                      </div>
                    </div>
                    <div class="image-column">
                      <span *ngIf="child.icon !== 'video_library'; else genericIcon" class="img-container">
                        <img *ngIf="child.imageUrl; else imageLoading" [src]="child.imageUrl" />
                        <mat-icon class="image-loading-error" [matTooltip]="translate.instant('media.tooltip.error-loading-thumbnail')" (click)="retryThumbnailRequest(child)">info</mat-icon>
                        <mat-icon class="image-loading-pending" [matTooltip]="translate.instant('media.tooltip.generating-thumbnail')">image</mat-icon>
                      </span>

                      <ng-template #imageLoading>
                        <div class="loading-background img-container">
                          <mat-progress-spinner [color]="primary" [mode]="'indeterminate'" diameter="32"></mat-progress-spinner>
                        </div>
                      </ng-template>

                      <ng-template #genericIcon>
                        <mat-icon>
                          {{ child.icon }}
                        </mat-icon>
                      </ng-template>

                      <button mat-mini-fab class="fab-icon-button" *ngIf="child.icon === 'picture_as_pdf'"
                        (click)="openPdf(child)" color="accent" [disabled]="isPdfModalOpen || !child.imageUrl">
                        <mat-icon class="button-wrapped-icon">
                          launch
                        </mat-icon>
                      </button>
                    </div>

                  </div>
                </div>

              </main>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>

        <mat-tab label="Log Messages" [disabled]="!this.logData.data?.length">
          <div class="table-header">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter Logs" autocomplete="off">
            </mat-form-field>
          </div>

          <mat-table #table [dataSource]="logData">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>{{ translate.instant('table.filename') }}</mat-header-cell>
              <mat-cell *matCellDef="let el"> {{ el.name }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="message">
              <mat-header-cell *matHeaderCellDef>{{ translate.instant('table.message') }}</mat-header-cell>
              <mat-cell *matCellDef="let el"> {{ el.message }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="logColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: logColumns;"></mat-row>

          </mat-table>
          <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
        </mat-tab>
      </mat-tab-group>

      <div class="form-footer">
        <button mat-button color='primary' [disabled]="resetDisabled" (click)="resetPage()">
          {{ translate.instant('button.reset') }}
        </button>
        <button mat-button color='primary' [disabled]="cancelDisabled" (click)="cancelUpload()">
          {{ translate.instant('button.stop') }}
        </button>
        <button mat-raised-button color='accent' [disabled]="uploaderDisabled" (click)="submitTrigger()">
          {{ translate.instant('button.submit') }}
        </button>
      </div>
    </div>
  </section>
</mat-card>