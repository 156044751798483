"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var data_service_1 = require("../services/data.service");
var translate_service_1 = require("../translation/translate.service");
var dialog_1 = require("@angular/material/dialog");
var cancel_modality_component_1 = require("../modals/cancel-modality/cancel-modality.component");
var date_format_service_1 = require("../services/date-format.service");
var manifest_service_1 = require("../services/manifest.service");
var guid_service_1 = require("../services/guid.service");
var session_service_1 = require("../services/session.service");
var configuration_service_1 = require("../services/configuration.service");
var rxjs_1 = require("rxjs");
var forms_1 = require("@angular/forms");
var _ = require("lodash");
var material_1 = require("@angular/material");
var modality_service_1 = require("../services/modality.service");
var fecha_1 = require("fecha");
var provider_utilities_1 = require("../utilities/provider-utilities");
var ModalityComponent = /** @class */ (function () {
    function ModalityComponent(dateFormatService, dialogService, guidService, manifestService, sessionService, configurationService, modalityService, dataService, translate, document) {
        var _this = this;
        this.dateFormatService = dateFormatService;
        this.dialogService = dialogService;
        this.guidService = guidService;
        this.manifestService = manifestService;
        this.sessionService = sessionService;
        this.configurationService = configurationService;
        this.modalityService = modalityService;
        this.dataService = dataService;
        this.translate = translate;
        this.document = document;
        this.CURRENT_TAB = 'modality';
        this.manifest = '';
        this.getCount = 0;
        this.studies = [];
        this.template = {};
        this.params = {};
        this.selectedStudyObject = {};
        this.currentlyUpdating = false;
        this.reasonForStudy = '';
        this.patient = {};
        this.stop = null;
        this.ordersListener = null;
        this.providers = [];
        this.selectedProvider = {};
        this.providersSelect = new forms_1.FormControl();
        this.providersFilter = new forms_1.FormControl();
        this.rendering = true;
        this.showOrdersTable = true;
        this.ordersTable = new material_1.MatTableDataSource();
        this.displayedColumns = ['accessionId', 'timestamp', 'studyDescription', 'authProvider', 'status', 'actions'];
        this.checkStatus = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.currentlyUpdating !== false) {
                            return [2 /*return*/];
                        }
                        // Set to prevent queuing of calls.
                        this.currentlyUpdating = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.manifestService.getStatusManifest(this.manifestRoute)];
                    case 2:
                        response = _a.sent();
                        // Set to allow calls to resume.
                        this.currentlyUpdating = false;
                        if (response.status !== 404) {
                            this.parseResponse(response);
                        }
                        else {
                            // This handles the typical 404 error message after timeout or a hard failure somewhere like server reboot. Server should try 3 times to make this call before it gets caught in the catch below.
                            this.getCount = this.getCount + 1;
                            if (this.getCount >= 3) {
                                this.clearAndShowError('error.timeout');
                            }
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        // If for some reason we get a JS error this handles stopping anything.
                        this.currentlyUpdating = false;
                        this.clearAndShowError('error.timeout');
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
    }
    ModalityComponent.prototype.ngOnInit = function () {
        this.params = this.dataService.setupData(this.params);
        this.translate.use(this.params.lang);
        this.setup();
    };
    ModalityComponent.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, providers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.params = this.dataService.setupData(this.params);
                        this.customerConfigs = this.dataService.configSettings.modality;
                        this.document.body.classList.add(this.customerConfigs.theme);
                        if (this.params.hideNavTabs) {
                            data_service_1.DataService.hideNavTabs.next(this.params.hideNavTabs);
                        }
                        else {
                            data_service_1.DataService.hideNavTabs.next(!this.customerConfigs.name);
                        }
                        if (!this.dataService.hasMinimumPatientInformation) {
                            this.errorStatus = this.translate.instant('error.session');
                            this.disabled = true;
                            this.providersSelect.disable();
                            return [2 /*return*/];
                        }
                        this.studies = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, this.CURRENT_TAB);
                        result = this.dataService.searchStudies(this.studies, this.params, 'modalityQuickOrder');
                        this.studies = result.studiesFilter;
                        this.selectedStudyObject = result.selectedStudy;
                        this.suggestedStudies = result.suggested;
                        this.notSuggestedStudies = result.notSuggested;
                        this.patient = this.dataService.patient;
                        return [4 /*yield*/, this.configurationService.getProviders()];
                    case 1:
                        providers = _a.sent();
                        // Process provider.json file to ensure that all names are properly formatted, remove invalid providers
                        this.providers = providers.map(provider_utilities_1.ProviderUtilities.validateProviderListItems).filter(function (provider) { return provider; });
                        this.filteredProviders = new rxjs_1.BehaviorSubject(this.providers
                            .sort(function (a, b) {
                            if (a.name.lastName === b.name.lastName) {
                                if (a.name.firstName === b.name.firstName) {
                                    return 0;
                                }
                                return a.name.firstName > b.name.firstName ? 1 : -1;
                            }
                            return a.name.lastName > b.name.lastName ? 1 : -1;
                        })
                            .slice(0, 20));
                        return [4 /*yield*/, this.setupOrdersTable()];
                    case 2:
                        _a.sent();
                        this.showOrdersTable = this.ordersTable.data
                            .filter(function (order) { return order.status === 'Pending'; }).length > 0;
                        this.rendering = false;
                        this.providersFilter.valueChanges
                            .pipe(operators_1.debounceTime(333), operators_1.filter(function (input) { return input.length > 2; }))
                            .subscribe(function (value) {
                            var providers = _this.providers
                                .map(provider_utilities_1.ProviderUtilities.compareProviderName(value))
                                .filter(function (p) { return p.search.firstNameScore > 0.75 || p.search.lastNameScore > 0.75; })
                                .sort(provider_utilities_1.ProviderUtilities.sortProviders(value));
                            _this.filteredProviders.next(providers);
                        });
                        this.providersSelect.valueChanges
                            .subscribe(function (provider) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.selectedProvider = provider;
                                return [2 /*return*/];
                            });
                        }); });
                        this.setupInitialStudyConfig();
                        return [4 /*yield*/, this.setupOrdersListener()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.setupOrdersListener = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.ordersListener = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.setupOrdersTable()];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, 3000);
                return [2 /*return*/];
            });
        });
    };
    ModalityComponent.prototype.setupOrdersTable = function () {
        return __awaiter(this, void 0, void 0, function () {
            var url, orders, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        url = "" + this.sessionService.getUrlPath(this.params.unittest);
                        return [4 /*yield*/, this.modalityService.getOpenOrders(this.patient.mrn, url)];
                    case 1:
                        orders = (_a.sent()).map(function (order) {
                            order.authProvider = order.authProvider.replace('^', ',');
                            var date = new Date(order.timestamp);
                            order.timestampDisplay = fecha_1.default.format(date, 'MMM D, YYYY HH:mma');
                            return order;
                        });
                        orders.sort(function (a, b) {
                            try {
                                if ((new Date(a.timestamp)) < (new Date(b.timestamp))) {
                                    return 1;
                                }
                            }
                            catch (error) {
                                console.log(error);
                            }
                            return -1;
                        });
                        this.ordersTable = new material_1.MatTableDataSource(orders);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.log(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.setupInitialStudyConfig = function () {
        var foundStudy = this.dataService.findStudy(this.studies, this.params.studyDescription);
        var suggestedStudy = this.suggestedStudies.length ? this.suggestedStudies[0] : null;
        var defaultStudy = suggestedStudy ? suggestedStudy : this.dataService.findStudy(this.studies, this.dataService.configSettings.modalityQuickOrder.defaultStudyDescription);
        var study = foundStudy.value ? foundStudy : defaultStudy;
        this.studyEnabled = !this.params.studyDescription;
        if (this.params.studyDescription) {
            this.selectedStudy = this.params.studyDescription;
            this.selectedStudyObject = {
                code: '-1',
                studyDescription: this.params.studyDescription
            };
        }
        else {
            this.selectedStudy = this.selectedStudyObject && this.selectedStudyObject.value ? this.selectedStudyObject.value : study.value;
            this.selectedStudyObject = study.value ? study : undefined;
        }
    };
    ModalityComponent.prototype.togglePageView = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.showOrdersTable) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setupOrdersTable()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.reset();
                        _a.label = 3;
                    case 3:
                        this.showOrdersTable = !this.showOrdersTable;
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.updateReasonForStudy = function ($event) {
        this.reasonForStudy = $event.target.value;
    };
    ModalityComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var encounterProvider, sessionOptions, session, modality, _a, response, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        this.captureInProgress = true;
                        this.dataService.showNavigationWarning = true;
                        this.providersSelect.disable();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, 6, 7]);
                        encounterProvider = {};
                        if (this.selectedProvider) {
                            encounterProvider = {
                                'encounterProviderId': this.selectedProvider.id,
                                'encounterProviderName': this.selectedProvider.name.lastName + ", " + this.selectedProvider.name.firstName
                            };
                        }
                        sessionOptions = __assign({ appName: 'modality', appAction: 'startExam', prov: this.params.prov, op: this.params.op, demo: this.params.demo, timeoutMinutes: this.dataService.configSettings.modalityQuickOrder.sessionTimeoutPeriod }, encounterProvider);
                        return [4 /*yield*/, this.sessionService.createSession(this.params, sessionOptions)];
                    case 2:
                        session = _b.sent();
                        this.manifestRoute = session.manifests + '/';
                        this.version = session.version;
                        modality = _.first(this.selectedStudyObject.modality);
                        this.manifestPostData = {
                            'session': session.key,
                            'patient.lastName': this.params.lastName,
                            'patient.firstName': this.params.firstName,
                            'patient.middleName': this.params.middleName,
                            'patient.title': this.params.title,
                            'patient.suffix': this.params.suffix,
                            'patient.externalId': this.params.mrn,
                            'patient.dateOfBirth': this.dateFormatService.formatDate(this.params.dob),
                            'patient.gender': this.params.gender,
                            'batch.size': 0,
                            'batch.sizeRemaining': 0,
                            'examCode': this.selectedStudyObject.code === '-1' ? '' : this.selectedStudyObject.code,
                            'studyDescription': this.selectedStudyObject.studyDescription,
                            'modality': this.params.modality ? this.params.modality : modality,
                            'sendToPACS': this.params.sendToPACS,
                            'sendToNuance': this.params.sendToNuance,
                            'sendToXDSb': this.params.sendToXDSb,
                            'reasonForStudy': this.reasonForStudy,
                            'xdsPatientId': this.params.xdsPatientId,
                            'xdsSourcePatientId': this.params.xdsSourcePatientId
                        };
                        _a = this;
                        return [4 /*yield*/, this.manifestService.createManifest(this.manifestRoute, this.manifestPostData)];
                    case 3:
                        _a.manifest = _b.sent();
                        return [4 /*yield*/, this.singleFileUpload(this.manifest.files)];
                    case 4:
                        response = _b.sent();
                        this.accessionNumber = response.accessionNumber;
                        this.qrCodeData = this.generateQrCode();
                        this.startStatus(this.manifest);
                        return [3 /*break*/, 7];
                    case 5:
                        error_3 = _b.sent();
                        this.currentlyUpdating = false;
                        this.errorStatus = this.translate.instant('error.manifest');
                        throw error_3;
                    case 6:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.reset = function () {
        this.loading = false;
        this.captureInProgress = false;
        this.manifestId = undefined;
        this.accessionNumber = undefined;
        this.reasonForStudy = '';
        this.providersSelect.enable();
        this.stopStatus();
    };
    ModalityComponent.prototype.end = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.sessionService.updateAction('endExam')];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.singleFileUpload(this.manifest.files)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_4 = _a.sent();
                        throw error_4;
                    case 5:
                        this.reset();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.endOpenExam = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            var url, baseUrl, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, 9, 10]);
                        url = this.sessionService.getUrlPath(this.params.unittest) + "sessions/" + row.sessionKey;
                        if (!(row.appName === 'Modality')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.sessionService.updateAction('endExam', url)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.singleFileUpload(url + "/manifests/" + row.manifestId + "/files")];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        baseUrl = "" + this.sessionService.getUrlPath(this.params.unittest);
                        return [4 /*yield*/, this.modalityService.endExam(baseUrl, row.sessionKey, row.manifestId)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [4 /*yield*/, this.setupOrdersTable()];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 10];
                    case 8:
                        error_5 = _a.sent();
                        throw error_5;
                    case 9:
                        this.reset();
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.cancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, this.sessionService.updateAction('cancelExam')];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.singleFileUpload(this.manifest.files)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_6 = _a.sent();
                        throw error_6;
                    case 5:
                        this.reset();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.promptCancel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cancelModality = this.dialogService.open(cancel_modality_component_1.CancelModalityComponent, {
                            minWidth: 300,
                            disableClose: true
                        });
                        return [4 /*yield*/, this.cancelModality.afterClosed().toPromise()];
                    case 1:
                        response = _a.sent();
                        if (response.status) {
                            this.cancel();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.promptCancelOpenOrder = function (row) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cancelModality = this.dialogService.open(cancel_modality_component_1.CancelModalityComponent, {
                            minWidth: 300,
                            disableClose: true
                        });
                        return [4 /*yield*/, this.cancelModality.afterClosed().toPromise()];
                    case 1:
                        response = _a.sent();
                        if (response.status) {
                            this.cancelOpenOrder(row);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.cancelOpenOrder = function (order) {
        return __awaiter(this, void 0, void 0, function () {
            var url, baseUrl, error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, 9, 10]);
                        url = this.sessionService.getUrlPath(this.params.unittest) + "sessions/" + order.sessionKey;
                        if (!(order.appName === 'Modality')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.sessionService.updateAction('cancelExam', url)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.singleFileUpload(url + "/manifests/" + order.manifestId + "/files")];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        baseUrl = "" + this.sessionService.getUrlPath(this.params.unittest);
                        return [4 /*yield*/, this.modalityService.cancelExam(baseUrl, order.sessionKey, order.manifestId)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [4 /*yield*/, this.setupOrdersTable()];
                    case 7:
                        _a.sent();
                        return [3 /*break*/, 10];
                    case 8:
                        error_7 = _a.sent();
                        throw error_7;
                    case 9:
                        this.reset();
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ModalityComponent.prototype.stopStatus = function () {
        clearInterval(this.stop);
        this.stop = undefined;
    };
    ModalityComponent.prototype.startStatus = function (manifest) {
        // Don't start a new interval if we are already have one, this prevents memory leaks.
        this.checkStatus();
        var pollingInterval = 3000;
        this.stop = setInterval(this.checkStatus, pollingInterval);
    };
    ModalityComponent.prototype.parseResponse = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var hasError;
            return __generator(this, function (_a) {
                hasError = response
                    .some(function (i) { return i.transmissionStatusSummaryHL7 && i.transmissionStatusSummaryHL7.ERROR > 0; });
                if (hasError) {
                    this.clearAndShowError('error.hl7');
                }
                return [2 /*return*/];
            });
        });
    };
    ModalityComponent.prototype.clearAndShowError = function (error) {
        if (error === void 0) { error = 'error.general'; }
        this.errorStatus = this.translate.instant(error);
    };
    ModalityComponent.prototype.singleFileUpload = function (files) {
        var formData = {
            'type': 'NONE',
            'diskPath': 'unknown',
            'clientDateCreated': '',
            'fileContent': '',
            'newlockKey': this.guidService.generateGuid()
        };
        return this.dataService.sendImage(files, formData, 0);
    };
    ModalityComponent.prototype.updateStudyDescription = function (selected) {
        this.selectedStudyObject = selected;
        this.selectedStudy = this.selectedStudyObject.value;
    };
    ModalityComponent.prototype.updateDescription = function (id, displayValue) {
        return __awaiter(this, void 0, void 0, function () {
            var queryUrl, error_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryUrl = this.manifestRoute + id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.dataService.updateStudyDescription(queryUrl, displayValue, this.selectedStudy)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_8 = _a.sent();
                        throw error_8;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // 'MRN|Last|First|Middle|Title|Suffix|DOBYear|DOBMonth|DOBDay|Operator|Accession'
    ModalityComponent.prototype.generateQrCode = function () {
        var dobProvided = !!this.params.dob;
        return this.params.mrn + "|" + this.params.lastName + "|" + this.params.firstName + "|" + this.params.middleName + "|" +
            (this.params.title + "|" + this.params.suffix + "|" + (dobProvided ? this.params.dob.substring(0, 4) : '') + "|") +
            ((dobProvided ? this.params.dob.substring(4, 6) : '') + "|" + (dobProvided ? this.params.dob.substring(6, 8) : '') + "|") +
            (this.params.operator + "|" + this.accessionNumber);
    };
    ModalityComponent.prototype.showInstructions = function () {
        return this.customerConfigs.hasOwnProperty('show_instructions') ? this.customerConfigs.show_instructions : true;
    };
    ModalityComponent.prototype.hasCustomInstructions = function () {
        return this.customerConfigs.custom_instructions && this.customerConfigs.custom_instructions.length > 0;
    };
    ModalityComponent.prototype.getCustomInstructions = function () {
        return this.customerConfigs.custom_instructions;
    };
    ModalityComponent.prototype.ngOnDestroy = function () {
        this.reset();
        this.document.body.classList.remove(this.customerConfigs.theme);
        this.dataService.showNavigationWarning = false;
        clearInterval(this.ordersListener);
        this.ordersListener = null;
    };
    return ModalityComponent;
}());
exports.ModalityComponent = ModalityComponent;
