"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var i0 = require("@angular/core");
var NameParseService = /** @class */ (function () {
    function NameParseService() {
    }
    NameParseService.prototype.parseName = function (name) {
        if (_.includes(name, '^')) {
            return this.split(name, '^');
        }
        if (_.includes(name, ',')) {
            return this.split(name, ',');
        }
        if (_.includes(name, '|')) {
            return this.split(name, '|');
        }
        return {
            lastName: name === 'undefined' ? undefined : name
        };
    };
    NameParseService.prototype.split = function (name, delimiter) {
        var splitName = (decodeURIComponent(name).split(delimiter));
        splitName = splitName.map(function (i) { return i.trim(); });
        return {
            lastName: splitName.shift(),
            firstName: splitName.shift() || '',
            middleName: splitName.shift() || '',
            title: splitName.shift() || '',
            suffix: splitName.shift() || ''
        };
    };
    NameParseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NameParseService_Factory() { return new NameParseService(); }, token: NameParseService, providedIn: "root" });
    return NameParseService;
}());
exports.NameParseService = NameParseService;
