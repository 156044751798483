"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var translate_service_1 = require("../../translation/translate.service");
var DestinationSelectorComponent = /** @class */ (function () {
    function DestinationSelectorComponent(translate, cdr) {
        this.translate = translate;
        this.cdr = cdr;
        this.change = new core_1.EventEmitter();
        this.serverPostLocationChange = new core_1.EventEmitter();
        this.selectedDestinationChange = new core_1.EventEmitter();
        this.nuanceStudyTagIdsChange = new core_1.EventEmitter();
        this.destination = {
            studyTags: {
                studyTag: []
            }
        };
    }
    DestinationSelectorComponent.prototype.ngOnInit = function () {
        this.displayOnly = [this.config.pacs, this.config.nuance, this.config.xdsb].filter(function (i) { return i; }).length < 2;
    };
    DestinationSelectorComponent.prototype.changed = function ($event) {
        this.serverPostLocationChange.emit(this.serverPostLocation);
        this.change.emit($event);
        this.cdr.markForCheck();
    };
    DestinationSelectorComponent.prototype.destinationChange = function ($event) {
        this.selectedDestinationChange.emit($event.value);
        var destination = this.nuanceDestinations.find(function (i) { return i.id === $event.value; });
        if (destination.studyTags) {
            this.destination = destination;
        }
        else {
            this.destination = {
                studyTags: {
                    studyTag: []
                }
            };
        }
        this.cdr.markForCheck();
    };
    DestinationSelectorComponent.prototype.selectTag = function ($event, destination) {
        destination.checked = $event.checked;
        this.nuanceStudyTagIds = this.destination.studyTags.studyTag.filter(function (i) { return i.checked; }).map(function (i) { return i.id; }).join(',');
        this.nuanceStudyTagIdsChange.emit(this.nuanceStudyTagIds);
    };
    return DestinationSelectorComponent;
}());
exports.DestinationSelectorComponent = DestinationSelectorComponent;
