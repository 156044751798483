import { MediaComponent } from './media/media.component';
import { DicomComponent } from './dicom/dicom.component';
import { ModalityComponent } from './modality/modality.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { StudyDescriptionResolver } from './resolvers/studyDescription.resolver';
import { ConfigResolver } from './resolvers/config.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { TransferComponent } from './transfer/transfer.component';

const routes: Routes = [
  {
    path: '',
    resolve: { config: ConfigResolver, studies: StudyDescriptionResolver, },
    children: [
      {
        path: 'qrcode',
        component: QrcodeComponent,
      },
      {
        path: 'dicom',
        component: DicomComponent
      },
      {
        path: 'media',
        component: MediaComponent
      },
      {
        path: 'modality',
        component: ModalityComponent
      },
      {
        path: 'dicom-image-exchange',
        component: PatientHistoryComponent
      },
      {
        path: 'sync',
        component: TransferComponent
      },
      {
        path: 'rounds',
        loadChildren: () => import('./rounds/rounds.module').then(m => m.RoundsModule)
      },
      {
        path: 'fhir/app',
        redirectTo: '/'

      },
      {
        path: 'integration/app',
        redirectTo: '/'
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  providers: [
    ConfigResolver,
    StudyDescriptionResolver
  ]
})
export class AppRoutingModule { }
