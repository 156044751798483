"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Media;
(function (Media) {
    var Counts = /** @class */ (function () {
        function Counts() {
            this.SUCCESS = 0;
            this.ERROR = 0;
            this.PROCESSING = 0;
            this.CANCELLED = 0;
            this.totalFiles = 0;
            this.filesReceived = 0;
        }
        return Counts;
    }());
    Media.Counts = Counts;
    function getMediaChildren(fileArray) {
        var files = [];
        // Extract all children from the file array into one master array
        fileArray.forEach(function (file) { return files.push.apply(files, file.children); });
        return files;
    }
    Media.getMediaChildren = getMediaChildren;
})(Media = exports.Media || (exports.Media = {}));
