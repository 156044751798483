import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '../../translation/translate.service';

@Component({
  selector: 'app-navigation-warning',
  templateUrl: './navigation-warning.component.html',
  styleUrls: ['./navigation-warning.component.scss']
})
export class NavigationWarningComponent {

  constructor(
    private dialogRef: MatDialogRef<NavigationWarningComponent>,
    public translate: TranslateService) { }

  submit() {
    this.dialogRef.close({
      rejectNavigation: false
    });
  }

  cancel() {
    this.dialogRef.close({
      rejectNavigation: true
    });
  }

}
