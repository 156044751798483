import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

interface MWLQuery {
  patientId?: string,
  firstName?: string,
  lastName?: string,
  accessionNumber?: string,
  dob?: string,
  sex?: string
}

@Injectable({
  providedIn: 'root'
})
export class ModalityWorklistService {

  baseUrl: string = `${environment.production ? '' : 'http://localhost:3000'}/pronghorn/v1/dicom/queryMwl`;
  mwlAvailable: boolean = false;

  constructor( private http: HttpClient ) {
    this.checkForMWL();
  }

  checkForMWL() {
    this
      .queryModalityWorklist({})
      .then(() => this.mwlAvailable = true)
      .catch(() => this.mwlAvailable = false);
  }

  queryModalityWorklist(query: MWLQuery) {
    const queryParams = new URLSearchParams(Object.entries(query));

    return this.http.get(`${this.baseUrl}?${queryParams.toString()}`).toPromise();
  }
}
