"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ModalityService = /** @class */ (function () {
    function ModalityService(http) {
        this.http = http;
    }
    ModalityService.prototype.enableMocksLocally = function () {
        return !environment_1.environment.production;
    };
    ModalityService.prototype.endExam = function (baseUrl, sessionKey, manifestId) {
        return this.doModalityAction(baseUrl, sessionKey, manifestId, 'endExam');
    };
    ModalityService.prototype.cancelExam = function (baseUrl, sessionKey, manifestId) {
        return this.doModalityAction(baseUrl, sessionKey, manifestId, 'cancelExam');
    };
    ModalityService.prototype.getOpenOrders = function (mrn, baseUrl) {
        if (false && this.enableMocksLocally()) {
            var data = [
                {
                    accessionId: 'MJC999000000128',
                    appName: 'Modality',
                    authProvider: 'Jeffrey Englewood MD',
                    encDept: 'Emergency',
                    reasonForStudy: 'Lacerations of the ankle',
                    timestamp: '2020-03-16T11:38:04.562-05:00',
                    status: 'Pending',
                    studyDescription: 'Patient Photo',
                    manifestId: 1,
                    sessionKey: 1
                },
                {
                    accessionId: 'MJC999000000127',
                    appName: 'Modality',
                    authProvider: 'Alex Smith MD',
                    manifestId: 1,
                    reasonForStudy: 'POC stuff',
                    sessionKey: 2,
                    timestamp: '2020-03-16T11:39:04.562-05:00',
                    studyDescription: 'POC ULTRASOUND',
                    encDept: 'Dermatology',
                    status: 'Completed',
                },
                {
                    accessionId: 'MJC999000000126',
                    appName: 'Modality',
                    authProvider: 'First^Last',
                    manifestId: 1,
                    reasonForStudy: 'derm issues',
                    sessionKey: 3,
                    timestamp: '2020-03-16T11:40:04.562-05:00',
                    studyDescription: 'DERM PHOTO',
                    encDept: 'ICU',
                    status: 'Failure'
                },
                {
                    accessionId: 'MJC999000000134',
                    appName: 'Modality',
                    authProvider: 'First^Last',
                    encDept: 'Derm',
                    manifestId: 1,
                    reasonForStudy: 'asdf',
                    sessionKey: 1,
                    status: 'Pending',
                    studyDescription: 'DERM PHOTO',
                    timestamp: '2020-03-16T11:41:04.562-05:00',
                },
                {
                    'accessionId': 'MJC999000000137',
                    'appName': 'Modality',
                    'authProvider': 'First^Last',
                    'encDept': 'Derm',
                    'manifestId': 2,
                    'reasonForStudy': 'asdf',
                    'sessionKey': 2,
                    'status': 'Failure',
                    'studyDescription': 'DERM PHOTO',
                    timestamp: '2020-03-16T11:49:04.562-05:00',
                },
                {
                    'accessionId': 'MJC999000000136',
                    'appName': 'Modality',
                    'authProvider': 'Test^Test',
                    'encDept': 'Derm',
                    'manifestId': 1,
                    'reasonForStudy': 'asdf',
                    'sessionKey': 1,
                    'status': 'Pending',
                    'studyDescription': 'DERM PHOTO',
                    timestamp: '2020-03-16T12:38:04.562-05:00',
                }
            ];
            return Promise.resolve(data);
        }
        return this.http.get(baseUrl + "patient/getOrdersPerMRN/" + mrn).toPromise();
    };
    ModalityService.prototype.doModalityAction = function (baseUrl, sessionKey, manifestId, action) {
        var url = baseUrl + "patient/" + action + "/" + sessionKey + "/" + manifestId;
        return this.http.post(url, {}).toPromise();
    };
    ModalityService.prototype.toQueryString = function (paramsObject) {
        return Object
            .keys(paramsObject)
            .map(function (key) { return encodeURIComponent(key) + "=" + encodeURIComponent(paramsObject[key]); })
            .join('&');
    };
    ModalityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalityService_Factory() { return new ModalityService(i0.ɵɵinject(i1.HttpClient)); }, token: ModalityService, providedIn: "root" });
    return ModalityService;
}());
exports.ModalityService = ModalityService;
