"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var translate_service_1 = require("../../translation/translate.service");
var data_service_1 = require("../../services/data.service");
var CancelModalityComponent = /** @class */ (function () {
    function CancelModalityComponent(dialogRef, dataService, translate) {
        this.dialogRef = dialogRef;
        this.dataService = dataService;
        this.translate = translate;
    }
    CancelModalityComponent.prototype.ngOnInit = function () {
        var params = this.dataService.setupData({});
    };
    CancelModalityComponent.prototype.close = function (status) {
        this.dialogRef.close({
            status: status
        });
    };
    return CancelModalityComponent;
}());
exports.CancelModalityComponent = CancelModalityComponent;
