"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var url_parameter_service_1 = require("./../../services/url-parameter.service");
var configuration_service_1 = require("./../../services/configuration.service");
var core_1 = require("@angular/core");
var translate_service_1 = require("../../translation/translate.service");
var dialog_1 = require("@angular/material/dialog");
var BodyView;
(function (BodyView) {
    BodyView[BodyView["Anterior"] = 0] = "Anterior";
    BodyView[BodyView["Posterior"] = 1] = "Posterior";
})(BodyView || (BodyView = {}));
var BodyMapDialogComponent = /** @class */ (function () {
    function BodyMapDialogComponent(configService, translate, urlService, dialogRef, data) {
        this.configService = configService;
        this.translate = translate;
        this.urlService = urlService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.Views = BodyView;
        this.view = BodyView.Anterior;
        this.showUnspecifiedButton = true;
        this.relatedList = { before: [], selected: undefined, after: [], children: [] };
        this.selectedModifiers = [];
    }
    BodyMapDialogComponent.prototype.ngOnInit = function () {
        // Get 2 character language reference to match body-mappings files
        this.localization = this.data.lang.slice(0, 2);
        this.setup();
    };
    BodyMapDialogComponent.prototype.submit = function (applyToUnspecified) {
        if (applyToUnspecified === void 0) { applyToUnspecified = false; }
        var result;
        if (!this.selectedBodyPart) {
            result = { name: this.translate.instant('table.notSpecified'), code: 'NOT SPECIFIED' };
        }
        else {
            result = {
                name: this.generateBodyPartString(),
                bodyPart: this.selectedBodyPart,
                modifiers: this.selectedModifiers,
                view: this.view,
                orientation: this.bodyMaps[this.view].orientation,
                relatedList: this.relatedList
            };
        }
        this.dialogRef.close({ bodyPart: result, applyToUnspecified: applyToUnspecified });
    };
    BodyMapDialogComponent.prototype.setView = function (viewID) {
        this.view = viewID;
        this.resetSelection();
    };
    /**
     * Set string to pascal case
     * @param {String} string String to be transformed
     * @example
     * // returns Test
     * pascalCase('test')
     *
     * @example
     * // returns TestComponent
     * pascalCase('test_component')
     */
    BodyMapDialogComponent.prototype.pascalCase = function (string) {
        if (string.length === 0) {
            return '';
        }
        return string
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/\s+(.)(\w+)/, 'g'), function (group1, group2, group3) { return "" + (group2.toUpperCase() + group3.toLowerCase()); })
            .replace(new RegExp(/\w/), function (s) { return s.toUpperCase(); });
    };
    BodyMapDialogComponent.prototype.generateBodyPartString = function () {
        return (this.getModifierString().trim() + " " + this.getLocalizedParentString(this.relatedList) + " " + this.getLocalizedBodyPartString(this.selectedBodyPart)).trim();
    };
    /**
     * Get localization string from BodyPart object using the component's localization property
     * @param {BodyPart} bodyPart
     */
    BodyMapDialogComponent.prototype.getLocalizedBodyPartString = function (bodyPart) {
        if (!bodyPart) {
            return '';
        }
        if (!bodyPart.localizedStrings[this.localization]) {
            return bodyPart.code;
        }
        try {
            return bodyPart.localizedStrings[this.localization].label;
        }
        catch (err) {
            console.error(bodyPart);
            return '';
        }
    };
    /**
   * Get localization string from RelatedList object parent using the component's localization property
   * @param {RelatedMaps} relatedList
   */
    BodyMapDialogComponent.prototype.getLocalizedParentString = function (relatedList) {
        if (!relatedList || !relatedList.parent || (relatedList.parent && relatedList.parent.length === 0)) {
            return '';
        }
        if (!relatedList.parent || !relatedList.parent[0].prependLabelToSubParts) {
            return '';
        }
        if (!relatedList.parent[0].localizedStrings[this.localization]) {
            return relatedList.parent[0].code;
        }
        try {
            return relatedList.parent[0].localizedStrings[this.localization].label;
        }
        catch (err) {
            console.error(relatedList);
            return '';
        }
    };
    /**
     * Get localization string from the modifier object using the component's localization property
     * @param {BodyPart} bodyPart
     */
    BodyMapDialogComponent.prototype.getLocalizedModifierString = function (modifier) {
        console.dir(modifier);
        if (!modifier) {
            return '';
        }
        return modifier.localizedStrings[this.localization].label;
    };
    /**
     * Generate string of selected modifiers
     */
    BodyMapDialogComponent.prototype.getModifierString = function () {
        var _this = this;
        return this.selectedModifiers
            .map(function (item) { return _this.getLocalizedModifierString(item.value.find(function (modifier) { return modifier.code === item.selected; })); })
            .join(' ');
    };
    BodyMapDialogComponent.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var bodyMappings, BodyMapJSON, inputBodyPart;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.data.file) return [3 /*break*/, 1];
                        bodyMappings = this.data.file;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.configService.getBodyParts()];
                    case 2:
                        BodyMapJSON = _a.sent();
                        bodyMappings = BodyMapJSON.bodyMappings;
                        _a.label = 3;
                    case 3:
                        this.showUnspecifiedButton = this.data.fileCount >= 2;
                        this.bodyMaps = bodyMappings.maps;
                        this.modifiers = bodyMappings.modifierTables.reduce(function (acc, cur) {
                            var _a;
                            return (__assign({}, acc, (_a = {}, _a[cur.name] = cur.categories, _a)));
                        }, {});
                        this.customBodyMaps = bodyMappings.customBodyMaps;
                        this.relatedList.before = this.bodyMaps[this.view].regions.slice(0, 5);
                        inputBodyPart = this.data.bodyPart;
                        if (inputBodyPart.name === this.translate.instant('media.not-anatomical')) {
                            return [2 /*return*/];
                        }
                        if (inputBodyPart.view) {
                            this.view = inputBodyPart.view;
                        }
                        // If user had previously selected a body part, set that as selected
                        if (inputBodyPart.bodyPart) {
                            this.setBodyPart(null, inputBodyPart.bodyPart);
                            this.selectedModifiers = inputBodyPart.modifiers;
                            this.relatedList = inputBodyPart.relatedList;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Set body part selected from guided button
     * @param {MouseEvent} event Event triggered by clicking on the button
     * @param {BodyPart} bodyPart Body part associated with that button
     */
    BodyMapDialogComponent.prototype.setBodyPart = function (event, bodyPart) {
        // Catch if user clicks on the same body part
        if (this.selectedBodyPart && bodyPart.code === this.selectedBodyPart.code) {
            return;
        }
        var previousBodyPart = this.selectedBodyPart;
        this.selectedBodyPart = bodyPart;
        this.relatedList = this.getRelated(bodyPart);
        this.selectedModifiers = this.getModifiers(this.selectedBodyPart);
        // Check if element is child of previous selection and append parent if it is
        if (previousBodyPart && previousBodyPart.children && previousBodyPart.children.findIndex(function (item) { return item.code === bodyPart.code; }) !== -1) {
            this.relatedList.parent = [previousBodyPart];
        }
    };
    /**
     * Set body part from svg body map.  The map stores body part names as part of the data-name attribute.
     * This function can be placed on the <svg> element and using event bubbling the target will still be the individual body part
     * @param {MouseEvent} event Event triggered by clicking on the body part
     */
    BodyMapDialogComponent.prototype.selectBodyPartFromMap = function (event) {
        // Get Element Data Name
        var selectedElement = event.target.getAttribute('data-name');
        // Catch missing configuration to avoid errors
        if (!selectedElement) {
            console.error('Missing element name ', selectedElement);
            this.resetSelection();
            return;
        }
        // Catch if user clicks on the same body part
        if (this.selectedBodyPart && this.selectedBodyPart.code === selectedElement) {
            return;
        }
        // Find Body Mapping with Data Name
        this.selectedBodyPart = this.getBodyPart(selectedElement);
        if (!this.selectedBodyPart) {
            console.error('Body Part missing from config: ', selectedElement);
            return;
        }
        // Get Related Body Parts
        this.relatedList = this.getRelated(this.selectedBodyPart);
        this.selectedModifiers = this.getModifiers(this.selectedBodyPart);
    };
    /**
     * Get the modifier objects that are listed in the BodyPart
     * @param {BodyPart} bodyPart BodyPart to be used to extract the modifiers from
     * @returns {ModifierMap[]} An array of Modifiers from the the modifier table that are associated with a specific BodyPart
     */
    BodyMapDialogComponent.prototype.getModifiers = function (bodyPart) {
        var _this = this;
        if (!bodyPart.modifierTables) {
            return [];
        }
        return bodyPart.modifierTables.map(function (item) { return ({ name: item, value: _this.modifiers[item], selected: '' }); });
    };
    /**
     * Updates modifier to reflect user selection
     * @param {MouseEvent} event Event triggered by clicking on the body part
     * @param {Number} modIndex Index of the modifier in the selectedModifiers array
     */
    BodyMapDialogComponent.prototype.selectModifier = function (event, modIndex) {
        this.selectedModifiers[modIndex].selected = event.target.checked ? event.target.id : '';
    };
    /**
     * Gets map of body parts related to by proximity or lineage to a specific element
     * @param {BodyPart} bodyPart BodyPart to be used to as the anchor
     * @returns {RelatedMaps} Object that defines elements in proximity
     */
    BodyMapDialogComponent.prototype.getRelated = function (bodyPart) {
        /* if (bodyPart.before || bodyPart.after ) {
          const beforeArray = this.toArray(bodyPart.before);
          const afterArray = this.toArray(bodyPart.after);
    
          const beforeElements = beforeArray.map(item => this.getBodyPart(item));
          const afterElements = afterArray.map(item => this.getBodyPart(item));
    
          return { before: beforeElements, selected: bodyPart, after: afterElements, children: [] };
        } */
        var _this = this;
        if (bodyPart.before || bodyPart.children) {
            var beforeArray = this.toArray(bodyPart.before);
            var childArray = bodyPart.children;
            var beforeElements = beforeArray.map(function (item) { return _this.getBodyPart(item); });
            return { before: beforeElements, selected: bodyPart, after: [], children: childArray };
        }
        if (bodyPart.after) {
            var afterArray = this.toArray(bodyPart.after);
            var afterElements = afterArray.map(function (item) { return _this.getBodyPart(item); });
            return { before: [], selected: bodyPart, after: afterElements, children: [] };
        }
        return { before: [], selected: bodyPart, after: [], children: [] };
    };
    /**
     * Retrieve body part information from config file using the 'code' property.  The current view is reviewed first, if the application cannot find a match it will check all body parts
     * @param {String} partCode Value to be used to look up the body part in the body mappings file
     * @returns {BodyPart} Body part found in the body mappings file
     */
    BodyMapDialogComponent.prototype.getBodyPart = function (partCode) {
        var viewResult = this.bodyMaps[this.view].regions.find(function (bodyPart) { return bodyPart.code === partCode; });
        // If result found in current view, return it
        if (viewResult) {
            return viewResult;
        }
        // Else check all provided maps
        var allMaps;
        this.bodyMaps.forEach(function (map) {
            var matchingBodyPart = map.regions.find(function (bodyPart) { return bodyPart.code === partCode; });
            if (matchingBodyPart) {
                allMaps = matchingBodyPart;
            }
        });
        return allMaps || {};
    };
    /**
     * Process input and return a string array.  If the item is a string array, it is returned as is.
     * @param {String | String[] | undefined } item Item to be transformed into an array
     */
    BodyMapDialogComponent.prototype.toArray = function (item) {
        if (!item) {
            return [];
        }
        if (Array.isArray(item)) {
            return item;
        }
        return [item];
    };
    /**
     * Reset the modal
     */
    BodyMapDialogComponent.prototype.resetSelection = function () {
        this.selectedBodyPart = null;
        this.relatedList = { before: this.bodyMaps[this.view].regions.slice(0, 5), after: [], children: [], parent: [], selected: null };
        this.selectedModifiers = [];
    };
    return BodyMapDialogComponent;
}());
exports.BodyMapDialogComponent = BodyMapDialogComponent;
