"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cancel-modality.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/a11y");
var i5 = require("@angular/platform-browser/animations");
var i6 = require("./cancel-modality.component");
var i7 = require("@angular/material/dialog");
var i8 = require("../../services/data.service");
var i9 = require("../../translation/translate.service");
var styles_CancelModalityComponent = [i0.styles];
var RenderType_CancelModalityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CancelModalityComponent, data: {} });
exports.RenderType_CancelModalityComponent = RenderType_CancelModalityComponent;
function View_CancelModalityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "form-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(5, 0, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(8, 0, [" ", " "]))], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 4, 0, currVal_3); var currVal_7 = "accent"; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate.instant("phrase.cancelExam"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 4).disabled || null); var currVal_2 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = _co.translate.instant("button.close"); _ck(_v, 5, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 7).disabled || null); var currVal_6 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_8 = _co.translate.instant("button.cancelExam"); _ck(_v, 8, 0, currVal_8); }); }
exports.View_CancelModalityComponent_0 = View_CancelModalityComponent_0;
function View_CancelModalityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cancel-modality", [], null, null, null, View_CancelModalityComponent_0, RenderType_CancelModalityComponent)), i1.ɵdid(1, 114688, null, 0, i6.CancelModalityComponent, [i7.MatDialogRef, i8.DataService, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CancelModalityComponent_Host_0 = View_CancelModalityComponent_Host_0;
var CancelModalityComponentNgFactory = i1.ɵccf("app-cancel-modality", i6.CancelModalityComponent, View_CancelModalityComponent_Host_0, {}, {}, []);
exports.CancelModalityComponentNgFactory = CancelModalityComponentNgFactory;
