<div mat-dialog-title class="dialog-title">
  <h2>{{translate.instant('phrase.selectExistingExam')}}</h2>
  <button mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<table mat-table [dataSource]="studiesTable">
  <ng-container matColumnDef="accessionNumber">
    <th mat-header-cell class="row" *matHeaderCellDef> {{translate.instant('table.accessionNumber')}} </th>
    <td mat-cell *matCellDef="let element"> {{element.accessionNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="studyDescription">
    <th mat-header-cell class="row" *matHeaderCellDef> {{translate.instant('table.study')}} </th>
    <td mat-cell *matCellDef="let element" class="date-column row"> {{element.studyDescription}} </td>
  </ng-container>

  <ng-container matColumnDef="modality">
    <th mat-header-cell class="row" *matHeaderCellDef> {{translate.instant('table.modality')}} </th>
    <td mat-cell *matCellDef="let element" class="row"> {{element.modality}} </td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell class="row" *matHeaderCellDef> Study Date </th>
    <td mat-cell *matCellDef="let element" class="row"> {{element.displayDate}} </td>
  </ng-container>

  <ng-container matColumnDef="startTime">
    <th mat-header-cell class="row" *matHeaderCellDef> Study Time </th>
    <td mat-cell *matCellDef="let element" class="row"> {{element.startTime}} </td>
  </ng-container>

  <ng-container matColumnDef="performingPhysician">
    <th mat-header-cell class="row" *matHeaderCellDef> {{translate.instant('table.physician')}} </th>
    <td mat-cell *matCellDef="let element" class="row"> {{element.performingPhysician}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell class="row" *matHeaderCellDef> {{translate.instant('table.status')}} </th>
    <td mat-cell *matCellDef="let element" class="row"> {{element.status}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
</table>

<section *ngIf="viewState === VIEW_STATES.Ready && examList.length === 0" class="empty-container">
  <p>{{translate.instant('standalone.noOrders')}}</p>
</section>

<section *ngIf="viewState === VIEW_STATES.Loading" class="loading-container">
  <p>{{ translate.instant('standalone.queryMWL') }}</p>
  <mat-progress-spinner
      [color]="primary"
      [mode]="'indeterminate'"
  ></mat-progress-spinner>
</section>

<section *ngIf="viewState === VIEW_STATES.Error" class="error-container">
  <mat-icon>error_outline</mat-icon>
  <p>{{ translate.instant('standalone.error') }}</p>
  <p>{{ translate.instant('standalone.error.noMWL') }}</p>
</section>

<mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10]"></mat-paginator>

<mat-dialog-actions>
  <button mat-flat-button color="primary" (click)="refresh()" *ngIf="viewState !== VIEW_STATES.Error">
    <mat-icon>refresh</mat-icon>
    <span>Refresh Orders</span>
  </button>

  <button mat-flat-button color="primary" (click)="refresh()" *ngIf="viewState === VIEW_STATES.Error" [disabled]="!allowRefresh">
    <mat-icon>refresh</mat-icon>
    <span>Try Again</span>
  </button>
</mat-dialog-actions>