import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '../../translation/translate.service';
import { PDFDocumentProxy } from 'pdfjs-dist';

@Component({
  selector: 'app-pdf-preview-modal',
  templateUrl: './pdf-preview-modal.component.html',
  styleUrls: ['./pdf-preview-modal.component.scss']
})
export class PdfPreviewModalComponent {

  pdfTitle: string;
  pdfFile;
  isLoading = true;

  constructor(
    public translate: TranslateService,
    private materialDialogRefererence: MatDialogRef<PdfPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pdfTitle = data.file.name;

    // TODO TDK 06/29/2021
    // When user selects a non PDF file (See: WITS-6429) then request a resized image
    // from pronghorn to display as a preview
    
    // Read file into memory so that it can be viewed by the PDF viewer
    const fileReader = new FileReader();
    fileReader.onload = (event: any) => {
      this.pdfFile = this.convertBase64ToUInt8({ dataUrl: event.target.result });
    }

    fileReader.readAsDataURL(data.file);
   }

  closeDialog() {
    this.materialDialogRefererence.close();
  }

  pdfDoneLoading(pdf: PDFDocumentProxy) {
    this.isLoading = false;
  }

  private convertBase64ToUInt8(pdfFile) {
    const BASE64_MARKER = ';base64,';

    const base64Index = pdfFile.dataUrl.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = pdfFile.dataUrl.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

}
