"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./pdf-preview-modal.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory");
var i3 = require("@angular/material/progress-spinner");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/common");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("../../../../node_modules/ng2-pdf-viewer/ng2-pdf-viewer.ngfactory");
var i8 = require("ng2-pdf-viewer");
var i9 = require("@angular/material/dialog");
var i10 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i11 = require("@angular/material/button");
var i12 = require("@angular/cdk/a11y");
var i13 = require("./pdf-preview-modal.component");
var i14 = require("../../translation/translate.service");
var styles_PdfPreviewModalComponent = [i0.styles];
var RenderType_PdfPreviewModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PdfPreviewModalComponent, data: {} });
exports.RenderType_PdfPreviewModalComponent = RenderType_PdfPreviewModalComponent;
function View_PdfPreviewModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PdfPreviewModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "pdf-viewer", [], null, [[null, "after-load-complete"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onPageResize() !== false);
        ad = (pd_0 && ad);
    } if (("after-load-complete" === en)) {
        var pd_1 = (_co.pdfDoneLoading($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_PdfViewerComponent_0, i7.RenderType_PdfViewerComponent)), i1.ɵdid(2, 9158656, null, 0, i8.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], renderText: [1, "renderText"] }, { afterLoadComplete: "after-load-complete" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pdfFile; var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PdfPreviewModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i9.MatDialogTitle, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], null, null), (_l()(), i1.ɵted(2, null, [" ", "\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i9.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfPreviewModalComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfPreviewModalComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i9.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(13, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _co.isLoading; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.pdfFile; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.pdfTitle; _ck(_v, 2, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 12).disabled || null); var currVal_5 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_6 = _co.translate.instant("button.close"); _ck(_v, 13, 0, currVal_6); }); }
exports.View_PdfPreviewModalComponent_0 = View_PdfPreviewModalComponent_0;
function View_PdfPreviewModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-preview-modal", [], null, null, null, View_PdfPreviewModalComponent_0, RenderType_PdfPreviewModalComponent)), i1.ɵdid(1, 49152, null, 0, i13.PdfPreviewModalComponent, [i14.TranslateService, i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], null, null); }
exports.View_PdfPreviewModalComponent_Host_0 = View_PdfPreviewModalComponent_Host_0;
var PdfPreviewModalComponentNgFactory = i1.ɵccf("app-pdf-preview-modal", i13.PdfPreviewModalComponent, View_PdfPreviewModalComponent_Host_0, {}, {}, []);
exports.PdfPreviewModalComponentNgFactory = PdfPreviewModalComponentNgFactory;
