import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateFormatService } from './date-format.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  baseUrl: string; // TODO : Get rid of

  private session: any;

  constructor(
    private http: HttpClient,
    private dateFormatService: DateFormatService
  ) { }

  async createSession(params, customOptions): Promise<any> {
    const local = params.unittest;

    const sessionObject = _.assign({
      'externalId': params.acc,
      'date': this.dateFormatService.formatDate(params.date),
      'time': params.time,
      'mdname': params.mdname,
      'mode': params.mode,
      'encDept': params.encDept,
      'encDeptID': params.encDeptID,
      'sourceSite': params.sourceSite,
      'customData': params.customData
    }, customOptions);

    const SESSION_URL = 'sessions/';

    this.baseUrl = this.getUrlPath(local);
    const session = this.http.post(this.baseUrl + SESSION_URL, sessionObject, {}).toPromise();
    this.session = await session;
    return session;
  }

  updateSession(expectedFiles): Promise<any> {
    return this.http.put(this.session.self, expectedFiles).toPromise();
  }

  updateAction(action, url = this.session.self): Promise<any> {
    return this.http.put(url, { 'appAction': action }).toPromise();
  }

  getUrlPath(local): string {
    const QA = 'https://dev.imagemovermd.com';
    const SERVICE_URL = '/pronghorn/v1/';

    this.baseUrl = '';

    // Check to see if using local or remote path
    if ((typeof local !== 'undefined' && local !== null)) {
      if (local === 'y') {
        this.baseUrl = QA + SERVICE_URL;
      } else {
        this.baseUrl = decodeURIComponent(local) + SERVICE_URL;
      }
    } else {
      this.baseUrl = this.baseUrl + SERVICE_URL;
    }

    return this.baseUrl;
  }

  getSession() {
    return this.session;
  }

  setSession(session) {
    this.session = session;
  }

  transferSession(operator, location, query) {
    const data = new FormData();

    data.append('key', operator );
    data.append('value', JSON.stringify({ location, query, session: this.session }) );

    return this.http.post(`${this.baseUrl}sessions/${this.session.key}/transfer/`, data).toPromise();
  }

  async retrieveTransfer(operator): Promise<any> {
    return this.http.get(`${this.baseUrl}sessions/active/${operator}`).toPromise();
  }
}
