import { ManifestService } from './../services/manifest.service';
import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DataService } from '../services/data.service';
import { TranslateService } from '../translation/translate.service';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../services/session.service';
import { QrCodeService } from '../services/qr-code.service';

@Component({
  selector: 'app-rounds',
  templateUrl: './rounds.component.html',
  styleUrls: ['./rounds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundsComponent implements OnInit, OnDestroy {

  customerConfigs;
  qrcodeConfigs;
  loading;
  qrCodeData;
  errorStatus;
  minimumInformation = false;
  doctor = {} as any;
  serverPostLocation;
  selectedDestination;
  params;
  pinCode = '';

  constructor(
    public dataService: DataService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private manifestService: ManifestService,
    private qrCodeService: QrCodeService,
    private cdr: ChangeDetectorRef,
    @Inject('Document') private document: Document
  ) { }

  ngOnInit() {
    this.cdr.markForCheck();
    this.dataService.showNavigationWarning = false;
    this.params = this.dataService.setupData({});
    this.translate.use(this.params.lang);

    this.customerConfigs = this.route.snapshot.data.config.rounds;
    this.qrcodeConfigs = this.route.snapshot.data.config.qrcode;
    if (this.params.hideNavTabs) {
      DataService.hideNavTabs.next(this.params.hideNavTabs);
    } else {
      DataService.hideNavTabs.next(!this.customerConfigs.name);
    }

    this.document.body.classList.add(this.customerConfigs.theme);

    this.setup();
  }

  private async setup() {
    try {
      this.loading = true;
      this.cdr.markForCheck();

      const sessionOptions = {
        appName: 'rounds',
        op: this.params.op,
        demo: false,
        externalId: ''
      };

      if (this.customerConfigs.sessionTimeoutPeriod) {
        sessionOptions['timeoutMinutes'] = this.customerConfigs.sessionTimeoutPeriod;
      }

      this.minimumInformation = !!this.params.op;
      this.doctor.name = this.params.op;
      const newSession = await this.sessionService.createSession(this.params, sessionOptions);

      const manifestPostData = {
        'session': newSession.key,
        'patient.lastName': '',
        'patient.firstName': '',
        'patient.middleName': '',
        'patient.title': '',
        'patient.suffix': '',
        'patient.externalId': '',
        'patient.dateOfBirth': '',
        'patient.gender': '',
        'batch.size': 0,
        'batch.sizeRemaining': 0,
        'sendToPACS': true,
        'sendToNuance': false,
        'sendToXDSb': false,
        'modality': '',
        'studyDescription': '',
        'genPinCode': this.qrcodeConfigs.hasPinCode,
      };

      const newManifest = await this.manifestService.createManifest(newSession.manifests, manifestPostData);

      if (this.qrcodeConfigs.hasPinCode) {
        this.pinCode = `${newManifest.serverId}${newManifest.pinCode}`;
      }

      newSession.self = `${newSession.self}&appName=rounds&sessionTimeoutPeriod=${this.customerConfigs.sessionTimeoutPeriod}`;

      this.qrCodeData = this.qrCodeService.createQrCode(newSession, false);

      this.loading = false;
      this.cdr.markForCheck();
    } catch (error) {
      throw error;
    }
  }

  ngOnDestroy() {
    this.dataService.showNavigationWarning = false;
    this.document.body.classList.remove(this.customerConfigs.theme);
  }

}
