"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".nuance[_ngcontent-%COMP%]{display:flex}.nuance[_ngcontent-%COMP%]   .footer-logo[_ngcontent-%COMP%]{padding-right:1rem}.nuance[_ngcontent-%COMP%]   .footer-content[_ngcontent-%COMP%]{text-align:left}"];
exports.styles = styles;
