<mat-card>
	<mat-card-title class="header-logo">
    <app-logo [logo]="customerConfigs.logo"></app-logo>
  </mat-card-title>
	<h2 class="header">{{ translate.instant('sync.title') }}</h2>
	<form class="number-grid" [formGroup]="form">
		<input autofocus formControlName="pin1" autocomplete="nun-ya-business" class="pin-input number-grid__pin-input" type="text" name="pin1" size="1" maxlength="1" (input)="moveNext($event)" (keydown)="moveBack($event)" required>
		<input formControlName="pin2" autocomplete="nun-ya-business" class="pin-input number-grid__pin-input" type="text" name="pin2" size="1" maxlength="1" (input)="moveNext($event)" (keydown)="moveBack($event)" required>
		<input formControlName="pin3" autocomplete="nun-ya-business" class="pin-input number-grid__pin-input" type="text" name="pin3" size="1" maxlength="1" (input)="moveNext($event)" (keydown)="moveBack($event)" required>
		<input formControlName="pin4" autocomplete="nun-ya-business" class="pin-input number-grid__pin-input" type="text" name="pin4" size="1" maxlength="1" (input)="submit($event)" (keydown)="moveBack($event)" required>
	</form>

	<div [ngClass]="{ 'loading-container': true, 'show-loading': displayLoading }">
		<h3 class="pulse">{{ translate.instant('sync.loading') }}</h3>
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
	
	<h4 [ngClass]="{ invalid: true, show: displayInvalidMessage }">{{ translate.instant('sync.invalid') }}</h4>
</mat-card>