"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var translate_service_1 = require("../../translation/translate.service");
var OverreadConfirmationComponent = /** @class */ (function () {
    function OverreadConfirmationComponent(dialogRef, translate) {
        this.dialogRef = dialogRef;
        this.translate = translate;
    }
    OverreadConfirmationComponent.prototype.submit = function () {
        this.dialogRef.close({
            submit: true,
            text: this.text
        });
    };
    OverreadConfirmationComponent.prototype.cancel = function () {
        this.dialogRef.close({
            submit: false
        });
    };
    return OverreadConfirmationComponent;
}());
exports.OverreadConfirmationComponent = OverreadConfirmationComponent;
