import { DestinationSelectorComponent } from './destination-selector.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule, MatFormFieldModule, MatSelectModule, MatExpansionModule, MatCheckboxModule } from '@angular/material';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DestinationSelectorComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatExpansionModule,
    MatCheckboxModule,
  ],
  exports: [DestinationSelectorComponent]
})
export class DestinationSelectorModule { }
