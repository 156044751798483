"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fecha_1 = require("fecha");
var _ = require("lodash");
var Patient = /** @class */ (function () {
    function Patient(data) {
        this.name = data.name ? data.name : '---';
        this.dob = data.dob;
        this.mrn = data.mrn;
        this.lastName = data.lastName;
        this.alternateLastName = '';
        this.firstName = data.firstName;
        this.middleName = data.middleName;
        this.dobFormat = data.dobFormat;
        this.title = data.title;
        this.suffix = data.suffix;
        this.gender = data.gender;
        this.studyDescription = data.studyDescription;
        this.sendToPACS = data.sendToPACS;
        this.sendToNuance = data.sendToNuance;
        this.sendToXDSb = data.sendToXDSb;
    }
    Patient.parseDate = function (stringDate) {
        var year = parseInt(stringDate.substring(0, 4), 10);
        var month = parseInt(stringDate.substring(4, 6), 10) - 1;
        var day = parseInt(stringDate.substring(6, 8), 10);
        var dateObject = new Date(Date.UTC(year, month, day, 12, 0, 0));
        var dobFormat = fecha_1.default.format(dateObject, 'MMM D, YYYY');
        return dobFormat;
    };
    Patient.parseName = function (stringName) {
        if (_.includes(stringName, '^')) {
            return this.split(stringName, '^');
        }
        if (_.includes(stringName, ',')) {
            return this.split(stringName, ',');
        }
        if (_.includes(stringName, '|')) {
            return this.split(stringName, '|');
        }
        return {
            lastName: stringName === 'undefined' ? undefined : stringName
        };
    };
    Patient.split = function (name, delimiter) {
        var splitName = (decodeURIComponent(name).split(delimiter));
        splitName = splitName.map(function (i) { return i.trim(); });
        return {
            lastName: splitName.shift(),
            firstName: splitName.shift() || '',
            middleName: splitName.shift() || '',
            title: splitName.shift() || '',
            suffix: splitName.shift() || ''
        };
    };
    return Patient;
}());
exports.Patient = Patient;
