"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var JaroWinkler = require("jaro-winkler");
var ProviderUtilities = /** @class */ (function () {
    function ProviderUtilities() {
    }
    ProviderUtilities.validateProviderListItems = function (provider) {
        // Old Name Format: <last name>, <first name> <middle name>, <title>
        var stringNameRegex = /([^,\n\s]+),\s?([^,\n]+),?\s?([^\n]+)?/g;
        var _provider = Object.assign({}, provider);
        if (typeof provider.name === 'string') {
            var isOldFormat = stringNameRegex.test(provider.name);
            if (isOldFormat) {
                var _a = provider.name.split(/,\s?/), lastName = _a[0], givenName = _a[1], title = _a[2];
                var _b = givenName.split(/\s/), firstName = _b[0], middleName = _b[1], surname = _b[2];
                var name_1 = {
                    lastName: lastName,
                    firstName: firstName,
                };
                if (title) {
                    name_1.title = title;
                }
                if (middleName) {
                    name_1.middleName = middleName;
                }
                if (surname) {
                    name_1.surname = surname;
                }
                _provider.name = name_1;
            }
            else {
                console.warn("\"" + provider.name + "\" is not a valid provider name");
                return undefined;
            }
        }
        return _provider;
    };
    /**
     * Compare the first and last name of a provider to the comparator and generate a score based on the JaroWinkler
     * algorithm.  Used with an Array<Provider>().map
     * @param {string} comparator The string the providers name should be compared against
     * @returns {(Provider) => Provider & ProviderSearch} A function that is consumed by an Array.map method
     */
    ProviderUtilities.compareProviderName = function (comparator) {
        return function (provider) {
            provider.search = {
                firstNameScore: JaroWinkler(provider.name.firstName, comparator, { caseSensitive: false }),
                lastNameScore: JaroWinkler(provider.name.lastName, comparator, { caseSensitive: false })
            };
            return provider;
        };
    };
    /**
     * Checks the name to see if it starts with the comparator string.  Names that start with the
     * comparator should be given more weight (end up higher on the list) than ones where the comparator
     * is located in the middle of the list
     * @param name Name to be checked
     * @param comparator The sub-string to check for at the start of the name string
     * @returns { number } Weight applied to this specific name
     */
    ProviderUtilities.weighProviderNames = function (name, comparator) {
        return name.toLowerCase().startsWith(comparator.toLowerCase()) ? 0.5 : 0;
    };
    // Sorts a list of providers by closest match to name 
    ProviderUtilities.sortProviders = function (comparator) {
        var _this = this;
        return function (providerA, providerB) {
            // Calculate name scores
            var aScores = [
                providerA.search.firstNameScore + _this.weighProviderNames(providerA.name.firstName, comparator),
                providerA.search.lastNameScore + _this.weighProviderNames(providerA.name.lastName, comparator)
            ];
            var bScores = [
                providerB.search.firstNameScore + _this.weighProviderNames(providerB.name.firstName, comparator),
                providerB.search.lastNameScore + _this.weighProviderNames(providerB.name.lastName, comparator)
            ];
            // Aggregator Object manages what the highest JaroWinkler score is and if it is a first name (0) or last name (1)
            var highestScore = {
                scoreIndex: 0,
                score: 0,
                compare: function (scores) {
                    var _this = this;
                    scores.forEach(function (score, index) {
                        if (score > _this.score) {
                            _this.score = score;
                            _this.scoreIndex = index;
                        }
                    });
                }
            };
            // Provide arrays of scores to aggregator
            highestScore.compare(aScores);
            highestScore.compare(bScores);
            var sortDirection = 1;
            // Get the values to be compared.  The scores are multiplied by -1 so that the sorting direction
            // is the same when sorting by score or by the secondary item.
            var aScoreComparator = aScores[highestScore.scoreIndex] * -1;
            var bScoreComparator = bScores[highestScore.scoreIndex] * -1;
            if (aScoreComparator === bScoreComparator) {
                // When the scores are equal (such as 2 people with the same last name) grab the other
                // name and sort based on that instead
                var secondaryIndex = highestScore.scoreIndex === 0 ? 'lastName' : 'firstName';
                // If this is true then you have two exact same names
                if (providerA.name[secondaryIndex] === providerB.name[secondaryIndex]) {
                    return 0;
                }
                else {
                    aScoreComparator = providerA.search[secondaryIndex];
                    bScoreComparator = providerB.search[secondaryIndex];
                    sortDirection = -1;
                }
            }
            // Sort based on highest score so closest match is at the top
            return aScoreComparator > bScoreComparator ? sortDirection : sortDirection * -1;
        };
    };
    return ProviderUtilities;
}());
exports.ProviderUtilities = ProviderUtilities;
