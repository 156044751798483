"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["pre[_ngcontent-%COMP%]{white-space:pre-wrap}p[_ngcontent-%COMP%]{width:40rem}.pin[_ngcontent-%COMP%]{text-align:center}.pin.expired[_ngcontent-%COMP%]{text-decoration:line-through;color:rgba(0,0,0,.125)}.red-text[_ngcontent-%COMP%]{font-weight:700;color:red}.actions[_ngcontent-%COMP%]{display:flex;justify-content:flex-end}"];
exports.styles = styles;
