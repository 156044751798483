import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormGroup,  FormBuilder, Validators, FormControl } from '@angular/forms';
import { SessionService } from '../services/session.service';
import { DataService } from '../services/data.service';
import { TranslateService } from '../translation/translate.service';

interface Response {
  path: string;
  session: string;
}

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {
  params = {} as any;
  customerConfigs;

  form: FormGroup;

  displayInvalidMessage = false;
  displayLoading = false;

  constructor(
    private sessionService: SessionService,
    public dataService: DataService,
    @Inject('Window') private window: Window,
    @Inject('Document') private document: Document,
    private router: Router,
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.params = this.dataService.setupData(this.params);
    this.createForm();
    this.setup();
  }

  setup() {
    this.customerConfigs = this.dataService.configSettings.sync;
    this.document.body.classList.add(this.customerConfigs.theme);
  }

  createForm() {
    this.form = this.formBuilder.group({
      pin1: ['', Validators.required],
      pin2: ['', Validators.required],
      pin3: ['', Validators.required],
      pin4: ['', Validators.required]
    });
  }

  moveBack(e) {
    const input = e.target;
    const previous = input.previousSibling;

    if (e.keyCode === 8) {
      this.form.controls[previous.name].setValue(null);
      this.form.controls[input.name].markAsPristine();
      this.form.controls[previous.name].markAsPristine();
      previous.focus();
      this.displayInvalidMessage = false;
    }
  }

  moveNext(e) {
    e.preventDefault();
    e.target.nextSibling.focus();
    this.displayInvalidMessage = false;
  }

  updateFormInvalid(e) {
    this.displayInvalidMessage = true;
    Object.keys(this.form.controls).forEach( control => {
      this.form.controls[control].setValue(null);
      this.form.controls[control].markAsDirty();
    });
  }

  async submit(e) {
    e.target.blur();
    this.displayLoading = true;

    if (!this.form.valid) {
      this.updateFormInvalid(e);
    } else {
      this.form.disable();
      const code = Object.keys(this.form.value).reduce( (acc, cur) => acc += this.form.value[cur], '');

      try {
        const data = await this.sessionService.retrieveTransfer(code);

        data.query.syncWorkflow = !!data.session.iePinCode;
        data.query.hideNavTabs = this.params.hideNavTabs;

        (window as any).postData = data.query;
        this.sessionService.setSession(data.session);

        this.router.navigate([data.location]);
      } catch (err) {
        console.error('Error In Transfer', err);
        this.updateFormInvalid(e);
      }

    }

    this.form.enable();
    this.displayLoading = false;
  }

}
