import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Patient } from '../classes/patient';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class NuanceService {

  nuance = 'nuance';

  constructor(
    private sessionService: SessionService,
    private http: HttpClient
  ) { }

  destinations(): Promise<any> {
    return this.http.get(`${this.sessionService.baseUrl}${this.nuance}/repos`).toPromise();
  }

  patients(query: string): Promise<Array<Patient>> {
    return this.http.get(`${this.sessionService.baseUrl}${this.nuance}/patients?${query}`).toPromise() as Promise<Array<Patient>>;
  }

  study(studyUID: string): Promise<any> {
    return this.http.get(`${this.sessionService.baseUrl}${this.nuance}/study?studyUID=${studyUID}`).toPromise();
  }

  dicomStudy(studyUID: string): Promise<any> {
    return this.http.get(`${this.sessionService.baseUrl}${this.nuance}/dicomStudy?studyUID=${studyUID}`).toPromise();
  }

  dicomImage(studyUID: string, imageUID: string): Promise<any> {
    return this.http.get(`${this.sessionService.baseUrl}${this.nuance}/dicomImage?studyUID=${studyUID}&imageUID=${imageUID}`).toPromise();
  }

}
