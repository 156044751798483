"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANG_EN_NAME = 'enus';
exports.LANG_EN_TRANSLATIONS = {
    // The translations of these should attempt to keep the English length if possible
    'word.processing': 'Processing',
    'word.cancelled': 'Cancelled',
    'word.failure': 'Failure',
    'word.success': 'Success',
    'word.connected': 'connected',
    'word.files': 'Files',
    'word.destination': 'Destination',
    'word.current': 'Current',
    'word.updated': 'Updated',
    'word.instructions': 'Instructions',
    'word.study-match': 'Suggested',
    'word.specialties': 'Specialties',
    'word.providers': 'Providers',
    'word.duration': 'Duration',
    'word.hours': 'Hours',
    'word.results': 'Results',
    'word.done': 'Done',
    'word.sensitive': 'Sensitive',
    'word.selected': 'Selected',
    'word.apply': 'Apply',
    'word.anterior': 'Anterior',
    'word.posterior': 'Posterior',
    'word.name': 'name',
    'word.size': 'size',
    'word.bytes': 'bytes',
    'word.date': 'date',
    'word.time': 'time',
    'word.accession': 'accession',
    'phrase.disconnected': 'Device Disconnected',
    'phrase.install': 'Need to install the mobile app?',
    'phrase.uploadFailed': 'Upload Failed!',
    // ‘QR’ is an acronym for Quick Response and may be universally understood and hence may not require translation
    'phrase.scanPartOne': 'Scan the QR code with your device, search for',
    'phrase.scanPartTwo': 'in the app store, or navigate to',
    'phrase.scanPartThree': 'from your phone.',
    // overreasds are when a provider would like a second opinion
    // PACS/VNA are acronyms and should not be translated
    'phrase.overreadnotavailable': 'Overreads are only available when sending to Local Archive (PACS/VNA)',
    'phrase.study': 'Study',
    'phrase.exam': 'Exam',
    'phrase.selectall': 'Select All',
    'phrase.selectdir': 'Select Folder',
    'phrase.selectfiles': 'Select Files',
    'phrase.folder': 'Folder Selection',
    'phrase.file': 'Multiple File Selection',
    'phrase.ehr': 'Patient Information',
    'phrase.ehrDoctor': 'EHR Operator Information',
    'phrase.navigation': 'Warning: You are about to leave this application',
    'phrase.patientinfo': 'Patient Information',
    'phrase.accession': 'Your accession number is',
    'phrase.cancelExam': 'Warning: You are about to cancel this exam',
    'phrase.pinCode': 'PIN Code',
    'phrase.selectSites': 'Select Sites',
    'phrase.searchSites': 'Search Sites',
    'phrase.siteSelector': 'Site Selection',
    'phrase.op': 'Operator',
    'phrase.selectedStudy': 'Selected Study',
    'phrase.selectStudy': 'Select Study',
    'phrase.selectExam': 'Select Exam',
    'phrase.newStudy': 'New Study',
    'phrase.mwl': 'Modality Work List',
    'phrase.pacs': 'Picture Archive',
    // overreasds are when a provider would like a second opinion
    'phrase.reasonOverread': 'Reason for overread',
    'phrase.filesendsuccess': 'File Sent Successfully',
    'phrase.fileprocessing': 'File Processing',
    'phrase.filesenderror': 'File Failed To Send',
    'phrase.filesprocessed': 'Total Files Processed',
    'phrase.filterlogs': 'Filter Logs',
    'phrase.authorizingprovider': 'Authorizing Provider',
    'phrase.transferstatus': 'Transfer Status:',
    'phrase.studyTag': 'Nuance Study Tags',
    'phrase.search': 'Search Parameters',
    'phrase.study-exists': 'Study already exists in your archive.',
    'phrase.addToExistingStudy': 'Add to Existing Study',
    'phrase.addToExistingExam': 'Add to Existing Exam',
    'phrase.addToExisting': 'Add to Existing',
    'phrase.selectExisting': 'Select Existing',
    'phrase.selectExistingStudy': 'Select Existing Study',
    'phrase.selectExistingExam': 'Select Existing Exam',
    // SOP classes are specific to dicom and should not be translated
    'phrase.filenotinwhitelist': 'Some studies contain SOP classes that are outside of the whitelist',
    // SOP classes are specific to dicom and should not be translated
    'phrase.fileinblacklist': 'Some studies conftain SOP classes that are blacklisted.',
    'patient.name': 'Name:',
    'patient.firstName': 'First Name:',
    'patient.lastName': 'Last Name:',
    'patient.dob': 'DOB:',
    'patient.sex': 'Sex:',
    'patient.mrn': 'MRN:',
    'patient.unknown': 'Unknown Patient',
    'patient.alternateLastName': 'Alternate Last Name:',
    'patient.notFound': 'Patient Not Found',
    'patient.studyNotFound': 'Patient studies not found.',
    'button.submit': 'Submit Files',
    'button.stop': 'Stop Upload',
    'button.cancel': 'Cancel',
    'button.begin': 'Begin Upload',
    'button.nevermind': 'Nevermind',
    'button.continue': 'Continue',
    'button.confirm': 'Confirm and Proceed',
    'button.capture': 'Prepare to Capture',
    'button.endexam': 'End Exam',
    'button.confirmReason': 'Confirm Reason for Exam',
    'button.confirmOverread': 'Confirm Reason for Overread',
    'button.reason': 'Reason for Exam',
    'button.cancelExam': 'Cancel Exam',
    'button.close': 'Close',
    'button.generate': 'Generate',
    'button.pinCode': 'Pin Code',
    'button.search': 'Search',
    'button.retrieve': 'Retrieve and Store',
    'button.refresh': 'Refresh',
    'button.createNewOrder': 'Create New Order',
    'button.viewPatientOrders': 'View Patient Orders',
    'button.copyPinUrl': 'Copy PIN URL',
    'button.reset': 'Reset',
    'button.login': 'Login',
    'button.logout': 'Logout',
    'table.filename': 'File Name',
    'table.message': 'Message',
    'table.filesize': 'File Size in bytes',
    'table.filedate': 'File Date',
    'table.patientname': 'Patient Name',
    'table.patientid': 'Patient ID',
    'table.patientsex': 'Sex',
    'table.patientdob': 'Patient DOB',
    'table.patientage': 'Age',
    'table.site': 'Site',
    'table.study': 'Study Description',
    'table.facility': 'Facility',
    'table.studyInstanceUID': 'Study Instance UID',
    'table.seriesInstanceUID': 'Series Instance UID',
    'table.series': 'Series Description',
    'table.physician': 'Physician',
    'table.department': 'Department',
    'table.referringphysician': 'Referring Physician',
    'table.archive': 'Archive',
    'table.status': 'Status',
    'table.actions': 'Actions',
    'table.accessionNumber': 'Accession Number',
    'table.startExamTimestamp': 'Date/Time of Service',
    'table.studyDescription/reasonForExam': 'Study Description / Reason for Exam',
    'table.bodyPart': 'Body Part',
    'table.notSpecified': '[Not specified]',
    'table.studySource': 'Source',
    // may or may not require translation because it is a medical term
    'table.modality': 'Modality',
    'table.modalities': 'Modalities',
    'table.date': 'Study Date',
    'table.seriesDate': 'Series Date',
    'table.files': '# Files',
    // overreasds are when a provider would like a second opinion
    'table.overread': 'Overread',
    'about.dialogPartOne': 'The',
    'about.dialogPartTwo': 'mobile app is not intended for management or diagnostic display of images.',
    'about.link': 'About',
    'about.build_date': 'Build Date: ',
    'about.help_text': 'This app is used to import images and videos into a hospital imaging system. See more at www.imagemovermd.com.',
    'about.non_diagnostic': 'Not for diagnostic, monitoring or therapeutic purposes or in any other manner for regular medical practice.',
    'about.rights': 'All rights are reserved.',
    'about.copyright': 'Reproduction or transmission in whole or in part, in any form or by any means, electronic, mechanical or otherwise, is prohibited without the prior written consent of the copyright owner.',
    'about.sw': 'Copyrights and all other proprietary rights in any software and related documentation ("Software") made available to you rest exclusively with ImageMoverMD or its licensors. No title or ownership in the Software is conferred to you. Use of the Software is subject to the end user license conditions as are available on request.',
    'about.engineering': 'To the maximum extent permitted by law, you shall not decompile and/or reverse engineer the software or any part thereof.',
    // The translations of these can be shortened or lengthened as needed.
    'error.timeout': 'Your connection has timed out or has been reset. Press F5 to refresh this page, or click your browser\'s reload button to continue. If you continue to experience issues, please contact support',
    'error.manifest': 'Your manifest could not be created, if you launched this page from your application please try again.',
    'error.session': 'Your session could not be started, if you launched this page from your application please try again.',
    'error.badcancelled': 'Your upload has stopped.',
    'error.general': 'We\'re sorry, an error has occurred.',
    'error.hl7': 'HL7 message transmission failed.',
    'error.dicom.examRequired': 'An exam is required for each selected file.',
    // EHR is an acronym for electronic health record
    'instruction.confirmation': 'Patient information on the selected studies to import will be updated as shown above to match the patient information from the EHR.  By clicking the Confirm and Proceed button, you are confirming that studies being imported belong to the patient.',
    'instruction.navigation': 'The application will reset if you switch to another application. Any completed activity will not be affected, but new activity will stop. Click Continue to change applications, or click Nevermind to remain in the current application.',
    'instruction.patients': 'Uploading studies from multiple patients into a patient record is not allowed. Please select only a single patient before continuing.',
    'instruction.modalityPartOne': '1. Select the appropriate Study Description.',
    'instruction.modalityPartTwo': '2. Click the \'Prepare to Capture\' button.',
    'instruction.capturePartOne': 'Point your',
    'instruction.capturePartTwo': 'iOS or Android app at this QR code to capture images.',
    'instruction.roundsPartOne': 'Point your',
    'instruction.roundsPartTwo': 'iOS or Android app at this QR code to capture images.',
    'instruction.pinCode': 'Click the button below to generate a code to share with your remote user.',
    'instruction.updateManifest': 'Scan the QR code to use your new selection.',
    // EHR is an acronym for electronic health record
    'instruction.modalityPartThree': 'An order will be created and sent to the EHR.',
    'instruction.modalityPartFour': 'On your modality select the order from the worklist and begin your exam.',
    'instruction.accession': 'Click \'End Exam\' when image acquisition is complete.',
    'validation.namematch': 'Last Name is an exact match.',
    'validation.namepartial': 'Last Name is a possible match.',
    'validation.namemismatch': 'Last Name does not match.',
    'validation.gendermatch': 'Gender is an exact match.',
    'validation.genderpartial': 'Gender is not included or is listed as other.',
    'validation.gendermismatch': 'Gender does not match.',
    'validation.dobmatch': 'Date of birth is an exact match.',
    'validation.dobpartial': 'Date of birth is not included or was anonymized.',
    'validation.dobmismatch': 'Date of birth does not match.',
    // overreasds are when a provider would like a second opinion
    'validation.overread': 'Study is scheduled for an overread for reason ',
    'validation.failedOverread': 'No descriptions for overread found.',
    'validation.failedModality': 'No descriptions for modality found.',
    'validation.noModalities': 'No modalities selected.',
    'validation.required': 'Required Field',
    // DICOM is a file type and should not be translated
    'dicom.location132': 'Required Prefix not found at location 132, this is not a DICOM file.',
    // DICOM is a file type and should not be translated
    'dicom.toosmall': 'File is to small to be a valid DICOM file.',
    'dicom.invalidresponse': 'Field x00100020 included an invalid response and was skipped.',
    'dicom.skipped': 'file type is considered a system file and was skipped.',
    'dicom.enabled': 'is not currently enabled and was skipped.',
    // DICOM sync text
    'dicom.pinTitle': 'Directory Upload Sync Code',
    'dicom.syncDesc': 'The Directory Upload Sync Code will allow you to transfer your session and patient information securely to another browser.',
    'dicom.notIE': 'At this time Microsoft Internet Explorer does not support the selection of directories or folders from the Windows file system.  This requires that, in Internet Explorer, files be selected individually or using keyboard shortcuts (such as Shift+Click or Ctrl+A)',
    'dicom.useOther': 'ImageMoverMD does support the capability to process entire directories/folders when used in conjunction with internet browsers that support that feature.  Popular browsers that support this feature include Google Chrome and Mozilla Firefox.',
    'dicom.pinNavigate': 'To access your current session and patient information in a supported browser, open the browser and navigate to',
    'dicom.enterPin': 'There you will be prompted for the 4 digit Directory Upload Sync Code (which is also listed below).  Simply enter the Sync code and the browser will retrieve your session and you can begin uploading your directories/folders.',
    'dicom.copyURL': 'Copy URL to Clipboard',
    'dicom.copyURLConfirm': 'URL Copied to the Clipboard',
    'dicom.refreshMessage': 'Your Sync code has expired and will need to be refreshed.  To get a new Sync code, click ',
    'dicom.startOver': 'Start Over',
    'dicom.goBackStartOver': 'An error occurred uploading your files, please click Done and enter a new Sync code to try again',
    'dicom.uploadSuccessMsg': 'Upload successful!  Click Done to start your next upload',
    'dicom.successUpload': 'Successful Uploads',
    'dicom.failedUpload': 'Failed Uploads',
    'dicom.filesUploaded': 'Files Processed',
    'dicom.examAccessionNumber': 'Exam Accession Number',
    'dicom.error.order-required': 'Please associate studies with an order',
    // Media Uploader
    'media.allSensitive': 'Set All as Sensitive',
    'media.sensitiveStudy': 'Sensitive Study',
    'media.change': 'change',
    'media.apply-to-unspecified': 'apply to unspecified',
    'media.not-anatomical': 'Not Anatomical',
    'media.select-different-body-part': 'Select A Different Body Part',
    'media.body-part-required': 'Body part required for every file',
    'media.not-applied-study-description': 'Not applied to study description',
    'media.study-required': 'Please associate files with a study.',
    'media.tooltip.generating-thumbnail': 'Generating Thumbnail',
    'media.tooltip.error-loading-thumbnail': 'Error loading image thumbnail',
    // Transfer page
    'transfer.title': 'Enter Session Sync Code',
    'transfer.wait': 'Retrieving session information, please wait',
    'transfer.error': 'Invalid Session Sync Code.  Please check the code listed in the uploader and try again',
    // Sync page
    'sync.title': 'Enter Directory Upload Sync Code',
    'sync.loading': 'Retrieving session information, please wait',
    'sync.invalid': 'Invalid Session Sync Code.  Please check the code listed in the uploader and try again',
    'sync.nextUploadTooltip': 'Click Done to start next upload',
    // DICOM is a file type and should not be translated
    'dicom.available': 'DICOM files available for import.',
    // DICOM is a file type and should not be translated
    'dicom.nonconformant': 'non-conformant DICOM files.',
    'dicom.mediaskipped': 'media files skipped.',
    'dicom.mediaready': 'media files available for import and conversion.',
    'dicom.fileskipped': 'files skipped.',
    'dicom.notmedia': 'File skipped due to not being media.',
    'dicom.nomedia': 'Media file skipped.',
    // Standalone Specific
    'standalone.button.queryFromDICOM': 'Query from DICOM',
    'standalone.patientSearch': 'Patient Search',
    'standalone.getStarted': 'Enter details about the exam or patient to get started',
    'standalone.noOrders': 'No exams found for this patient',
    'standalone.emptyResponse': 'No orders found with those values.  Please refine the details about the exam or patient and try again.',
    'standalone.error': 'Oops, we encountered an issue!',
    'standalone.error.noMWL': 'Error: Unable to query modality work list!',
    'standalone.error.noDICOM': 'Error: No valid DICOM files found in that directory.',
    'standalone.queryMWL': 'Querying Modality Worklist, please wait...',
    'standalone.loadingFiles': 'Loading files, please wait....',
    // Local archive should be translated, but not (PACS/VNA)
    'radio.pacs': 'Local archive (PACS/VNA)',
    // Do not translate these two because they are brand names
    'radio.powershare': 'PowerShare Network',
    'radio.xdsb': 'XDS.b repository'
};
