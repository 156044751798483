"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var data_service_1 = require("../services/data.service");
var translate_service_1 = require("../translation/translate.service");
var dicom_query_retrieve_service_1 = require("../services/dicom-query-retrieve.service");
var _ = require("lodash");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
var dialog_1 = require("@angular/material/dialog");
var select_1 = require("@angular/material/select");
var overread_confirmation_component_1 = require("../modals/overread-confirmation/overread-confirmation.component");
var image_exchange_types_1 = require("../classes/image-exchange-types");
var table_1 = require("@angular/material/table");
var paginator_1 = require("@angular/material/paginator");
var patient_data_confirmation_component_1 = require("../modals/patient-data-confirmation/patient-data-confirmation.component");
var file_parse_service_1 = require("../services/file-parse.service");
var name_parse_service_1 = require("../services/name-parse.service");
var date_format_service_1 = require("../services/date-format.service");
var UploadState;
(function (UploadState) {
    UploadState[UploadState["STARTUP"] = 0] = "STARTUP";
    UploadState[UploadState["IN_PROGRESS"] = 1] = "IN_PROGRESS";
    UploadState[UploadState["FAILED"] = 2] = "FAILED";
    UploadState[UploadState["SUCCESS"] = 3] = "SUCCESS";
    UploadState[UploadState["CANCELLED"] = 4] = "CANCELLED";
})(UploadState || (UploadState = {}));
var PatientHistoryComponent = /** @class */ (function () {
    function PatientHistoryComponent(cdr, dialogService, dataService, translate, dicomQueryRetrieveService, fileParseService, nameParseService, dateFormatService, document) {
        var _this = this;
        this.cdr = cdr;
        this.dialogService = dialogService;
        this.dataService = dataService;
        this.translate = translate;
        this.dicomQueryRetrieveService = dicomQueryRetrieveService;
        this.fileParseService = fileParseService;
        this.nameParseService = nameParseService;
        this.dateFormatService = dateFormatService;
        this.document = document;
        this.CURRENT_TAB = 'dicom-image-exchange';
        this.destinationConfigs = {
            pacs: true,
            nuance: false,
            xdsb: false
        };
        this.params = {};
        this.counts = {};
        this.reposSelect = new forms_1.FormControl();
        this.reposFilter = new forms_1.FormControl();
        this.stop = {};
        this.status = UploadState.IN_PROGRESS;
        this.UploadState = UploadState;
        this.logData = new table_1.MatTableDataSource();
        this.logColumns = ['status', 'message'];
        this.checkStatus = function (response) { return __awaiter(_this, void 0, void 0, function () {
            var _i, _a, each;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.polling = true;
                        _i = 0, _a = response.data;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        each = _a[_i];
                        return [4 /*yield*/, (function (study) { return __awaiter(_this, void 0, void 0, function () {
                                var patientStudy, countResponse, count;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            patientStudy = this.studies.find(function (i) { return i.studyInstanceUID === study.studyInstanceUID; });
                                            return [4 /*yield*/, this.dicomQueryRetrieveService.status(study.manifest.id)];
                                        case 1:
                                            countResponse = _a.sent();
                                            count = countResponse.data;
                                            count.filesReceived = count.SUCCESS + count.PROCESSING + count.ERROR + count.CANCELLED;
                                            count.totalFiles = +patientStudy.numberOfStudyRelatedInstances;
                                            patientStudy.counts = count;
                                            if (count.ERROR === count.totalFiles) {
                                                this.studyFailed(patientStudy, countResponse.status, countResponse.message);
                                                return [2 /*return*/];
                                            }
                                            patientStudy.progressPercentage = Math.round(((count.filesReceived - count.PROCESSING) / count.totalFiles) * 100);
                                            if (count.filesReceived > 0) {
                                                patientStudy.bufferPercentage = Math.round((count.filesReceived / count.totalFiles) * 100);
                                                patientStudy.progressMode = patientStudy.bufferPercentage > 0 ? 'buffer' : 'determinate';
                                                patientStudy.progressColor = 'primary';
                                            }
                                            if (patientStudy.progressPercentage === 100) {
                                                this.stopStatus(image_exchange_types_1.Study.getUID(study));
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); })(each)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        if (this.studies.every(function (study) { return study.progressPercentage === 100; })) {
                            this.captureInProgress = false;
                            this.dataService.showNavigationWarning = false;
                            if (this.studies.every(function (study) { return study.counts.SUCCESS === study.counts.totalFiles; })) {
                                this.status = UploadState.SUCCESS;
                            }
                            else {
                                this.status = UploadState.FAILED;
                            }
                        }
                        this.polling = false;
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        }); };
    }
    PatientHistoryComponent.prototype.ngOnInit = function () {
        this.params = this.dataService.setupData(this.params);
        this.translate.use(this.params.lang);
        this.patientInfoConfig = [
            { label: this.translate.instant('patient.name'), key: 'name' },
            { label: this.translate.instant('patient.dob'), key: 'dobFormat' },
            { label: this.translate.instant('patient.sex'), key: 'gender' },
            { label: this.translate.instant('patient.mrn'), key: 'mrn' }
        ];
        this.setup();
    };
    PatientHistoryComponent.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.params = this.dataService.setupData(this.params);
                        this.fileParseService.params = this.params;
                        this.customerConfigs = this.dataService.configSettings[this.CURRENT_TAB];
                        this.document.body.classList.add(this.customerConfigs.theme);
                        if (this.params.hideNavTabs) {
                            data_service_1.DataService.hideNavTabs.next(this.params.hideNavTabs);
                        }
                        else {
                            data_service_1.DataService.hideNavTabs.next(!this.customerConfigs.name);
                        }
                        this.studyDescriptions = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, 'dicom');
                        if (!this.dataService.hasMinimumPatientInformation) {
                            this.errorStatus = this.translate.instant('error.session');
                            this.disabled = true;
                            return [2 /*return*/];
                        }
                        this.patient = this.dataService.patient;
                        _a = this;
                        return [4 /*yield*/, this.dicomQueryRetrieveService.sites()];
                    case 1:
                        _a.repos = _b.sent();
                        this.filteredRepos = new rxjs_1.BehaviorSubject(this.repos.data);
                        this.reposFilter.valueChanges
                            .subscribe(function (value) {
                            var repos = _this.repos.data.filter(function (repo) { return repo === '' || repo.toLowerCase().includes(value); });
                            _this.filteredRepos.next(repos);
                            _this.cdr.markForCheck();
                        });
                        this.reposSelect.valueChanges
                            .subscribe(function (repos) { return __awaiter(_this, void 0, void 0, function () {
                            var previousState, userSelectedAll, userSelectedEach, userSelectedNone;
                            return __generator(this, function (_a) {
                                previousState = this.selectedRepos ? this.selectedRepos.slice() : [];
                                this.selectedRepos = repos;
                                userSelectedAll = repos[0] === 'Select All' && previousState[0] !== 'Select All';
                                userSelectedEach = repos.length === this.filteredRepos.getValue().length && previousState[0] !== 'Select All';
                                userSelectedNone = previousState[0] === 'Select All' && repos[0] !== 'Select All';
                                if (userSelectedAll || userSelectedEach) {
                                    this.selectedRepos = ['Select All'].concat(this.filteredRepos.getValue());
                                    this.reposSelect.setValue(this.selectedRepos, { emitEvent: false });
                                }
                                else if (userSelectedNone) {
                                    this.selectedRepos = [];
                                    this.reposSelect.setValue([], { emitEvent: false });
                                }
                                else {
                                    if (repos[0] === 'Select All') {
                                        repos.shift();
                                    }
                                    this.selectedRepos = repos;
                                    this.reposSelect.setValue(this.selectedRepos, { emitEvent: false });
                                }
                                this.cdr.markForCheck();
                                return [2 /*return*/];
                            });
                        }); });
                        if (!(this.repos.data.length < 2)) return [3 /*break*/, 3];
                        this.reposSelect.setValue(this.repos.data);
                        this.reposSelect.disable();
                        return [4 /*yield*/, this.search()];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        this.logData.paginator = this.paginator;
                        this.logData.filter = '';
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    PatientHistoryComponent.prototype.hasMismatchPatient = function (patient, field) {
        return this.patients.data.some(function (i) { return i.data.some(function (p) { return p.site === patient.site && p[field] !== patient[field]; }); });
    };
    PatientHistoryComponent.prototype.hasMismatchName = function (patient) {
        return this.patient.name !== patient.patientNameDisplay;
    };
    PatientHistoryComponent.prototype.search = function () {
        return __awaiter(this, void 0, void 0, function () {
            var repos, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(!this.selectedRepos || this.selectedRepos.length === 0)) return [3 /*break*/, 1];
                        this.select.open();
                        return [3 /*break*/, 3];
                    case 1:
                        this.loadingPatients = true;
                        this.select.close();
                        this.cdr.markForCheck();
                        repos = this.selectedRepos;
                        if (repos[0] === 'Select All') {
                            repos.shift();
                        }
                        _a = this;
                        return [4 /*yield*/, this.dicomQueryRetrieveService.patients({
                                mrn: this.patient.mrn,
                                firstName: this.patient.firstName,
                                middleName: this.patient.middleName,
                                lastName: this.patient.lastName,
                                alternateLastName: this.patient.alternateLastName,
                                sex: this.patient.gender,
                                birthDate: this.patient.dob,
                                sites: repos
                            })];
                    case 2:
                        _a.patients = _b.sent();
                        this.displayPatients = _.chain(this.patients.data)
                            .map(function (patient) {
                            if (patient.data.length === 0) {
                                patient.data.push({
                                    patientNameDisplay: _this.patient.name,
                                    siteName: patient.message.replace('No patients found at ', '').replace('.', ''),
                                    error: '',
                                    studies: []
                                });
                            }
                            return patient.data;
                        })
                            .flatten()
                            .sortBy(function (patient) { return _this.hasMismatchName(patient); })
                            .value();
                        this.loadingPatients = false;
                        _b.label = 3;
                    case 3:
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    PatientHistoryComponent.prototype.selectStudy = function ($event, patient, study, existsTooltip) {
        return __awaiter(this, void 0, void 0, function () {
            var existsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        study.selected = $event.checked;
                        if (!study.selected) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.dicomQueryRetrieveService.studyExists(study)];
                    case 1:
                        existsResponse = (_a.sent()).data;
                        study.studyExists = existsResponse.studyExists;
                        this.cdr.detectChanges();
                        if (study.studyExists) {
                            existsTooltip.show(400);
                            setTimeout(function () {
                                existsTooltip.hide();
                            }, 12000);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        study.overread = false;
                        _a.label = 3;
                    case 3:
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    PatientHistoryComponent.prototype.patientOpened = function (patient) {
        return __awaiter(this, void 0, void 0, function () {
            var STUDY_TIMEOUT, delayPromise, responsePromise, response, error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!patient.archive) {
                            return [2 /*return*/];
                        }
                        STUDY_TIMEOUT = 60000;
                        patient.loadingStudies = true;
                        this.cdr.markForCheck();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        delayPromise = this.delay(STUDY_TIMEOUT, null);
                        responsePromise = this.dicomQueryRetrieveService.studies(__assign({}, patient, { repos: this.selectedRepos }));
                        return [4 /*yield*/, Promise.race([responsePromise, delayPromise])];
                    case 2:
                        response = _a.sent();
                        if (response) {
                            patient.studies = response.data;
                            patient.studies.forEach(function (study) {
                                study.loading = true;
                                _this.cdr.detectChanges();
                                _this.dicomQueryRetrieveService.studyExists(study).then(function (res) {
                                    study.studyExists = res.data.studyExists;
                                    study.loading = false;
                                    _this.cdr.detectChanges();
                                }).catch(console.log);
                            });
                        }
                        else {
                            throw new Error('study fetch timeout');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        patient.studies = [];
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        patient.loadingStudies = false;
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    PatientHistoryComponent.prototype.delay = function (t, v) {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, v), t);
        });
    };
    PatientHistoryComponent.prototype.patientClosed = function (patient) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    PatientHistoryComponent.prototype.patientUpdated = function (patient) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.patient = patient;
                this.cdr.markForCheck();
                return [2 /*return*/];
            });
        });
    };
    PatientHistoryComponent.prototype.selectOverread = function ($event, study) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!$event.checked) {
                            study.reasonForStudy = undefined;
                            study.overread = false;
                            return [2 /*return*/];
                        }
                        this.overreadConfirmation = this.dialogService.open(overread_confirmation_component_1.OverreadConfirmationComponent, {
                            minWidth: 300,
                            disableClose: true,
                        });
                        return [4 /*yield*/, this.overreadConfirmation.afterClosed().toPromise()];
                    case 1:
                        response = _a.sent();
                        study.reasonForStudy = response.text;
                        study.overread = response.submit;
                        study.selected = true;
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    PatientHistoryComponent.prototype.anySelected = function () {
        return this.patients && this.patients.data.some(function (i) { return i.data.some(function (p) { return Array.isArray(p.studies) ? p.studies.some(function (s) { return s.selected; }) : p.selected; }); });
    };
    PatientHistoryComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var manifestList, pollingInterval;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.studies = _.chain(this.patients.data)
                            .map(function (patient) { return patient.data; })
                            .flatten()
                            .filter(function (patient) { return patient.studies && patient.studies.length > 0; })
                            .map(function (patient) { return patient.studies; })
                            .flatten()
                            .filter(function (study) { return study.selected; })
                            .value();
                        this.totalFiles = this.studies.map(function (study) { return study.numberOfStudyRelatedInstances; }).reduce(function (a, b) { return a + b; });
                        this.buildManifestList();
                        return [4 /*yield*/, this.openPatientDataConfirmationModal()];
                    case 1:
                        manifestList = _a.sent();
                        if (manifestList.length === 0) {
                            return [2 /*return*/];
                        }
                        this.captureInProgress = true;
                        this.status = UploadState.IN_PROGRESS;
                        this.studies.forEach(function (study) {
                            study.progressMode = 'indeterminate';
                            study.progressColor = 'accent';
                        });
                        this.cdr.markForCheck();
                        pollingInterval = 3000;
                        this.studies.forEach(function (study) {
                            var manifest = manifestList.find(function (eachManifest) { return eachManifest.studyid === study.studyInstanceUID; });
                            _this.dicomQueryRetrieveService.retrieve([study], {
                                mrn: _this.patient.mrn,
                                firstName: _this.patient.firstName,
                                middleName: _this.patient.middleName,
                                lastName: _this.patient.lastName,
                                alternateLastName: _this.patient.alternateLastName,
                                sex: _this.patient.gender,
                                birthDate: _this.patient.dob,
                                studyDescription: manifest.studyDescriptionObject.label,
                                examCode: manifest.studyDescriptionObject.value,
                                reasonForStudy: manifest.overreadText,
                                overread: manifest.overread,
                                demo: _this.params.demo,
                                externalId: _this.params.acc
                            }).then(function (response) {
                                _this.cdr.markForCheck();
                                study.manifest = response.data[0].manifest;
                                _this.checkStatus(response);
                                _this.stop[image_exchange_types_1.Study.getUID(study)] = setInterval(function () {
                                    _this.checkStatus(response);
                                }, pollingInterval);
                            }).catch(function (error) { return _this.studyFailed(study, error.status, error.message); });
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PatientHistoryComponent.prototype.buildManifestList = function () {
        var _this = this;
        this.manifestList = [];
        var overreadText;
        this.studies
            .forEach(function (file) {
            var modalityFilter;
            var studyDescription;
            var overreadFilter;
            if (!file.modalitiesInStudy) {
                modalityFilter = _this.studyDescriptions;
            }
            else {
                modalityFilter = _this.dataService.filterAndMapStudies(_this.studyDescriptions, file.modalitiesInStudy, 'modality');
            }
            if (modalityFilter.length > 0) {
                overreadFilter = _this.dataService.filterAndMapStudies(modalityFilter, (!!file.overread).toString(), 'overread');
                if (overreadFilter.length > 0) {
                    var _loop_1 = function (o) {
                        var current = overreadFilter[o];
                        current.searchTerms.forEach(function (element, index, array) {
                            if (studyDescription === undefined && file.studyDescription) {
                                if (file.studyDescription.toLowerCase().indexOf(element.toLowerCase()) > -1) {
                                    studyDescription = current;
                                }
                            }
                        });
                    };
                    for (var o = 0; o < overreadFilter.length; o++) {
                        _loop_1(o);
                    }
                    // Catch to match first item if nothing else matches
                    if (studyDescription === undefined) {
                        studyDescription = overreadFilter[0];
                    }
                }
                else {
                    // Overread Returned 0 results use Modality Filter
                    overreadFilter = modalityFilter;
                    studyDescription = overreadFilter[0];
                    overreadText = _this.translate.instant('validation.failedOverread');
                }
            }
            else {
                // No Study Description Valid Select Value from Dicom File
                overreadFilter = [{ 'value': '', 'overread': false, 'label': file.studyDescription }];
                studyDescription = overreadFilter[0];
                overreadText = _this.translate.instant('validation.failedModality');
            }
            if (file.overread) {
                overreadText = _this.translate.instant('validation.overread');
            }
            studyDescription = _this.customerConfigs.studyDescriptionRequired ? null : studyDescription;
            if (overreadFilter.some(function (o) { return o.label === file.studyDescription; })) {
                studyDescription = overreadFilter.find(function (o) { return o.label === file.studyDescription; });
            }
            _this.manifestList.push({
                'studyid': file.studyInstanceUID,
                'patientid': file.patientId,
                'study': file.studyDescription,
                'modality': file.modalitiesInStudy,
                'studies': overreadFilter,
                'studyDescriptionValue': studyDescription ? studyDescription.value : '',
                'studyDescription': studyDescription ? studyDescription.label : '',
                'studyDescriptionObject': studyDescription,
                'overread': _this.params.sendToPACS ? file.overread : false,
                'overreadText': _this.params.sendToPACS ? overreadText : ''
            });
        });
    };
    PatientHistoryComponent.prototype.openPatientDataConfirmationModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var study, file, patientObj, $event;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        study = this.studies[0];
                        file = {
                            patientName: study.patientNameDisplay,
                            gender: study.sex,
                            dob: this.dateFormatService.prettyPrint(study.birthDate),
                            patientID: study.patientId,
                            patientNameObject: this.nameParseService.parseName(study.patientNameDisplay)
                        };
                        patientObj = this.fileParseService.createPatientObject(file);
                        this.patientDataConfirmation = this.dialogService.open(patient_data_confirmation_component_1.PatientDataConfirmationComponent, {
                            minWidth: 300,
                            disableClose: true,
                            data: {
                                studies: this.studies,
                                params: this.params,
                                patient: file,
                                manifestList: this.manifestList,
                                validation: patientObj.validation,
                                imageExchange: true
                            }
                        });
                        return [4 /*yield*/, this.patientDataConfirmation.afterClosed().toPromise()];
                    case 1:
                        $event = _a.sent();
                        if ($event.submit) {
                            return [2 /*return*/, $event.manifestList];
                        }
                        return [2 /*return*/, []];
                }
            });
        });
    };
    PatientHistoryComponent.prototype.studyFailed = function (study, status, message) {
        study.progressMode = 'determinate';
        study.uploadFailed = true;
        study.progressPercentage = 100;
        study.progressColor = 'warn';
        this.log(status, message);
        if (study.manifest && study.manifest.id) {
            this.stopStatus(image_exchange_types_1.Study.getUID(study));
        }
    };
    PatientHistoryComponent.prototype.log = function (status, message) {
        var row = { status: status, message: message };
        this.logData.data.push(row);
        this.logTable.renderRows();
        this.logData._updateChangeSubscription();
        this.cdr.markForCheck();
    };
    PatientHistoryComponent.prototype.stopStatus = function (studyInstanceUID) {
        clearInterval(this.stop[studyInstanceUID]);
    };
    PatientHistoryComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.logData.filter = filterValue;
    };
    PatientHistoryComponent.prototype.ngOnDestroy = function () {
        this.captureInProgress = false;
        this.document.body.classList.remove(this.customerConfigs.theme);
        this.dataService.showNavigationWarning = false;
        this.cdr.markForCheck();
    };
    return PatientHistoryComponent;
}());
exports.PatientHistoryComponent = PatientHistoryComponent;
