<mat-form-field>
  <mat-label>{{ translate.instant('table.study') }}</mat-label>
  <mat-select panelClass="study-selection-panel" [placeholder]="translate.instant('table.study')"
    [formControl]="studySelect">
    <mat-optgroup>
      <mat-option>
        <ngx-mat-select-search [placeholderLabel]="translate.instant('button.search')" [formControl]="studyFilter"
          noEntriesFoundLabel="">
        </ngx-mat-select-search>
      </mat-option>
    </mat-optgroup>

    <mat-optgroup *ngIf="filteredSuggestedStudies?.value?.length > 0" [label]="suggestedLabel">
      <mat-option *ngFor="let study of filteredSuggestedStudies | async" [value]="study">
        {{ study.label }}
      </mat-option>

    </mat-optgroup>

    <mat-option *ngFor="let study of filteredNotSuggestedStudies | async" [value]="study">
      {{ study.label }}
    </mat-option>

  </mat-select>

  <mat-error *ngIf="studySelect.invalid">{{translate.instant('validation.required')}}</mat-error>
</mat-form-field>