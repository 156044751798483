"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".form-footer[_ngcontent-%COMP%]{display:flex;justify-content:flex-end}mat-form-field[_ngcontent-%COMP%]{width:100%}"];
exports.styles = styles;
