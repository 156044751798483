import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient)
  { }

  getSetupConfig(): Promise<any> {
    const path = environment.production ? 'script/configs.json' : 'assets/configs.json';
    return this.http.get(path).toPromise();
  }

  getStudyDescription(): Promise<any> {
    const path = environment.production ? 'script/studyDescription.json' : 'assets/studyDescription.json';
    return this.http.get(path).toPromise();
  }

  getStudyDescriptionFormats(): Promise<any> {
    const path = environment.production ? 'script/studyDescriptionFormats.json' : 'assets/studyDescriptionFormats.json';
    return this.http.get(path).toPromise().catch((err: HttpErrorResponse) => {
      console.error('An error occurred:', err.error);
    });
  }

  getProviders(): Promise<any> {
    const path = environment.production ? 'script/providers.json' : 'assets/providers.json';
    return this.http.get(path).toPromise();
  }

  getCloudServerURL(): Promise<any> {
    return this.http.get('api/cloud-server-url').toPromise();
  }

  getBodyParts(): Promise<any> {
    const path = environment.production ? 'script/bodyMappings.v2.json' : 'assets/bodyMappings.v2.json';
    return this.http.get(path).toPromise();
  }
}
