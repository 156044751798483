"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var rounds_component_1 = require("./rounds.component");
var routes = [{ path: '', component: rounds_component_1.RoundsComponent }];
var RoundsRoutingModule = /** @class */ (function () {
    function RoundsRoutingModule() {
    }
    return RoundsRoutingModule;
}());
exports.RoundsRoutingModule = RoundsRoutingModule;
