import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {

  @Input() logo: string;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.logo = this.logo.toLowerCase();

    this.cdr.markForCheck();
  }
}
