"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mat-dialog-title[_ngcontent-%COMP%]{display:flex;justify-content:space-between}.mat-dialog-title[_ngcontent-%COMP%]   h2[_ngcontent-%COMP%]{display:inline-block}table[_ngcontent-%COMP%]{width:100%}.row[_ngcontent-%COMP%]{min-width:5rem;padding-left:.5rem}tr[mat-row][_ngcontent-%COMP%]:hover{background-color:#ececee;cursor:pointer}.empty-container[_ngcontent-%COMP%] > p[_ngcontent-%COMP%]{text-align:center}.loading-container[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:center;opacity:.75;margin-bottom:.5rem}.error-container[_ngcontent-%COMP%]{display:flex;flex-direction:column;justify-content:center;align-items:center}.error-container[_ngcontent-%COMP%]   mat-icon[_ngcontent-%COMP%]{color:red;font-size:5rem;height:5rem;width:5rem;display:flex;justify-content:center;opacity:33%}.error-container[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin:.5rem 0}mat-dialog-actions[_ngcontent-%COMP%]{justify-content:flex-end;margin-top:1rem}"];
exports.styles = styles;
