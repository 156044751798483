"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANG_DE_NAME = 'dech';
exports.LANG_DE_TRANSLATIONS = {
    // The translations of these should attempt to keep the English length if possible
    'word.processing': 'Verarbeit.',
    'word.cancelled': 'Abgebr.',
    'word.failure': 'Fehler',
    'word.success': 'Erfolg',
    'word.connected': 'verbunden',
    'word.files': 'Datei',
    'word.destination': 'Ziel',
    'word.current': 'Aktuell',
    'word.updated': 'Aktual.',
    'word.instructions': 'Anweisungen',
    'word.study-match': 'Empfohlen',
    'word.specialties': 'Fachgeb.',
    'word.providers': 'Anbieter',
    'word.duration': 'Dauer',
    'word.hours': 'Stunden',
    'word.results': 'Ergebnisse',
    'word.done': 'Fertig',
    'word.sensitive': 'Vertraulich',
    'word.selected': 'Ausgewählt',
    'word.apply': 'Verwenden',
    'word.anterior': 'Anterior',
    'word.posterior': 'Hintere',
    'word.name': 'name',
    'word.size': 'größe',
    'word.bytes': 'bytes',
    'word.date': 'datum',
    'phrase.disconnected': 'Gerät getrennt',
    'phrase.install': 'Soll die mobile App installiert werden?',
    'phrase.uploadFailed': 'Upload fehlgeschlagen!',
    'phrase.scanPartOne': 'Scannen Sie den QR-Code mit Ihrem Gerät, suchen Sie nach ',
    'phrase.scanPartTwo': ' im App Store, oder navigieren Sie von Ihrem Telefon aus zu ',
    'phrase.scanPartThree': '.',
    // overreasds are when a provider would like a second opinion
    // PACS/VNA are acronyms and should not be translated
    'phrase.overreadnotavailable': 'Zweitbefundungen sind nur bei der Übertragung an das lokale Archiv (PACS/VNA) verfügbar',
    'phrase.study': 'Untersuchung:',
    'phrase.selectall': 'Alles auswählen',
    'phrase.selectdir': 'Select Folder',
    'phrase.selectfiles': 'Dateien auswählen',
    'phrase.folder': 'Ordnerauswahl',
    'phrase.file': 'Auswahl mehrerer Dateien',
    'phrase.ehr': 'Elektronische Patientenakte',
    'phrase.ehrDoctor': 'EHR Operator Information',
    'phrase.navigation': 'Warnung: Sie sind dabei, diese Anwendung zu verlassen',
    'phrase.patientinfo': 'Patienteninformationen',
    'phrase.accession': 'Ihre Zugangsnummer ist',
    'phrase.cancelExam': 'Warnung: Sie sind dabei, diese Untersuchung abzubrechen',
    'phrase.pinCode': 'PIN Code',
    'phrase.selectSites': 'Standorte auswählen',
    'phrase.searchSites': 'Standorte durchsuchen',
    'phrase.siteSelector': 'Standortauswahl',
    'phrase.op': 'Operator',
    'phrase.selectedStudy': 'Ausgewählt',
    'phrase.newStudy': 'Neue Untersuchung',
    'phrase.reasonOverread': 'Grund für Zweitbefundung',
    'phrase.filesendsuccess': 'Datei erfolgreich gesendet',
    'phrase.fileprocessing': 'Datei wird verarbeitet',
    'phrase.filesenderror': 'Fehler beim Senden der Datei',
    'phrase.filesprocessed': 'Verarbeitete Dateien insgesamt',
    'phrase.filterlogs': 'Filterprotokolle',
    'phrase.authorizingprovider': 'Autorisierender Anbieter',
    'phrase.transferstatus': 'Übertragungsstatus:',
    'phrase.studyTag': 'Nuance-Untersuchungs-Tags',
    'phrase.search': 'Suchparameter',
    'phrase.study-exists': 'Die Untersuchung ist in Ihrem Archiv bereits vorhanden.',
    'phrase.addToExistingStudy': 'Zur vorhandenen Untersuchung hinzufügen',
    'phrase.selectExistingStudy': 'Vorhandene Untersuchung auswählen',
    'phrase.filenotinwhitelist': 'Manche Untersuchungen enthalten SOP-Klassen, die ausserhalb der Positivliste liegen',
    // SOP classes are specific to dicom and should not be translated
    'phrase.fileinblacklist': 'Manche Untersuchungen enthalten SOP-Klassen, die gesperrt sind',
    'patient.name': 'Name:',
    'patient.firstName': 'Vorname:',
    'patient.lastName': 'Nachname:',
    'patient.dob': 'Geb.-Datum:',
    'patient.sex': 'Geschl.:',
    'patient.mrn': 'MRN:',
    'patient.unknown': 'Unbekannter Patient',
    'patient.alternateLastName': 'Alternativer Nachname:',
    'patient.notFound': 'Patient nicht gefunden',
    'patient.studyNotFound': 'Patientenuntersuchungen nicht gefunden.',
    'button.submit': 'Dateien einreichen',
    'button.stop': 'Upload stoppen',
    'button.cancel': 'Abbrechen',
    'button.begin': 'Upload starten',
    'button.nevermind': 'Nein, danke',
    'button.continue': 'Weiter',
    'button.confirm': 'Bestätigen und fortfahren',
    'button.capture': 'Für Erfassung vorbereiten',
    'button.endexam': 'Untersuchung beenden',
    'button.confirmReason': 'Grund für Untersuchung bestätigen',
    'button.reason': 'Grund für die Prüfung',
    'button.cancelExam': 'Untersuchung abbrechen',
    'button.close': 'Schliessen',
    'button.generate': 'Generieren',
    'button.pinCode': 'Pin-Code',
    'button.search': 'Suchen',
    'button.retrieve': 'Abrufen und speichern',
    'button.refresh': 'Aktualisieren',
    'button.createNewOrder': 'Neue Verordnung erstellen',
    'button.backToOrders': 'Zurück zu Verordnungen',
    'button.reset': 'Zurücksetzen',
    'table.filename': 'Dateiname',
    'table.message': 'Nachricht',
    'table.filesize': 'Dateigrösse in Byte',
    'table.filedate': 'Dateidatum',
    'table.patientname': 'Patientenname',
    'table.patientid': 'Patienten-ID',
    'table.patientsex': 'Geschlecht',
    'table.patientdob': 'Patienten-Geburtsdatum',
    'table.patientage': 'Alter',
    'table.site': 'Standort',
    'table.study': 'Untersuchungsbeschreibung',
    'table.facility': 'Einrichtung',
    'table.studyInstanceUID': 'Untersuchungsinstanz-UID',
    'table.seriesInstanceUID': 'Serieninstanz-UID',
    'table.series': 'Serienbeschreibung',
    'table.physician': 'Arzt',
    'table.referringphysician': 'Überweisender Arzt',
    'table.archive': 'Archiv',
    'table.status': 'Status',
    'table.accessionNumber': 'Zugangsnummer',
    'table.modality': 'Modalität',
    'table.modalities': 'Modalitäten',
    'table.date': 'Unters.datum',
    'table.seriesDate': 'Seriendatum',
    'table.files': 'Anz. Dateien',
    'table.overread': 'Zweitbefundung',
    'table.bodyPart': 'Körperteil',
    'table.notSpecified': '[Nicht angegeben]',
    'about.dialogPartOne': 'Die mobile App ',
    'about.dialogPartTwo': ' ist nicht für das Management oder die diagnostische Anzeige von Bildern gedacht.',
    'about.link': 'Info',
    'about.build_date': 'Erstellungsdatum: ',
    'about.help_text': 'Diese App wird zum Importieren von Bildern und Videos in ein Spital-Bildgebungssystem verwendet. Weitere Informationen finden Sie unter www.imagemovermd.com. Die ImageMover Mobile-App ist nicht für das Management oder die diagnostische Anzeige von Bildern gedacht.',
    'about.non_diagnostic': 'Nicht für diagnostische, überwachende oder therapeutische Zwecke oder in anderer Weise für regelmäßige medizinische Praxis.',
    'about.rights': 'Alle Rechte vorbehalten.',
    'about.copyright': 'Jegliche Verwendung außerhalb der engen gesetzlichen Grenzen des Urheberrechts bedarf der vorherigen schriftlichen Zustimmung.',
    'about.sw': 'Sämtliche Urheber- und Eigentumsrechte an der Software und der dazugehörigen Dokumentation (“Software”), die Ihnen zur Verfügung gestellt wurde, verbleiben (ausschließlich) bei ImageMoverMD oder seinen Lizenzgebern. Ihnen wird kein Eigentum an der Software übertragen. Die Nutzung der Software unterliegt den Endbenutzer- Lizenzbedingungen, die auf Anfrage erhältlich sind.',
    'about.engineering': 'Soweit gesetzlich zulässig, ist es Ihnen untersagt, die Software oder Teile davon zu dekompilieren und/oder zurückzuentwickeln.',
    'error.timeout': 'Zeitüberschreitung bei Verbindung oder Verbindung wurde zurückgesetzt. Drücken Sie auf F5, um diese Seite zu aktualisieren, oder klicken Sie auf die Schaltfläche «Erneut laden», um fortzufahren. Wenn weiterhin Probleme auftreten, wenden Sie sich bitte an den Support',
    'error.manifest': 'Ihr Manifest konnte nicht erstellt werden. Wenn Sie diese Seite von Ihrer Anwendung gestartet haben, versuchen Sie es erneut.',
    'error.session': 'Ihre Sitzung konnte nicht gestartet werden. Wenn Sie diese Seite von Ihrer Anwendung gestartet haben, versuchen Sie es erneut.',
    'error.badcancelled': 'Ihr Upload wurde gestoppt.',
    'error.general': 'Leider ist ein Fehler aufgetreten.',
    'error.hl7': 'Fehler bei der Übertragung der HL7-Nachricht.',
    'instruction.confirmation': 'Die Patienteninformationen aus den ausgewählten zu importierenden Untersuchungen werden wie oben gezeigt aktualisiert, um sie an die Patienteninformationen aus der ePA anzugleichen. Durch Klicken auf «Bestätigen und fortfahren» bestätigen Sie, dass die importierten Untersuchungen zum Patienten gehören.',
    'instruction.navigation': 'Die Anwendung wird zurückgesetzt, wenn Sie zu einer anderen Anwendung wechseln. Abgeschlossene Aktivitäten werden davon nicht berührt, aber neue Aktivitäten werden gestoppt. Klicken Sie auf «Weiter», um zu einer anderen Anwendung zu wechseln, oder klicken Sie auf «Nein, danke», um in der derzeitigen Anwendung zu bleiben.',
    'instruction.patients': 'Das Hochladen von Untersuchungen von mehreren Patienten in eine Patientenakte ist nicht zulässig. Wählen Sie bitte nur einen einzigen Patienten, bevor Sie fortfahren.',
    'instruction.modalityPartOne': '1. Wählen Sie die entsprechende Untersuchungsbeschreibung.',
    'instruction.modalityPartTwo': '2. Klicken Sie auf die Schaltfläche «Für Erfassung vorbereiten».',
    'instruction.capturePartOne': 'Zeigen Sie mit Ihrer ',
    'instruction.capturePartTwo': ' iOS- oder Android-App auf diesen QR-Code, um Bilder zu erfassen.',
    'instruction.roundsPartOne': 'Zeigen Sie mit Ihrer',
    'instruction.roundsPartTwo': 'iOS- oder Android-App auf diesen QR-Code, um Bilder zu erfassen.',
    'instruction.pinCode': 'Klicken Sie auf die Schaltfläche weiter unten, um einen Code zu generieren, den Sie mit Ihrem Remote-Benutzer teilen können.',
    'instruction.updateManifest': 'Scannen Sie den QR-Code, um Ihre neue Auswahl zu verwenden.',
    'instruction.modalityPartThree': 'Ein Auftrag wird erstellt und an die ePA gesendet.',
    'instruction.modalityPartFour': 'Wählen Sie auf Ihrer Modalität den Auftrag aus der Arbeitsliste, und beginnen Sie mit Ihrer Untersuchung.',
    'instruction.accession': 'Klicken Sie auf «Untersuchung beenden», wenn die Bilderfassung abgeschlossen ist.',
    'validation.namematch': 'Nachname ist eine genaue Übereinstimmung.',
    'validation.namepartial': 'Nachname ist eine mögliche Übereinstimmung.',
    'validation.namemismatch': 'Nachname stimmt nicht überein.',
    'validation.gendermatch': 'Geschlecht ist eine genaue Übereinstimmung.',
    'validation.genderpartial': 'Geschlecht ist nicht enthalten oder als «Sonstiges» aufgeführt.',
    'validation.gendermismatch': 'Geschlecht stimmt nicht überein.',
    'validation.dobmatch': 'Geburtsdatum ist eine genaue Übereinstimmung.',
    'validation.dobpartial': 'Geburtsdatum ist nicht enthalten oder wurde anonymisiert.',
    'validation.dobmismatch': 'Geburtsdatum stimmt nicht überein.',
    'validation.overread': 'Die Untersuchung wurde aus dem folgenden Grund für Zweitbefundung geplant',
    'validation.failedOverread': 'Es wurden keine Beschreibungen für Zweitbefundung gefunden.',
    'validation.failedModality': 'Es wurden keine Beschreibungen für Modalität gefunden.',
    'validation.noModalities': 'Keine Modalitäten gewählt.',
    'validation.required': 'Pflichtfeld',
    'dicom.location132': 'Erforderliches Präfix an Ort 132 nicht gefunden; dies ist keine DICOM-Datei.',
    'dicom.toosmall': 'Datei ist für eine gültige DICOM-Datei zu klein.',
    'dicom.invalidresponse': 'Feld x00100020 enthielt eine ungültige Antwort und wurde übersprungen.',
    'dicom.skipped': 'Dateityp gilt als Systemdatei und wurde übersprungen.',
    'dicom.enabled': 'ist derzeit nicht aktiviert und wurde übersprungen.',
    'dicom.pinTitle': 'PIN-Code für Verzeichnis-Upload',
    'dicom.syncDesc': 'Mit dem PIN-Code für den Verzeichnis-Upload können Sie Ihre Sitzungs- und Patienteninformationen sicher an einen anderen Browser übertragen.',
    'dicom.notIE': 'Zu diesem Zeitpunkt bietet Microsoft Internet Explorer keine Unterstützung für die Auswahl von Verzeichnissen oder Ordnern aus dem Windows-Dateisystem.  Deshalb müssen Dateien in Internet Explorer einzeln oder mithilfe von Tastenkombinationen (z. B. Umschalt+Klick oder Strg+A) ausgewählt werden',
    'dicom.useOther': 'ImageMoverMD unterstützt die Fähigkeit, ganze Verzeichnisse/Ordner zu verarbeiten, wenn Internetbrowser verwendet werden, die diese Funktion unterstützen.  Gängige Browser, welche diese Funktion unterstützen, sind u. a. Google Chrome und Mozilla Firefox.',
    'dicom.pinNavigate': 'Um auf Ihre aktuellen Sitzungs- und Patienteninformationen in einem unterstützten Browser zuzugreifen, öffnen Sie den Browser und navigieren Sie zu',
    'dicom.enterPin': 'Dort werden Sie zur Eingabe des 4-stelligen PIN-Codes für den Verzeichnis-Upload aufgefordert (der auch nachstehend aufgeführt ist).  Geben Sie ganz einfach den Pin-Code ein, und der Browser ruft die Sitzung ab. Dann können Sie mit dem Hochladen Ihrer Verzeichnisse/Ordner beginnen.',
    'dicom.copyURL': 'URL in Zwischenablage kopieren',
    'dicom.copyURLConfirm': 'URL in Zwischenablage kopiert',
    'dicom.refreshMessage': 'Your pin code has expired and will need to be refreshed.  To get a new PIN code, click ',
    'dicom.startOver': 'Vertrauliche Untersuchung',
    'dicom.goBackStartOver': 'Beim Hochladen Ihrer Dateien ist ein Fehler aufgetreten; klicken Sie bitte auf „Fertig“, und geben Sie einen neuen Sync-Code ein, um es erneut zu versuchen',
    'dicom.uploadSuccessMsg': 'Upload erfolgreich! Klicken Sie auf „Fertig“, um den nächsten Upload zu starten',
    'dicom.successUpload': 'Erfolgreiche Uploads',
    'dicom.failedUpload': 'Fehlgeschlagene Uploads',
    'dicom.filesUploaded': 'Verarbeitete Dateien',
    'media.allSensitive': 'Alle als Vertraulich einstellen',
    'media.sensitiveStudy': 'Vertrauliche Untersuchung',
    'media.change': 'Veränderung',
    'media.apply-to-unspecified': 'Bewerben Sie sich bei Nicht spezifiziert',
    'media.not-anatomical': 'Nicht anatomisch',
    'media.select-different-body-part': 'Select A Different Body Part',
    'media.body-part-required': 'Körperteil für jede Datei erforderlich',
    'media.not-applied-study-description': 'Nicht auf die Studienbeschreibung angewendet',
    'media.tooltip.generating-thumbnail': 'Erstellen einer Miniaturansicht',
    'media.tooltip.error-loading-thumbnail': 'Fehler beim Laden der Miniaturansicht des Bildes',
    'transfer.title': 'Enter Session Pin Code',
    'transfer.wait': 'Retrieving session information, please wait',
    'transfer.error': 'Invalid Session Pin Code.  Please check the code listed in the uploader and try again',
    'sync.title': 'Sitzungs-Pin-Code eingeben',
    'sync.loading': 'Die Sitzungsinformationen werden abgerufen, bitte warten',
    'sync.invalid': 'Ungültiger Sitzungs-Pin-Code  Prüfen Sie den im Uploader aufgeführten Code und versuchen Sie es erneut',
    'sync.nextUploadTooltip': 'Klicken Sie auf „Fertig“, um den nächsten Upload zu starten',
    'dicom.available': 'DICOM-Dateien für den Import verfügbar.',
    'dicom.nonconformant': 'nicht konforme DICOM-Dateien.',
    'dicom.mediaskipped': 'Mediendateien übersprungen.',
    'dicom.mediaready': 'Mediendateien für den Import und die Konvertierung verfügbar.',
    'dicom.fileskipped': 'Dateien übersprungen.',
    'dicom.notmedia': 'Datei übersprungen, weil keine Mediendatei.',
    'dicom.nomedia': 'Mediendatei übersprungen.',
    'radio.pacs': 'Lokales Archiv (PACS/VNA)',
    'radio.powershare': 'PowerShare Network',
    'radio.xdsb': 'XDS.b repository',
};
