import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { Patient } from '../classes/patient';
import { StudyType } from '../classes/study-type';

import { UrlParameterService } from './url-parameter.service';

import * as _ from 'lodash';
import { Subject } from 'rxjs';

/**
 * NOTICE: This class is deprecated, please DO NOT add to it. Currently it
 * does too many things and needs to be split out to more and more subservices.
 */
@Injectable()
export class DataService {

  static hideNavTabs = new Subject<any>();
  copyrightYear = environment.COPYRIGHT;
  standalone: boolean;
  hasMinimumPatientInformation: boolean;
  showNavigationWarning: boolean;
  currentRoute: string;
  patient: Patient = {} as any;
  qrCode = '';
  configSettings;
  studyDescriptionSettings: Array<StudyType>;
  headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private title: Title,
    private urlParameterService: UrlParameterService,
    @Inject('Window') private window: Window) { }

  updateStudyDescription(queryURL, studyDescription, examCode): Promise<any> {
    return this.http.put(queryURL, { 'studyDescription': studyDescription, 'examCode': examCode }).toPromise();
  }

  // -- sending image -- //
  sendImage(files, formData, count): Promise<any> {
    const workingFormData = new FormData();
    for (const key of Object.keys(formData)) {
      workingFormData.append(key, formData[key]);
    }

    const action = this.http.post(files, workingFormData)
      .toPromise()
      .then((response) => {
        return response;
      }).catch(() => {
        if (count < 3) {
          count++;
          this.sendImage(files, formData, count);
        }
      });

    return action;
  }

  // -- Data initialization -- //

  private initializeData() {
    const parameters = this.urlParameterService.buildParameterContainer(this.configSettings);

    this.initializePatient(parameters);

    return parameters;
  }

  private initializePatient(parameters) {
    this.patient = new Patient(parameters);
    this.hasMinimumPatientInformation = this.patient.mrn !== undefined || this.standalone;
  }

  setupData(params): any {
    return _.assign(params, this.initializeData());
  }

  findStudy(studies: Array<StudyType>, compare: string, prop: 'label' | 'value' = 'label'): StudyType {
    const study = studies.find(i => {
      return i[prop] === compare;
    });
    return study ? study : {} as StudyType;
  }

  filterAndMapStudies(arrayToFilter: Array<StudyType>, filterValue: string, studyProperty: 'tabs' | 'mode' | 'modality' | 'overread' = 'tabs'): Array<any> {
    return arrayToFilter
      .filter(i => {
        if (typeof i[studyProperty] !== 'object') {
          return i[studyProperty].toString().includes(filterValue);
        }

        const val = (i[studyProperty] as any).length === 0
          || (i[studyProperty] as any).some(j => j === filterValue);
        return val;
      })
      .map(i => {
        return {
          value: i.code,
          label: i.studyDescription,
          tabs: i.tabs,
          mode: i.mode,
          modality: i.modality,
          overread: i.overread,
          searchTerms: i.searchTerms,
          code: i.code,
          studyDescription: i.studyDescription,
        };
      })
      .sort(this.studyTypeAlphabeticalSort);
  }

  studyTypeAlphabeticalSort(a: StudyType, b: StudyType) {
    if (a.studyDescription < b.studyDescription) {
      return -1;
    }

    if (a.studyDescription > b.studyDescription) {
      return 1;
    }

    return 0;
  }

  searchStudies(studies: Array<StudyType>, params, tab: string) {
    const filteringType = this.configSettings[tab].studyDescriptionFiltering;
    let studiesFilter: Array<StudyType> = studies;
    let selectedStudy: StudyType;

    if (params.encDept) {
      selectedStudy = studies.find((study) => this.findStudyPredicate(study, params.encDept));
    }

    if (filteringType === 'first' && selectedStudy) {
      studiesFilter = [selectedStudy];
    } else if (filteringType === 'subset' && selectedStudy) {
      studiesFilter = studies.filter((study) => this.findStudyPredicate(study, params.encDept));
    } else {
      this.foundSearchPriorityAlphabeticalSort(studiesFilter, params.encDept);
      selectedStudy = studies.find(study => study.studyDescription === this.configSettings[tab].defaultStudyDescription);
    }

    const splitStudies = this.splitStudiesSuggested(studiesFilter);

    return {
      studiesFilter,
      selectedStudy,
      suggested: splitStudies.suggested,
      notSuggested: splitStudies.notSuggested
    };
  }

  foundSearchPriorityAlphabeticalSort(studiesFilter: Array<StudyType>, search: string) {
    studiesFilter.sort((a: StudyType, b: StudyType) => {
      const aExists = this.findStudyPredicate(a, search);
      const bExists = this.findStudyPredicate(b, search);
      const aIsLower = a.studyDescription < b.studyDescription;

      if (aExists && !bExists) {
        return -1;
      }

      if (!aExists && bExists) {
        return 1;
      }

      return aIsLower ? -1 : 1;
    });
  }

  findStudyPredicate(study: StudyType, encDept: string) {
    const searchTerms = study.searchTerms ? study.searchTerms : [];
    const isInSearch = searchTerms.some(term => encDept && encDept.toLowerCase().indexOf(term.toLowerCase()) > -1);
    study.isInSearch = isInSearch;
    return isInSearch;
  }

  navigate(route: string) {
    this.currentRoute = route;
    const params = this.setupData({});
    const config = this.configSettings[route];
    delete params.app;
    this.title.setTitle(config.title);

    const isGetRequest = _.isUndefined((this.window as any).postData);
    if (isGetRequest) {
      this.router.navigate([config.url], {
        queryParams: params,
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate([config.url], {
        queryParams: { code: params.code, state: params.state },
        queryParamsHandling: 'merge'
      });
    }
  }

  splitStudiesSuggested(studies: Array<StudyType>) {
    return {
      suggested: studies.filter(study => study.isInSearch),
      notSuggested: studies.filter(study => !study.isInSearch)
    };
  }

}
