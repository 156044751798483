import { Provider } from './provider';

export namespace Dicom {

  export interface Validation {
    name: string;
    nameMessage: string;
    gender: string;
    genderMessage: string;
    dob: string;
    dobMessage: string;
  }

  export interface PatientNameObject {
    lastName: string;
    firstName: string;
  }

  export interface Exam {
    studyDescription: string;
    accessionNumber: string;
    modality: string,
    performingPhysician: string,
    startDate: string,
    startTime: string
  }

  export interface Child {
    UID: string;
    id: string;
    studyAccession: string,
    studyDate: string;
    modality: string;
    studyDescription: string;
    patientNameObject: PatientNameObject;
    patientName: string;
    dob: string;
    gender: string;
    age: string;
    studyUID: string;
    seriesUID: string;
    mediaType: string;
    label: string;
    selected: boolean;
    patientID: string;
    type: string;
    lastModified: number;
    lastModifiedDate: Date;
    webkitRelativePath: string;
    size: string;
    sopClass: string;
  }

  export class Study {
    study: boolean;
    selected: boolean;
    id: string;
    UID: string;
    studyProgress: string;
    studyDescription: string;
    isModalityAllowed: boolean;
    ageInDays: number;
    modality: string;
    studyDate: string;
    reasonForStudy: string;
    children: Child[];
    mode: string;
    overread: boolean;
    provider: Provider;
    specialty: string;
    progressPercentage: number;
    bufferPercentage: number;
    progressMode: string;
    counts: Counts;
    errors: string[];
    hasDisallowedSopClass: boolean;
    disallowedSopClassMessage: string;
    exam?: {
      studyDescription: string;
      accessionNumber: string;      
    }

    static diffDate(date): number {
      const now = new Date();
      const studyDate = new Date(date);
      const timeDiff = Math.abs(studyDate.getTime() - now.getTime());
      return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }
  }

  export class Counts {
    SUCCESS = 0;
    ERROR = 0;
    PROCESSING = 0;
    CANCELLED = 0;
    totalFiles = 0;
    filesReceived = 0;
  }

  export interface DicomFile {
    patient: boolean;
    patientID: string;
    selected: boolean;
    id: string;
    patientName: string;
    gender: string;
    dob: string;
    age: string;
    patientValidated: boolean;
    validation: Validation;
    children: Study[];
  }
}

