<div mat-dialog-content>
	<pre>{{ userMessage }}</pre>

	<p *ngIf="data?.tokenExpired">{{ translate.instant('dicom.refreshMessage') }} <span class="red-text">{{ translate.instant('button.refresh') }}</span></p>
</div>

<div mat-dialog-actions class="actions">
	<button mat-button mat-outline color="primary" [mat-dialog-close]>{{ translate.instant('button.close') }}</button>
	<button *ngIf="data?.tokenExpired" mat-raised-button color="warn" [mat-dialog-close]="'refresh'">{{ translate.instant('button.refresh') }}</button>
	<button mat-raised-button color="primary" (click)="copyText()" >{{ translate.instant('dicom.copyURL') }}</button>
</div>