import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent implements OnInit {

  theme = 'imagemovermd';
  nameRequiredLanguages: string;
  version = environment.VERSION;
  buildDate = environment.BUILD_DATE;
  copyrightYear = environment.COPYRIGHT;

  productNames = {
    imagemovermd: 'ImageMover',
    philips: 'IntelliSpace Image Capture'
  };

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    private dialogRef: MatDialogRef<AboutDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject('Document') private document: Document
  ) { }

  ngOnInit() {
    const params = this.dataService.setupData({});
    try {
      this.theme = this.document.body.classList[0].toLowerCase();
    } catch (error) {
      console.log(error);
    }

    console.log(this.translate.currentLang);

    // French, French-Canadian, and Italian require that the product name be included before the non-diagnostic statement
    this.nameRequiredLanguages = ['frch', 'frca', 'itch', 'nl'].includes(this.translate.currentLang) ? this.productNames[this.theme] : '';


    this.cdr.markForCheck();
    this.setup();
  }

  async setup() {
    if (this.theme !== 'philips') {
      return;
    }
    
    this.cdr.markForCheck();
  }

  close() {
    this.dialogRef.close();
  }

}
