<h2 mat-dialog-title>
  {{ pdfTitle }}
</h2>
<mat-dialog-content class="mat-typography">
  <mat-spinner *ngIf="isLoading"></mat-spinner>

  <div *ngIf="pdfFile">
    <pdf-viewer [src]="pdfFile" [render-text]="false" (after-load-complete)="pdfDoneLoading($event)"></pdf-viewer>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="closeDialog()">
    {{ translate.instant('button.close') }}
  </button>
</mat-dialog-actions>