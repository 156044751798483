import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

// User Components
import { AppComponent } from './app.component';
import { DataService } from './services/data.service';
import { TranslateService } from './translation/translate.service';
import { TRANSLATION_PROVIDERS } from './translation/translations';
import { LogoModule } from './shared/logo/logo.module';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { ModalityComponent } from './modality/modality.component';
import { TranslatePipe } from './translation/translate.pipe';
import { QRCodeModule } from 'angular2-qrcode';
import { DicomComponent } from './dicom/dicom.component';
import { MediaComponent } from './media/media.component';
import { FileParseService } from './services/file-parse.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientDataConfirmationComponent } from './modals/patient-data-confirmation/patient-data-confirmation.component';
import { OverreadConfirmationComponent } from './modals/overread-confirmation/overread-confirmation.component';
import { NavigationWarningComponent } from './modals/navigation-warning/navigation-warning.component';
import { StudySelectionComponent } from './shared/study-selection/study-selection.component';
import { FooterModule } from './shared/footer/footer.module';
import { CancelModalityComponent } from './modals/cancel-modality/cancel-modality.component';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { SearchPatientsComponent } from './modals/search-patients/search-patients.component';
import { PdfPreviewModalComponent } from './modals/pdf-preview-modal/pdf-preview-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TransferConfirmModalComponent } from './modals/transfer-confirm-modal/transfer-confirm-modal.component';
import { TransferComponent } from './transfer/transfer.component';
import { SelectStudyDialogComponent } from './modals/select-study-dialog/select-study-dialog.component';
import { PatientInformationModule } from './shared/patient-information/patient-information.module';
import { DestinationSelectorModule } from './shared/destination-selector/destination-selector.module';
import { BodyMapDialogComponent } from './modals/body-map-dialog/body-map-dialog.component';
import { SelectExamComponent } from './modals/select-exam/select-exam.component';
import { CustomDatePipe } from './pipes/custom-date.pipe.ts.pipe';

@NgModule({
  declarations: [
    AppComponent,
    CancelModalityComponent,
    DicomComponent,
    MediaComponent,
    ModalityComponent,
    NavigationWarningComponent,
    OverreadConfirmationComponent,
    PatientDataConfirmationComponent,
    PatientHistoryComponent,
    PdfPreviewModalComponent,
    QrcodeComponent,
    SearchPatientsComponent,
    StudySelectionComponent,
    TranslatePipe,
    TransferConfirmModalComponent,
    TransferComponent,
    SelectStudyDialogComponent,
    BodyMapDialogComponent,
    SelectExamComponent,
    CustomDatePipe
  ],
  imports: [
    // core
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PdfViewerModule,
    QRCodeModule,
    // material
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxMatSelectSearchModule,
    // routing
    AppRoutingModule,
    // shared
    FooterModule,
    PatientInformationModule,
    DestinationSelectorModule,
    LogoModule
  ],
  providers: [
    DataService,
    FileParseService,
    TranslateService,
    TRANSLATION_PROVIDERS,
    { provide: 'Window', useValue: window },
    { provide: 'Document', useValue: document }
  ],
  entryComponents: [
    BodyMapDialogComponent,
    CancelModalityComponent,
    NavigationWarningComponent,
    OverreadConfirmationComponent,
    PatientDataConfirmationComponent,
    PdfPreviewModalComponent,
    SearchPatientsComponent,
    TransferConfirmModalComponent,
    SelectStudyDialogComponent,
    SelectExamComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
