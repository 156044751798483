"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var modality_worklist_service_1 = require("./../services/modality-worklist.service");
var select_exam_component_1 = require("./../modals/select-exam/select-exam.component");
var router_1 = require("@angular/router");
var nuance_service_1 = require("./../services/nuance.service");
var core_1 = require("@angular/core");
var data_service_1 = require("../services/data.service");
var translate_service_1 = require("../translation/translate.service");
var file_parse_service_1 = require("../services/file-parse.service");
var dialog_1 = require("@angular/material/dialog");
var paginator_1 = require("@angular/material/paginator");
var table_1 = require("@angular/material/table");
var patient_data_confirmation_component_1 = require("../modals/patient-data-confirmation/patient-data-confirmation.component");
var overread_confirmation_component_1 = require("../modals/overread-confirmation/overread-confirmation.component");
var transfer_confirm_modal_component_1 = require("../modals/transfer-confirm-modal/transfer-confirm-modal.component");
var date_format_service_1 = require("../services/date-format.service");
var configuration_service_1 = require("../services/configuration.service");
var manifest_service_1 = require("../services/manifest.service");
var guid_service_1 = require("../services/guid.service");
var session_service_1 = require("../services/session.service");
var url_parameter_service_1 = require("../services/url-parameter.service");
var JaroWinkler = require("jaro-winkler");
var dicom_utils_1 = require("./dicom.utils");
var abstract_uploader_page_component_1 = require("../shared/abstract-uploader-page.component");
var SyncResultsState;
(function (SyncResultsState) {
    SyncResultsState[SyncResultsState["IN_PROGRESS"] = 0] = "IN_PROGRESS";
    SyncResultsState[SyncResultsState["FAILED"] = 1] = "FAILED";
    SyncResultsState[SyncResultsState["SUCCESS"] = 2] = "SUCCESS";
    SyncResultsState[SyncResultsState["CANCELLED"] = 3] = "CANCELLED";
})(SyncResultsState || (SyncResultsState = {}));
var RequestSource;
(function (RequestSource) {
    RequestSource[RequestSource["Integrated"] = 0] = "Integrated";
    RequestSource[RequestSource["Standalone"] = 1] = "Standalone";
})(RequestSource || (RequestSource = {}));
var DicomComponent = /** @class */ (function (_super) {
    __extends(DicomComponent, _super);
    function DicomComponent(configurationService, dateFormatService, dialogService, guidService, manifestService, nuanceService, sessionService, dataService, fileParseService, translate, urlParameterService, router, dicomUtils, mwlService, document, window) {
        var _this = _super.call(this) || this;
        _this.configurationService = configurationService;
        _this.dateFormatService = dateFormatService;
        _this.dialogService = dialogService;
        _this.guidService = guidService;
        _this.manifestService = manifestService;
        _this.nuanceService = nuanceService;
        _this.sessionService = sessionService;
        _this.dataService = dataService;
        _this.fileParseService = fileParseService;
        _this.translate = translate;
        _this.urlParameterService = urlParameterService;
        _this.router = router;
        _this.dicomUtils = dicomUtils;
        _this.mwlService = mwlService;
        _this.document = document;
        _this.window = window;
        _this.CURRENT_TAB = 'dicom';
        _this.uploadResultTotals = { total: 0, success: 0, failures: 0, ignored: 0, processed: 0 };
        /**
         * Identifies the origin of the request.  This will either be via the integrated
         * origin (ie: standard browser or EHR) or via the IM Standalone application.  Depending
         * on the source the uploader should behave slightly different.  For example, all studies must
         * have an exam when the origin is the Standalone application.
         */
        _this.requestSource = RequestSource.Integrated;
        _this.guidLocked = '';
        _this.getCount = 0;
        _this.errorStatus = '';
        _this.uploaderDisabled = true;
        _this.cancelDisabled = true;
        _this.continueChain = true;
        _this.allowCoerce = true;
        _this.batchSize = 1;
        _this.promiseList = [];
        _this.manifestList = [];
        _this.params = {};
        _this.currentlyUpdating = false;
        _this.nuanceDestinations = [];
        _this.nuanceStudyTagIds = '';
        _this.syncResultsState = SyncResultsState;
        _this.syncUploadResult = SyncResultsState.IN_PROGRESS;
        _this.logData = new table_1.MatTableDataSource();
        _this.logColumns = ['name', 'message'];
        _this.allFilesSelected = true;
        _this.checkStatus = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.currentlyUpdating !== false) {
                            return [2 /*return*/];
                        }
                        // Set scope variable to prevent queuing of calls.
                        this.currentlyUpdating = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.manifestService.getStatusManifest(this.manifestRoute)];
                    case 2:
                        response = _a.sent();
                        // Set to allow calls to resume.
                        this.currentlyUpdating = false;
                        if (response.status !== 404) {
                            this.parseResponse(response);
                        }
                        else {
                            // This handles the typical 404 error message after timeout or a hard failure somewhere like server reboot. Server should try 3 times to make this call before it gets caught in the catch below.
                            this.getCount = this.getCount + 1;
                            if (this.getCount >= 3) {
                                this.clearAndShowError('error.timeout');
                            }
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        // If for some reason we get a JS error this handles stopping anything.
                        this.currentlyUpdating = false;
                        this.clearAndShowError('error.timeout');
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    DicomComponent.prototype.ngOnInit = function () {
        this.browserIsIE = !('customElements' in window);
        this.params = this.dataService.setupData(this.params);
        this.translate.use(this.params.lang);
        this.setup();
    };
    DicomComponent.prototype.ngAfterViewInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.iePinCode.nativeElement.offsetParent || this.params.enableSync === 'yes') {
                    this.setupSessionWithPin();
                }
                return [2 /*return*/];
            });
        });
    };
    DicomComponent.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.params = this.dataService.setupData(this.params);
                this.params.batchSize = this.params.batchSize > 0 ? this.params.batchSize : 3;
                this.patient = this.dataService.patient;
                if (this.params.exams) {
                    this.dicomUtils.exams = JSON.parse(this.params.exams);
                }
                if (this.params.requestSource) {
                    this.requestSource = Number(this.params.requestSource);
                }
                this.isRawImport = !this.params.mrn && (this.dataService.configSettings.dicom.standalone || this.params.isStandalone);
                this.customerConfigs = this.dataService.configSettings.dicom;
                this.document.body.classList.add(this.customerConfigs.theme);
                if (this.params.hideNavTabs) {
                    data_service_1.DataService.hideNavTabs.next(this.params.hideNavTabs);
                }
                else {
                    data_service_1.DataService.hideNavTabs.next(!this.customerConfigs.name);
                }
                this.setDefaultServerPostLocation();
                if (!this.dataService.hasMinimumPatientInformation && !this.isRawImport) {
                    this.clearAndShowError('error.session');
                    this.inputDisabled = true;
                    return [2 /*return*/];
                }
                this.studies = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, this.CURRENT_TAB);
                this.selectedDestination = this.nuanceDestinations.length > 0 ? this.nuanceDestinations[0].id : undefined;
                if (this.customerConfigs.enableAuthorizingProvider) {
                    this.setProviders();
                }
                return [2 /*return*/];
            });
        });
    };
    DicomComponent.prototype.setupSessionWithPin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var configs, session, pinCodeTimeout, params, result, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configurationService.getSetupConfig()];
                    case 1:
                        configs = _a.sent();
                        return [4 /*yield*/, this.generateSessionInformation(null, true)];
                    case 2:
                        session = _a.sent();
                        this.generatePinCode(session);
                        pinCodeTimeout = configs.dicom.transferTokenAgeInSeconds || 60;
                        this.PinCodeLifespan = setTimeout(function () {
                            _this.PinCodeLifespan = null;
                        }, pinCodeTimeout * 1000);
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        params = this.urlParameterService.getRawParameters();
                        delete params.enableSync;
                        return [4 /*yield*/, this.sessionService.transferSession(this.PinCode, window.location.pathname, params)];
                    case 4:
                        result = _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.setProviders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, nonUniqueSpecialties;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.configurationService.getProviders()];
                    case 1:
                        _a.providers = _b.sent();
                        nonUniqueSpecialties = this.providers
                            .map(function (value) { return value.specialties; })
                            .reduce(function (a, b) { return a.concat(b); }, []);
                        this.specialties = Array.from(new Set(nonUniqueSpecialties));
                        return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.setDefaultServerPostLocation = function () {
        this.serverPostLocation = this.customerConfigs.sendToServerDefault;
        this.setServerPostLocation({ value: this.serverPostLocation });
    };
    DicomComponent.prototype.setServerPostLocation = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                _super.prototype.setServerPostLocation.call(this, $event);
                this.overreadsActive = $event.value === 'PACS';
                if (!this.overreadsActive) {
                    this.overreadEnabledMessage = this.translate.instant('phrase.overreadnotavailable');
                    if (this.hasOverreads()) {
                        this.clearAndShowError('phrase.overreadnotavailable');
                    }
                }
                if (this.customerConfigs.destinations) {
                    this.isInSopList($event.value);
                }
                return [2 /*return*/];
            });
        });
    };
    DicomComponent.prototype.isInSopList = function (destinationName) {
        var _this = this;
        var logs = [];
        var destination = this.customerConfigs.destinations.find(function (i) { return i.key === destinationName.toLowerCase(); });
        if (destination && destination.isListActive && this.files && this.files.length > 0) {
            // show each individual study that is failing the check
            // recheck for each selection change and server post location change
            this.files
                .forEach(function (i) { return i.children
                .forEach(function (j) {
                j.hasDisallowedSopClass = destination.isBlackList ?
                    j.children.some(function (each) { return _this.fileParseService.isInSopList(each, destination); }) :
                    j.children.every(function (each) { return !_this.fileParseService.isInSopList(each, destination); });
                var disallowedFiles = destination.isBlackList ?
                    j.children.filter(function (each) { return _this.fileParseService.isInSopList(each, destination); }) :
                    j.children.filter(function (each) { return !_this.fileParseService.isInSopList(each, destination); });
                if (j.hasDisallowedSopClass) {
                    j.disallowedSopClassMessage = destination.isBlackList ?
                        _this.translate.instant('phrase.fileinblacklist') :
                        _this.translate.instant('phrase.filenotinwhitelist');
                    logs = logs.concat(disallowedFiles.map(function (file) {
                        return {
                            name: file.label + " (" + file.sopClass + ")",
                            message: j.disallowedSopClassMessage
                        };
                    }));
                }
            }); });
            this.logData = new table_1.MatTableDataSource(this.fileParseService.parsedFiles.log.concat(logs));
            this.logData.paginator = this.paginator;
            this.selectedFilesAllowedForUpload(destination);
        }
    };
    DicomComponent.prototype.selectedFilesAllowedForUpload = function (destination) {
        var _this = this;
        if (!destination || !destination.isListActive) {
            return;
        }
        if (destination.isBlackList) {
            var isInSopList = this.files.some(function (i) {
                return i.children.some(function (j) { return j.selected &&
                    j.children.some(function (k) { return _this.fileParseService.isInSopList(k, destination); }); });
            });
            this.uploaderDisabled = isInSopList;
            if (this.uploaderDisabled) {
                this.clearAndShowError('phrase.fileinblacklist');
            }
            else {
                this.errorStatus = '';
            }
        }
        else {
            var onlyInSopList = this.files.every(function (i) {
                return i.children.every(function (j) {
                    return j.children.every(function (k) { return !_this.fileParseService.isInSopList(k, destination); });
                });
            });
            this.uploaderDisabled = onlyInSopList;
            if (this.uploaderDisabled) {
                this.clearAndShowError('phrase.filenotinwhitelist');
            }
            else {
                this.errorStatus = '';
            }
        }
    };
    DicomComponent.prototype.stopStatus = function () {
        clearInterval(this.stop);
        this.stop = undefined;
        this.addErrorsToLogs();
    };
    DicomComponent.prototype.startStatus = function () {
        if (this.stop) {
            return;
        }
        // Don't start a new interval if we are already have one, this prevents memory leaks.
        this.checkStatus();
        var pollingInterval = 3000;
        this.stop = setInterval(this.checkStatus, pollingInterval);
    };
    DicomComponent.prototype.parseResponse = function (response) {
        var _this = this;
        response.forEach(function (status) {
            var countObject = status.transmissionStatusSummary;
            var study = _this.selectedChildren.find(function (child) { return child.UID === status.studyStringID; });
            countObject.totalFiles = status.expectedFiles > 0 ? status.expectedFiles : study.counts.totalFiles;
            countObject.filesReceived = status.filesReceived;
            study.counts = countObject;
            study.errors = status.transmissionStatusErrorReasons ? status.transmissionStatusErrorReasons : [];
            study.progressPercentage = Math.round(((countObject.filesReceived - countObject.PROCESSING) / countObject.totalFiles) * 100);
            study.bufferPercentage = Math.round((countObject.filesReceived / countObject.totalFiles) * 100);
            study.progressMode = study.bufferPercentage > 0 ? 'buffer' : 'determinate';
        });
        this.uploadResultTotals = { total: 0, success: 0, failures: 0, processed: 0 };
        this.selectedChildren.forEach(function (study) {
            if (!study.counts) {
                return;
            }
            _this.uploadResultTotals.total += study.counts.totalFiles;
            _this.uploadResultTotals.processed += study.counts.SUCCESS + study.counts.ERROR;
            _this.uploadResultTotals.success += study.counts.SUCCESS;
            _this.uploadResultTotals.failures += study.counts.ERROR;
        });
        if (this.selectedChildren.every(function (child) { return child.progressPercentage === 100 && child.selected && child.counts.SUCCESS === child.counts.totalFiles; })) {
            this.inputDisabled = this.params.syncWorkflow ? true : false;
            this.uploaderDisabled = true;
            this.finished = true;
            this.syncUploadResult = SyncResultsState.SUCCESS;
            this.cancelDisabled = true;
            this.dataService.showNavigationWarning = false;
            this.stopStatus();
            this.window.scrollTo({ top: 0, left: 0 });
            // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
            window.parent.postMessage('enable-inputs', location.protocol + "//" + location.hostname + ":8443");
        }
        if (this.selectedChildren.some(function (child) { return child.progressPercentage === 100 && child.counts.SUCCESS !== child.counts.totalFiles; }) && this.params.syncWorkflow) {
            this.cancelDisabled = true;
            this.errorStatus = 'true';
            this.window.scrollTo({ top: 0, left: 0 });
            this.syncUploadResult = SyncResultsState.FAILED;
        }
        this.addErrorsToLogs();
    };
    DicomComponent.prototype.addErrorsToLogs = function () {
        var _this = this;
        if (!this.selectedChildren) {
            return;
        }
        var logs = this.selectedChildren.map(function (i) {
            if (!i.errors) {
                i.errors = [];
            }
            return i.errors.map(function (j) {
                return {
                    name: 'Error',
                    message: j
                };
            });
        }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (i) { return !_this.fileParseService.parsedFiles.log.includes(i); });
        this.logData = new table_1.MatTableDataSource(this.fileParseService.parsedFiles.log.concat(logs));
        this.logData.paginator = this.paginator;
    };
    DicomComponent.prototype.clearAndShowError = function (error) {
        if (error === void 0) { error = 'error.general'; }
        this.stopStatus();
        this.errorStatus = this.translate.instant(error);
    };
    DicomComponent.prototype.setUploaderMultiPatientConfiguration = function () {
        if (this.isRawImport) {
            this.uploaderDisabled = false;
            this.multiplePatientWarning = false;
            return;
        }
        // strict compare to false because null denotes indeterminate
        var selectedFiles = this.files.filter(function (file) { return file.selected !== false; });
        if (selectedFiles.length > 1) {
            this.uploaderDisabled = true;
            this.multiplePatientWarning = true;
        }
        else if (selectedFiles.length === 0) {
            this.uploaderDisabled = true;
            this.multiplePatientWarning = false;
        }
        else {
            this.uploaderDisabled = false;
            this.multiplePatientWarning = false;
        }
        selectedFiles = [];
    };
    // this function manages wether or not we open the modal for submission
    DicomComponent.prototype.submitTrigger = function () {
        return __awaiter(this, void 0, void 0, function () {
            var overreadText, validation, allFilesHaveStudy;
            var _this = this;
            return __generator(this, function (_a) {
                this.errorStatus = '';
                // Check for Disabled
                if (this.uploaderDisabled) {
                    return [2 /*return*/];
                }
                this.manifestList = [];
                try {
                    this.files
                        .filter(function (file) { return file.selected !== false; })
                        .forEach(function (file) { return file.children.forEach(function (child) {
                        var _a, _b;
                        validation = file.validation;
                        var modeFilter;
                        var modalityFilter;
                        var studyDescription;
                        var overreadFilter;
                        if (child.selected && child.study) {
                            if (!_this.params.mode) {
                                modeFilter = _this.studies;
                            }
                            else {
                                modeFilter = _this.dataService.filterAndMapStudies(_this.studies, child.mode, 'mode');
                            }
                            if (!child.modality) {
                                modalityFilter = modeFilter;
                            }
                            else {
                                modalityFilter = _this.dataService.filterAndMapStudies(modeFilter, child.modality, 'modality');
                            }
                            if (modalityFilter.length > 0) {
                                overreadFilter = _this.dataService.filterAndMapStudies(modalityFilter, (!!child.overread).toString(), 'overread');
                                if (overreadFilter.length > 0) {
                                    var _loop_1 = function (o) {
                                        var current = overreadFilter[o];
                                        current.searchTerms.forEach(function (element, index, array) {
                                            if (studyDescription === undefined && child.studyDescription) {
                                                if (child.studyDescription.toLowerCase().indexOf(element.toLowerCase()) > -1) {
                                                    studyDescription = current;
                                                }
                                            }
                                        });
                                    };
                                    for (var o = 0; o < overreadFilter.length; o++) {
                                        _loop_1(o);
                                    }
                                    // Catch to match first item if nothing else matches
                                    if (studyDescription === undefined) {
                                        studyDescription = overreadFilter[0];
                                    }
                                }
                                else {
                                    // Overread Returned 0 results use Modality Filter
                                    overreadFilter = modalityFilter;
                                    studyDescription = overreadFilter[0];
                                    overreadText = _this.translate.instant('validation.failedOverread');
                                }
                            }
                            else {
                                // No Study Description Valid Select Value from Dicom File
                                overreadFilter = [{ 'value': '', 'overread': false, 'label': child.studyDescription }];
                                studyDescription = overreadFilter[0];
                                overreadText = _this.translate.instant('validation.failedModality');
                            }
                            _this.promiseList[child.UID] = [];
                            if (child.overread) {
                                overreadText = _this.translate.instant('validation.overread') + '"' + child.reasonForStudy + '".';
                            }
                            if (_this.dataService.configSettings.dicom.disableStudy || _this.isRawImport) {
                                overreadFilter = [{ 'value': '', 'overread': false, 'label': child.studyDescription }];
                                overreadText = _this.translate.instant('validation.disabled');
                                studyDescription = overreadFilter[0];
                            }
                            studyDescription = _this.customerConfigs.studyDescriptionRequired ? null : studyDescription;
                            if (overreadFilter.some(function (o) { return o.label === child.studyDescription; })) {
                                studyDescription = overreadFilter.find(function (o) { return o.label === child.studyDescription; });
                            }
                            // Sort study description by most similar.
                            if (overreadFilter && child.studyDescription && !child.exam) {
                                //precompute jarko-winkler distance values
                                var jaro = [];
                                for (var i = 0; i < overreadFilter.length; i++) {
                                    var distance = JaroWinkler(overreadFilter[i].label, child.studyDescription, { caseSensitive: false });
                                    jaro.push(distance);
                                }
                                // sort by most similar.
                                for (var i = 0; i < overreadFilter.length; i++) {
                                    var distance1 = jaro[i];
                                    for (var j = i + 1; j < overreadFilter.length; j++) {
                                        var distance2 = jaro[j];
                                        // bubble up study descriptions that are more similar to the top.
                                        if (distance2 > distance1) {
                                            _a = [overreadFilter[j], overreadFilter[i]], overreadFilter[i] = _a[0], overreadFilter[j] = _a[1];
                                            _b = [jaro[j], jaro[i]], jaro[i] = _b[0], jaro[j] = _b[1];
                                            distance1 = distance2;
                                        }
                                    }
                                }
                                // If we sorted something, the 1st one on the list is what we want.
                                if (overreadFilter.length > 0) {
                                    studyDescription = overreadFilter[0];
                                }
                            }
                            // When an exam is assigned to a study, the application should use that exam study description
                            // unapologetically.
                            if (child.exam) {
                                studyDescription = overreadFilter.find(function (studyDescription) { return studyDescription.label.startsWith(child.exam.studyDescription); });
                                // If no study description matches, just display the study that was selected by the exam
                                if (!studyDescription) {
                                    studyDescription = { label: child.exam.studyDescription };
                                }
                            }
                            _this.manifestList.push({
                                'studyid': child.UID,
                                'patientid': child.children[0].patientID,
                                'study': child.studyDescription,
                                'modality': child.modality,
                                'studies': overreadFilter,
                                'studyDescriptionValue': studyDescription ? studyDescription.value : child.exam,
                                'studyDescription': studyDescription ? studyDescription.label : '',
                                'studyDescriptionObject': studyDescription,
                                'overread': _this.params.sendToPACS ? child.overread : undefined,
                                'overreadText': _this.params.sendToPACS ? overreadText : undefined,
                                'reasonForStudy': _this.params.sendToPACS ? child.reasonForStudy : undefined,
                                'studyAccessionNumber': child.children[0].studyAccession,
                                'accessionNumber': child.exam ? child.exam.accessionNumber : undefined,
                                exam: child.exam
                            });
                            child.children.forEach(function (secondLevelChild) {
                                _this.promiseList[secondLevelChild.studyUID].push(secondLevelChild);
                            });
                        }
                    }); });
                    allFilesHaveStudy = this.files
                        .filter(function (file) { return file.selected; })
                        .every(function (file) {
                        return file.children.every(function (child) { return child.exam; });
                    });
                    if (this.requestSource === RequestSource.Standalone && !allFilesHaveStudy) {
                        throw Error('dicom.error.order-required');
                    }
                    if (this.requestSource === RequestSource.Integrated && this.isRawImport) {
                        this.folderUpload();
                    }
                    else {
                        // fire a method here to open a modal
                        this.openPatientDataConfirmationModal(validation);
                    }
                }
                catch (error) {
                    this.currentlyUpdating = false;
                    this.clearAndShowError(error.message);
                    throw error;
                }
                return [2 /*return*/];
            });
        });
    };
    DicomComponent.prototype.openPatientDataConfirmationModal = function (validation) {
        return __awaiter(this, void 0, void 0, function () {
            var $event;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.patientDataConfirmation = this.dialogService.open(patient_data_confirmation_component_1.PatientDataConfirmationComponent, {
                            minWidth: 300,
                            disableClose: true,
                            data: {
                                studies: this.studies,
                                params: this.params,
                                patient: this.files.find(function (file) { return file.selected !== false; }),
                                manifestList: this.manifestList,
                                validation: validation,
                                providers: this.providers,
                                specialties: this.specialties
                            }
                        });
                        return [4 /*yield*/, this.patientDataConfirmation.afterClosed().toPromise()];
                    case 1:
                        $event = _a.sent();
                        if ($event.submit) {
                            this.manifestList = $event.manifestList;
                            this.folderUpload($event.provider);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.generatePinCode = function (session) {
        var pin = "" + session.iePinCode;
        if (pin.length < 4) {
            var zero = '0';
            this.PinCode = "" + zero.repeat(4 - pin.length) + pin;
        }
        else {
            this.PinCode = pin.slice(-4);
        }
    };
    DicomComponent.prototype.generateSessionInformation = function (provider, includePin) {
        return __awaiter(this, void 0, void 0, function () {
            var sessionSettings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.params.demo) {
                            this.params.coerceUIDs = true;
                        }
                        if (this.isRawImport) {
                            this.allowCoerce = false;
                        }
                        sessionSettings = {
                            appName: 'dicom',
                            op: this.params.op,
                            coerce: this.allowCoerce,
                            coerceUIDs: this.params.coerceUIDs,
                            prov: provider ? provider.id : this.params.prov,
                            isStandalone: this.isRawImport,
                            sourceSite: this.params.sourceSite,
                            genIEPinCode: includePin,
                            demo: this.params.demo
                        };
                        return [4 /*yield*/, this.sessionService.createSession(this.params, sessionSettings)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DicomComponent.prototype.folderUpload = function (provider) {
        return __awaiter(this, void 0, void 0, function () {
            var session, nuanceRepoId, postData, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.inputDisabled = true;
                        this.uploaderDisabled = true;
                        this.cancelDisabled = false;
                        // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
                        window.parent.postMessage('disable-inputs', location.protocol + "//" + location.hostname + ":8443");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        // hard compare to false because null is selected, but not all children
                        this.selectedChildren = this.files
                            .filter(function (file) { return file.selected !== false; })
                            .map(function (file) { return file.children; })
                            .reduce(function (a, b) { return a.concat(b); }, [])
                            .filter(function (file) { return file.selected; });
                        return [4 /*yield*/, this.generateSessionInformation(provider)];
                    case 2:
                        session = _a.sent();
                        this.manifestRoute = session.manifests + '/';
                        this.version = session.version;
                        nuanceRepoId = this.selectedDestination && this.params.sendToNuance ? this.selectedDestination : undefined;
                        postData = {
                            coerce: this.allowCoerce,
                            'session': session.key,
                            'patient.lastName': this.params.lastName,
                            'patient.firstName': this.params.firstName,
                            'patient.middleName': this.params.middleName,
                            'patient.title': this.params.title,
                            'patient.suffix': this.params.suffix,
                            'patient.externalId': this.params.mrn,
                            'patient.dateOfBirth': this.dateFormatService.formatDate(this.params.dob),
                            'patient.gender': this.params.gender,
                            'batchSize': this.batchSize,
                            'batch.size': this.batchSize,
                            'sendToPACS': this.params.sendToPACS,
                            'sendToNuance': this.params.sendToNuance,
                            'sendToXDSb': this.params.sendToXDSb,
                            'studyDescription': this.params.studyDescription,
                            'modality': this.params.modality,
                            'expectedFiles': 0,
                            'studyStringID': '',
                            'isOverread': false,
                            'examCode': '',
                            'reasonForStudy': '',
                            'nuanceRepoId': nuanceRepoId,
                            'nuanceRepoStudyTagIds': this.nuanceStudyTagIds,
                            'xdsPatientId': this.params.xdsPatientId,
                            'xdsSourcePatientId': this.params.xdsSourcePatientId
                        };
                        return [4 /*yield*/, this.createManifest(postData, this.promiseList, 0)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        this.errorStatus = this.translate.instant('error.session');
                        throw error_2;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.createManifest = function (postData, promiseList, count) {
        return __awaiter(this, void 0, void 0, function () {
            var current, exam, list, manifest, error_3, error_4, len, totalExpected, j, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        current = this.manifestList[count];
                        if (!(current && this.continueChain)) return [3 /*break*/, 10];
                        exam = current.exam;
                        postData.expectedFiles = promiseList[current.studyid].length;
                        postData.studyStringID = current.studyid;
                        postData.modality = current.modality;
                        postData.isOverread = current.overread;
                        postData.studyDescription = exam && exam.studyDescription ? exam.studyDescription : current.studyDescription;
                        postData.examCode = current.studyDescriptionValue;
                        postData.reasonForStudy = current.reasonForStudy;
                        postData.accessionNumber = current.accessionNumber;
                        postData.useAccessionNumberProvided = !!current.accessionNumber;
                        list = this.manifestList[count];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, this.manifestService.createManifest(this.manifestRoute, postData)];
                    case 2:
                        manifest = _a.sent();
                        list.manifestId = manifest.id;
                        list.self = manifest.self;
                        current.files = manifest.files;
                        current.expectedFiles = postData.expectedFiles;
                        current.count = {};
                        count++;
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 7]);
                        this.batchTransmit(promiseList[current.studyid], this.params.batchSize, current.files);
                        this.startStatus();
                        return [4 /*yield*/, this.createManifest(postData, promiseList, count)];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 5:
                        error_3 = _a.sent();
                        console.log(error_3);
                        return [4 /*yield*/, this.createManifest(postData, promiseList, count)];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        error_4 = _a.sent();
                        this.errorStatus = this.translate.instant('error.manifest');
                        throw error_4;
                    case 9: return [3 /*break*/, 14];
                    case 10:
                        if (!this.continueChain) return [3 /*break*/, 14];
                        len = this.manifestList.length;
                        totalExpected = 0;
                        for (j = 0; j < len; j++) {
                            totalExpected = totalExpected + this.manifestList[j].expectedFiles;
                        }
                        _a.label = 11;
                    case 11:
                        _a.trys.push([11, 13, , 14]);
                        return [4 /*yield*/, this.sessionService.updateSession(totalExpected)];
                    case 12:
                        _a.sent();
                        return [3 /*break*/, 14];
                    case 13:
                        error_5 = _a.sent();
                        this.errorStatus = this.translate.instant('error.session');
                        throw error_5;
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.cancelUpload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var updateObject, len, j, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.continueChain = false;
                        this.cancelDisabled = true;
                        this.inputDisabled = true;
                        this.syncUploadResult = SyncResultsState.CANCELLED;
                        // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
                        window.parent.postMessage('enable-inputs', location.protocol + "//" + location.hostname + ":8443");
                        // Remove loading animation from 
                        this.selectedChildren.forEach(function (child) {
                            child.progressPercentage = 0;
                            child.progressMode = 'determinate';
                        });
                        updateObject = { expectedFiles: 0 };
                        len = this.manifestList.length;
                        j = 0;
                        _a.label = 1;
                    case 1:
                        if (!(j < len)) return [3 /*break*/, 7];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, 5, 6]);
                        return [4 /*yield*/, this.manifestService.updateManifestByRoute(this.manifestList[j].self, updateObject)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_6 = _a.sent();
                        this.failureStatus = this.translate.instant('error.badcancelled');
                        console.log(error_6);
                        return [3 /*break*/, 6];
                    case 5:
                        this.stopStatus();
                        return [7 /*endfinally*/];
                    case 6:
                        j++;
                        return [3 /*break*/, 1];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.batchTransmit = function (tasks, batch_size, files) {
        return __awaiter(this, void 0, void 0, function () {
            var promise_array, len, i, task, promise_batch, error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.continueChain) return [3 /*break*/, 6];
                        this.currentlyUpdating = false;
                        // Finished?
                        if (!tasks || tasks.length === 0) {
                            return [2 /*return*/];
                        }
                        promise_array = [];
                        len = tasks.length;
                        for (i = 0; (i < batch_size) && (i < len); i++) {
                            try {
                                task = tasks[i];
                                promise_array.push(this.singleFileUpload(task, files));
                            }
                            catch (error) {
                                console.log(error);
                            }
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 6]);
                        promise_batch = Promise.all(promise_array);
                        return [4 /*yield*/, promise_batch];
                    case 2:
                        _a.sent();
                        tasks.splice(0, batch_size);
                        return [4 /*yield*/, this.batchTransmit(tasks, batch_size, files)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_7 = _a.sent();
                        tasks.splice(0, batch_size);
                        return [4 /*yield*/, this.batchTransmit(tasks, batch_size, files)];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.singleFileUpload = function (file, files) {
        var path = '';
        // console.log('File Last Modified Date:', file.lastModified || 'none');
        // // Date Formatting
        // let lastModified = new Date(file.lastModified);
        // if (!lastModified) {
        //   lastModified = new Date(file.lastModifiedDate);
        // }
        // console.log('Last Modified - Pre-Format: ', lastModified);
        // const date = format(lastModified, 'YYYY-MM-DD\'T\'HH:mm:ss\'Z\'');
        // console.log({
        //   lastModified,
        //   date
        // });
        // Relative Path
        if (file.webkitRelativePath === '') {
            path = '';
        }
        else {
            path = file.webkitRelativePath;
        }
        var formData = {
            'type': file.mediaType,
            'diskPath': path,
            // 'clientDateCreated': date, // "yyyy-MM-DD'T'HH:mm:ss'Z'"
            'fileContent': file,
            'newlockKey': this.guidLocked === '' ? this.guidService.generateGuid() : this.guidLocked
        };
        return this.dataService.sendImage(files, formData, 0);
    };
    DicomComponent.prototype.ieNotification = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var ieDialog;
            var _this = this;
            return __generator(this, function (_a) {
                ieDialog = this.dialogService.open(transfer_confirm_modal_component_1.TransferConfirmModalComponent, {
                    data: {
                        pin: this.PinCode,
                        path: "https://" + this.window.location.host + "/sync",
                        tokenExpired: !this.PinCodeLifespan,
                        message: this.customerConfigs.directoryUploadMessaging
                    },
                    position: { top: '10vh' },
                    width: '50rem'
                });
                ieDialog.afterClosed().toPromise().then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!result) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.newSession()];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    DicomComponent.prototype.openExamSelectDialog = function ($event, file, index) {
        return __awaiter(this, void 0, void 0, function () {
            var examDialog;
            var _this = this;
            return __generator(this, function (_a) {
                examDialog = this.dialogService.open(select_exam_component_1.SelectExamComponent, {
                    minWidth: '75vw',
                    maxWidth: '600px',
                    position: {
                        top: '2rem'
                    },
                    data: {
                        exams: this.dicomUtils.exams,
                        mrn: this.patient.mrn,
                        allowNew: this.requestSource === RequestSource.Integrated
                    }
                });
                examDialog.afterClosed().toPromise().then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (result) {
                            if (result.exam) {
                                file.children[index].exam = result.exam;
                            }
                            else {
                                file.children[index].exam = undefined;
                            }
                            if (result.list) {
                                this.dicomUtils.exams = result.list;
                            }
                        }
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    DicomComponent.prototype.newSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Remove the current session so a new one can be created
                        this.sessionService.setSession(false);
                        this.PinCode = '';
                        return [4 /*yield*/, this.setupSessionWithPin()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.fileChange = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // run a destroy of old state
                        this.resetPage();
                        this.finished = false;
                        this.dataService.showNavigationWarning = true;
                        return [4 /*yield*/, this.fileParseService.parseDicomFolder($event)];
                    case 1:
                        _a.sent();
                        this.files = this.fileParseService.parsedFiles.files;
                        this.setUploaderMultiPatientConfiguration();
                        this.logData = new table_1.MatTableDataSource(this.fileParseService.parsedFiles.log);
                        this.logData.paginator = this.paginator;
                        if (this.customerConfigs.destinations) {
                            this.isInSopList(this.serverPostLocation);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.selectAllFiles = function ($event) {
        var _this = this;
        this.allFilesSelected = $event.checked;
        this.files.forEach(function (file) {
            _this.selectAllChildren($event, file);
        });
    };
    DicomComponent.prototype.selectAllChildren = function ($event, file) {
        var _this = this;
        file.selected = $event.checked;
        file.children.forEach(function (i) { return i.selected = $event.checked; });
        this.updateSelectAllStatus();
        var destination = this.customerConfigs.destinations && this.customerConfigs.destinations.find(function (i) { return i.key === _this.serverPostLocation.toLowerCase(); });
        this.selectedFilesAllowedForUpload(destination);
        this.setUploaderMultiPatientConfiguration();
    };
    DicomComponent.prototype.updateSelectAllStatus = function () {
        var allSelected = this.files.every(function (i) { return i.selected; });
        var anySelected = this.files.some(function (i) { return i.selected !== false; });
        var isIndeterminate = !allSelected && anySelected;
        this.allFilesSelected = isIndeterminate ? null : allSelected;
    };
    DicomComponent.prototype.selectFile = function ($event, file, child) {
        var _this = this;
        child.selected = $event.checked;
        this.updateSelectChildrenStatus(file);
        this.updateSelectAllStatus();
        var destination = this.customerConfigs.destinations && this.customerConfigs.destinations.find(function (i) { return i.key === _this.serverPostLocation.toLowerCase(); });
        this.selectedFilesAllowedForUpload(destination);
        this.setUploaderMultiPatientConfiguration();
    };
    DicomComponent.prototype.updateSelectChildrenStatus = function (file) {
        var allSelected = file.children.every(function (i) { return i.selected; });
        var anySelected = file.children.some(function (i) { return i.selected; });
        var isIndeterminate = !allSelected && anySelected;
        file.selected = isIndeterminate ? null : allSelected;
    };
    DicomComponent.prototype.setAllStudySelection = function (isSelected) {
        this.files.forEach(function (file) {
            file.selected = isSelected;
            file.children.forEach(function (child) { return child.selected = isSelected; });
        });
        this.setUploaderMultiPatientConfiguration();
    };
    DicomComponent.prototype.selectOverread = function ($event, child) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!$event.checked) {
                            child.reasonForStudy = undefined;
                            child.overread = false;
                            return [2 /*return*/];
                        }
                        this.overreadConfirmation = this.dialogService.open(overread_confirmation_component_1.OverreadConfirmationComponent, {
                            minWidth: 300,
                            disableClose: true,
                            data: {
                                child: child
                            }
                        });
                        return [4 /*yield*/, this.overreadConfirmation.afterClosed().toPromise()];
                    case 1:
                        response = _a.sent();
                        child.reasonForStudy = response.text;
                        child.overread = response.submit;
                        return [2 /*return*/];
                }
            });
        });
    };
    DicomComponent.prototype.hasOverreads = function () {
        return this.files && this.files.some(function (file) { return file.children.some(function (child) { return child.overread; }); });
    };
    DicomComponent.prototype.applyFilter = function (filterValue) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.logData.filter = filterValue;
    };
    DicomComponent.prototype.trackByFile = function (index, file) {
        return file.patientID;
    };
    DicomComponent.prototype.resetPage = function () {
        this.performance = '';
        this.cancelDisabled = true;
        this.finished = false;
        this.uploaderDisabled = true;
        this.inputDisabled = false;
        this.dataService.showNavigationWarning = false;
        this.fileParseService.params = this.params;
        this.fileParseService.customerConfigs = this.dataService.configSettings.dicom;
        this.fileParseService.parsedFiles = {
            logType: [0, 0, 0],
            log: [],
            files: []
        };
        this.files = [];
        this.syncUploadResult = SyncResultsState.IN_PROGRESS;
        this.uploadResultTotals = { total: 0, success: 0, failures: 0, ignored: 0, processed: 0 };
        this.dicomUtils.exams = [];
        this.stopStatus();
        // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
        window.parent.postMessage('enable-inputs', location.protocol + "//" + location.hostname + ":8443");
    };
    DicomComponent.prototype.renderExamDetailButton = function (exam) {
        var output = [];
        if (exam.studyDescription) {
            output.push(exam.studyDescription);
        }
        if (exam.modality) {
            output.push(exam.modality);
        }
        if (exam.performingPhysician) {
            output.push(exam.performingPhysician);
        }
        return output.join(' - ');
    };
    DicomComponent.prototype.newPatient = function () {
        history.replaceState({}, 'Sync', '/sync');
        this.router.navigate(['/sync'], { queryParams: { hideNavTabs: this.params.hideNavTabs } });
    };
    DicomComponent.prototype.ngOnDestroy = function () {
        this.resetPage();
        this.document.body.classList.remove(this.customerConfigs.theme);
    };
    return DicomComponent;
}(abstract_uploader_page_component_1.default));
exports.DicomComponent = DicomComponent;
