"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var GuidService = /** @class */ (function () {
    function GuidService() {
    }
    // TODO: This is not how GUIDs are generated. This has potential for collision.
    GuidService.prototype.generateGuid = function () {
        var s4 = this.generateSubstring;
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    };
    GuidService.prototype.generateSubstring = function () {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    GuidService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuidService_Factory() { return new GuidService(); }, token: GuidService, providedIn: "root" });
    return GuidService;
}());
exports.GuidService = GuidService;
