"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var date_format_service_1 = require("./../../services/date-format.service");
var core_1 = require("@angular/core");
var translate_service_1 = require("../../translation/translate.service");
var dialog_1 = require("@angular/material/dialog");
var data_service_1 = require("../../services/data.service");
var dicom_query_retrieve_service_1 = require("../../services/dicom-query-retrieve.service");
var table_1 = require("@angular/material/table");
var forms_1 = require("@angular/forms");
var rxjs_1 = require("rxjs");
var material_1 = require("@angular/material");
var modality_worklist_service_1 = require("../../services/modality-worklist.service");
var DialogViewStates;
(function (DialogViewStates) {
    DialogViewStates[DialogViewStates["Loading"] = 0] = "Loading";
    DialogViewStates[DialogViewStates["Ready"] = 1] = "Ready";
    DialogViewStates[DialogViewStates["Error"] = 2] = "Error";
})(DialogViewStates || (DialogViewStates = {}));
var SelectStudyDialogComponent = /** @class */ (function () {
    function SelectStudyDialogComponent(translate, dataService, dateFormatService, dialogRef, cdr, dicomQueryRetrieveService, modalityWorkListService, data) {
        this.translate = translate;
        this.dataService = dataService;
        this.dateFormatService = dateFormatService;
        this.dialogRef = dialogRef;
        this.cdr = cdr;
        this.dicomQueryRetrieveService = dicomQueryRetrieveService;
        this.modalityWorkListService = modalityWorkListService;
        this.data = data;
        this.VIEW_STATES = DialogViewStates;
        this.studiesTable = new table_1.MatTableDataSource();
        this.displayedColumns = ['studyDate', 'studyDescription', 'modalities', 'referringPhysician', 'accessionNumber', 'studySource'];
        this.studiesLoading = true;
        this.selectedModalities = [];
        this.modalitySelect = new forms_1.FormControl();
        this.modalityFilter = new forms_1.FormControl();
        this.timeSelect = new forms_1.FormControl();
        this.newStudy = {
            accessionNumber: '',
            archive: '',
            birthDate: '',
            error: '',
            firstName: '',
            instanceNumber: '',
            lastName: '',
            middleName: '',
            modalitiesInStudy: '',
            modalitiesInStudyDisplay: '',
            modality: '',
            numberOfSeriesRelatedInstances: '',
            numberOfStudyRelatedInstances: '',
            numberOfStudyRelatedSeries: '',
            patientId: '',
            referringPhysician: '',
            seriesInstanceUID: '',
            seriesNumber: '',
            sex: '',
            sopClassUID: '',
            sopInstanceUID: '',
            studyDate: '',
            studyDateDisplay: '',
            studyDescription: 'New Study',
            studyInstanceUID: ''
        };
    }
    SelectStudyDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var defaultTime, modalities, items;
            var _this = this;
            return __generator(this, function (_a) {
                this.state = DialogViewStates.Loading;
                this.studiesTable.paginator = this.paginator;
                this.newStudy.studyDateDisplay = this.dateFormatService.convertDateToMediumDate(new Date());
                this.params = this.dataService.setupData({});
                delete this.params.studyDescription;
                this.params.patientId = this.params.mrn;
                this.params.birthDate = this.params.dob;
                this.times = this.dataService.configSettings.timeMapping.times;
                defaultTime = this.times.find(function (t) { return t.default; });
                this.selectedTime = !!defaultTime ? defaultTime : this.times[0];
                this.timeSelect.setValue(this.selectedTime);
                modalities = this.dataService.configSettings.modalities.options;
                this.filteredModalities = new rxjs_1.BehaviorSubject(modalities.filter(function (item) { return !!item; }));
                this.modalitySelect.setValue(modalities);
                this.selectedModalities = modalities;
                this.modalityFilter.valueChanges
                    .subscribe(function (value) {
                    var repos = modalities.filter(function (repo) { return repo === '' || repo.toLowerCase().includes(value); });
                    _this.filteredModalities.next(repos);
                    _this.cdr.markForCheck();
                });
                this.modalitySelect.valueChanges
                    .subscribe(function (values) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.selectedModalities = values || [];
                                return [4 /*yield*/, this.updateAvailableStudies(this.params)];
                            case 1:
                                _a.sent();
                                this.cdr.markForCheck();
                                return [2 /*return*/];
                        }
                    });
                }); });
                this.timeSelect.valueChanges
                    .subscribe(function (time) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.selectedTime = time;
                                return [4 /*yield*/, this.updateAvailableStudies(this.params)];
                            case 1:
                                _a.sent();
                                this.cdr.markForCheck();
                                return [2 /*return*/];
                        }
                    });
                }); });
                if (this.data.exams.length > 0) {
                    items = this.data.exams.slice();
                    if (this.data.allowNew) {
                        items.unshift(this.newStudy);
                    }
                    this.updateList(items);
                    this.state = DialogViewStates.Ready;
                    this.cdr.markForCheck();
                }
                else {
                    this.updateAvailableStudies(this.params);
                }
                return [2 /*return*/];
            });
        });
    };
    SelectStudyDialogComponent.prototype.ngAfterViewInit = function () {
        this.updateList(this.studiesTable.data);
    };
    SelectStudyDialogComponent.prototype.updateAvailableStudies = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response, items;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state = DialogViewStates.Loading;
                        this.studiesLoading = true;
                        this.updateList([]);
                        this.cdr.markForCheck();
                        if (this.selectedModalities.length === 0) {
                            this.state = DialogViewStates.Ready;
                            this.cdr.markForCheck();
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Promise.all([
                                this.getStudies(params),
                                this.getExams(params)
                            ])];
                    case 1:
                        response = _a.sent();
                        items = response
                            .flat()
                            .sort(this.sortByDate)
                            .filter(this.filterUnique);
                        this.state = DialogViewStates.Ready;
                        this.studiesLoading = false;
                        if (this.data.allowNew) {
                            items.unshift(this.newStudy);
                        }
                        this.updateList(items);
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectStudyDialogComponent.prototype.getStudies = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.selectedModalities.length === 0) {
                            return [2 /*return*/, []];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.dicomQueryRetrieveService.queryExistingStudies(__assign({}, params, { time: JSON.stringify(this.selectedTime), modalitiesInStudy: this.selectedModalities }))];
                    case 2:
                        response = _a.sent();
                        response.data.forEach(function (item) {
                            item.type = 'study';
                            var startDate = _this.dateFormatService.stringToDateTransformer(item.studyDate, 'YYYYMMDD');
                            item.startDate = startDate;
                            item.studyDateDisplay = _this.dateFormatService.convertDateToMediumDate(startDate);
                        });
                        return [2 /*return*/, response.data];
                    case 3:
                        err_1 = _a.sent();
                        console.warn(err_1);
                        return [2 /*return*/, []];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SelectStudyDialogComponent.prototype.getExams = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var response, exams, err_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.modalityWorkListService.queryModalityWorklist({ patientId: params.mrn })];
                    case 1:
                        response = _a.sent();
                        exams = response.data[0] ? response.data[0].exams : [];
                        exams.forEach(function (item) {
                            item.type = 'exam';
                            var startDate = _this.dateFormatService.stringToDateTransformer(item.startDate, 'YYYYMMDD');
                            item.studyInstanceUID = '';
                            item.modalitiesInStudyDisplay = [item.modality];
                            item.studyDateDisplay = _this.dateFormatService.convertDateToMediumDate(startDate);
                            item.startDate = startDate.valueOf();
                        });
                        return [2 /*return*/, exams];
                    case 2:
                        err_2 = _a.sent();
                        console.warn(err_2);
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SelectStudyDialogComponent.prototype.selectStudy = function (study) {
        this.selectedStudy = study;
        this.submit();
    };
    SelectStudyDialogComponent.prototype.cancel = function () {
        this.dialogRef.close({
            cancel: true
        });
    };
    SelectStudyDialogComponent.prototype.close = function () {
        this.dialogRef.close({
            studyInstanceUID: null,
            accessionNumber: null
        });
    };
    SelectStudyDialogComponent.prototype.updateList = function (exams) {
        this.studiesTable = new table_1.MatTableDataSource(exams);
        this.studiesTable.paginator = this.paginator;
    };
    SelectStudyDialogComponent.prototype.sortByDate = function (studyA, studyB) {
        var aDate = studyA.startDate;
        var bDate = studyB.startDate;
        if (aDate != bDate) {
            return aDate > bDate ? -1 : 1;
        }
        return 0;
    };
    SelectStudyDialogComponent.prototype.filterUnique = function (item, index, parentArr) {
        var firstIndex = parentArr.findIndex(function (exam) { return exam.accessionNumber === item.accessionNumber; });
        // Studies in the PACS should take precident over exams in the Modality Worklist.  When determining if the exam is unique,
        // check the rest of the list for a study with the same accession number.  If one exists return false
        if (item.type === 'exam') {
            var studyIndex = parentArr.findIndex(function (exam) { return exam.accessionNumber === item.accessionNumber && exam.type === 'study'; });
            if (studyIndex !== -1) { // Study found in the list
                return false;
            }
        }
        return firstIndex === index;
    };
    SelectStudyDialogComponent.prototype.submit = function () {
        var study = this.selectedStudy;
        this.dialogRef.close({
            cancel: false,
            type: study.type,
            list: this.studiesTable.data.filter(function (item) { return item.studyDescription !== 'New Study'; }),
            study: {
                studyInstanceUID: study.studyInstanceUID,
                accessionNumber: study.accessionNumber,
                studyDescription: study.studyDescription,
                studyModalities: study.modalitiesInStudyDisplay,
                studyTime: study.studyDateDisplay,
                modalitiesInStudy: this.selectedModalities,
                time: this.selectedTime,
            }
        });
    };
    return SelectStudyDialogComponent;
}());
exports.SelectStudyDialogComponent = SelectStudyDialogComponent;
