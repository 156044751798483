<div>
  <strong *ngIf="enableDoctorFacing; else enablePatient">
    {{translate.instant('phrase.ehrDoctor')}}
  </strong>
  <ng-template #enablePatient>
    <strong>
      {{translate.instant('phrase.ehr')}}
    </strong>
  </ng-template>
</div>
<div *ngFor="let i of patientInfoConfig" class="display">
  <div *ngIf="patient[i.key]">
    <span>{{translate.instant(i.label)}}</span>{{patient[i.key]}}
  </div>
</div>

<div *ngFor="let i of doctorInfoConfig" class="display">
  <div *ngIf="doctor[i.key]">
    <span>{{translate.instant(i.label)}}</span>{{doctor[i.key]}}
  </div>
</div>

<div *ngIf="allowEditLastName" class="display">
  <mat-form-field appearance="standard">
    <mat-label>{{translate.instant('patient.alternateLastName')}}</mat-label>
    <input matInput [value]="patient?.alternateLastName" [formControl]="lastName" />
  </mat-form-field>
</div>

<ng-content></ng-content>