import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Dicom } from '../../classes/dicom-file';
import { ExistingStudy } from '../../classes/image-exchange-types';
import { DateFormatService } from '../../services/date-format.service';
import { ModalityWorklistService } from '../../services/modality-worklist.service';
import { TranslateService } from '../../translation/translate.service';

interface IExam {
  accessionNumber: string;
  startDate: string;
  displayDate: string;
  startTime: string;
  modality?: string;
  studyDescription: string;
  performingPhysician: string;
  status?: string;
}

export interface ISelectExamResponse {
  cancel: boolean;
  exam?: IExam;
  list?: IExam[];
}

export interface ISelectExamInput {
  exams: IExam[],
  mrn: string,
  allowNew: boolean;
}

enum ViewStates {
  Loading,
  Ready,
  Error
}

@Component({
  selector: 'app-select-exam',
  templateUrl: './select-exam.component.html',
  styleUrls: ['./select-exam.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectExamComponent implements OnInit {
  allowRefresh: boolean = true;

  displayedColumns = [ 'accessionNumber', 'studyDescription', 'modality', 'startDate', 'startTime', 'performingPhysician', 'status' ]
  VIEW_STATES = ViewStates;
  viewState: ViewStates;

  examList: IExam[] = [];
  private noExam: IExam = {
    accessionNumber: '',
    startDate: '',
    startTime: '',
    displayDate: this.dateService.convertDateToMediumDate(new Date()),
    studyDescription: 'New Order',
    performingPhysician: ''
  };

  studiesTable = new MatTableDataSource<any>();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private cdr:  ChangeDetectorRef,
    public mwlService: ModalityWorklistService,
    public translate: TranslateService,
    public dateService: DateFormatService,
    private dialogRef: MatDialogRef<SelectExamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectExamInput
  ) { }

  ngOnInit() {
    if (!this.data.mrn) {
      this.viewState = ViewStates.Error;
      this.allowRefresh = false;
      this.cdr.markForCheck();
      return;
    } else {
      this.allowRefresh = true;
    }

    if (this.data.exams.length === 0) {
      this.refresh();
    } else {
      this.viewState = ViewStates.Ready;
      this.generateOrderList(this.data.exams);
    }
  }

  private generateOrderList(orders: IExam[]) {
    this.examList = [ ...orders ];

    this.examList.forEach(exam => {
      const date = this.dateService.stringToDateTransformer(exam.startDate, 'YYYYMMDD');
      exam.displayDate = this.dateService.convertDateToMediumDate(date);
    });

    if (this.data.allowNew && !this.examList.find(exam => exam.accessionNumber === '')) {
      this.examList.unshift(this.noExam);
    }

    this.updateList(this.examList);
  }

  
  cancel() {
    this.dialogRef.close({ cancel: true })
  }
  
  select(exam: IExam) {
    if (!exam.accessionNumber) {
      return this.dialogRef.close({ cancel: false, list: this.examList, exam: undefined });
    }
    
    this.dialogRef.close({ cancel: false, exam, list: this.examList });
  }
  
  async refresh() {
    this.examList = [];
    this.viewState = ViewStates.Loading;
    this.cdr.markForCheck();
    
    try {
      const result: any = await this.mwlService.queryModalityWorklist({ patientId: this.data.mrn });
      
      if (result.data.length >= 1) {
        this.generateOrderList(result.data[0].exams);
      } else {
        this.generateOrderList([]);
      }
      
      this.viewState = ViewStates.Ready;
    } catch (err) {
      this.viewState = ViewStates.Error;
      console.error(err);
    }
    
    this.cdr.markForCheck();
  }
  
  private updateList(exams: any[]) {
    this.studiesTable = new MatTableDataSource<any>(exams);
    this.studiesTable.paginator = this.paginator;
  }
  
}
