"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
// import translations
var locale_en_US_1 = require("./languages/locale-en_US");
var locale_fr_CH_1 = require("./languages/locale-fr_CH");
var locale_fr_CA_1 = require("./languages/locale-fr_CA");
var locale_it_CH_1 = require("./languages/locale-it_CH");
var locale_de_CH_1 = require("./languages/locale-de_CH");
var locale_nl_NL_1 = require("./languages/locale-nl_NL");
// translation token
exports.TRANSLATIONS = new core_1.InjectionToken('translations');
// all translations
exports.dictionary = (_a = {},
    _a[locale_en_US_1.LANG_EN_NAME] = locale_en_US_1.LANG_EN_TRANSLATIONS,
    _a[locale_fr_CH_1.LANG_FRCH_NAME] = locale_fr_CH_1.LANG_FRCH_TRANSLATIONS,
    _a[locale_fr_CA_1.LANG_FRCA_NAME] = locale_fr_CA_1.LANG_FRCA_TRANSLATIONS,
    _a[locale_it_CH_1.LANG_ITCH_NAME] = locale_it_CH_1.LANG_ITCH_TRANSLATIONS,
    _a[locale_de_CH_1.LANG_DE_NAME] = locale_de_CH_1.LANG_DE_TRANSLATIONS,
    _a[locale_nl_NL_1.LANG_NL_NAME] = locale_nl_NL_1.LANG_NL_TRANSLATIONS,
    _a);
// providers
exports.TRANSLATION_PROVIDERS = [
    { provide: exports.TRANSLATIONS, useValue: exports.dictionary },
];
