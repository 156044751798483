import { InjectionToken } from '@angular/core';

// import translations
import { LANG_EN_NAME, LANG_EN_TRANSLATIONS } from './languages/locale-en_US';
import { LANG_FRCH_NAME, LANG_FRCH_TRANSLATIONS } from './languages/locale-fr_CH';
import { LANG_FRCA_NAME, LANG_FRCA_TRANSLATIONS } from './languages/locale-fr_CA';
import { LANG_ITCH_NAME, LANG_ITCH_TRANSLATIONS } from './languages/locale-it_CH';
import { LANG_DE_NAME, LANG_DE_TRANSLATIONS } from './languages/locale-de_CH';
import { LANG_NL_NAME, LANG_NL_TRANSLATIONS } from './languages/locale-nl_NL';


// translation token
export const TRANSLATIONS = new InjectionToken('translations');

// all translations
export const dictionary = {
    [LANG_EN_NAME]: LANG_EN_TRANSLATIONS,
    [LANG_FRCH_NAME]: LANG_FRCH_TRANSLATIONS,
    [LANG_FRCA_NAME]: LANG_FRCA_TRANSLATIONS,
    [LANG_ITCH_NAME]: LANG_ITCH_TRANSLATIONS,
    [LANG_DE_NAME]: LANG_DE_TRANSLATIONS,
    [LANG_NL_NAME]: LANG_NL_TRANSLATIONS
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];
