"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ManifestService = /** @class */ (function () {
    function ManifestService(http) {
        this.http = http;
    }
    ManifestService.prototype.createManifest = function (route, manifest) {
        return this.http.post(route, manifest, {
            headers: new http_1.HttpHeaders()
        }).toPromise();
    };
    ManifestService.prototype.updateManifestByObj = function (obj, manifest) {
        return this.http.put(manifest.self, obj).toPromise();
    };
    ManifestService.prototype.updateManifestByRoute = function (route, dataObject) {
        return this.http.put(route, { 'expectedFiles': dataObject.expectedFiles }).toPromise();
    };
    ManifestService.prototype.getStatusManifest = function (route) {
        var now = (new Date()).getTime();
        var params = new http_1.HttpParams().set('no-cache', now.toString());
        return this.http.get(route, { params: params }).toPromise();
    };
    ManifestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManifestService_Factory() { return new ManifestService(i0.ɵɵinject(i1.HttpClient)); }, token: ManifestService, providedIn: "root" });
    return ManifestService;
}());
exports.ManifestService = ManifestService;
