"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var manifest_service_1 = require("./../services/manifest.service");
var core_1 = require("@angular/core");
var data_service_1 = require("../services/data.service");
var translate_service_1 = require("../translation/translate.service");
var router_1 = require("@angular/router");
var session_service_1 = require("../services/session.service");
var qr_code_service_1 = require("../services/qr-code.service");
var RoundsComponent = /** @class */ (function () {
    function RoundsComponent(dataService, translate, route, sessionService, manifestService, qrCodeService, cdr, document) {
        this.dataService = dataService;
        this.translate = translate;
        this.route = route;
        this.sessionService = sessionService;
        this.manifestService = manifestService;
        this.qrCodeService = qrCodeService;
        this.cdr = cdr;
        this.document = document;
        this.minimumInformation = false;
        this.doctor = {};
        this.pinCode = '';
    }
    RoundsComponent.prototype.ngOnInit = function () {
        this.cdr.markForCheck();
        this.dataService.showNavigationWarning = false;
        this.params = this.dataService.setupData({});
        this.translate.use(this.params.lang);
        this.customerConfigs = this.route.snapshot.data.config.rounds;
        this.qrcodeConfigs = this.route.snapshot.data.config.qrcode;
        if (this.params.hideNavTabs) {
            data_service_1.DataService.hideNavTabs.next(this.params.hideNavTabs);
        }
        else {
            data_service_1.DataService.hideNavTabs.next(!this.customerConfigs.name);
        }
        this.document.body.classList.add(this.customerConfigs.theme);
        this.setup();
    };
    RoundsComponent.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sessionOptions, newSession, manifestPostData, newManifest, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.loading = true;
                        this.cdr.markForCheck();
                        sessionOptions = {
                            appName: 'rounds',
                            op: this.params.op,
                            demo: false,
                            externalId: ''
                        };
                        if (this.customerConfigs.sessionTimeoutPeriod) {
                            sessionOptions['timeoutMinutes'] = this.customerConfigs.sessionTimeoutPeriod;
                        }
                        this.minimumInformation = !!this.params.op;
                        this.doctor.name = this.params.op;
                        return [4 /*yield*/, this.sessionService.createSession(this.params, sessionOptions)];
                    case 1:
                        newSession = _a.sent();
                        manifestPostData = {
                            'session': newSession.key,
                            'patient.lastName': '',
                            'patient.firstName': '',
                            'patient.middleName': '',
                            'patient.title': '',
                            'patient.suffix': '',
                            'patient.externalId': '',
                            'patient.dateOfBirth': '',
                            'patient.gender': '',
                            'batch.size': 0,
                            'batch.sizeRemaining': 0,
                            'sendToPACS': true,
                            'sendToNuance': false,
                            'sendToXDSb': false,
                            'modality': '',
                            'studyDescription': '',
                            'genPinCode': this.qrcodeConfigs.hasPinCode,
                        };
                        return [4 /*yield*/, this.manifestService.createManifest(newSession.manifests, manifestPostData)];
                    case 2:
                        newManifest = _a.sent();
                        if (this.qrcodeConfigs.hasPinCode) {
                            this.pinCode = "" + newManifest.serverId + newManifest.pinCode;
                        }
                        newSession.self = newSession.self + "&appName=rounds&sessionTimeoutPeriod=" + this.customerConfigs.sessionTimeoutPeriod;
                        this.qrCodeData = this.qrCodeService.createQrCode(newSession, false);
                        this.loading = false;
                        this.cdr.markForCheck();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    RoundsComponent.prototype.ngOnDestroy = function () {
        this.dataService.showNavigationWarning = false;
        this.document.body.classList.remove(this.customerConfigs.theme);
    };
    return RoundsComponent;
}());
exports.RoundsComponent = RoundsComponent;
