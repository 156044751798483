import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { StudyType } from '../../classes/study-type';
import { TranslateService } from '../../translation/translate.service';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-study-selection',
  templateUrl: './study-selection.component.html',
  styleUrls: ['./study-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudySelectionComponent implements OnInit {

  _selectedStudy: string;
  _disabled: boolean;
  _notSuggestedStudies: Array<StudyType>;
  _suggestedStudies: Array<StudyType>;

  filteredNotSuggestedStudies: BehaviorSubject<Array<StudyType>>;
  filteredSuggestedStudies: BehaviorSubject<Array<StudyType>>;

  @Input() suggestedLabel: string;
  @Output() selectedStudyChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  @Output() selectionDisabled = new EventEmitter();

  studyFilter = new FormControl();
  studySelect = new FormControl(undefined, [Validators.required]);

  @Input()
  get selectedStudy() {
    return this._selectedStudy;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  get notSuggestedStudies(): Array<StudyType> {
    return this._notSuggestedStudies;
  }

  @Input()
  get suggestedStudies(): Array<StudyType> {
    return this._suggestedStudies;
  }

  set notSuggestedStudies(val: Array<StudyType>) {
    this._notSuggestedStudies = val;
    this.filteredNotSuggestedStudies = new BehaviorSubject<Array<StudyType>>(this._notSuggestedStudies);
  }

  set suggestedStudies(val: Array<StudyType>) {
    this._suggestedStudies = val;
    this.filteredSuggestedStudies = new BehaviorSubject<Array<StudyType>>(this._suggestedStudies);
  }

  set disabled(val: boolean) {
    this._disabled = val;

    if (this._disabled) {
      this.studySelect.disable({
        emitEvent: false,
        onlySelf: true
      });
    } else {
      this.studySelect.enable({
        emitEvent: false,
        onlySelf: true
      });
    }
    this.selectionDisabled.emit(this._disabled);
  }

  set selectedStudy(val) {
    this._selectedStudy = val;
    this.studySelect.setValue(this._selectedStudy, {
      emitEvent: false,
      onlySelf: true
    });
  }

  constructor(
    public translate: TranslateService,
    public cdr: ChangeDetectorRef
  ) { }

  filterStudies(studies: Array<StudyType>, search: string): Array<StudyType> {
    return studies.filter(study => `(${study.code}) ${study.studyDescription}`.toLowerCase().includes(search.toLowerCase()));
  }

  ngOnInit() {
    this.studySelect.markAsTouched();
    this.filteredNotSuggestedStudies = new BehaviorSubject<Array<StudyType>>(this.notSuggestedStudies);
    this.filteredSuggestedStudies = new BehaviorSubject<Array<StudyType>>(this.suggestedStudies);
    this.suggestedStudies = this.suggestedStudies ? this.suggestedStudies : [];

    this.studyFilter.valueChanges
      .subscribe(async (search) => {
        this.filteredSuggestedStudies.next(this.filterStudies(this.suggestedStudies, search));
        this.filteredNotSuggestedStudies.next(this.filterStudies(this.notSuggestedStudies, search));
        this.cdr.markForCheck();
      });

    this.studySelect.valueChanges
      .subscribe(async (study) => {
        this.selectionChange.emit(study);
        this.cdr.markForCheck();
      });

    this.cdr.markForCheck();
  }

}
