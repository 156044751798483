import { Injectable } from '@angular/core';
import fecha from 'fecha';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  convertDateToMediumDate(date: Date): string {
    return fecha.format(date, 'mediumDate').replace(',', '');
  }

  formatDate(date): string {
    if ((typeof date !== 'undefined' && date !== null) !== false) {
      return date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2);
    }
  }

  prettyPrint(date): string {
    const formatted = this.formatDate(date);
    const pretty = fecha.format(new Date(`${formatted} 00:00:00`), 'mediumDate');
    return pretty;
  }

  /**
   * Consumes a date as a string and returns a new Date object
   * @param {string} date Date to be transformed
   * @param {string} inputFormat The format code of the date string where `Y` is year, `M` is month, and `D` is date 
   * @returns {Date} a new Date object
   * 
   * @example
   * 
   *  const myDate = stringToDateTransformer('20210502', 'YYYYMMDD')
   *  
   *  # Expected Output: 5/2/2021 <Date>
   */
  stringToDateTransformer(date: string, format: string = 'YYYYMMDD') {
    return fecha.parse(date, format);
  }

}
