"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var QrCodeService = /** @class */ (function () {
    function QrCodeService() {
    }
    QrCodeService.prototype.createQrCode = function (manifest, isManifest) {
        if (isManifest === void 0) { isManifest = true; }
        var location = isManifest ? 'manifest' : 'session';
        return "http://www.imagemovermd.com/?" + location + "=" + manifest.self + "#install";
    };
    QrCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QrCodeService_Factory() { return new QrCodeService(); }, token: QrCodeService, providedIn: "root" });
    return QrCodeService;
}());
exports.QrCodeService = QrCodeService;
