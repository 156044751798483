"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LogoComponent = /** @class */ (function () {
    function LogoComponent(cdr) {
        this.cdr = cdr;
    }
    LogoComponent.prototype.ngOnInit = function () {
        this.logo = this.logo.toLowerCase();
        this.cdr.markForCheck();
    };
    return LogoComponent;
}());
exports.LogoComponent = LogoComponent;
