import { Component, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { DataService } from './services/data.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NavigationWarningComponent } from './modals/navigation-warning/navigation-warning.component';
import { environment } from '../environments/environment';
import * as _ from 'lodash';
import { UrlParameterService } from './services/url-parameter.service';
import { SessionService } from './services/session.service';
import { HttpClient } from '@angular/common/http';
import { RequestSource } from './utilities/inter-app-communication.util';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: []
})
export class AppComponent implements OnInit {

  public version: string = environment.VERSION;


  hideNavTabs: any = true;
  navigationWarning: MatDialogRef<NavigationWarningComponent>;

  constructor(
    private dialogService: MatDialog,
    private sessionService: SessionService,
    private urlParameterService: UrlParameterService,
    public dataService: DataService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    @Inject('Window') private window: Window) { }

  ngOnInit() {
    this.dataService.currentRoute = this.window.location.pathname.replace('/', '');
    this.sessionService.getUrlPath(this.urlParameterService.getUrlParameter('unittest'));

    DataService.hideNavTabs.subscribe((data) => {
      requestAnimationFrame(() => {
        this.hideNavTabs = data;
      });
    });

    // DataService.hideNavTabs.next(this.urlParameterService.getUrlParameter('hideNavTabs') || this.dataService.currentRoute === '/sync')

    this.refreshFhir();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.dataService.currentRoute = this.window.location.pathname.replace('/', '');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    // todo review this for use in a true post workflow
    // if (_.isUndefined((window as any).postData)) {
    //   return;
    // }

    // event.preventDefault();
    // event.returnValue = 'prompt';
    // return 'prompt';
  }

  @HostListener('click', ['$event'])
  onUserInteraction() {
    const location = window.location;
    const wrapperOrigin = `${location.protocol}//${location.hostname}:8443`;
    window.parent.postMessage('user-action', wrapperOrigin);
  }

  refreshFhir() {
    const params = this.urlParameterService.getAllParametersViaUrl() as any;

    if (!params.state && !params.code) {
      return;
    }

    const INTERVAL = 1000 * 60 * 2;
    const timer = setInterval(async () => {
      try {
        await this.http.get(`/fhir/refresh?code=${params.code}&state=${params.state}`).toPromise();
      } catch (error) {
        console.log(error);
        clearInterval(timer);
      }
    }, INTERVAL);
  }

  async navigate(route: string) {
    if (this.dataService.showNavigationWarning) {
      this.navigationWarning = this.dialogService.open(NavigationWarningComponent, {
        minWidth: 200,
        disableClose: true,
        maxWidth: '768px'
      });

      const response = await this.navigationWarning.afterClosed().toPromise();

      if (response.rejectNavigation) {
        return;
      }
    }

    this.dataService.navigate(route);
  }

  filterTabsWithName() {
    const params = this.dataService.setupData({});
    let order = [];

    const filterWithConfig = (tab: any) => !!this.dataService.configSettings[tab].name;

    const hasNavigationConfiguration = this.dataService.configSettings && this.dataService.configSettings.navigation && this.dataService.configSettings.navigation.order;
    const hasStandaloneConfiguration = this.dataService.configSettings && this.dataService.configSettings.navigation && this.dataService.configSettings.navigation.standalone;

    if (params.requestSource == RequestSource.Standalone && hasStandaloneConfiguration) {
       order = this.dataService.configSettings.navigation.standalone.filter(filterWithConfig);
    } else if(hasNavigationConfiguration) {
      order = this.dataService.configSettings.navigation.order.filter(filterWithConfig);
    }

    return order;
  }
}
