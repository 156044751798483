import { ModalityWorklistService } from './../services/modality-worklist.service';
import { ISelectExamResponse, SelectExamComponent } from './../modals/select-exam/select-exam.component';
import { Router } from '@angular/router';
import { NuanceService } from './../services/nuance.service';
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Inject, ElementRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { TranslateService } from '../translation/translate.service';
import { FileParseService } from '../services/file-parse.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PatientDataConfirmationComponent } from '../modals/patient-data-confirmation/patient-data-confirmation.component';
import { OverreadConfirmationComponent } from '../modals/overread-confirmation/overread-confirmation.component';
import { TransferConfirmModalComponent } from '../modals/transfer-confirm-modal/transfer-confirm-modal.component';

import { Dicom } from '../classes/dicom-file';
import { Provider } from '../classes/provider';
import { Configs } from '../classes/configs';
import { DateFormatService } from '../services/date-format.service';
import { ConfigurationService } from '../services/configuration.service';
import { ManifestService } from '../services/manifest.service';
import { GuidService } from '../services/guid.service';
import { SessionService } from '../services/session.service';
import { UrlParameterService } from '../services/url-parameter.service';
import JaroWinkler = require('jaro-winkler');
import { DicomUtils } from './dicom.utils';
import AbstractUploaderPage from '../shared/abstract-uploader-page.component';

enum SyncResultsState {
  IN_PROGRESS,
  FAILED,
  SUCCESS,
  CANCELLED
}

enum RequestSource {
  Integrated,
  Standalone
}

@Component({
  selector: 'app-dicom',
  templateUrl: './dicom.component.html',
  styleUrls: ['./dicom.component.scss']
})
export class DicomComponent extends AbstractUploaderPage implements OnInit, AfterViewInit, OnDestroy {

  private CURRENT_TAB = 'dicom';

  /**
   * Flag that identifies if the application is running in IE by checking for the
   * customElements property on the window object
   * 
   * @see ngOnInit
   */
  browserIsIE: boolean;
  uploadResultTotals = { total: 0, success: 0, failures: 0, ignored: 0, processed: 0 } as any;
  finished: boolean;
  
  selectedChildren: Array<Dicom.Study>;
  customerConfigs;
  stop;

  /**
   * Flag to identify when the application is run without patient context.  The DICOM Uploader
   * will instead upload the DICOM files as-is to the PACS
   * 
   * @deprecated
   * @see isRawImport
   */
  isStandalone: boolean;

  /**
   * Flag that identifies that the DICOM should be sent directly to the PACS without any coercion.  
   * 
   * This flag will modify the application in the following ways when true:
   *   1. Studies and accession # will not be coerced by pronghorn
   *   2. Users will not be asked to verify the updates made to the files
   */
  isRawImport: boolean;

  /**
   * Identifies the origin of the request.  This will either be via the integrated
   * origin (ie: standard browser or EHR) or via the IM Standalone application.  Depending
   * on the source the uploader should behave slightly different.  For example, all studies must
   * have an exam when the origin is the Standalone application.
   */
  requestSource: RequestSource = RequestSource.Integrated;

  guidLocked = '';
  getCount = 0;
  errorStatus = '';
  uploaderDisabled = true;
  cancelDisabled = true;
  continueChain = true;
  allowCoerce = true;
  batchSize = 1;
  promiseList = [];
  manifestList = [];
  manifestRoute: string;
  params = {} as any;
  currentlyUpdating = false;
  multiplePatientWarning;
  inputDisabled: boolean;
  nuanceDestinations = [];
  nuanceStudyTagIds = '';
  // IE Sync Pin Code
  PinCode: string;
  PinCodeLifespan;
  syncResultsState = SyncResultsState;
  syncUploadResult: SyncResultsState = SyncResultsState.IN_PROGRESS;
  // =====================
  selectedDestination;
  version;
  failureStatus;
  selectedStudy;
  patientDataConfirmation: MatDialogRef<PatientDataConfirmationComponent>;
  overreadConfirmation: MatDialogRef<OverreadConfirmationComponent>;
  logData = new MatTableDataSource<Element>();
  logColumns = ['name', 'message'];
  performance: string;
  files: Array<Dicom.DicomFile>;
  studies;
  studyEnabled;
  patient;
  serverPostLocation;
  allFilesSelected = true;
  providers: Array<Provider>;
  specialties: Array<string>;
  overreadsActive: boolean;
  overreadEnabledMessage: string;
  sopListWarningActive: boolean;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild('iepin', { static: false, read: ElementRef }) iePinCode: ElementRef;

  constructor(
    private configurationService: ConfigurationService,
    private dateFormatService: DateFormatService,
    private dialogService: MatDialog,
    private guidService: GuidService,
    private manifestService: ManifestService,
    protected nuanceService: NuanceService,
    private sessionService: SessionService,
    public dataService: DataService,
    public fileParseService: FileParseService,
    public translate: TranslateService,
    private urlParameterService: UrlParameterService,
    private router: Router,
    private dicomUtils: DicomUtils,
    public mwlService: ModalityWorklistService,
    @Inject('Document') private document: Document,
    @Inject('Window') private window: Window) {
      super();
    }

  ngOnInit() {
    this.browserIsIE = !( 'customElements' in window );

    this.params = this.dataService.setupData(this.params);
    this.translate.use(this.params.lang);
    this.setup();
  }

  async ngAfterViewInit() {
    if (this.iePinCode.nativeElement.offsetParent || this.params.enableSync === 'yes') {
      this.setupSessionWithPin();
    }
  }

  async setup() {
    this.params = this.dataService.setupData(this.params);
    this.params.batchSize = this.params.batchSize > 0 ? this.params.batchSize : 3;
    this.patient = this.dataService.patient;

    if (this.params.exams) {
      this.dicomUtils.exams = JSON.parse(this.params.exams);
    }

    if (this.params.requestSource) {
      this.requestSource = Number(this.params.requestSource);
    }

    this.isRawImport = !this.params.mrn && (this.dataService.configSettings.dicom.standalone || this.params.isStandalone);

    this.customerConfigs = this.dataService.configSettings.dicom;
    this.document.body.classList.add(this.customerConfigs.theme);

    if (this.params.hideNavTabs) {
      DataService.hideNavTabs.next(this.params.hideNavTabs);
    } else {
      DataService.hideNavTabs.next(!this.customerConfigs.name);
    }

    this.setDefaultServerPostLocation();

    if (!this.dataService.hasMinimumPatientInformation && !this.isRawImport) {
      this.clearAndShowError('error.session');
      this.inputDisabled = true;
      return;
    }


    this.studies = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, this.CURRENT_TAB);

    this.selectedDestination = this.nuanceDestinations.length > 0 ? this.nuanceDestinations[0].id : undefined;
    if (this.customerConfigs.enableAuthorizingProvider) {
      this.setProviders();
    }
  }

  async setupSessionWithPin() {
    const configs = await this.configurationService.getSetupConfig();
    const session = await this.generateSessionInformation(null, true);
    this.generatePinCode(session);

    const pinCodeTimeout = configs.dicom.transferTokenAgeInSeconds || 60;
    this.PinCodeLifespan = setTimeout(() => {
      this.PinCodeLifespan = null;
    }, pinCodeTimeout * 1000);

    try {
      const params = this.urlParameterService.getRawParameters();
      delete params.enableSync;

      const result = await this.sessionService.transferSession(this.PinCode, window.location.pathname, params);

    } catch (err) {
      console.error(err);
    }
  }

  async setProviders() {
    this.providers = await this.configurationService.getProviders();
    const nonUniqueSpecialties = this.providers
      .map((value: Provider) => value.specialties)
      .reduce((a, b) => a.concat(b), []);
    this.specialties = Array.from(new Set(nonUniqueSpecialties));
  }

  setDefaultServerPostLocation() {
    this.serverPostLocation = this.customerConfigs.sendToServerDefault;
    this.setServerPostLocation({ value: this.serverPostLocation } as MatRadioChange);
  }

  async setServerPostLocation($event: MatRadioChange) {
    super.setServerPostLocation($event);
    this.overreadsActive = $event.value ==='PACS';

    if (!this.overreadsActive) {
      this.overreadEnabledMessage = this.translate.instant('phrase.overreadnotavailable');
      if (this.hasOverreads()) {
        this.clearAndShowError('phrase.overreadnotavailable');
      }
    }

    if (this.customerConfigs.destinations) {
      this.isInSopList($event.value);
    }
  }

  isInSopList(destinationName: string): void {
    let logs = [];
    const destination: Configs.Destination = this.customerConfigs.destinations.find(i => i.key === destinationName.toLowerCase());
    if (destination && destination.isListActive && this.files && this.files.length > 0) {
      // show each individual study that is failing the check
      // recheck for each selection change and server post location change
      this.files
        .forEach(i => i.children
          .forEach(j => {
            j.hasDisallowedSopClass = destination.isBlackList ?
              j.children.some((each) => this.fileParseService.isInSopList(each, destination)) :
              j.children.every((each) => !this.fileParseService.isInSopList(each, destination));

            const disallowedFiles = destination.isBlackList ?
              j.children.filter((each) => this.fileParseService.isInSopList(each, destination)) :
              j.children.filter((each) => !this.fileParseService.isInSopList(each, destination));


            if (j.hasDisallowedSopClass) {
              j.disallowedSopClassMessage = destination.isBlackList ?
                this.translate.instant('phrase.fileinblacklist') :
                this.translate.instant('phrase.filenotinwhitelist');

              logs = logs.concat(disallowedFiles.map(file => {
                return {
                  name: `${file.label} (${file.sopClass})`,
                  message: j.disallowedSopClassMessage
                };
              }));
            }
          }));

      this.logData = new MatTableDataSource<Element>(this.fileParseService.parsedFiles.log.concat(logs));
      this.logData.paginator = this.paginator;
      this.selectedFilesAllowedForUpload(destination);
    }
  }

  selectedFilesAllowedForUpload(destination) {
    if (!destination || !destination.isListActive) {
      return;
    }

    if (destination.isBlackList) {
      const isInSopList =
        this.files.some(i =>
          i.children.some(j => j.selected &&
            j.children.some(k => this.fileParseService.isInSopList(k, destination))));

      this.uploaderDisabled = isInSopList;
      if (this.uploaderDisabled) {
        this.clearAndShowError('phrase.fileinblacklist');
      } else {
        this.errorStatus = '';
      }
    } else {
      const onlyInSopList =
        this.files.every(i =>
          i.children.every(j =>
            j.children.every(k => !this.fileParseService.isInSopList(k, destination))));

      this.uploaderDisabled = onlyInSopList;
      if (this.uploaderDisabled) {
        this.clearAndShowError('phrase.filenotinwhitelist');
      } else {
        this.errorStatus = '';
      }
    }
  }

  stopStatus() {
    clearInterval(this.stop);
    this.stop = undefined;
    this.addErrorsToLogs();
  }

  startStatus() {
    if (this.stop) {
      return;
    }

    // Don't start a new interval if we are already have one, this prevents memory leaks.
    this.checkStatus();
    const pollingInterval = 3000;
    this.stop = setInterval(this.checkStatus, pollingInterval);
  }

  checkStatus = async () => {
    if (this.currentlyUpdating !== false) {
      return;
    }

    // Set scope variable to prevent queuing of calls.
    this.currentlyUpdating = true;
    try {
      const response: any = await this.manifestService.getStatusManifest(this.manifestRoute);
      // Set to allow calls to resume.
      this.currentlyUpdating = false;
      if (response.status !== 404) {
        this.parseResponse(response);
      } else {
        // This handles the typical 404 error message after timeout or a hard failure somewhere like server reboot. Server should try 3 times to make this call before it gets caught in the catch below.
        this.getCount = this.getCount + 1;
        if (this.getCount >= 3) {
          this.clearAndShowError('error.timeout');
        }
      }

    } catch (error) {
      // If for some reason we get a JS error this handles stopping anything.
      this.currentlyUpdating = false;
      this.clearAndShowError('error.timeout');
      console.log(error);
    }
  }

  parseResponse(response) {

    response.forEach(status => {
      const countObject = status.transmissionStatusSummary as Dicom.Counts;
      const study = this.selectedChildren.find(child => child.UID === status.studyStringID);

      countObject.totalFiles = status.expectedFiles > 0 ? status.expectedFiles : study.counts.totalFiles;
      countObject.filesReceived = status.filesReceived;
      study.counts = countObject;
      study.errors = status.transmissionStatusErrorReasons ? status.transmissionStatusErrorReasons : [];

      study.progressPercentage = Math.round(((countObject.filesReceived - countObject.PROCESSING) / countObject.totalFiles) * 100);
      study.bufferPercentage = Math.round((countObject.filesReceived / countObject.totalFiles) * 100);
      study.progressMode = study.bufferPercentage > 0 ? 'buffer' : 'determinate';
    });

    this.uploadResultTotals = { total: 0, success: 0, failures: 0, processed: 0 };
    this.selectedChildren.forEach( study => {
      if (!study.counts) {
        return;
      }
      this.uploadResultTotals.total += study.counts.totalFiles;
      this.uploadResultTotals.processed += study.counts.SUCCESS + study.counts.ERROR;
      this.uploadResultTotals.success += study.counts.SUCCESS;
      this.uploadResultTotals.failures += study.counts.ERROR;
    });

    if (this.selectedChildren.every(child => child.progressPercentage === 100 && child.selected && child.counts.SUCCESS === child.counts.totalFiles)) {
      this.inputDisabled = this.params.syncWorkflow ? true : false;
      this.uploaderDisabled = true;
      this.finished = true;
      this.syncUploadResult = SyncResultsState.SUCCESS;
      this.cancelDisabled = true;
      this.dataService.showNavigationWarning = false;
      this.stopStatus();
      this.window.scrollTo({ top: 0, left: 0 });

      // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
      window.parent.postMessage('enable-inputs', `${location.protocol}//${location.hostname}:8443`);
    }

    if (this.selectedChildren.some( child => child.progressPercentage === 100 && child.counts.SUCCESS !== child.counts.totalFiles) && this.params.syncWorkflow) {
      this.cancelDisabled = true;
      this.errorStatus = 'true';
      this.window.scrollTo({ top: 0, left: 0 });
      this.syncUploadResult = SyncResultsState.FAILED;
    }

    this.addErrorsToLogs();
  }

  addErrorsToLogs() {
    if (!this.selectedChildren) {
      return;
    }

    const logs = this.selectedChildren.map(i => {
      if (!i.errors) {
        i.errors = [];
      }

      return i.errors.map(j => {
        return {
          name: 'Error',
          message: j
        };
      });
    }).reduce((a, b) => a.concat(b), []).filter(i => !this.fileParseService.parsedFiles.log.includes(i));

    this.logData = new MatTableDataSource<Element>(this.fileParseService.parsedFiles.log.concat(logs));
    this.logData.paginator = this.paginator;
  }

  clearAndShowError(error: string = 'error.general') {
    this.stopStatus();
    this.errorStatus = this.translate.instant(error);
  }

  setUploaderMultiPatientConfiguration() {
    if (this.isRawImport) {
      this.uploaderDisabled = false;
      this.multiplePatientWarning = false;
      return;
    }

    // strict compare to false because null denotes indeterminate
    let selectedFiles = this.files.filter(file => file.selected !== false);

    if (selectedFiles.length > 1) {
      this.uploaderDisabled = true;
      this.multiplePatientWarning = true;
    } else if (selectedFiles.length === 0) {
      this.uploaderDisabled = true;
      this.multiplePatientWarning = false;
    } else {
      this.uploaderDisabled = false;
      this.multiplePatientWarning = false;
    }

    selectedFiles = [];
  }

  // this function manages wether or not we open the modal for submission
  async submitTrigger() {
    this.errorStatus = '';
    
    // Check for Disabled
    if (this.uploaderDisabled) {
      return;
    }

    this.manifestList = [];
    let overreadText;
    let validation;

    try {
      this.files
        .filter(file => file.selected !== false)
        .forEach((file) => file.children.forEach((child: Dicom.Study) => {
          validation = file.validation;
          let modeFilter;
          let modalityFilter;
          let studyDescription;
          let overreadFilter;

          if (child.selected && child.study) {

            if (!this.params.mode) {
              modeFilter = this.studies;
            } else {
              modeFilter = this.dataService.filterAndMapStudies(this.studies, child.mode, 'mode');
            }

            if (!child.modality) {
              modalityFilter = modeFilter;
            } else {
              modalityFilter = this.dataService.filterAndMapStudies(modeFilter, child.modality, 'modality');
            }

            if (modalityFilter.length > 0) {
              overreadFilter = this.dataService.filterAndMapStudies(modalityFilter, (!!child.overread).toString(), 'overread');
              if (overreadFilter.length > 0) {

                for (let o = 0; o < overreadFilter.length; o++) {
                  const current = overreadFilter[o];
                  current.searchTerms.forEach(function (element, index, array) {
                    if (studyDescription === undefined && child.studyDescription) {
                      if (child.studyDescription.toLowerCase().indexOf(element.toLowerCase()) > -1) {
                        studyDescription = current;
                      }
                    }
                  });
                }

                // Catch to match first item if nothing else matches
                if (studyDescription === undefined) {
                  studyDescription = overreadFilter[0];
                }

              } else {
                // Overread Returned 0 results use Modality Filter
                overreadFilter = modalityFilter;
                studyDescription = overreadFilter[0];
                overreadText = this.translate.instant('validation.failedOverread');
              }
            } else {
              // No Study Description Valid Select Value from Dicom File
              overreadFilter = [{ 'value': '', 'overread': false, 'label': child.studyDescription }];
              studyDescription = overreadFilter[0];
              overreadText = this.translate.instant('validation.failedModality');
            }


            this.promiseList[child.UID] = [];

            if (child.overread) {
              overreadText = this.translate.instant('validation.overread') + '"' + child.reasonForStudy + '".';
            }

            if (this.dataService.configSettings.dicom.disableStudy || this.isRawImport) {
              overreadFilter = [{ 'value': '', 'overread': false, 'label': child.studyDescription }];
              overreadText = this.translate.instant('validation.disabled');
              studyDescription = overreadFilter[0];
            }

            studyDescription = this.customerConfigs.studyDescriptionRequired ? null : studyDescription;

            if (overreadFilter.some(o => o.label === child.studyDescription)) {
              studyDescription = overreadFilter.find(o => o.label === child.studyDescription);
            }

            // Sort study description by most similar.
            if (overreadFilter && child.studyDescription && !child.exam){
              
              //precompute jarko-winkler distance values
              let jaro : Array<number> = [];
              for (let i=0; i < overreadFilter.length; i++){
                let distance = JaroWinkler(overreadFilter[i].label, child.studyDescription, {caseSensitive : false});
                jaro.push(distance);
              }

              // sort by most similar.
              for (let i=0; i < overreadFilter.length; i++){
                let distance1 = jaro[i];
                for (let j=i+1; j < overreadFilter.length; j++){
                  let distance2 = jaro[j];
                  // bubble up study descriptions that are more similar to the top.
                  if (distance2 > distance1){
                    [overreadFilter[i], overreadFilter[j]] = [overreadFilter[j], overreadFilter[i]];
                    [jaro[i], jaro[j]] = [jaro[j], jaro[i]];
                    distance1 = distance2;
                  }
                }
              }

              // If we sorted something, the 1st one on the list is what we want.
              if (overreadFilter.length > 0){
                studyDescription = overreadFilter[0];
              }
            }


            // When an exam is assigned to a study, the application should use that exam study description
            // unapologetically.
            if (child.exam) {
              studyDescription = overreadFilter.find( studyDescription => studyDescription.label.startsWith(child.exam.studyDescription));
            
              // If no study description matches, just display the study that was selected by the exam
              if (!studyDescription) {
                studyDescription = { label: child.exam.studyDescription }
              }
            }

            this.manifestList.push({
              'studyid': child.UID,
              'patientid': child.children[0].patientID,
              'study': child.studyDescription,
              'modality': child.modality,
              'studies': overreadFilter,
              'studyDescriptionValue': studyDescription ? studyDescription.value : child.exam,
              'studyDescription': studyDescription ? studyDescription.label : '',
              'studyDescriptionObject': studyDescription,
              'overread': this.params.sendToPACS ? child.overread : undefined,
              'overreadText': this.params.sendToPACS ? overreadText : undefined,
              'reasonForStudy': this.params.sendToPACS ? child.reasonForStudy : undefined,
              'studyAccessionNumber': child.children[0].studyAccession,
              'accessionNumber': child.exam ? child.exam.accessionNumber : undefined,
              exam: child.exam
            });

            child.children.forEach((secondLevelChild) => {
              this.promiseList[secondLevelChild.studyUID].push(secondLevelChild);
            });
          }
        }));


        const allFilesHaveStudy = this.files
          .filter(file => file.selected)
          .every(file => {
            return file.children.every(child => child.exam);
          });

        if (this.requestSource === RequestSource.Standalone && !allFilesHaveStudy) {
          throw Error('dicom.error.order-required')
        }

      if (this.requestSource === RequestSource.Integrated && this.isRawImport) {
        this.folderUpload();
      } else {
        // fire a method here to open a modal
        this.openPatientDataConfirmationModal(validation);
      }
    } catch (error) {
      this.currentlyUpdating = false;
      this.clearAndShowError(error.message);

      throw error;
    }
  }

  async openPatientDataConfirmationModal(validation) {
    this.patientDataConfirmation = this.dialogService.open(PatientDataConfirmationComponent, {
      minWidth: 300,
      disableClose: true,
      data: {
        studies: this.studies,
        params: this.params,
        patient: this.files.find(file => file.selected !== false),
        manifestList: this.manifestList,
        validation: validation,
        providers: this.providers,
        specialties: this.specialties
      }
    });

    const $event = await this.patientDataConfirmation.afterClosed().toPromise();

    if ($event.submit) {
      this.manifestList = $event.manifestList;
      this.folderUpload($event.provider);
    }
  }

  generatePinCode(session) {
    const pin = `${session.iePinCode}`;
    if (pin.length < 4) {
      const zero = '0';
      this.PinCode = `${zero.repeat(4 - pin.length)}${pin}`;
    } else {
      this.PinCode = pin.slice(-4);
    }
  }

  async generateSessionInformation(provider?: Provider, includePin?: boolean): Promise<any> {
    if (this.params.demo) {
      this.params.coerceUIDs = true;
    }

    if (this.isRawImport) {
      this.allowCoerce = false;
    }

    const sessionSettings = {
      appName: 'dicom',
      op: this.params.op,
      coerce: this.allowCoerce,
      coerceUIDs: this.params.coerceUIDs,
      prov: provider ? provider.id : this.params.prov,
      isStandalone: this.isRawImport,
      sourceSite: this.params.sourceSite,
      genIEPinCode: includePin,
      demo: this.params.demo
    };

    return await this.sessionService.createSession(this.params, sessionSettings);
  }

  async folderUpload(provider?: Provider) {
    this.inputDisabled = true;
    this.uploaderDisabled = true;
    this.cancelDisabled = false;

    // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
    window.parent.postMessage('disable-inputs', `${location.protocol}//${location.hostname}:8443`);

    // Create Session, Manifest and QrCode
    try {
      // hard compare to false because null is selected, but not all children
      this.selectedChildren = this.files
        .filter((file: Dicom.DicomFile) => file.selected !== false)
        .map((file: Dicom.DicomFile) => file.children)
        .reduce((a: Array<Dicom.Study>, b: Array<Dicom.Study>) => a.concat(b), [])
        .filter((file: Dicom.Study) => file.selected);

      const session = await this.generateSessionInformation(provider);

      this.manifestRoute = session.manifests + '/';
      this.version = session.version;

      const nuanceRepoId = this.selectedDestination && this.params.sendToNuance ? this.selectedDestination : undefined;
 
      const postData = {
        coerce: this.allowCoerce,
        'session': session.key,
        'patient.lastName': this.params.lastName,
        'patient.firstName': this.params.firstName,
        'patient.middleName': this.params.middleName,
        'patient.title': this.params.title,
        'patient.suffix': this.params.suffix,
        'patient.externalId': this.params.mrn,
        'patient.dateOfBirth': this.dateFormatService.formatDate(this.params.dob),
        'patient.gender': this.params.gender,
        'batchSize': this.batchSize,
        'batch.size': this.batchSize,
        'sendToPACS': this.params.sendToPACS,
        'sendToNuance': this.params.sendToNuance,
        'sendToXDSb': this.params.sendToXDSb,
        'studyDescription': this.params.studyDescription,
        'modality': this.params.modality,
        'expectedFiles': 0,
        'studyStringID': '',
        'isOverread': false,
        'examCode': '',
        'reasonForStudy': '',
        'nuanceRepoId': nuanceRepoId,
        'nuanceRepoStudyTagIds': this.nuanceStudyTagIds,
        'xdsPatientId': this.params.xdsPatientId,
        'xdsSourcePatientId': this.params.xdsSourcePatientId
      };

      await this.createManifest(postData, this.promiseList, 0);
    } catch (error) {
      this.errorStatus = this.translate.instant('error.session');
      throw error;
    }

  }

  async createManifest(postData, promiseList, count) {
    const current = this.manifestList[count];
    if (current && this.continueChain) {
      const exam = current.exam;

      postData.expectedFiles = promiseList[current.studyid].length;
      postData.studyStringID = current.studyid;
      postData.modality = current.modality;
      postData.isOverread = current.overread;
      postData.studyDescription = exam && exam.studyDescription ? exam.studyDescription : current.studyDescription;
      postData.examCode = current.studyDescriptionValue;
      postData.reasonForStudy = current.reasonForStudy;
      postData.accessionNumber = current.accessionNumber;
      postData.useAccessionNumberProvided = !!current.accessionNumber;

      const list = this.manifestList[count];
      try {
        const manifest = await this.manifestService.createManifest(this.manifestRoute, postData);

        list.manifestId = manifest.id;
        list.self = manifest.self;
        current.files = manifest.files;
        current.expectedFiles = postData.expectedFiles;
        current.count = {};
        count++;
        try {
          this.batchTransmit(promiseList[current.studyid], this.params.batchSize, current.files);
          this.startStatus();
          await this.createManifest(postData, promiseList, count);
        } catch (error) {
          console.log(error);
          await this.createManifest(postData, promiseList, count);
        }
      } catch (error) {
        this.errorStatus = this.translate.instant('error.manifest');
        throw error;
      }
    } else if (this.continueChain) {
      const len = this.manifestList.length;
      let totalExpected = 0;
      for (let j = 0; j < len; j++) {
        totalExpected = totalExpected + this.manifestList[j].expectedFiles;
      }

      try {
        await this.sessionService.updateSession(totalExpected);
      } catch (error) {
        this.errorStatus = this.translate.instant('error.session');
        throw error;
      }
    }
  }

  async cancelUpload() {
    this.continueChain = false;
    this.cancelDisabled = true;
    this.inputDisabled = true;
    this.syncUploadResult = SyncResultsState.CANCELLED;

    // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
    window.parent.postMessage('enable-inputs', `${location.protocol}//${location.hostname}:8443`);

    // Remove loading animation from 
    this.selectedChildren.forEach( child => {
      child.progressPercentage = 0;
      child.progressMode = 'determinate';
    });

    const updateObject = { expectedFiles: 0 };
    const len = this.manifestList.length;
    for (let j = 0; j < len; j++) {
      try {
        await this.manifestService.updateManifestByRoute(this.manifestList[j].self, updateObject);
      } catch (error) {
        this.failureStatus = this.translate.instant('error.badcancelled');
        console.log(error);
      } finally {
        this.stopStatus();
      }
    }
  }

  async batchTransmit(tasks, batch_size, files) {
    if (this.continueChain) {
      this.currentlyUpdating = false;
      // Finished?
      if (!tasks || tasks.length === 0) {
        return;
      }
      // Call the function batch_size times to get the promises
      const promise_array = [];
      const len = tasks.length;
      for (let i = 0; (i < batch_size) && (i < len); i++) {
        try {
          const task = tasks[i];
          promise_array.push(this.singleFileUpload(task, files));
        } catch (error) {
          console.log(error);
        }
      }

      // Execute the batch_size, then go again
      try {
        const promise_batch = Promise.all(promise_array);
        await promise_batch;
        tasks.splice(0, batch_size);
        await this.batchTransmit(tasks, batch_size, files);
      } catch (error) {
        tasks.splice(0, batch_size);
        await this.batchTransmit(tasks, batch_size, files);
      }
    }
  }

  singleFileUpload(file: Dicom.Child, files) {
    let path = '';

    // console.log('File Last Modified Date:', file.lastModified || 'none');

    // // Date Formatting
    // let lastModified = new Date(file.lastModified);

    // if (!lastModified) {
    //   lastModified = new Date(file.lastModifiedDate);
    // }
    // console.log('Last Modified - Pre-Format: ', lastModified);

    // const date = format(lastModified, 'YYYY-MM-DD\'T\'HH:mm:ss\'Z\'');
    // console.log({
    //   lastModified,
    //   date
    // });

    // Relative Path
    if (file.webkitRelativePath === '') {
      path = '';
    } else {
      path = file.webkitRelativePath;
    }

    const formData = {
      'type': file.mediaType,
      'diskPath': path,
      // 'clientDateCreated': date, // "yyyy-MM-DD'T'HH:mm:ss'Z'"
      'fileContent': file,
      'newlockKey': this.guidLocked === '' ? this.guidService.generateGuid() : this.guidLocked
    };

    return this.dataService.sendImage(files, formData, 0);

  }

  async ieNotification($event) {
    const ieDialog = this.dialogService.open(TransferConfirmModalComponent, {
      data: {
        pin: this.PinCode,
        path: `https://${this.window.location.host}/sync`,
        tokenExpired: !this.PinCodeLifespan,
        message: this.customerConfigs.directoryUploadMessaging
      },
      position: { top: '10vh' },
      width: '50rem'
    });

    ieDialog.afterClosed().toPromise().then( async result => {
      if (result) {
        await this.newSession();
      }
    });
  }

  async openExamSelectDialog($event: Event, file: Dicom.DicomFile, index: number ) {
    const examDialog = this.dialogService.open(SelectExamComponent, {
      minWidth: '75vw',
      maxWidth: '600px',
      position: {
        top: '2rem'
      },
      data: {
        exams: this.dicomUtils.exams,
        mrn: this.patient.mrn,
        allowNew: this.requestSource === RequestSource.Integrated
      }
    });

    examDialog.afterClosed().toPromise().then( async (result: ISelectExamResponse) => {
      if (result) {
        if (result.exam) {
          file.children[index].exam = result.exam;
        } else {
          file.children[index].exam = undefined;
        }

        if (result.list) {
          this.dicomUtils.exams = result.list;
        }
      }
    });
  }

  async newSession() {
    // Remove the current session so a new one can be created
    this.sessionService.setSession(false);
    this.PinCode = '';
    await this.setupSessionWithPin();
  }

  async fileChange($event) {
    // run a destroy of old state
    this.resetPage();
    this.finished = false;
    this.dataService.showNavigationWarning = true;

    await this.fileParseService.parseDicomFolder($event);
    this.files = this.fileParseService.parsedFiles.files;


    this.setUploaderMultiPatientConfiguration();

    this.logData = new MatTableDataSource<Element>(this.fileParseService.parsedFiles.log);
    this.logData.paginator = this.paginator;

    if (this.customerConfigs.destinations) {
      this.isInSopList(this.serverPostLocation);
    }
  }

  selectAllFiles($event: MatCheckboxChange) {
    this.allFilesSelected = $event.checked;
    this.files.forEach(file => {
      this.selectAllChildren($event, file);
    });
  }

  selectAllChildren($event: MatCheckboxChange, file) {
    file.selected = $event.checked;
    file.children.forEach(i => i.selected = $event.checked);
    this.updateSelectAllStatus();

    const destination: Configs.Destination = this.customerConfigs.destinations && this.customerConfigs.destinations.find(i => i.key === this.serverPostLocation.toLowerCase());
    this.selectedFilesAllowedForUpload(destination);
    this.setUploaderMultiPatientConfiguration();
  }

  updateSelectAllStatus() {
    const allSelected = this.files.every(i => i.selected);
    const anySelected = this.files.some(i => i.selected !== false);
    const isIndeterminate = !allSelected && anySelected;

    this.allFilesSelected = isIndeterminate ? null : allSelected;
  }

  selectFile($event: MatCheckboxChange, file, child) {
    child.selected = $event.checked;
    this.updateSelectChildrenStatus(file);
    this.updateSelectAllStatus();

    const destination: Configs.Destination = this.customerConfigs.destinations && this.customerConfigs.destinations.find(i => i.key === this.serverPostLocation.toLowerCase());
    this.selectedFilesAllowedForUpload(destination);
    this.setUploaderMultiPatientConfiguration();
  }

  updateSelectChildrenStatus(file) {
    const allSelected = file.children.every(i => i.selected);
    const anySelected = file.children.some(i => i.selected);
    const isIndeterminate = !allSelected && anySelected;

    file.selected = isIndeterminate ? null : allSelected;
  }

  setAllStudySelection(isSelected: boolean) {
    this.files.forEach(file => {
      file.selected = isSelected;
      file.children.forEach(child => child.selected = isSelected);
    });

    this.setUploaderMultiPatientConfiguration();
  }

  async selectOverread($event: MatCheckboxChange, child: Dicom.Study) {
    if (!$event.checked) {
      child.reasonForStudy = undefined;
      child.overread = false;
      return;
    }

    this.overreadConfirmation = this.dialogService.open(OverreadConfirmationComponent, {
      minWidth: 300,
      disableClose: true,
      data: {
        child: child
      }
    });

    const response = await this.overreadConfirmation.afterClosed().toPromise();

    child.reasonForStudy = response.text;
    child.overread = response.submit;
  }

  hasOverreads(): boolean {
    return this.files && this.files.some(file => file.children.some(child => child.overread));
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.logData.filter = filterValue;
  }

  trackByFile(index, file) {
    return file.patientID;
  }

  resetPage() {
    this.performance = '';
    this.cancelDisabled = true;
    this.finished = false;
    this.uploaderDisabled = true;
    this.inputDisabled = false;
    this.dataService.showNavigationWarning = false;
    this.fileParseService.params = this.params;
    this.fileParseService.customerConfigs = this.dataService.configSettings.dicom;
    this.fileParseService.parsedFiles = {
      logType: [0, 0, 0],
      log: [],
      files: []
    };
    this.files = [];
    this.syncUploadResult = SyncResultsState.IN_PROGRESS;
    this.uploadResultTotals = { total: 0, success: 0, failures: 0, ignored: 0, processed: 0 } as any;
    
    this.dicomUtils.exams = [];
    this.stopStatus();

    // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
    window.parent.postMessage('enable-inputs', `${location.protocol}//${location.hostname}:8443`);
  }

  renderExamDetailButton(exam: any) {
    const output = [];
    if (exam.studyDescription) {
      output.push(exam.studyDescription);
    }

    if (exam.modality) {
      output.push(exam.modality);
    }

    if (exam.performingPhysician) {
      output.push(exam.performingPhysician);
    }
    
    return output.join(' - ');
  }

  newPatient() {
    history.replaceState({}, 'Sync', '/sync');
    this.router.navigate(['/sync'], { queryParams: { hideNavTabs: this.params.hideNavTabs } });
  }

  ngOnDestroy() {
    this.resetPage();
    this.document.body.classList.remove(this.customerConfigs.theme);
  }

}
