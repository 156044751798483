import { Component, Input, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';
import { AboutDialogComponent } from '../../modals/about-dialog/about-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  @Input() version: string;
  @Input() logo: string;
  about: MatDialogRef<AboutDialogComponent>;
  aboutString: string;


  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    private dialogService: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const params = this.dataService.setupData({});
    this.translate.use(params.lang || this.dataService.configSettings.language);
    this.aboutString = this.translate.instant('about.link');
  }

  async open($event) {
    $event.preventDefault();
    this.about = this.dialogService.open(AboutDialogComponent, {
      minWidth: 300,
      maxWidth: '100vw',
      maxHeight: '80vh'
    });

    await this.about.afterClosed().toPromise();
  }
}
