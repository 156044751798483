export const LANG_ITCH_NAME = 'itch';
export const LANG_ITCH_TRANSLATIONS = {
  'word.processing': 'Elaboraz.',
  'word.cancelled': 'Annullato',
  'word.failure': 'Errore',
  'word.success': 'Riusc.',
  'word.connected': 'connesso',
  'word.files': 'File',
  'word.destination': 'Destinaz.',
  'word.current': 'Corr.',
  'word.updated': 'Aggior.',
  'word.instructions': 'Istruzioni',
  'word.study-match': 'Suggerito',
  'word.specialties': 'Specialità',
  'word.providers': 'Fornitori',
  'word.duration': 'Durata',
  'word.hours': 'ore',
  'word.results': 'Risultati',
  'word.done': 'Fatto',
  'word.sensitive': 'Sensibile',
  'word.selected': 'Selezionato',
  'word.apply': 'Applicare',          // Google Translated: 12/15/2020
  'word.anterior': 'Anteriore',   // Google Translated: 12/15/2020
  'word.posterior': 'Posteriore', // Google Translated: 12/15/2020
  'word.name': 'nome', // Google Translated: 12/16/2020
  'word.size': 'taglia', // Google Translated: 12/16/2020
  'word.bytes': 'bytes', // Google Translated: 12/16/2020
  'word.date': 'data', // Google Translated: 12/16/2020
  'phrase.disconnected': 'Dispositivo disconnesso',
  'phrase.install': 'Occorre installare l\'app mobile?',
  'phrase.uploadFailed': 'Caricamento non riuscito!',
  'phrase.scanPartOne': 'Eseguire la scansione del codice QR con il proprio dispositivo, cercare ',
  'phrase.scanPartTwo': ' nell\'app store oppure andare a ',
  'phrase.scanPartThree': ' dal proprio telefono.',
  'phrase.overreadnotavailable': 'Le riletture sono disponibili solo quando si esegue l\'invio a Local Archive (PACS/VNA)',
  'phrase.study': 'Studio:',
  'phrase.selectall': 'Seleziona tutto',
  'phrase.selectdir': 'Selezionare cartella',
  'phrase.selectfiles': 'Seleziona file',
  'phrase.folder': 'Selezione cartella',
  'phrase.file': 'Selezione multipla di file',
  'phrase.ehr': 'Informazioni sul paziente EHR',
  'phrase.ehrDoctor': '',
  'phrase.navigation': 'Avvertenza: Si sta per lasciare questa applicazione',
  'phrase.patientinfo': 'Informazioni sul paziente',
  'phrase.accession': 'Il numero di registrazione è',
  'phrase.cancelExam': 'Avvertenza: Si sta per annullare questo esame',
  'phrase.pinCode': 'Codice Pin',
  'phrase.selectSites': 'Seleziona Siti',
  'phrase.searchSites': 'Ricerca Siti',
  'phrase.siteSelector': 'Selezione sito',
  'phrase.op': '',
  'phrase.selectedStudy': 'Selezionato',
  'phrase.newStudy': 'Nuovo studio',
  'phrase.reasonOverread': 'Motivazione della rilettura',
  'phrase.filesendsuccess': 'File inviato correttamente',
  'phrase.fileprocessing': 'Elaborazione file in corso',
  'phrase.filesenderror': 'Invio file non riuscito',
  'phrase.filesprocessed': 'File totali elaborati',
  'phrase.filterlogs': 'Filtra registri',
  'phrase.authorizingprovider': 'Fornitore di autorizzazione',
  'phrase.transferstatus': 'Stato trasferimento:',
  'phrase.studyTag': 'Etichette studio sfumature',
  'phrase.search': 'Parametri di ricerca',
  'phrase.study-exists': 'Lo studio è già presente nell’archivio.',
  'phrase.addToExistingStudy': 'Aggiungi allo studio esistente',
  'phrase.selectExistingStudy': 'Seleziona studio esistente',
  'phrase.filenotinwhitelist': 'Alcuni studi contengono classi SOP esterne alla whitelist',
  'phrase.fileinblacklist': 'Alcuni studi contengono classi SOP appartenenti alla blacklist',
  'patient.name': 'Nome:',
  'patient.firstName': 'Nome di battesimo:',
  'patient.lastName': 'Cognome:',
  'patient.dob': 'DOB:',
  'patient.sex': 'Sesso:',
  'patient.mrn': 'MRN:',
  'patient.unknown': 'Paziente sconosciuto',
  'patient.alternateLastName': 'Cognome alternativo:',
  'patient.notFound': 'Paziente non trovato',
  'patient.studyNotFound': 'Non sono stati trovati studi relativi al paziente.',
  'button.submit': 'Invia file',
  'button.stop': 'Interrompi caricamento',
  'button.cancel': 'Annulla',
  'button.begin': 'Inizia caricamento',
  'button.nevermind': 'Non importa',
  'button.continue': 'Continua',
  'button.confirm': 'Conferma e procedi',
  'button.capture': 'Prepara per acquisizione',
  'button.endexam': 'Termina esame',
  'button.confirmReason': 'Conferma motivo dell\'esame',
  'button.reason': 'Motivo dell\'esame',
  'button.cancelExam': 'Annulla esame',
  'button.close': 'Chiudi',
  'button.generate': 'Genera',
  'button.pinCode': 'Codice Pin',
  'button.search': 'Cerca',
  'button.retrieve': 'Recupera e memorizza',
  'button.refresh': 'Aggiorna',
  'button.reset': 'Ripristina', // Google Translated: 1/4/2021
  'button.createNewOrder': 'Crea nuovo ordine',
  'button.backToOrders': 'Torna agli ordini',
  'table.filename': 'Nome file',
  'table.message': 'Messaggio',
  'table.filesize': 'Dimensione file in byte',
  'table.filedate': 'Data file',
  'table.patientname': 'Nome paziente',
  'table.patientid': 'ID paziente',
  'table.patientsex': 'Sesso',
  'table.patientdob': 'DOB paziente',
  'table.patientage': 'Età',
  'table.site': 'Sito',
  'table.study': 'Descrizione studio',
  'table.facility': 'Struttura',
  'table.studyInstanceUID': 'Istanza di studio UID',
  'table.seriesInstanceUID': 'Istanza di serie UID',
  'table.series': 'Descrizione serie',
  'table.physician': 'Medico',
  'table.referringphysician': 'Medico di riferimento',
  'table.archive': 'Archivio',
  'table.status': 'Stato',
  'table.accessionNumber': 'Numero di registrazione',
  'table.modality': 'Modality',
  'table.modalities': 'Modalità',
  'table.date': 'Data studio',
  'table.seriesDate': 'Data serie',
  'table.files': 'N. file',
  'table.overread': 'Rilettura',
  'table.bodyPart': 'Parte del corpo',
  'table.notSpecified': '[Non specificato]',
  'about.dialogPartOne': 'L\'app mobile ',
  'about.dialogPartTwo': ' non è destinata alla gestione o alla visualizzazione diagnostica delle immagini.',
  'about.link': 'Informazioni su',
  'about.build_date': 'Data di costruzione: ',
  'about.help_text': 'Questa app viene utilizzata per importare immagini e video in un sistema di imaging. Ulteriori informazioni sono disponibili su www.imagemovermd.com.\n\nL\'app mobile ImageMover non è destinata alla gestione o alla visualizzazione diagnostica delle immagini.\n\n',
  'about.non_diagnostic': 'non è concepito per scopi diagnostici, di monitoraggio o terapeutici o per qualsiasi altro utilizzo di pratica medica standard.',
  'about.rights': 'Tutti i diritti sono riservati.',
  'about.copyright': 'La riproduzione o la trasmissione, parziali o integrali, in qualsiasi forma o con qualsiasi mezzo elettronico, meccanico o di qualsivoglia altra natura, sono vietate senza il previo consenso scritto del titolare del copyright.',
  'about.sw': 'I copyright e tutti gli altri diritti di proprietà del software e della documentazione correlata ("Software") resi disponibili all\'utente rimangono di esclusiva proprietà di ImageMoverMD o dei suoi licenziatari. All\'utente non è conferito alcun titolo o proprietà del Software. L\'uso del Software è soggetto alle condizioni della licenza per l\'utente finale, disponibili su richiesta.',
  'about.engineering': 'La legge fa divieto di decompilare e/o disassemblare il software o qualsiasi sua parte.',
  'error.timeout': 'La connessione è scaduta o è stata reimpostata. Premere F5 per aggiornare questa pagina oppure fare clic sul pulsante di ricaricamento del browser per continuare. Se continuano a verificarsi problemi, contattare l\'assistenza',
  'error.manifest': 'Impossibile creare il manifesto. Se questa pagina è stata avviata dall\'applicazione, riprovare.',
  'error.session': 'Impossibile avviare la sessione. Se questa pagina è stata avviata dall\'applicazione, riprovare.',
  'error.badcancelled': 'Il caricamento è stato interrotto.',
  'error.general': 'Spiacenti, si è verificato un errore.',
  'error.hl7': 'Trasmissione messaggio HL7 non riuscita.',
  'instruction.confirmation': 'Le informazioni del paziente negli studi selezionati da importare saranno aggiornate come sopra mostrato in modo da corrispondere alle informazioni del paziente provenienti da EHR. Facendo clic sul pulsante Conferma e procedi, si conferma che gli studi da importare appartengono al paziente.',
  'instruction.navigation': 'L\'applicazione verrà reimpostata se si passa a un\'altra applicazione. Le attività completate non saranno interessate, ma una nuova attività sarà interrotta. Fare clic su Continua per cambiare applicazione oppure su Non importa per rimanere nell\'applicazione corrente.',
  'instruction.patients': 'Il caricamento degli studi da più pazienti in un record di paziente non è consentita. Selezionare un singolo paziente prima di continuare.',
  'instruction.modalityPartOne': '1. Selezionare la Descrizione studio appropriata.',
  'instruction.modalityPartTwo': '2. Fare clic su \'Prepara per acquisizione\'.',
  'instruction.capturePartOne': 'Puntare l\'app ',
  'instruction.capturePartTwo': ' iOS o Android verso questo codice QR per acquisire le immagini.',
  'instruction.roundsPartOne': 'Puntare',
  'instruction.roundsPartTwo': 'l\'app iOS o Android verso questo codice QR per acquisire le immagini.',
  'instruction.pinCode': 'Fare clic sul pulsante sotto riportato per generare un codice da condividere con l\'utente remoto.',
  'instruction.updateManifest': 'Eseguire la scansione del codice QR per utilizzare la nuova selezione.',
  'instruction.modalityPartThree': 'Un ordine verrà creato e inviato a EHR.',
  'instruction.modalityPartFour': 'Nella modality attuale, selezionare l\'ordine dall\'elenco di lavoro e iniziare l\'esame.',
  'instruction.accession': 'Fare clic su \'Termina esame\' quando l\'acquisizione dell\'immagine è completa.',
  'validation.namematch': 'Corrispondenza esatta per Cognome.',
  'validation.namepartial': 'Possibile corrispondenza per Cognome.',
  'validation.namemismatch': 'Mancata corrispondenza per Cognome.',
  'validation.gendermatch': 'Corrispondenza esatta per Sesso.',
  'validation.genderpartial': 'Il Sesso non è incluso oppure è elencato come alto.',
  'validation.gendermismatch': 'Mancata corrispondenza per Sesso.',
  'validation.dobmatch': 'Corrispondenza esatta per Data di nascita.',
  'validation.dobpartial': 'La Data di nascita non è inclusa oppure è stata resa anonima.',
  'validation.dobmismatch': 'Mancata corrispondenza per Data di nascita.',
  'validation.overread': 'Lo studio è pianificato per una rilettura con motivazione',
  'validation.failedOverread': 'Nessuna descrizione trovata per la rilettura.',
  'validation.failedModality': 'Nessuna descrizione trovata per la modality.',
  'validation.noModalities': 'Non è stata selezionata nessuna modalità.',
  'validation.required': 'Campo obbligatorio',
  'dicom.location132': 'Prefissi obbligatorio non trovato nella posizione 132, non è un file DICOM.',
  'dicom.toosmall': 'File troppo piccolo per essere un file DICOM valido.',
  'dicom.invalidresponse': 'Il campo x00100020 contiene una risposta non valida ed è stato ignorato.',
  'dicom.skipped': '(tipo file) è considerato un file di sistema ed è stato ignorato.',
  'dicom.enabled': 'non è al momento abilitato ed è stato ignorato.',
  'dicom.pinTitle': 'Codice PIN di upload directory',
  'dicom.syncDesc': 'Il Codice PIN di upload directory consentirà il trasferimento sicuro della sessione e dei dati del paziente in un altro browser.',
  'dicom.notIE': 'Attualmente, Microsoft Internet Explorer non supporta la selezione di directory o cartelle dal file system di Windows.  Ciò richiede che in Internet Explorer, i file vengano selezionati singolarmente o tramite le scorciatoie da tastiera (come Maiusc+clic o Ctrl+A)',
  'dicom.useOther': 'ImageMoverMD è in grado di elaborare intere directory/cartelle, se usato in combinazione con i browser internet che supportano tale funzione.  I browser più diffusi che supportano tale funzione includono Google Chrome e Mozilla Firefox.',
  'dicom.pinNavigate': 'Per accedere all’attuale sessione e ai dati del paziente in un browser supportato, aprire il browser e accedere',
  'dicom.enterPin': 'A questo punto, verrà richiesto il Codice PIN di upload directory di 4 cifre (che viene anche elencato di seguito).  Immettere semplicemente il codice pin e il browser recupererà la sessione, e sarà possibile iniziare a caricare le directory/cartelle.',
  'dicom.copyURL': 'Copiare l’URL negli Appunti',
  'dicom.copyURLConfirm': 'URL copiato negli Appunti',
  'dicom.refreshMessage': 'Il codice pin è scaduto e deve essere aggiornato.  Per ottenere un nuovo codice PIN, fare clic ',
  'dicom.startOver': 'Ricominciare da zero',
  'dicom.goBackStartOver': 'Si è verificato un errore durante il caricamento dei file, fare clic su Done (fatto) e inserire un nuovo codice di sincronizzazione per riprovare',
  'dicom.uploadSuccessMsg': 'Caricamento effettuato! Fare clic su Done (fatto) per avviare il prossimo caricamento',
  'dicom.successUpload': 'Caricamenti effettuati',
  'dicom.failedUpload': 'Caricamenti falliti',
  'dicom.filesUploaded': 'File elaborati',
  'media.allSensitive': 'Imposta Tutto come Sensibile',
  'media.sensitiveStudy': 'Studio sensibile',
  'media.change': 'modificare',                                // Google Translated: 12/11/2020
  'media.apply-to-unspecified': 'applicare a non specificato', // Google Translated: 12/11/2020
  'media.not-anatomical': 'Nicht anatomisch',                  // Google Translated: 12/11/2020
  'media.select-different-body-part': 'Select A Different Body Part',    // Google Translated: 12/15/2020
  'media.body-part-required': 'Parte del corpo richiesta per ogni file',  // Google Translated: 12/16/2020
  'media.not-applied-study-description': 'Non applicato alla descrizione dello studio', // Google Translated: 12/17/2020
  'media.tooltip.generating-thumbnail': 'Generazione di miniature',  // Google Translated: 08/02/2021
  'media.tooltip.error-loading-thumbnail': 'Errore durante il caricamento della miniatura dell\'immagine', // Google Translated: 08/02/2021
  'transfer.title': 'Enter Session Pin Code',
  'transfer.wait': 'Retrieving session information, please wait',
  'transfer.error': 'Invalid Session Pin Code.  Please check the code listed in the uploader and try again',
  'sync.title': 'Immettere il codice pin della sessione',
  'sync.loading': 'Recupero della sessione in corso, si prega di attendere',
  'sync.invalid': 'Codice PIN della sessione non valido.  Verificare il codice elencato nell’uploader e riprovare',
  'sync.nextUploadTooltip': 'Fare clic su Done (fatto) per iniziare il prossimo caricamento',
  'dicom.available': 'File DICOM disponibili per l\'importazione.',
  'dicom.nonconformant': 'file DICOM non conformi.',
  'dicom.mediaskipped': 'file multimediali ignorati.',
  'dicom.mediaready': 'file multimediali disponibili per l\'importazione e la conversione.',
  'dicom.fileskipped': 'file ignorati.',
  'dicom.notmedia': 'File ignorato in quanto non è un file multimediale.',
  'dicom.nomedia': 'File multimediale ignorato.',
  'radio.pacs': 'Local archive (PACS/VNA)',
  'radio.powershare': 'PowerShare Network',
  'radio.xdsb': 'XDS.b repository',

};
