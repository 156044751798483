"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var patient_1 = require("../classes/patient");
var name_parse_service_1 = require("./name-parse.service");
var i0 = require("@angular/core");
var i1 = require("./name-parse.service");
var UrlParameterService = /** @class */ (function () {
    function UrlParameterService(nameParseService, window) {
        this.nameParseService = nameParseService;
        this.window = window;
    }
    UrlParameterService.prototype.getUrlParameter = function (parameterName) {
        var allParameters = this.getAllClientProvidedParameters();
        return _.get(allParameters, parameterName, '');
    };
    UrlParameterService.prototype.buildParameterContainer = function (configSettings) {
        var parameters = this.getAllClientProvidedParameters();
        parameters = _.assign(parameters, this.getNameInformation(parameters.name));
        parameters = _.assign(parameters, this.getDateOfBirthInformation(parameters.dob));
        parameters = _.assign(parameters, this.getLanguageInformation(parameters.lang, configSettings));
        parameters = _.assign(parameters, this.getRemappedParameters(parameters));
        return parameters;
    };
    UrlParameterService.prototype.getAllParametersViaUrl = function () {
        var _this = this;
        var searchParameters = this.window.location.search;
        if (!searchParameters) {
            return '';
        }
        return _.chain(searchParameters)
            .replace('?', '')
            .split('&') // separate query elements
            .map(function (variable) { return _.split(variable, '='); }) // break each element into key/value pairs
            .map(function (pair) { return [decodeURIComponent(pair[0]), decodeURIComponent(pair[1])]; }) // decode special characters
            .reduce(function (output, param) {
            var key = param[0], value = param[1];
            var _a = _this.convertKeyStringToArray(key), primaryKey = _a[0], secondaryKeys = _a.slice(1);
            var paramAsObject = _this.createParamObject(_this.convertKeyStringToArray(key), value);
            // If the property does not exist yet within the returned object, just set it
            if (!output.hasOwnProperty(primaryKey)) {
                return Object.assign(output, paramAsObject);
            }
            // Lodash's setWith function takes an array of square bracketed strings to walk into the object
            var secondaryBracketPath = secondaryKeys.map(function (key) { return "[" + key + "]"; }).join('');
            // Basically a deep value setter
            _.setWith(output[primaryKey], secondaryBracketPath, value);
            return output;
        }, {})
            .value();
    };
    UrlParameterService.prototype.getDefaultModality = function (params) {
        return params.modality ? params.modality : '';
    };
    UrlParameterService.prototype.getRawParameters = function () {
        return this.getAllClientProvidedParameters();
    };
    /**
     * Removes square brackets from a string
     * @param string input string to be update
     * @returns string without square brackets
     */
    UrlParameterService.prototype.rmBrackets = function (string) {
        return _.replace(string, /(\[|\])/g, '');
    };
    UrlParameterService.prototype.convertKeyStringToArray = function (key) {
        return key.split('[').map(this.rmBrackets);
    };
    UrlParameterService.prototype.createParamObject = function (array, value) {
        var _a, _b;
        var _arr = Array.isArray(array) ? array.slice() : [array];
        var _item = _arr.shift();
        if (_arr.length > 0) {
            return _a = {}, _a[_item] = this.createParamObject(_arr, value), _a;
        }
        return _b = {}, _b[_item] = value, _b;
    };
    UrlParameterService.prototype.getAllClientProvidedParameters = function () {
        var postData = window.postData;
        var urlData = this.getAllParametersViaUrl();
        if (_.isUndefined(postData)) {
            return urlData;
        }
        return Object.assign(urlData, postData);
    };
    UrlParameterService.prototype.getNameInformation = function (name) {
        if (_.isUndefined(name) || _.isNull(name)) {
            return {};
        }
        var parsedName = this.nameParseService.parseName(name);
        return {
            lastName: parsedName.lastName,
            firstName: parsedName.firstName,
            middleName: parsedName.middleName,
            title: parsedName.title,
            suffix: parsedName.suffix,
            name: parsedName.lastName + ", " + parsedName.firstName + (parsedName.middleName ? ", " + parsedName.middleName : '')
        };
    };
    UrlParameterService.prototype.getDateOfBirthInformation = function (dateOfBirth) {
        if (_.isUndefined(dateOfBirth) || _.isNull(dateOfBirth)) {
            return {
                dobFormat: '---'
            };
        }
        return {
            dobFormat: patient_1.Patient.parseDate(dateOfBirth)
        };
    };
    UrlParameterService.prototype.getLanguageInformation = function (language, configSettings) {
        return {
            lang: language || _.get(configSettings, 'language', 'enus')
        };
    };
    UrlParameterService.prototype.getRemappedParameters = function (parameters) {
        return {
            gender: parameters.sex ? parameters.sex : '---'
        };
    };
    UrlParameterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UrlParameterService_Factory() { return new UrlParameterService(i0.ɵɵinject(i1.NameParseService), i0.ɵɵinject("Window")); }, token: UrlParameterService, providedIn: "root" });
    return UrlParameterService;
}());
exports.UrlParameterService = UrlParameterService;
