import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ManifestService {

  constructor(
    private http: HttpClient
  ) { }

  createManifest(route: string, manifest: any): Promise<any> {
    return this.http.post(route, manifest, {
      headers: new HttpHeaders()
    }).toPromise();
  }

  updateManifestByObj(obj: any, manifest: any) {
    return this.http.put(manifest.self, obj).toPromise();
  }

  updateManifestByRoute(route: string, dataObject: any): Promise<any> {
    return this.http.put(route, { 'expectedFiles': dataObject.expectedFiles }).toPromise();
  }

  getStatusManifest(route: string): Promise<any> {
    const now = (new Date()).getTime();
    const params = new HttpParams().set('no-cache', now.toString());

    return this.http.get(route, { params: params }).toPromise();
  }
}
