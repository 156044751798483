"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ɵ0 = window, ɵ1 = document;
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
