import { DateFormatService } from './../../services/date-format.service';
import { Component, OnInit, ChangeDetectorRef, Inject, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { DicomQueryRetrieveService } from '../../services/dicom-query-retrieve.service';
import { ExistingStudy } from '../../classes/image-exchange-types';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatPaginator } from '@angular/material';
import { Dicom } from '../../classes/dicom-file';
import { ModalityWorklistService } from '../../services/modality-worklist.service';

type Exams = ExistingStudy | Dicom.Exam;
type ExamDisplay = Exams & { type: 'study' | 'exam' };

enum DialogViewStates {
  Loading,
  Ready,
  Error
}

@Component({
  selector: 'app-select-study-dialog',
  templateUrl: './select-study-dialog.component.html',
  styleUrls: ['./select-study-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectStudyDialogComponent implements OnInit {

  state: DialogViewStates;
  VIEW_STATES = DialogViewStates;

  params: any;

  studiesTable = new MatTableDataSource<ExistingStudy | Dicom.Exam>();
  displayedColumns = ['studyDate', 'studyDescription', 'modalities', 'referringPhysician', 'accessionNumber', 'studySource'];
  selectedStudy: ExistingStudy | Dicom.Exam;
  studiesLoading = true;

  selectedModalities: string[] = [];
  modalitySelect = new FormControl();
  modalityFilter = new FormControl();
  filteredModalities: BehaviorSubject<string[]>;

  timeSelect = new FormControl();
  times: any[];
  selectedTime: any;

  newStudy: ExistingStudy = {
    accessionNumber: '',
    archive: '',
    birthDate: '',
    error: '',
    firstName: '',
    instanceNumber: '',
    lastName: '',
    middleName: '',
    modalitiesInStudy: '',
    modalitiesInStudyDisplay: '',
    modality: '',
    numberOfSeriesRelatedInstances: '',
    numberOfStudyRelatedInstances: '',
    numberOfStudyRelatedSeries: '',
    patientId: '',
    referringPhysician: '',
    seriesInstanceUID: '',
    seriesNumber: '',
    sex: '',
    sopClassUID: '',
    sopInstanceUID: '',
    studyDate: '',
    studyDateDisplay: '',
    studyDescription: 'New Study',
    studyInstanceUID: ''
  };


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public translate: TranslateService,
    public dataService: DataService,
    private dateFormatService: DateFormatService,
    private dialogRef: MatDialogRef<SelectStudyDialogComponent>,
    private cdr: ChangeDetectorRef,
    private dicomQueryRetrieveService: DicomQueryRetrieveService,
    private modalityWorkListService: ModalityWorklistService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit() {
    this.state = DialogViewStates.Loading;
    this.studiesTable.paginator = this.paginator;

    this.newStudy.studyDateDisplay = this.dateFormatService.convertDateToMediumDate(new Date());

    this.params = this.dataService.setupData({});
    delete this.params.studyDescription;
    this.params.patientId = this.params.mrn;
    this.params.birthDate = this.params.dob;

    this.times = this.dataService.configSettings.timeMapping.times;

    const defaultTime = this.times.find(t => t.default);
    this.selectedTime = !!defaultTime ? defaultTime : this.times[0];
    this.timeSelect.setValue(this.selectedTime);

    const modalities = this.dataService.configSettings.modalities.options as string[];

    this.filteredModalities = new BehaviorSubject<string[]>(modalities.filter(item => !!item));
    this.modalitySelect.setValue(modalities);
    this.selectedModalities = modalities;

    this.modalityFilter.valueChanges
      .subscribe((value) => {
        const repos = modalities.filter(repo => repo === '' || repo.toLowerCase().includes(value));
        this.filteredModalities.next(repos);
        this.cdr.markForCheck();
      });

    this.modalitySelect.valueChanges
      .subscribe(async (values) => {
        this.selectedModalities = values || [];
        await this.updateAvailableStudies(this.params);
        this.cdr.markForCheck();
      });

    this.timeSelect.valueChanges
      .subscribe(async (time) => {
        this.selectedTime = time;
        await this.updateAvailableStudies(this.params);
        this.cdr.markForCheck();
      });

    if (this.data.exams.length > 0) {
      let items = [ ...this.data.exams ];
      if (this.data.allowNew) {
        items.unshift(this.newStudy);
      }

      this.updateList(items);

      this.state = DialogViewStates.Ready;
      this.cdr.markForCheck();
    } else {
      this.updateAvailableStudies(this.params);
    }
  }

  ngAfterViewInit() {
    this.updateList(this.studiesTable.data)
  }

  async updateAvailableStudies(params) {
    this.state = DialogViewStates.Loading;
    this.studiesLoading = true;
    this.updateList([]);
    this.cdr.markForCheck();

    if (this.selectedModalities.length === 0) {
      this.state = DialogViewStates.Ready;
      this.cdr.markForCheck();
      return;
    }

    const response = await Promise.all([
      this.getStudies(params),
      this.getExams(params)
    ]);

    const items = (response as any)
      .flat()
      .sort(this.sortByDate)
      .filter(this.filterUnique);

    this.state = DialogViewStates.Ready;
    this.studiesLoading = false;

    if (this.data.allowNew) {
      items.unshift(this.newStudy);
    }

    this.updateList(items);
    this.cdr.markForCheck();
  }

  async getStudies(params) {
    if (this.selectedModalities.length === 0) {
      return [];
    }

    try {
      const response = await this.dicomQueryRetrieveService.queryExistingStudies({
        ...params,
        time: JSON.stringify(this.selectedTime),
        modalitiesInStudy: this.selectedModalities
      });

      response.data.forEach(item => {
        (item as any).type = 'study';

        const startDate = this.dateFormatService.stringToDateTransformer(item.studyDate, 'YYYYMMDD');
        (item as any).startDate = startDate;
        item.studyDateDisplay = this.dateFormatService.convertDateToMediumDate(startDate);
      });

      return response.data;
    } catch (err) {
      console.warn(err);
      return [];
    }
  } 

  async getExams(params) {
    try {
      const response: any = await this.modalityWorkListService.queryModalityWorklist({ patientId: params.mrn })
    
      const exams = response.data[0] ? response.data[0].exams : [];

      exams.forEach(item => {
        (item as any).type = 'exam'
      
        const startDate = this.dateFormatService.stringToDateTransformer(item.startDate, 'YYYYMMDD');
        
        item.studyInstanceUID = '';
        item.modalitiesInStudyDisplay = [ item.modality ];
        item.studyDateDisplay = this.dateFormatService.convertDateToMediumDate(startDate);
        item.startDate = startDate.valueOf();
      });
      return exams;
    } catch (err) {
      console.warn(err);
      return []
    }
  }

  selectStudy(study: ExistingStudy) {
    this.selectedStudy = study;

    this.submit();
  }

  cancel() {
    this.dialogRef.close({
      cancel: true
    });
  }

  close() {
    this.dialogRef.close({
      studyInstanceUID: null,
      accessionNumber: null
    });
  }

  private updateList(exams: any[]) {
    this.studiesTable = new MatTableDataSource<any>(exams);
    this.studiesTable.paginator = this.paginator;
  }

  private sortByDate(studyA, studyB) {
    const aDate = studyA.startDate;
    const bDate = studyB.startDate;

    if (aDate != bDate) {
      return aDate > bDate ? -1 : 1;
    }

    return 0;
  }

  private filterUnique(item: ExamDisplay, index: number, parentArr: ExamDisplay[]) {
    const firstIndex = parentArr.findIndex(exam => exam.accessionNumber === item.accessionNumber);

    // Studies in the PACS should take precident over exams in the Modality Worklist.  When determining if the exam is unique,
    // check the rest of the list for a study with the same accession number.  If one exists return false
    if (item.type === 'exam') {
      const studyIndex = parentArr.findIndex(exam => exam.accessionNumber === item.accessionNumber && exam.type === 'study');
      if (studyIndex !== -1) { // Study found in the list
        return false;
      }
    }
    
    return firstIndex === index;
  }

  submit() {
    const study: any = this.selectedStudy;

    this.dialogRef.close({
      cancel: false,
      type: study.type,
      list: this.studiesTable.data.filter(item => item.studyDescription !== 'New Study'),
      study: {
        studyInstanceUID: study.studyInstanceUID,
        accessionNumber: study.accessionNumber,
        studyDescription: study.studyDescription,
        studyModalities: study.modalitiesInStudyDisplay,
        studyTime: study.studyDateDisplay,
        modalitiesInStudy: this.selectedModalities,
        time: this.selectedTime,
      }
    });
  }

}
