"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var translate_service_1 = require("../translation/translate.service");
var data_service_1 = require("./data.service");
var fecha_1 = require("fecha");
var dicom_file_1 = require("../classes/dicom-file");
var dicomParser = require("dicom-parser/dist/dicomParser.js");
var name_parse_service_1 = require("./name-parse.service");
var dicom_utils_1 = require("../utilities/dicom.utils");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
// import * as cornerstone from 'cornerstone-core';
// export const MediaFormats = {
//   const images = ['jpeg', 'jpg'];
//   const videos = ['mpeg', 'mpg', 'quicktime', 'mov', 'mp4', 'x-msvideo', 'avi'];
//   const others = ['png', 'tiff', 'gif', 'pdf'];
// }
var ParsedFileCountType;
(function (ParsedFileCountType) {
    ParsedFileCountType[ParsedFileCountType["Parsed"] = 0] = "Parsed";
    ParsedFileCountType[ParsedFileCountType["Failed"] = 1] = "Failed";
    ParsedFileCountType[ParsedFileCountType["Skipped"] = 2] = "Skipped";
    ParsedFileCountType[ParsedFileCountType["Media"] = 3] = "Media";
    ParsedFileCountType[ParsedFileCountType["OtherSkipped"] = 4] = "OtherSkipped"; // File skipped for other reason
})(ParsedFileCountType || (ParsedFileCountType = {}));
var FileParseService = /** @class */ (function () {
    function FileParseService(window, dataService, nameParseService, translate, zone, http) {
        var _this = this;
        this.window = window;
        this.dataService = dataService;
        this.nameParseService = nameParseService;
        this.translate = translate;
        this.zone = zone;
        this.http = http;
        this.filesParsed = [0, 0, 0, 0, 0];
        this.patientCount = 0;
        this.MAX_SIZE = 49182;
        this.parsedUpdate = {
            progressPercentage: 0
        };
        this.patientFileMap = new Map();
        this.parseMediaFile = function (file) {
            var images = ['jpeg', 'jpg'];
            var videos = ['mpeg', 'mpg', 'quicktime', 'mov', 'mp4', 'x-msvideo', 'avi'];
            var others = ['png', 'tiff', 'gif', 'pdf'];
            if (images.some(function (type) { return file.type.indexOf(type) > -1; })) {
                if (_this.customerConfigs.jpeg !== true) {
                    _this.filesParsed[2] = _this.filesParsed[2] + 1;
                    _this.parsedFiles.log.push({ name: file.name, message: 'JPG ' + _this.translate.instant('dicom.enabled'), class: 'validation-partial' });
                    _this.parsedFiles.logType[1] = _this.parsedFiles.logType[1] + 1;
                }
                else {
                    _this.parsedFiles.files = _this.setFilePropertiesForType(_this.parsedFiles.files, file, 'JPEG', 'JPEG');
                }
                // Handle Video
            }
            else if (videos.some(function (type) { return file.type.indexOf(type) > -1; })) {
                if (_this.customerConfigs.mpeg !== true) {
                    _this.filesParsed[2] = _this.filesParsed[2] + 1;
                    _this.parsedFiles.log.push({ name: file.name, message: 'MPEG ' + _this.translate.instant('dicom.enabled'), class: 'validation-partial' });
                    _this.parsedFiles.logType[1] = _this.parsedFiles.logType[1] + 1;
                }
                else {
                    _this.parsedFiles.files = _this.setFilePropertiesForType(_this.parsedFiles.files, file, 'MPEG', 'MPEG');
                }
                // Handle Other Media
            }
            else if (others.some(function (type) { return file.type.indexOf(type) > -1; })) {
                if (_this.customerConfigs.other !== true) {
                    _this.filesParsed[2] = _this.filesParsed[2] + 1;
                    _this.parsedFiles.log.push({ name: file.name, message: file.type + ' ' + _this.translate.instant('dicom.enabled'), class: 'validation-partial' });
                    _this.parsedFiles.logType[1] = _this.parsedFiles.logType[1] + 1;
                }
                else if (file.type.indexOf('png') > 0) {
                    _this.parsedFiles.files = _this.setFilePropertiesForType(_this.parsedFiles.files, file, 'PNG', 'PNG', false);
                }
                else if (file.type.indexOf('tiff') > 0) {
                    _this.parsedFiles.files = _this.setFilePropertiesForType(_this.parsedFiles.files, file, 'TIFF', 'TIF', false);
                }
                else if (file.type.indexOf('gif') > 0) {
                    _this.parsedFiles.files = _this.setFilePropertiesForType(_this.parsedFiles.files, file, 'GIF', 'GIF', false);
                }
                else if (file.type.indexOf('pdf') > 0) {
                    _this.parsedFiles.files = _this.setFilePropertiesForType(_this.parsedFiles.files, file, 'PDF', 'PDF', false);
                }
            }
            else {
                _this.parsedFiles.logType[0] = _this.parsedFiles.logType[0] + 1;
                _this.filesParsed[4] = _this.filesParsed[4] + 1;
                _this.parsedFiles.log.push({ name: file.name, message: _this.translate.instant('dicom.notmedia'), class: 'IMblack' });
            }
            _this.parseUpdateBatchToUI(_this.parseUpdate());
        };
        var params = this.dataService.setupData({});
    }
    FileParseService.prototype.createPatientObject = function (file) {
        this.patientCount++;
        var patientId = {};
        var validateLastName = {};
        var validateGender = {};
        var validateDateOfBirth = {};
        patientId = this.handlePatientId(file);
        // Name Validation
        if (this.params.lastName) {
            validateLastName = this.validateLastName(file);
        }
        // Gender Validation
        if (this.params.gender) {
            validateGender = this.validateGender(file);
        }
        // Dob Validation
        if (this.params.dobFormat) {
            validateDateOfBirth = this.validateDateOfBirth(file);
        }
        var validation = {
            'name': validateLastName.validationName,
            'nameMessage': validateLastName.validationNameMessage,
            'gender': validateGender.validationGender,
            'genderMessage': validateGender.validationGenderMessage,
            'dob': validateDateOfBirth.validationDob,
            'dobMessage': validateDateOfBirth.validationDobMessage
        };
        var patient = {
            'patient': true,
            'selected': true,
            'id': patientId.id,
            'patientID': patientId.patientID,
            'patientName': file.patientName,
            'gender': file.gender,
            'dob': file.dob,
            'age': file.age,
            'patientValidated': validateLastName.patientValidated && validateGender.patientValidated && validateDateOfBirth.patientValidated,
            'validation': validation,
            'children': []
        };
        return patient;
    };
    FileParseService.prototype.handlePatientId = function (file) {
        var id, patientID;
        if (typeof file.patientID !== 'object') {
            if (file.patientID && (file.patientID.indexOf('.') > 0)) {
                id = 'u' + file.patientID.split('.').join('');
            }
            else {
                id = 'u' + file.patientID;
            }
            patientID = file.patientID;
        }
        else {
            patientID = 'Unknown.';
        }
        return {
            id: id,
            patientID: patientID
        };
    };
    FileParseService.prototype.validateDateOfBirth = function (file) {
        var validationDob, validationDobMessage, patientValidated = true;
        if (file.dob === this.params.dobFormat) {
            validationDob = 'validation-success';
            validationDobMessage = this.translate.instant('validation.dobmatch');
        }
        else if (file.dob === '' || file.dob === '00010101') {
            validationDob = 'validation-partial';
            validationDobMessage = this.translate.instant('validation.dobpartial');
        }
        else if (file.patientNameObject.lastName === 'Media Files') {
            validationDob = '';
        }
        else {
            validationDob = 'validation-error';
            validationDobMessage = this.translate.instant('validation.dobmismatch');
            patientValidated = false;
            this.patientFailedValidation = true;
        }
        return {
            validationDob: validationDob,
            validationDobMessage: validationDobMessage,
            patientValidated: patientValidated
        };
    };
    FileParseService.prototype.validateGender = function (file) {
        var validationGender, validationGenderMessage, patientValidated = true;
        if (file.gender.toLowerCase() === this.params.gender.toLowerCase()) {
            validationGender = 'validation-success';
            validationGenderMessage = this.translate.instant('validation.gendermatch');
        }
        else if (file.gender === ' ' || file.gender === 'O') {
            validationGender = 'validation-partial';
            validationGenderMessage = this.translate.instant('validation.genderpartial');
        }
        else if (file.patientNameObject.lastName === 'Media Files') {
            validationGender = '';
        }
        else {
            validationGender = 'validation-error';
            validationGenderMessage = this.translate.instant('validation.gendermismatch');
            patientValidated = false;
            this.patientFailedValidation = true;
        }
        return {
            validationGender: validationGender,
            validationGenderMessage: validationGenderMessage,
            patientValidated: patientValidated
        };
    };
    FileParseService.prototype.validateLastName = function (file) {
        var validationName, validationNameMessage, patientValidated = true;
        var name = this.nameParseService.parseName(file.patientName);
        if (file.patientNameObject.lastName.toLowerCase() === this.params.lastName.toLowerCase() && name.firstName.toLowerCase() === this.params.firstName.toLowerCase()) {
            validationName = 'validation-success';
            validationNameMessage = this.translate.instant('validation.namematch');
        }
        else if (file.patientNameObject.lastName.toLowerCase().indexOf(this.params.lastName.toLowerCase()) > -1) {
            validationName = 'validation-partial';
            validationNameMessage = this.translate.instant('validation.namepartial');
        }
        else if (file.patientNameObject.lastName === 'Media Files') {
            validationName = '';
        }
        else {
            validationName = 'validation-error';
            validationNameMessage = this.translate.instant('validation.namemismatch');
            patientValidated = false;
            this.patientFailedValidation = true;
        }
        return {
            validationName: validationName,
            validationNameMessage: validationNameMessage,
            patientValidated: patientValidated
        };
    };
    FileParseService.prototype.createStudyObject = function (file) {
        var id;
        if (file.studyUID && (file.studyUID.indexOf('.') > 0)) {
            id = 'u' + file.studyUID.split('.').join('');
        }
        else {
            id = 'u' + file.studyUID;
        }
        var study = {
            'study': true,
            'selected': true,
            'id': id,
            'UID': file.studyUID,
            'studyProgress': '',
            'studyDescription': file.studyDescription,
            'isModalityAllowed': this.customerConfigs.disabledModalitiesForOverread ? this.customerConfigs.disabledModalitiesForOverread.every(function (c) { return c !== file.modality; }) : true,
            'tooOld': dicom_file_1.Dicom.Study.diffDate(file.studyDate) > this.customerConfigs.maxStudyAgeInDays,
            'modality': file.modality,
            'studyDate': file.studyDate,
            'reasonForStudy': ''
        };
        return study;
    };
    FileParseService.prototype.saveImage = function (file, type) {
        var _this = this;
        var thumbnailSubject = new rxjs_1.BehaviorSubject('');
        var body = new FormData();
        body.append('fileContent', file);
        body.append('type', type);
        this
            .http
            .post('/pronghorn/v1/image/save', body)
            .pipe(operators_1.map(function (result) { return result.data.newFileName; }), operators_1.catchError(function (error) {
            _this.appendLogEntry(file.name, error.message);
            console.dir(error);
            return rxjs_1.of('data:image/png;base64,error');
        })).subscribe(function (thumbnailFilename) {
            thumbnailSubject.next(thumbnailFilename);
        });
        return thumbnailSubject;
    };
    FileParseService.prototype.parseMediaFolder = function (folder) {
        return __awaiter(this, void 0, void 0, function () {
            var folderArray;
            var _this = this;
            return __generator(this, function (_a) {
                this.patientCount = 0;
                this.patientFailedValidation = false;
                this.filesParsed = [0, 0, 0, 0, 0];
                folderArray = Array.from(folder);
                folder = null;
                folderArray.forEach(function (file) { return __awaiter(_this, void 0, void 0, function () {
                    var _a, group, type;
                    return __generator(this, function (_b) {
                        _a = file.type.split('/'), group = _a[0], type = _a[1];
                        if (!file.type.startsWith('video')) {
                            file.thumbnail = this.saveImage(file, type);
                            file.imageUrl = 'data:image/png;base64,pending';
                        }
                        this.parseMediaFile(file);
                        return [2 /*return*/];
                    });
                }); });
                this.parseUpdateBatchToUI(this.parseUpdate());
                this.parsedUpdate.progressPercentage = 0;
                return [2 /*return*/];
            });
        });
    };
    FileParseService.prototype.setFilePropertiesForType = function (files, file, type, mediaType, isMedia) {
        if (isMedia === void 0) { isMedia = true; }
        file.patientName = type + " " + this.translate.instant('word.files');
        file.patientID = type + " " + this.translate.instant('word.files');
        file.patientNameObject = { 'firstName': '', 'lastName': type + " " + this.translate.instant('word.files') };
        file.studyUID = type + " " + this.translate.instant('word.files');
        file.UID = type + " " + this.translate.instant('word.files');
        file.mediaType = "" + mediaType;
        file.gender = '';
        file.isMedia = isMedia;
        if (!file.lastModified && file.lastModifiedDate) {
            file.lastModified = new Date(file.lastModifiedDate);
        }
        // Another IE Support Item.  In EI (aka. IE) File classes do not have the
        //  lastModified property.  See: https://developer.mozilla.org/en-US/docs/Web/API/File/lastModified#browser_compatibility
        //  So when there is not a last-modified property, we will set it to today
        if (file.lastModified) {
            file.date = fecha_1.default.format(file.lastModified, 'MMM DD, YYYY');
        }
        else {
            file.date = fecha_1.default.format(Date.now(), 'MMM DD, YYYY');
        }
        file.id = file.name;
        file.label = file.name;
        file.selected = true;
        file.file = true;
        this.filesParsed[3] = this.filesParsed[3] + 1;
        return this.mediaMatch(files, file);
    };
    FileParseService.prototype.parseDicomFolder = function (folder) {
        return __awaiter(this, void 0, void 0, function () {
            var folderArray, _i, folderArray_1, each;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.patientCount = 0;
                        this.folderLength = folder.length;
                        this.patientFailedValidation = false;
                        this.filesParsed = [0, 0, 0, 0, 0];
                        folderArray = Array.from(folder);
                        folder = null;
                        _i = 0, folderArray_1 = folderArray;
                        _a.label = 1;
                    case 1:
                        if (!(_i < folderArray_1.length)) return [3 /*break*/, 4];
                        each = folderArray_1[_i];
                        return [4 /*yield*/, (function (file) { return __awaiter(_this, void 0, void 0, function () {
                                var name, skipTypes, mediaTypes;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            name = file.name.toLowerCase();
                                            skipTypes = ['DS_Store', '.oly', '.xmd', '.inf', '.ico', '.exe', '.zip',
                                                '.csv', '.doc', '.htaccess', '.jar', '.bfc', '.src', '.html', '.txt', '.bat',
                                                '.properties', '.pf', '.dll', '.xml', '.iso', '.bmp', '.chm', '.db', '.xsl',
                                                '.config', '.htm', '.ttf', '.cpl', '.jsa'];
                                            mediaTypes = ['jpeg', 'jpg', 'mpeg', 'mpg', 'mov', 'mp4', 'png', 'tiff', 'gif', 'pdf'];
                                            if (!skipTypes.some(function (type) { return name.indexOf(type) > -1; })) return [3 /*break*/, 1];
                                            this.filesParsed[4] = this.filesParsed[4] + 1;
                                            this.parsedFiles.log.push({ name: file.name, message: file.type + ' ' + this.translate.instant('dicom.skipped') });
                                            this.parsedFiles.logType[0] = this.parsedFiles.logType[0] + 1;
                                            return [3 /*break*/, 5];
                                        case 1:
                                            if (!(file.name.indexOf('DICOMDIR') > -1)) return [3 /*break*/, 2];
                                            this.filesParsed[4] = this.filesParsed[4] + 1;
                                            this.parsedFiles.log.push({ name: file.name, message: file.type + ' ' + this.translate.instant('dicom.skipped') });
                                            this.parsedFiles.logType[0] = this.parsedFiles.logType[0] + 1;
                                            return [3 /*break*/, 5];
                                        case 2:
                                            if (!mediaTypes.some(function (type) { return file.type.indexOf(type) > -1; })) return [3 /*break*/, 3];
                                            this.filesParsed[2] = this.filesParsed[2] + 1;
                                            this.parsedFiles.log.push({ name: file.name, message: this.translate.instant('dicom.nomedia') });
                                            this.parsedFiles.logType[1] = this.parsedFiles.logType[1] + 1;
                                            return [3 /*break*/, 5];
                                        case 3: return [4 /*yield*/, this.parseDicomFile(file)];
                                        case 4:
                                            _a.sent();
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            }); })(each)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.parseUpdateBatchToUI(this.parseUpdate());
                        this.parsedUpdate.progressPercentage = 0;
                        return [2 /*return*/];
                }
            });
        });
    };
    FileParseService.prototype.parseDicomFile = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_1, code;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.parseFile(file)];
                    case 1:
                        response = _a.sent();
                        dicom_utils_1.DicomUtils.parseDicomFile(file, response, ParsedFileCountType.Failed);
                        this.dicomMatch(file);
                        this.filesParsed[0] = this.filesParsed[0] + 1;
                        this.parseUpdateBatchToUI(this.parseUpdate());
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.dir({
                            error: error_1.message,
                            stack: error_1.stack,
                            code: error_1.code || ParsedFileCountType.OtherSkipped
                        });
                        code = error_1.code || ParsedFileCountType.OtherSkipped;
                        if (code === ParsedFileCountType.Failed) {
                            // Not a Valid Dicom File Does not Contain Object
                            this.parsedFiles.log.push({ name: file.name, message: this.translate.instant('dicom.invalidresponse'), class: 'validation-error' });
                            this.parsedFiles.logType[ParsedFileCountType.Failed]++;
                        }
                        else {
                            // Not a valid file type count as failure and move on.
                            this.parsedFiles.log.push({ name: file.name, message: error_1.message, class: error_1.class });
                            this.parsedFiles.logType[ParsedFileCountType.Parsed]++;
                            this.filesParsed[code]++;
                        }
                        this.parseUpdateBatchToUI(this.parseUpdate());
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FileParseService.prototype.isInSopList = function (file, destination) {
        return destination.sopList.some(function (i) { return i === file.sopClass; });
    };
    FileParseService.prototype.parseUpdateBatchToUI = function (parsedUpdate) {
        var _this = this;
        this.zone.run(function () {
            var oldProgress = _this.parsedUpdate.progressPercentage;
            var newProgress = parsedUpdate.progressPercentage;
            _this.parsedUpdate = parsedUpdate;
            _this.parsedUpdate.progressPercentage = newProgress > oldProgress ? newProgress : oldProgress;
        });
    };
    FileParseService.prototype.parseFile = function (file) {
        var _this = this;
        var reader = new FileReader();
        return new Promise(function (resolve, reject) {
            reader.onload = function () {
                _this.readLoadedFile(reader, resolve, reject);
            };
            reader.onerror = function (error) {
                reader = undefined;
                return resolve(error);
            };
            reader.readAsArrayBuffer(file.size < _this.MAX_SIZE ? file : file.slice(0, _this.MAX_SIZE));
        });
    };
    FileParseService.prototype.readLoadedFile = function (reader, resolve, reject) {
        var arrayBuffer = reader.result;
        reader = undefined;
        // Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
        // Uint8Array so we create that here
        var byteArray = new Uint8Array(arrayBuffer);
        try {
            var options = {
                omitPrivateAttibutes: true,
                untilTag: 'x0020000e',
                maxElementLength: 128
            };
            // const dataSet = this.window['dicomParser'].parseDicom(byteArray, options);
            var dataSet = dicomParser.parseDicom(byteArray, options);
            return resolve(dicom_utils_1.DicomUtils.extractDicomData(dataSet));
        }
        catch (error) {
            if (error === 'dicomParser.readPart10Header: DICM prefix not found at location 132 - this is not a valid DICOM P10 file.') {
                return reject(new Error(this.translate.instant('dicom.location132')));
            }
            else {
                var errorMessage = null;
                if (typeof error.exception === 'object') {
                    var tempObject = error.exception;
                    errorMessage = tempObject.exception;
                }
                else if (typeof error.exception === 'string') {
                    errorMessage = error.exception;
                }
                else {
                    errorMessage = 'Unknown Error';
                    console.error(error);
                }
                return reject(new Error(errorMessage));
            }
        }
    };
    FileParseService.prototype.isASCII = function (str) {
        return /^[\x00-\x7F]*$/.test(str);
    };
    FileParseService.prototype.convertDataSet = function (dataSet) {
        // tags array restricts header set allowed for consumption
        var tagsArray = ['x00100020', 'x00100010', 'x00100030', 'x00100040', 'x00101010', 'x0020000d', 'x0020000e', 'x00080018', 'x00080016', 'x00080020', 'x00080060', 'x00081030', 'x00080050'];
        var output = {};
        console.dir(dataSet);
        for (var propertyName in dataSet.elements) {
            // These are all the elements we care about
            if (tagsArray.indexOf(propertyName) > -1) {
                var element = dataSet.elements[propertyName];
                if (!(element.items && element.fragments) && element.length < 256) {
                    var str = dataSet.string(propertyName);
                    var stringIsAscii = this.isASCII(str);
                    if (stringIsAscii) {
                        if (str !== undefined) {
                            output[propertyName] = str;
                        }
                    }
                }
            } // End if
        } // End For
        return output;
    };
    FileParseService.prototype.dicomMatch = function (file) {
        var study;
        var lastPatient;
        var lastStudy;
        if (this.parsedFiles.files.length >= 1) {
            // Set last patient since there is at least 1 patient
            lastPatient = this.parsedFiles.files[(this.parsedFiles.files.length - 1)];
            // Alternative Loop through all values, slight performance hit but checks against all patients
            for (var j = 0; j < this.parsedFiles.files.length; j++) {
                var lp = this.parsedFiles.files[j];
                if (lp.patientID === file.patientID) {
                    lastPatient = lp;
                    break;
                }
            }
            if (lastPatient.patientID === file.patientID) {
                // Set last study since there is at least 1 study
                lastStudy = lastPatient.children[(lastPatient.children.length - 1)];
                // Alternative Loop through all values, slight performance hit but checks against all studies for that patient
                for (var k = 0; k < lastPatient.children.length; k++) {
                    var ls = lastPatient.children[k];
                    if (ls.UID === file.studyUID) {
                        lastStudy = ls;
                        break;
                    }
                }
                if (lastStudy.UID === file.studyUID) {
                    // Bind to existing study and patient
                    lastStudy.children.push(file);
                }
                else {
                    // Bind to existing patient but make new study
                    study = this.createStudyObject(file);
                    study.children = [file];
                    lastPatient.children.push(study);
                }
            }
            else {
                this.newStudyAndPatient(study, file);
            }
        }
        else {
            this.newStudyAndPatient(study, file);
        }
    };
    FileParseService.prototype.newStudyAndPatient = function (study, file) {
        // Make New Study And Patient
        study = this.createStudyObject(file);
        var patient = this.createPatientObject(file);
        study.children = [file];
        patient.children = [study];
        this.parsedFiles.files.push(patient);
    };
    FileParseService.prototype.mediaMatch = function (files, file) {
        var patient;
        var lastPatient;
        if (files.length >= 1) {
            // Set last patient since there is at least 1 patient
            lastPatient = files[(files.length - 1)];
            // Alternative Loop through all values, slight performance hit but checks against all patients
            for (var j = 0; j < files.length; j++) {
                var lp = files[j];
                if (lp.patientID === file.patientID) {
                    lastPatient = lp;
                    break;
                }
            }
            if (lastPatient.patientID === file.patientID) {
                // Set last study since there is at least 1 study
                lastPatient.children.push(file);
            }
            else {
                // Make New Study And Patient
                patient = this.createPatientObject(file);
                file.first = true;
                patient.children = [file];
                patient.media = true;
                patient.UID = file.UID;
                patient.patient = false;
                files.push(patient);
            }
        }
        else {
            // Make New Study And Patient
            patient = this.createPatientObject(file);
            file.first = true;
            patient.children = [file];
            patient.media = true;
            patient.UID = file.UID;
            patient.patient = false;
            files.push(patient);
        }
        return files;
    };
    FileParseService.prototype.parseUpdate = function () {
        var parsed, failed, skipped, media, system;
        if (this.filesParsed[0] > 0) {
            parsed = this.filesParsed[0] + ' ' + this.translate.instant('dicom.available');
        }
        else {
            parsed = '';
        }
        if (this.filesParsed[1] > 0) {
            failed = this.filesParsed[1] + ' ' + this.translate.instant('dicom.nonconformant');
        }
        else {
            failed = '';
        }
        if (this.filesParsed[2] > 0) {
            skipped = this.filesParsed[2] + ' ' + this.translate.instant('dicom.mediaskipped');
        }
        else {
            skipped = '';
        }
        if (this.filesParsed[3] > 0) {
            media = this.filesParsed[3] + ' ' + this.translate.instant('dicom.mediaready');
        }
        else {
            media = '';
        }
        if (this.filesParsed[4] > 0) {
            system = this.filesParsed[4] + ' ' + this.translate.instant('dicom.fileskipped');
        }
        else {
            system = '';
        }
        var totalFilesParsed = (this.filesParsed[0] + this.filesParsed[1] + this.filesParsed[2] + this.filesParsed[3] + this.filesParsed[4]);
        return {
            parsed: parsed,
            failed: failed,
            skipped: skipped,
            media: media,
            system: system,
            totalFilesParsed: totalFilesParsed,
            progressPercentage: Math.round((totalFilesParsed / this.folderLength) * 100)
        };
    };
    FileParseService.prototype.appendLogEntry = function (filename, message) {
        this.parsedFiles.log.push({ name: filename, message: message });
    };
    return FileParseService;
}());
exports.FileParseService = FileParseService;
