"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./navigation-warning.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/a11y");
var i5 = require("@angular/platform-browser/animations");
var i6 = require("./navigation-warning.component");
var i7 = require("@angular/material/dialog");
var i8 = require("../../translation/translate.service");
var styles_NavigationWarningComponent = [i0.styles];
var RenderType_NavigationWarningComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationWarningComponent, data: {} });
exports.RenderType_NavigationWarningComponent = RenderType_NavigationWarningComponent;
function View_NavigationWarningComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "form-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(8, 0, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(11, 0, [" ", " "]))], function (_ck, _v) { var currVal_4 = "primary"; _ck(_v, 7, 0, currVal_4); var currVal_8 = "accent"; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate.instant("phrase.navigation"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.translate.instant("instruction.navigation"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = _co.translate.instant("button.nevermind"); _ck(_v, 8, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 10).disabled || null); var currVal_7 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_9 = _co.translate.instant("button.continue"); _ck(_v, 11, 0, currVal_9); }); }
exports.View_NavigationWarningComponent_0 = View_NavigationWarningComponent_0;
function View_NavigationWarningComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation-warning", [], null, null, null, View_NavigationWarningComponent_0, RenderType_NavigationWarningComponent)), i1.ɵdid(1, 49152, null, 0, i6.NavigationWarningComponent, [i7.MatDialogRef, i8.TranslateService], null, null)], null, null); }
exports.View_NavigationWarningComponent_Host_0 = View_NavigationWarningComponent_Host_0;
var NavigationWarningComponentNgFactory = i1.ɵccf("app-navigation-warning", i6.NavigationWarningComponent, View_NavigationWarningComponent_Host_0, {}, {}, []);
exports.NavigationWarningComponentNgFactory = NavigationWarningComponentNgFactory;
