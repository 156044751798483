import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '../../translation/translate.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-cancel-modality',
  templateUrl: './cancel-modality.component.html',
  styleUrls: ['./cancel-modality.component.scss']
})
export class CancelModalityComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CancelModalityComponent>,
    public dataService: DataService,
    public translate: TranslateService) { }

  ngOnInit() {
    const params = this.dataService.setupData({});
  }

  close(status: boolean) {
    this.dialogRef.close({
      status: status
    });
  }

}
