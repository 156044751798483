<div [ngSwitch]="logo">
  <div *ngSwitchCase="'ImageMoverMD'">
    Patented technology by ImageMoverMD™. Copyright {{ dataService.copyrightYear }}. All rights reserved.
    <span>v{{version}}</span>
  </div>

  <div *ngSwitchCase="'nuance'" class="nuance">
    <div class="footer-logo">
      <img src="https://www1.nuancepowershare.com/smr/assets/nps/images/psn2x/NuanceLogo120-2x.png">
    </div>
    <div class="footer-content">
      <p style="margin: 2px 0 2px 0;padding: 0; line-height: 14px;    color: #626d6f;">By using this site, you agree to
        Nuance terms of use. Commercial use of this site without express authorization is
        prohibited.
      </p>
      <p class="footer-links" style="margin: 2px 0 2px 0;padding: 0; line-height: 14px;color: #626d6f;">
        <a href="https://www1.nuancepowershare.com/smr/terms/view.action" target="_blank">User Agreement</a> |
        <a href="https://www1.nuancepowershare.com/smr/registration/privacy.action" target="_blank">Privacy Policy</a>
        |
        <a href="mailto:&quot;Nuance PowerShare Support&quot;&lt;support@nuancepowershare.com&gt;">Contact Us</a> or
        Call Support:1-866-809-4746 </p>
      <p style="margin: 2px 0 2px 0;padding: 0; line-height: 14px;color: #626d6f;">&copy; {{ dataService.copyrightYear }} Nuance, All rights
        reserved, Patented.
        <span>v{{version}}</span>
      </p>
    </div>
  </div>

  <div *ngSwitchCase="'philips'" class="philips">
    Patented Technology. Copyright {{ dataService.copyrightYear }}. All rights reserved.
    <span>v{{version}}</span>
  </div>
</div>
<div>
  <a mat-button href="/" (click)="open($event)">{{translate.instant('about.link')}}</a>
</div>