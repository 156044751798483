"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{display:block;max-width:1024px;margin:auto}nav[_ngcontent-%COMP%]{background:#fff;z-index:2}nav[_ngcontent-%COMP%]     .mat-tab-links{display:flex}footer[_ngcontent-%COMP%]{display:block;text-align:center;padding:1rem;font-size:.8rem}"];
exports.styles = styles;
