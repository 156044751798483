import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-patient-information',
  templateUrl: './patient-information.component.html',
  styleUrls: ['./patient-information.component.scss']
})
export class PatientInformationComponent implements OnInit {

  @Input() patient = {} as any;
  @Input() doctor = {} as any;
  @Input() enableDoctorFacing = false;
  @Input() allowEditLastName = false;
  patientInfoConfig = [
    { label: 'patient.name', key: 'name' },
    { label: 'patient.dob', key: 'dobFormat' },
    { label: 'patient.sex', key: 'gender' },
    { label: 'patient.mrn', key: 'mrn' }
  ];
  doctorInfoConfig = [
    { label: 'phrase.op', key: 'name' },
    { label: 'phrase.providerId', key: 'id' }
  ];
  lastName = new FormControl();
  @Output() changeLastName = new EventEmitter();

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit() {

    this.lastName.valueChanges
      .subscribe(value => {
        this.patient.alternateLastName = value;
        this.changeLastName.emit(this.patient);
      });

  }

}
