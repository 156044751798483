<mat-card [class.finished]="finished">
  <header>
    <app-logo [logo]="customerConfigs.logo"></app-logo>
  </header>
  <section>
    <div class="head-display">
      <aside class="column-left">
        <div>
          <strong>
            {{translate.instant('phrase.folder')}}
          </strong>
        </div>
        <div class="file-selection" [matTooltip]="translate.instant('sync.nextUploadTooltip')" [matTooltipDisabled]="(!params.syncWorkflow || !inputDisabled ) || (!finished && !errorStatus)" [matTooltipPosition]="'below'">
          <input hidden type="file" #dicomFolderSelect [disabled]="inputDisabled" (change)="fileChange($event.target['files'])"
            webkitdirectory directory multiple />
          <button 
            mat-raised-button 
            color='primary' 
            [disabled]="(fileParseService.parsedUpdate?.progressPercentage > 0 && 100 < fileParseService.parsedUpdate?.progressPercentage) || inputDisabled"
            (click)="dicomFolderSelect.click()">{{translate.instant('phrase.selectdir')}}</button>
            
          <mat-progress-bar color='primary' mode='determinate' [value]="fileParseService.parsedUpdate?.progressPercentage"></mat-progress-bar>
        </div>

        <div #iepin class="file-selection file-selection__ie-only" [class.demo]="params.enableSync === 'yes'">
          <h4>{{ translate.instant('dicom.pinTitle') }}<mat-icon (click)="ieNotification($event)" color="primary">help_outline</mat-icon></h4>
          <span *ngIf="PinCode" class="big-txt" [ngClass]="{ expired: !PinCodeLifespan, 'text-fadeIn': !Loading  }" (click)="newSession()" >{{ PinCode }}</span>
          <mat-spinner [diameter]="50" *ngIf="!PinCode"></mat-spinner> 
        </div>

        <div>
          <app-destination-selector [inputDisabled]="inputDisabled" [(serverPostLocation)]="serverPostLocation"
            [(selectedDestination)]="selectedDestination" [(nuanceStudyTagIds)]="nuanceStudyTagIds"
            [nuanceDestinations]="nuanceDestinations" [config]="customerConfigs" (change)="setServerPostLocation($event)"></app-destination-selector>
        </div>
      </aside>
      <div class="column-right">
        <app-patient-information [patient]="patient"></app-patient-information>
      </div>

    </div>
    <div>
      <mat-tab-group>
        <mat-tab label="DICOM">
          <div class="file-header-row" *ngIf="errorStatus && (!params.syncWorkflow || browserIsIE)">
            <div class="error">
              {{errorStatus}}
            </div>
          </div>

          <div [class.finished]="finished" id="finished" *ngIf="!params.syncWorkflow || browserIsIE">
            <span>
              {{translate.instant('word.success')}}
            </span>
          </div>

          <mat-card [class.sync-finished]="syncUploadResult !== syncResultsState.IN_PROGRESS" id="sync-finished"
            *ngIf="params.syncWorkflow && !browserIsIE">
            <span class="header validation-success" *ngIf="syncUploadResult === syncResultsState.SUCCESS">
              {{ translate.instant('word.success')}}!
            </span>
            <span class="header validation-error" *ngIf="syncUploadResult === syncResultsState.FAILED">
              {{ translate.instant('word.failure') }}
            </span>
            <span class="header validation-partial" *ngIf="syncUploadResult === syncResultsState.CANCELLED">
              {{ translate.instant('word.cancelled') }}
            </span>
            <div class="results">
              <div class="data">
                <p>{{ translate.instant('dicom.filesUploaded') }}: <span>{{ uploadResultTotals?.processed }}/{{ uploadResultTotals?.total }}</span></p>
                <p color="success">{{ translate.instant('dicom.successUpload')}}: {{ uploadResultTotals?.success }}</p>
                <p color="failure" *ngIf="syncUploadResult === syncResultsState.FAILED">{{ translate.instant('dicom.failedUpload')}}: {{ uploadResultTotals?.failures }}</p>
              </div>
              <div class="message">
                <p *ngIf="syncUploadResult === syncResultsState.FAILED">{{ translate.instant('dicom.goBackStartOver') }}</p>
                <p *ngIf="syncUploadResult === syncResultsState.SUCCESS">{{ translate.instant('dicom.uploadSuccessMsg') }}</p>
                <p *ngIf="syncUploadResult === syncResultsState.CANCELLED">{{ translate.instant('sync.nextUploadTooltip') }}</p>
              </div>
            </div>
            <div class="actions">
              <button mat-raised-button color="primary" (click)="newPatient()" tabindex="0" class="done-button">
                <span>Done</span>
              </button>
            </div>
          </mat-card>

          <div class="file-header-row" *ngIf="multiplePatientWarning">
            <div class="error">
              {{translate.instant('instruction.patients')}}
            </div>
          </div>

          <div *ngIf="files?.length > 0 && isStandalone" class="select-all-files">
            <mat-checkbox [disabled]="inputDisabled" (change)="selectAllFiles($event)" [indeterminate]="allFilesSelected == null"
              [checked]="allFilesSelected" color="primary">{{translate.instant('phrase.selectall')}}</mat-checkbox>
          </div>

          <mat-accordion [multi]="true">
            <mat-expansion-panel #panel [expanded]="true" *ngFor="let file of files; trackByFile">
              <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                <table class="patient-header">
                  <thead>
                    <tr>
                      <th>{{translate.instant('table.patientname')}}</th>
                      <th>{{translate.instant('table.patientid')}}</th>
                      <th>{{translate.instant('table.patientsex')}}</th>
                      <th>{{translate.instant('table.patientdob')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span [class]="file.validation.name" [matTooltip]="file.validation.nameMessage">{{file.patientName}}</span>
                      </td>
                      <td>
                        <span>{{file.patientID}}</span>
                      </td>
                      <td>
                        <span *ngIf="file.gender.trim()" [class]="file.validation.gender" [matTooltip]="file.validation.genderMessage">{{file.gender}}</span>
                      </td>
                      <td>
                        <span *ngIf="file.dob" [class]="file.validation.dob" [matTooltip]="file.validation.dobMessage">{{file.dob}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-expansion-panel-header>
              <main>
                <mat-checkbox [disabled]="inputDisabled" (change)="selectAllChildren($event, file)" [indeterminate]="file.selected == null"
                  [checked]="file.selected" color="primary" class="select-all">{{translate.instant('phrase.selectall')}}</mat-checkbox>
                <div class="row top">
                  <div class="checkbox-column"></div>
                  <div class="study-column">{{translate.instant('table.study')}}</div>
                  <div class="modality-column">{{translate.instant('table.modality')}}</div>
                  <div class="date-column">{{translate.instant('table.date')}}</div>
                  <div class="files-column">{{translate.instant('table.files')}}</div>
                  <div class="overread-column" *ngIf="customerConfigs.overread">{{translate.instant('table.overread')}}</div>
                </div>

                <div *ngFor="let child of file?.children; let i = index; trackBy: trackByFile;" class="select">
                  <div class="row">
                    <div class="checkbox-column">
                      <mat-checkbox [disabled]="inputDisabled" (change)="selectFile($event, file, child)" [checked]="child.selected"
                        color="primary">
                      </mat-checkbox>
                    </div>
                    <div class="study-column" [class.disallowed-sop-class]="child.hasDisallowedSopClass" [matTooltip]="child.disallowedSopClassMessage">{{child.studyDescription}}</div>
                    <div class="modality-column">{{child.modality}}</div>
                    <div class="date-column">{{child.studyDate}}</div>
                    <div class="files-column">{{child.children.length}}</div>
                    <div class="checkbox-column">
                      <mat-checkbox *ngIf="customerConfigs.overread"
                        [disabled]="inputDisabled || !overreadsActive || !child.selected || !child.isModalityAllowed || child.tooOld"
                        (change)="selectOverread($event, child)" [(ngModel)]="child.overread" [matTooltip]="overreadEnabledMessage"
                        color="primary">
                      </mat-checkbox>
                    </div>
                    <div class="add-column" *ngIf="!child.counts && !inputDisabled && mwlService.mwlAvailable">
                      <button
                        mat-raised-button
                        color="primary"
                        class="add-to-existing-btn"
                        (click)="openExamSelectDialog($event, file, i)"
                      >
                        {{ child.exam ? renderExamDetailButton(child.exam) : translate.instant('phrase.addToExistingExam') }}
                      </button>
                    </div>
                    <div class="counts-column" *ngIf="child.counts">
                      <span class="file-success" [matTooltip]="translate.instant('phrase.filesendsuccess')">
                        {{child.counts?.SUCCESS}}
                      </span>
                      <span class="file-processing" [matTooltip]="translate.instant('phrase.fileprocessing')">
                        {{child.counts?.PROCESSING}}
                      </span>
                      <span class="file-error" [matTooltip]="translate.instant('phrase.filesenderror')">
                        {{child.counts?.ERROR}}
                      </span>
                      <span class="file-total" [matTooltip]="translate.instant('phrase.filesprocessed')" *ngIf="child.progressPercentage === 100">
                        {{child.counts?.SUCCESS + child.counts?.ERROR  }}&nbsp;/&nbsp;{{child.counts?.totalFiles}}
                      </span>
                    </div>
                  </div>
                  <mat-progress-bar color='primary' [mode]='child.progressMode' [value]="child.progressPercentage"
                    [bufferValue]="child.bufferPercentage"></mat-progress-bar>
                </div>
              </main>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-tab>

        <mat-tab label="Log Messages" [disabled]="!this.logData.data?.length">
          <div class="table-header">
            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" [placeholder]="translate.instant('phrase.filterlogs')"
                autocomplete="off">
            </mat-form-field>
          </div>
          <mat-table #table [dataSource]="logData">
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>{{translate.instant('table.filename')}}</mat-header-cell>
              <mat-cell *matCellDef="let el"> {{el.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="message">
              <mat-header-cell *matHeaderCellDef>{{translate.instant('table.message')}}</mat-header-cell>
              <mat-cell *matCellDef="let el"> {{el.message}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="logColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: logColumns;"></mat-row>

          </mat-table>
          <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]">
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>

      <div class="form-footer">
        <button class="suffix-btn" mat-stroked-button color='primary' [disabled]="inputDisabled" (click)="newPatient()"
          *ngIf='params.syncWorkflow && (syncUploadResult === syncResultsState.IN_PROGRESS) && !browserIsIE'>
          <mat-icon>arrow_back</mat-icon>
          <span>{{translate.instant('dicom.startOver')}}</span>
        </button>
        <button mat-button color='primary' [disabled]="cancelDisabled" (click)="cancelUpload()">
          {{translate.instant('button.stop')}}
        </button>
        <button mat-raised-button color='accent' [disabled]="uploaderDisabled" (click)="submitTrigger()">
          {{translate.instant('button.submit')}}
        </button>
      </div>
    </div>
  </section>
</mat-card>