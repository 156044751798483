"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var data_service_1 = require("../../services/data.service");
var fecha_1 = require("fecha");
var guid_service_1 = require("../../services/guid.service");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var i1 = require("../../services/data.service");
var i2 = require("../../services/guid.service");
var i3 = require("@angular/common/http");
var FileService = /** @class */ (function () {
    function FileService(dataService, guidService, http) {
        this.dataService = dataService;
        this.guidService = guidService;
        this.http = http;
        this.FILE_ICON_MAP = {
            'photo_library': /tiff.*/,
            'picture': /image.*/,
            'picture_as_pdf': /pdf.*/,
            'video_library': /video.*/
        };
        this.DEFAULT_FILE_ICON = 'insert_drive_file';
    }
    FileService.prototype.setFileIcons = function (files) {
        var _this = this;
        files
            .map(function (i) { return i.children; })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .forEach(function (file) {
            file.icon = _this.getFileIcon(file);
            if (file.icon !== 'video_library' && !file.$dataUrl) {
                file.$dataUrl = _this.getThumbnail(file);
            }
            else {
                file.$dataUrl = new rxjs_1.Subject();
            }
            return;
        });
    };
    /**
     * Upload a single image to pronghorn
     * @param file {File} File to be uploaded
     * @param files {string} URL of where in pronghorn to upload the file to
     * @returns HTTP response object from request
     */
    FileService.prototype.uploadSingleFile = function (file, files, sensitive, seriesDescription) {
        if (sensitive === void 0) { sensitive = false; }
        var lastModified = new Date(file.lastModified);
        if (!lastModified) {
            lastModified = new Date(file.lastModifiedDate);
        }
        var formData = {
            'seriesDescription': seriesDescription,
            'type': file.mediaType,
            'diskPath': file.webkitRelativePath,
            'clientDateCreated': fecha_1.default.format(lastModified, 'YYYY-MM-DD\'T\'HH:mm:ss\'Z\''),
            'newlockKey': this.guidService.generateGuid(),
        };
        var fileThumbnail = file.thumbnail ? file.thumbnail.getValue() : '';
        if (!fileThumbnail || fileThumbnail === 'data:image/png;base64,error') {
            formData.fileName = file.name;
            formData.fileContent = file;
        }
        else {
            formData.fileName = fileThumbnail;
        }
        // If a body part is selected, then append the body part details to the file
        if (file.bodyPart.bodyPart) {
            formData.bodyRegionOrientation = file.bodyPart.orientation;
            formData.bodyRegionCode = file.bodyPart.bodyPart.code;
        }
        return this.dataService.sendImage(files, formData, 0);
    };
    FileService.prototype.deleteRemoteThumbnailSource = function (filename) {
        var fileNames = Array.isArray(filename) ? filename : [filename];
        var body = { fileNames: fileNames };
        return this
            .http.request('post', 'pronghorn/v1/image/delete', { body: body })
            .toPromise();
    };
    FileService.prototype.getFileIcon = function (file) {
        var mapElements = _.entries(this.FILE_ICON_MAP);
        var entryValue = _.find(mapElements, function (mapElement) { return file.type.match(mapElement[1]); });
        return _.head(entryValue) || this.DEFAULT_FILE_ICON;
    };
    FileService.prototype.setBodyPart = function (file, bodyPart) {
        file.bodyPart = bodyPart;
        return file;
    };
    /**
     * Utility function that will either retrieve the file from the instance or using the
     * thumbnail filename request the thumbnail from pronghorn
     * @param file Media file requesting an image
     */
    FileService.prototype.getThumbnail = function (file) {
        var _this = this;
        file.$dataUrl = file.$dataUrl || new rxjs_1.Subject();
        file.imageUrl = '';
        file.thumbnail.subscribe(function (thumbnailFilename) {
            file.imageUrl = '';
            var query = new URLSearchParams();
            query.append('imageToRescale', thumbnailFilename);
            query.append('imageQuality', 'L');
            query.append('outputWidth', '40');
            if (!thumbnailFilename) {
                file.imageUrl = 'data:image/png;base64,pending';
                return;
            }
            if (thumbnailFilename === 'data:image/png;base64,error') {
                file.imageUrl = 'data:image/png;base64,error';
                return;
            }
            _this.http
                .get("/pronghorn/v1/image/rescale?" + query.toString())
                .pipe(operators_1.map(function (json) {
                return "data:image/png;base64," + json.data.base64String;
            }), operators_1.catchError(function (error) {
                console.error('Error getting image');
                return rxjs_1.of('data:image/png;base64,error');
            }), operators_1.take(1))
                .subscribe(function (result) {
                file.$dataUrl.next(result);
                file.imageUrl = result;
            });
        });
        return file.$dataUrl;
    };
    FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.GuidService), i0.ɵɵinject(i3.HttpClient)); }, token: FileService, providedIn: "root" });
    return FileService;
}());
exports.FileService = FileService;
