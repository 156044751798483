import { UrlParameterService } from './../../services/url-parameter.service';
import { ConfigurationService } from './../../services/configuration.service';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

enum BodyView {
  Anterior,
  Posterior
}

interface ModifierMap {
  name: string;
  value: any[];
  selected: string;
}

interface RelatedMaps {
  before: BodyPart[];
  after: BodyPart[];
  children: BodyPart[];
  parent?: BodyPart[];
  selected: BodyPart;
}

interface BodyMap {
  label: string;
  orientation: string;
  regions: BodyPart[];
}

interface BodyPart {
  bpe: string;
  code: string;
  id: string;
  prependLabelToSubParts?: boolean;

  modifierTables?: string[];
  before?: string;
  after?: string;

  children?: BodyPart[];

  localizedStrings: {
    de: any;
    en: any;
    fr: any;
    it: any;
    nl: any;
  };
}

@Component({
  selector: 'app-body-map-dialog',
  templateUrl: './body-map-dialog.component.html',
  styleUrls: ['./body-map-dialog.component.scss']
})
export class BodyMapDialogComponent implements OnInit {

  localization: string;

  public Views = BodyView;
  public view: BodyView = BodyView.Anterior;
  public showUnspecifiedButton: boolean = true;

  private bodyMaps: BodyMap[];
  private modifiers: any[];
  private customBodyMaps;

  selectedBodyPart: BodyPart | null;
  relatedList: RelatedMaps = { before: [], selected: undefined, after: [], children: [] };
  selectedModifiers: any[] = [];

  constructor(
    private configService: ConfigurationService,
    public translate: TranslateService,
    private urlService: UrlParameterService,
    public dialogRef: MatDialogRef<BodyMapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    // Get 2 character language reference to match body-mappings files
    this.localization = this.data.lang.slice(0,2);
     
    this.setup();
  }

  submit(applyToUnspecified: boolean = false ) {
    let result;

    if (!this.selectedBodyPart) {
      result = { name: this.translate.instant('table.notSpecified'), code: 'NOT SPECIFIED' };
    } else {
      result = {
        name: this.generateBodyPartString(),
        bodyPart: this.selectedBodyPart,
        modifiers: this.selectedModifiers,
        view: this.view,
        orientation: this.bodyMaps[this.view].orientation,
        relatedList: this.relatedList
      };
    }

    this.dialogRef.close({ bodyPart: result, applyToUnspecified });
  }

  setView(viewID: BodyView) {
    this.view = viewID;
    this.resetSelection();
  }

  /**
   * Set string to pascal case
   * @param {String} string String to be transformed
   * @example
   * // returns Test
   * pascalCase('test')
   * 
   * @example
   * // returns TestComponent
   * pascalCase('test_component')
   */
  pascalCase(string: string) {
    if (string.length === 0) {
      return '';
    }

    return string
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/\s+(.)(\w+)/, 'g'), (group1, group2, group3) => `${group2.toUpperCase() + group3.toLowerCase()}`)
            .replace(new RegExp(/\w/), s => s.toUpperCase());
  }

  generateBodyPartString() {
    return `${this.getModifierString().trim()} ${this.getLocalizedParentString(this.relatedList)} ${this.getLocalizedBodyPartString(this.selectedBodyPart)}`.trim();
  }

  /**
   * Get localization string from BodyPart object using the component's localization property
   * @param {BodyPart} bodyPart
   */
  getLocalizedBodyPartString(bodyPart: BodyPart): string {
    if (!bodyPart) {
      return '';
    }

    if (!bodyPart.localizedStrings[this.localization]) {
      return bodyPart.code;
    }

   try {
      return bodyPart.localizedStrings[this.localization].label;
   } catch (err) {
     console.error(bodyPart);
     return '';
   }
  }

    /**
   * Get localization string from RelatedList object parent using the component's localization property
   * @param {RelatedMaps} relatedList
   */
  getLocalizedParentString(relatedList: RelatedMaps): string {
    if (!relatedList || !relatedList.parent || (relatedList.parent && relatedList.parent.length === 0)) {
      return '';
    }

    if (!relatedList.parent || !relatedList.parent[0].prependLabelToSubParts) {
      return '';
    }

    if (!relatedList.parent[0].localizedStrings[this.localization]) {
      return relatedList.parent[0].code;
    }

   try {
      return relatedList.parent[0].localizedStrings[this.localization].label;
   } catch (err) {
     console.error(relatedList);
     return '';
   }
  }

  /**
   * Get localization string from the modifier object using the component's localization property
   * @param {BodyPart} bodyPart 
   */
  getLocalizedModifierString(modifier): string {
    console.dir(modifier);
    
    if (!modifier) {
      return '';
    }

    return modifier.localizedStrings[this.localization].label;
  }

  /**
   * Generate string of selected modifiers
   */
  getModifierString(): string {
    return this.selectedModifiers
              .map( item => this.getLocalizedModifierString(item.value.find( modifier => modifier.code === item.selected)))
              .join(' ');
  }

  private async setup() {
    let bodyMappings;
    if (this.data.file) {
      bodyMappings = this.data.file;
    } else {
      const BodyMapJSON = await this.configService.getBodyParts();
      bodyMappings = BodyMapJSON.bodyMappings;
    }

    this.showUnspecifiedButton = this.data.fileCount >= 2;
    this.bodyMaps = bodyMappings!.maps;
    this.modifiers = bodyMappings!.modifierTables.reduce( (acc, cur) => ({ ...acc, [cur.name]: cur.categories}) , {});
    this.customBodyMaps = bodyMappings!.customBodyMaps;

    this.relatedList.before = this.bodyMaps[this.view].regions.slice(0, 5);

    
    const inputBodyPart = this.data.bodyPart;

    if (inputBodyPart.name === this.translate.instant('media.not-anatomical')) {
      return;
    }

    if (inputBodyPart.view) {
      this.view = inputBodyPart.view;
    }
    
    // If user had previously selected a body part, set that as selected
    if (inputBodyPart.bodyPart) {
      this.setBodyPart(null, inputBodyPart.bodyPart);
      this.selectedModifiers = inputBodyPart.modifiers;
      this.relatedList = inputBodyPart.relatedList;
    }
  }

  /**
   * Set body part selected from guided button
   * @param {MouseEvent} event Event triggered by clicking on the button
   * @param {BodyPart} bodyPart Body part associated with that button
   */
  private setBodyPart(event, bodyPart: BodyPart) {
    // Catch if user clicks on the same body part
    if (this.selectedBodyPart && bodyPart.code === this.selectedBodyPart.code) {
      return;
    }

    const previousBodyPart = this.selectedBodyPart;

    this.selectedBodyPart = bodyPart;
    this.relatedList = this.getRelated(bodyPart);
    this.selectedModifiers = this.getModifiers(this.selectedBodyPart);

    // Check if element is child of previous selection and append parent if it is
    if (previousBodyPart && previousBodyPart.children && previousBodyPart.children.findIndex( item => item.code === bodyPart.code) !== -1) {
      this.relatedList.parent = [ previousBodyPart ];
    }
  }

  /**
   * Set body part from svg body map.  The map stores body part names as part of the data-name attribute.
   * This function can be placed on the <svg> element and using event bubbling the target will still be the individual body part
   * @param {MouseEvent} event Event triggered by clicking on the body part 
   */
  private selectBodyPartFromMap(event) {    
    // Get Element Data Name
    const selectedElement = event.target.getAttribute('data-name');

    // Catch missing configuration to avoid errors
    if (!selectedElement) {
      console.error('Missing element name ', selectedElement);
      this.resetSelection();
      return;
    }

    // Catch if user clicks on the same body part
    if (this.selectedBodyPart && this.selectedBodyPart.code === selectedElement) {
      return;
    }

    // Find Body Mapping with Data Name
    this.selectedBodyPart = this.getBodyPart(selectedElement);

    if (!this.selectedBodyPart) {
      console.error('Body Part missing from config: ', selectedElement);
      return;
    }

    // Get Related Body Parts
    this.relatedList = this.getRelated(this.selectedBodyPart);
    this.selectedModifiers = this.getModifiers(this.selectedBodyPart);
  }

  /**
   * Get the modifier objects that are listed in the BodyPart
   * @param {BodyPart} bodyPart BodyPart to be used to extract the modifiers from
   * @returns {ModifierMap[]} An array of Modifiers from the the modifier table that are associated with a specific BodyPart
   */
  private getModifiers(bodyPart: BodyPart): ModifierMap[] {
    if (!bodyPart.modifierTables) {
      return [];
    }

    return bodyPart.modifierTables.map( item => ({ name: item, value: this.modifiers[item], selected: ''}) );
  }

  /**
   * Updates modifier to reflect user selection
   * @param {MouseEvent} event Event triggered by clicking on the body part
   * @param {Number} modIndex Index of the modifier in the selectedModifiers array
   */
  private selectModifier(event, modIndex) {
    this.selectedModifiers[modIndex].selected = event.target.checked ? event.target.id : '';
  }

  /**
   * Gets map of body parts related to by proximity or lineage to a specific element
   * @param {BodyPart} bodyPart BodyPart to be used to as the anchor
   * @returns {RelatedMaps} Object that defines elements in proximity
   */
  private getRelated(bodyPart: BodyPart): RelatedMaps {
    /* if (bodyPart.before || bodyPart.after ) {
      const beforeArray = this.toArray(bodyPart.before);
      const afterArray = this.toArray(bodyPart.after);

      const beforeElements = beforeArray.map(item => this.getBodyPart(item));
      const afterElements = afterArray.map(item => this.getBodyPart(item));

      return { before: beforeElements, selected: bodyPart, after: afterElements, children: [] };
    } */

    if (bodyPart.before || bodyPart.children ) {
      const beforeArray = this.toArray(bodyPart.before);
      const childArray = bodyPart.children;

      const beforeElements = beforeArray.map(item => this.getBodyPart(item));

      return { before: beforeElements, selected: bodyPart, after: [], children: childArray };
    }

    if (bodyPart.after ) {
      const afterArray = this.toArray(bodyPart.after);

      const afterElements = afterArray.map(item => this.getBodyPart(item));

      return { before: [], selected: bodyPart, after: afterElements, children: [] };
    }

    return { before: [], selected: bodyPart, after: [], children: []};
  }

  /**
   * Retrieve body part information from config file using the 'code' property.  The current view is reviewed first, if the application cannot find a match it will check all body parts
   * @param {String} partCode Value to be used to look up the body part in the body mappings file
   * @returns {BodyPart} Body part found in the body mappings file
   */
  private getBodyPart(partCode: string): BodyPart {
    const viewResult = this.bodyMaps[this.view].regions.find( bodyPart => bodyPart.code === partCode );

    // If result found in current view, return it
    if (viewResult) {
      return viewResult;
    }

    // Else check all provided maps
    let allMaps;
    this.bodyMaps.forEach( map => {
      const matchingBodyPart = map.regions.find( bodyPart => bodyPart.code === partCode );
      if (matchingBodyPart) {
        allMaps = matchingBodyPart;
      }
    });

    return allMaps || {} as BodyPart;

  }

  /**
   * Process input and return a string array.  If the item is a string array, it is returned as is.
   * @param {String | String[] | undefined } item Item to be transformed into an array
   */
  private toArray( item: string | string[] | undefined ): string[] {
    if (!item) {
      return [];
    }

    if (Array.isArray(item)) {
      return item;
    }

    return [ item ];
  }

  /**
   * Reset the modal
   */
  private resetSelection() {
    this.selectedBodyPart = null;
    this.relatedList = { before: this.bodyMaps[this.view].regions.slice(0, 5), after: [], children: [], parent: [], selected: null };
    this.selectedModifiers = [];
  }
}
