"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("./../../environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(http) {
        this.http = http;
    }
    ConfigurationService.prototype.getSetupConfig = function () {
        var path = environment_1.environment.production ? 'script/configs.json' : 'assets/configs.json';
        return this.http.get(path).toPromise();
    };
    ConfigurationService.prototype.getStudyDescription = function () {
        var path = environment_1.environment.production ? 'script/studyDescription.json' : 'assets/studyDescription.json';
        return this.http.get(path).toPromise();
    };
    ConfigurationService.prototype.getStudyDescriptionFormats = function () {
        var path = environment_1.environment.production ? 'script/studyDescriptionFormats.json' : 'assets/studyDescriptionFormats.json';
        return this.http.get(path).toPromise().catch(function (err) {
            console.error('An error occurred:', err.error);
        });
    };
    ConfigurationService.prototype.getProviders = function () {
        var path = environment_1.environment.production ? 'script/providers.json' : 'assets/providers.json';
        return this.http.get(path).toPromise();
    };
    ConfigurationService.prototype.getCloudServerURL = function () {
        return this.http.get('api/cloud-server-url').toPromise();
    };
    ConfigurationService.prototype.getBodyParts = function () {
        var path = environment_1.environment.production ? 'script/bodyMappings.v2.json' : 'assets/bodyMappings.v2.json';
        return this.http.get(path).toPromise();
    };
    ConfigurationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.ɵɵinject(i1.HttpClient)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
exports.ConfigurationService = ConfigurationService;
