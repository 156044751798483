import { DeviceStatus, DeviceCounts } from '../classes/device-status';
import { DataService } from '../services/data.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TranslateService } from '../translation/translate.service';
import { MatRadioChange } from '@angular/material/radio';
import { StudyType } from '../classes/study-type';
import { NuanceService } from '../services/nuance.service';
import { DateFormatService } from '../services/date-format.service';
import { ManifestService } from '../services/manifest.service';
import { QrCodeService } from '../services/qr-code.service';
import { SessionService } from '../services/session.service';
import { UrlParameterService } from '../services/url-parameter.service';
import { MatSnackBar } from '@angular/material';
import { ConfigurationService } from '../services/configuration.service';
import AbstractUploaderPage from '../shared/abstract-uploader-page.component';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent extends AbstractUploaderPage implements OnInit, OnDestroy {

  private CURRENT_TAB = 'qrcode';
  public server: string;
  public pin: string;
  public length: number;
  public min = 1;
  public max = 24;
  private manifest: any;

  private multiplier = 3;
  private MINUTES_PER_HOUR = 60;

  customerConfigs;
  loading: boolean;
  qrCodeData = null;
  selectedStudy: string;
  suggestedStudies: Array<StudyType>;
  notSuggestedStudies: Array<StudyType>;
  studyEnabled = true;
  patient = {};
  manifestStatus: boolean;
  deviceStatusList: Array<DeviceStatus> = [];
  errorStatus: string;
  serverPostLocation;
  selectedDestination;
  nuanceStudyTagIds = '';
  cloudServer = 'https://pwa.imagemovermd.com/pin-code/';

  private manifestRoute: string;
  private currentlyUpdating = false;
  private intervalReference = null;
  private intervalGetAttempts: number;
  private manifestList: Array<any>;
  protected params = {} as any;
  private manifestPostData = {} as any;
  private sessions: any[] = [];

  constructor(
    private dateFormatService: DateFormatService,
    private manifestService: ManifestService,
    protected nuanceService: NuanceService,
    private qrCodeService: QrCodeService,
    private sessionService: SessionService,
    private urlParamService: UrlParameterService,
    private snackbar: MatSnackBar,
    public dataService: DataService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    @Inject('Document') private document: Document) {
      super(); 
    }

  ngOnInit() {
    this.loading = true;
    this.dataService.showNavigationWarning = false;
    this.params = this.dataService.setupData(this.params);
    this.translate.use(this.params.lang);

    this.customerConfigs = this.dataService.configSettings.qrcode;
    this.setDefaultServerPostLocation();

    this.setup();
  }

  ngOnDestroy() {
    this.stopStatus();
    this.dataService.showNavigationWarning = false;
    this.document.body.classList.remove(this.customerConfigs.theme);
  }

  formatLabel(value: number | null) {
    return `${value} ${this.translate.instant('word.hours')}`;
  }

  setDefaultServerPostLocation() {
    this.serverPostLocation = this.customerConfigs.sendToServerDefault;
    this.setServerPostLocation({ value: this.serverPostLocation } as MatRadioChange);
  }

  async setServerPostLocation($event: MatRadioChange) {
    super.setServerPostLocation($event);

    if (!this.manifest) {
      return;
    }

    await this.manifestService.updateManifestByObj({
      'sendToPACS': this.params.sendToPACS,
      'sendToNuance': this.params.sendToNuance,
      'sendToXDSb': this.params.sendToXDSb
    }, this.manifest);

    if ($event.value === 'Nuance') {
      await this.getNuanceDestinations();
      await this.updateNuancePostData();
    }
  }

  async updateNuancePostData() {
    await this.manifestService.updateManifestByObj({
      'nuanceRepoId': this.selectedDestination,
      'nuanceRepoStudyTagIds': this.nuanceStudyTagIds
    }, this.manifest);
  }

  trackDeviceStatusBy(__index, device) {
    return device.id;
  }

  async getPinCode($event: MouseEvent) {
    this.manifest = await this.manifestService.updateManifestByObj({
      genPinCode: true
    }, this.manifest);

    this.server = this.manifest.serverId;
    this.pin = this.manifest.pinCode;
  }

  async copyPinUrl($event: MouseEvent, input: HTMLInputElement) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(`${this.cloudServer}${this.server}${this.pin}`);
    } else {
      input.select();
      this.document.execCommand('copy');
    }

    this.snackbar.open(this.translate.instant('dicom.copyURLConfirm'), null, {
      duration: 2000
    });
  }

  // ====== PRIVATE ===== //

  private async setup() {
    const getCloudServer = await this.configService.getCloudServerURL();
    this.cloudServer = getCloudServer.url;

    try {
      this.document.body.classList.add(this.customerConfigs.theme);

        if (this.params.hideNavTabs) {
        DataService.hideNavTabs.next(this.params.hideNavTabs);
      } else {
        DataService.hideNavTabs.next(!this.customerConfigs.name);
      }

      if (!this.dataService.hasMinimumPatientInformation) {
        this.clearIntervalAndShowError('error.session');
        this.loading = false;
        return;
      }

      this.pin = '';

      const sessionOptions = {
        appName: 'qrcode',
        op: this.params.op,
        prov: this.params.prov,
        demo: this.params.demo
      };

      const newSession = await this.sessionService.createSession(this.params, sessionOptions);

      this.sessions.push(newSession);

      this.nuanceDestinations = [];

      this.selectedDestination = this.nuanceDestinations.length > 0 ? this.nuanceDestinations[0].id : undefined;

      this.patient = this.dataService.patient;

      await this.createManifest(newSession);

      this.qrCodeData = this.qrCodeService.createQrCode(this.manifest);
      this.startStatus(newSession);

      this.loading = false;

    } catch (error) {
      this.stopStatus();
      throw error;
    }
  }

  private async createManifest(session) {
    session.manifestRoute = session.manifests + '/';

    const nuanceRepoId = this.selectedDestination && this.params.sendToNuance ? this.selectedDestination : undefined;
    this.manifestPostData = {
      'session': session.key,
      'patient.lastName': this.params.lastName,
      'patient.firstName': this.params.firstName,
      'patient.middleName': this.params.middleName,
      'patient.title': this.params.title,
      'patient.suffix': this.params.suffix,
      'patient.externalId': this.params.mrn,
      'patient.dateOfBirth': this.dateFormatService.formatDate(this.params.dob),
      'patient.gender': this.params.gender,
      'batch.size': 0,
      'batch.sizeRemaining': 0,
      'sendToPACS': this.params.sendToPACS,
      'sendToNuance': this.params.sendToNuance,
      'sendToXDSb': this.params.sendToXDSb,
      'modality': this.urlParamService.getDefaultModality(this.params),
      'studyDescription': this.params.studyDescription,
      'genPinCode': false, // this.customerConfigs.hasPinCode always default to false
      'nuanceRepoId': nuanceRepoId,
      'nuanceRepoStudyTagIds': this.nuanceStudyTagIds,
      'xdsPatientId': this.params.xdsPatientId,
      'xdsSourcePatientId': this.params.xdsSourcePatientId
    };

    try {
      this.manifest = await this.manifestService.createManifest(session.manifestRoute, this.manifestPostData);
    } catch (error) {
      console.log(error);
      this.clearIntervalAndShowError('error.timeout');
    }
  }

  private startStatus(session) {
    this.checkStatus(session);
    const pollingInterval = 3000;

    this.intervalReference = setInterval(() => {
      this.checkStatus(session);
    }, pollingInterval);
  }

  private stopStatus() {
    clearInterval(this.intervalReference);
    this.intervalReference = undefined;
  }

  private async checkStatus(session) {
    if (session.status === 404) {
      console.log('session expired: ', session);
      return;
    }
    try {
      const response: any = await this.manifestService.getStatusManifest(session.manifestRoute);

      this.parseResponse(response);

    } catch (error) {
      session.status = error.status;

      if (error.status === 404 && this.sessions.every(s => s.status === 404)) {
        this.clearIntervalAndShowError('error.timeout');
      }
      console.log(error);
    }
  }

  private async parseResponse(response) {
    // Loop through entire array and add status if not null.
    const parsedResponse = response
      .map(element => {
        return {
          result: this.parseNote(element),
          original: element
        };
      });

    this.deviceStatusList = this.deviceStatusList.map((device) => {
      if (device.id === response[0].id) {
        device = this.parseDeviceStatus(parsedResponse[0]);
      }

      return device;
    });

    if (this.manifest.id === response[0].id && this.manifest.notes !== response[0].notes) {
      this.deviceStatusList.push(this.parseDeviceStatus(parsedResponse[0]));
      this.setup();
    }
  }

  private parseDeviceStatus(element): DeviceStatus {
    this.manifestStatus = true;
    const deviceStatus: DeviceStatus = {
      connectionNotes: '',
      progressMode: 'determinate',
      progressUpload: 0,
      id: element.original.id,
      counts: element.result
    };

    if (element.original.expectedFiles > 0) {
      deviceStatus.progressMode = 'determinate';
      deviceStatus.progressUpload = element.result.percentComplete;
    }

    if (element.result.phoneName === this.translate.instant('phrase.disconnected')) {
      deviceStatus.progressMode = 'determinate';
      deviceStatus.connectionNotes = `${element.result.phoneName}`;
    } else {
      deviceStatus.connectionNotes = `${element.result.phoneName} ${this.translate.instant('word.connected')}`;
    }

    return deviceStatus;
  }

  private parseNote(note) {
    let phoneName = '';
    let noteTotal = 0;

    if (!(note && note.notes)) {
      return null;
    }

    const notes = note.notes.split('|');


    if (notes.length >= 6 && notes[5].length > 0) {
      phoneName = notes[5] + ' ';
    }

    if (notes[0] === 'D') {
      phoneName = this.translate.instant('phrase.disconnected');
    }

    if (note.transmissionStatusSummary.ERROR !== 0) {
      noteTotal += note.transmissionStatusSummary.ERROR;
    }

    if (note.transmissionStatusSummary.SUCCESS !== 0) {
      noteTotal += note.transmissionStatusSummary.SUCCESS;
    }

    const object: DeviceCounts = {
      phoneName: phoneName.trim(),
      processingTotal: note.transmissionStatusSummary.PROCESSING,
      canceledTotal: note.transmissionStatusSummary.CANCELLED,
      failureTotal: note.transmissionStatusSummary.ERROR,
      successTotal: note.transmissionStatusSummary.SUCCESS,
      percentComplete: (noteTotal / note.expectedFiles) * 100,
      filesReceived: noteTotal,
      totalFiles: note.expectedFiles
    };

    return object;
  }

  private clearIntervalAndShowError(error: string = 'error.general') {
    this.stopStatus();
    this.qrCodeData = '';
    this.patient = {
      name: '---',
      dobFormat: '---',
      gender: '---',
      mrn: '---'
    };
    this.pin = undefined;
    this.server = undefined;
    this.errorStatus = this.translate.instant(error);
  }
}
