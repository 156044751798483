import { Injectable } from '@angular/core';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NameParseService {

  constructor() { }

  parseName(name: string): any {

    if (_.includes(name, '^')) {
      return this.split(name, '^');
    }

    if (_.includes(name, ',')) {
      return this.split(name, ',');
    }

    if (_.includes(name, '|')) {
      return this.split(name, '|');
    }

    return {
      lastName: name === 'undefined' ? undefined : name
    };
  }

  private split(name: string, delimiter: string) {
    let splitName = (decodeURIComponent(name).split(delimiter));
    splitName = splitName.map(i => i.trim());

    return {
      lastName: splitName.shift(),
      firstName: splitName.shift() || '',
      middleName: splitName.shift() || '',
      title: splitName.shift() || '',
      suffix: splitName.shift() || ''
    };
  }
}
