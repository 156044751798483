import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '../../translation/translate.service';

const SCRIPT_REGEX = /<\/?script>/g;
const PIN_REGEX = /\$PIN/g;
const URL_REGEX = /\$URL/g;

@Component({
  selector: 'app-transfer-confirm-modal',
  templateUrl: './transfer-confirm-modal.component.html',
  styleUrls: ['./transfer-confirm-modal.component.scss']
})
export class TransferConfirmModalComponent {

  userMessage = '';
  tokenExpired = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
  ) {

    const _message = data.message || `${translate.instant('dicom.syncDesc')}\n\nSync URL: ${this.data.path}\n\nDirectory Upload Sync Code: ${this.data.pin}`;

    this.tokenExpired = data.tokenExpired;
    this.userMessage = this.generateMessage(_message);
  }

  generateMessage(message: string) {
    let output = message;
    // Replace script tags with emtpy strings
    output = output.replace(SCRIPT_REGEX, '');
    output = output.replace(PIN_REGEX, this.data.pin);
    output = output.replace(URL_REGEX, this.data.path);

    return output;
  }

  copyText() {
    const el = document.createElement('textarea');
    el.value = this.data.path;
    el.setAttribute('readonly', '');
    el.style.opacity = '0';
    el.style.transform = 'scale(0)';
    el.style.pointerEvents = 'none';

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    this._snackBar.open(this.translate.instant('dicom.copyURLConfirm'), null, {
      duration: 2000
    });
  }

}
