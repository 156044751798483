<mat-card>
  <header>
    <app-logo [logo]="customerConfigs.logo"></app-logo>
  </header>
  <section>
    <aside>
      <qr-code class="unresolved" [class.unresolved]="!qrCodeData" [value]="qrCodeData" [size]="280" [level]="'M'"></qr-code>
      <div class="unresolved code-container" [class.unresolved]="!pinCode">
        <div class="code">
          <div class="title">
            {{translate.instant('phrase.pinCode')}}
          </div>
          <div>
            {{pinCode}}
          </div>
        </div>
      </div>
    </aside>

    <main>
      <mat-progress-spinner *ngIf="loading; else finishedLoading" color="accent" mode="indeterminate">
      </mat-progress-spinner>
      <ng-template #finishedLoading>
        <div class="instructions">
          {{translate.instant('instruction.roundsPartOne')}}&nbsp;{{customerConfigs.appName}}&nbsp;{{translate.instant('instruction.roundsPartTwo')}}
        </div>
        <div class="ehr-information" *ngIf="minimumInformation">
          <app-patient-information [doctor]="doctor" [enableDoctorFacing]="true"></app-patient-information>
        </div>

        <div *ngIf="errorStatus" class="error">
          {{errorStatus}}
        </div>

        <footer *ngIf="customerConfigs.showFooterHelpText">
          {{ translate.instant('phrase.install') }}
          <br /> {{ translate.instant('phrase.scanPartOne') }}&nbsp;{{ customerConfigs.appName }}&nbsp;{{
          translate.instant('phrase.scanPartTwo') }}
          <a href="https://www.imagemovermd.com/install" target="_blank"
            rel="noopener noreferrer">imagemovermd.com/install</a>
          {{ translate.instant('phrase.scanPartThree') }}
        </footer>
      </ng-template>
    </main>
  </section>
</mat-card>