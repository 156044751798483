<mat-card *ngIf="!rendering">
  <header>
    <app-logo [logo]="customerConfigs.logo"></app-logo>
  </header>
  <div *ngIf="showOrdersTable; else createNewOrder" class="ordersTable">
    <div class="header">
      <div>
        <button mat-raised-button color="primary" (click)="togglePageView()">
          {{ translate.instant('button.createNewOrder') }}
        </button>
      </div>
      <app-patient-information [patient]="patient"></app-patient-information>
    </div>
    <table mat-table [dataSource]="ordersTable" class="table">

      <ng-container matColumnDef="accessionId">
        <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.accessionNumber')}} </th>
        <td mat-cell *matCellDef="let element"> {{element.accessionId}} </td>
      </ng-container>
    
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.startExamTimestamp')}} </th>
        <td mat-cell *matCellDef="let element"> 
          <div>
            {{element.timestampDisplay}} 
          </div>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="studyDescription">
        <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.studyDescription/reasonForExam')}} </th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span>{{element.studyDescription}}</span>
            <span>{{element.reasonForStudy}}</span>
          </div>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="authProvider">
        <th mat-header-cell *matHeaderCellDef> {{translate.instant('phrase.authorizingprovider')}} / {{ translate.instant('table.department') }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span>{{element.authProvider}}</span>
            <span>{{element.encDept}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.status')}} </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{translate.instant('table.actions')}} </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.status === 'Pending'">
            <button mat-raised-button color="accent" (click)="endOpenExam(element)">
              {{ translate.instant('button.endexam') }}
            </button>
            <button mat-button color="primary" [disabled]="disabled" (click)="promptCancelOpenOrder(element)">
              {{ translate.instant('button.cancel') }}
            </button>
          </div>
        </td>
      </ng-container>

      
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <ng-template #createNewOrder>
    <section>
      <div class="header">
        <button mat-raised-button color="primary" (click)="togglePageView()" *ngIf="ordersTable.data.length > 0">
          {{ translate.instant('button.viewPatientOrders') }}
        </button>
      </div>

      <aside *ngIf="showInstructions(); else blankInstructions">
        <div *ngIf="!accessionNumber; else accessionNumberDisplay">
          <ng-container *ngIf="hasCustomInstructions(); else defaultInstructions">
            <p *ngFor="let instruction of getCustomInstructions()">{{ instruction }}</p>
          </ng-container>
        </div>
        <ng-template #accessionNumberDisplay>
          <div>
            <p [matTooltip]="translate.instant('phrase.accession')">
              <span class="accession-number">
                {{ accessionNumber }}
              </span>
            </p>
            <p>
              <qr-code [value]="qrCodeData" [size]="200" [level]="'M'"></qr-code>
            </p>
          </div>
        </ng-template>
      </aside>
      
      <ng-template #defaultInstructions>
        <p>
          {{ translate.instant('word.instructions') }}:
        </p>
        <p>
          {{ translate.instant('instruction.modalityPartOne') }}
        </p>
        <p>
          {{ translate.instant('instruction.modalityPartTwo') }}
        </p>
        <p>
          {{ translate.instant('instruction.modalityPartThree') }}
        </p>
        <p>
          {{ translate.instant('instruction.modalityPartFour') }}
        </p>
      </ng-template>

      <ng-template #blankInstructions>
        <aside></aside>
      </ng-template>
  
      <main>
        <div class="ehr-information" *ngIf="!disabled">
          <app-patient-information [patient]="patient">
            <div *ngIf="selectedStudyObject?.code === '-1'; else studyDescriptionSelector" class="display">
              <span>{{translate.instant('table.study')}}:</span>{{selectedStudyObject.studyDescription}}
            </div>

            <ng-template #studyDescriptionSelector>
              <app-study-selection [suggestedStudies]="suggestedStudies" [notSuggestedStudies]="notSuggestedStudies"
                (selectionChange)="updateStudyDescription($event)" [(selectedStudy)]="selectedStudyObject"
                [suggestedLabel]="translate.instant('word.study-match')" [disabled]="captureInProgress">
              </app-study-selection>
            </ng-template>
  
            <mat-form-field>
              <input [value]="reasonForStudy" [disabled]="captureInProgress || disabled" matInput (keyup)="updateReasonForStudy($event)"
                [placeholder]="translate.instant('button.reason')" autocomplete="off">
            </mat-form-field>
  
            <mat-form-field>
              <mat-label>{{translate.instant('phrase.authorizingprovider')}}</mat-label>
              <mat-select [formControl]="providersSelect" #select>
                <mat-option>
                  <ngx-mat-select-search [placeholderLabel]="translate.instant('button.search')" noEntriesFoundLabel=""
                    [formControl]="providersFilter"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let p of filteredProviders | async" [value]="p" class="site-list">
                  <span>{{p.name.lastName}},</span>
                  <span> {{p.name.firstName}}</span>
                  <span *ngIf="p.name.middleName"> {{ p.name.middleName }}</span>
                  <span *ngIf="p.name.title">, {{ p.name?.title}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </app-patient-information>
        </div>
  
        <p class="end-exam-description" *ngIf="accessionNumber">
          {{ translate.instant('instruction.accession') }}
        </p>
  
        <div *ngIf="errorStatus" class="error">
          {{errorStatus}}
        </div>
      </main>
  
      <footer>
        <mat-progress-spinner *ngIf="loading" color="accent" mode="indeterminate" diameter="25">
        </mat-progress-spinner>
        <button mat-raised-button color="accent" [disabled]="disabled || selectedStudyObject === 'undefined'" (click)="submit()"
          *ngIf="!accessionNumber; else endExam">
          {{ translate.instant('button.capture') }}
        </button>
        <ng-template #endExam>
          <button mat-button color="primary" [disabled]="disabled" (click)="promptCancel()">
            {{ translate.instant('button.cancel') }}
          </button>
          <button mat-raised-button color="accent" [disabled]="disabled" (click)="end()">
            {{ translate.instant('button.endexam') }}
          </button>
        </ng-template>
      </footer>
    </section>
  </ng-template>
</mat-card>