"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/material/tabs");
var i3 = require("@angular/cdk/platform");
var i4 = require("@angular/material/core");
var i5 = require("@angular/cdk/a11y");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("../../node_modules/@angular/material/tabs/typings/index.ngfactory");
var i8 = require("@angular/cdk/bidi");
var i9 = require("@angular/cdk/scrolling");
var i10 = require("@angular/common");
var i11 = require("./shared/footer/footer.component.ngfactory");
var i12 = require("./shared/footer/footer.component");
var i13 = require("./translation/translate.service");
var i14 = require("./services/data.service");
var i15 = require("@angular/material/dialog");
var i16 = require("@angular/router");
var i17 = require("./app.component");
var i18 = require("./services/session.service");
var i19 = require("./services/url-parameter.service");
var i20 = require("@angular/common/http");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "mat-tab-link"], ["mat-tab-link", ""]], [[1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, [[1, 4]], 0, i2.MatTabLink, [i2.MatTabNav, i1.ElementRef, i1.NgZone, i3.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { active: [0, "active"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_v.context.$implicit === _co.dataService.currentRoute); _ck(_v, 1, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).active ? "page" : null); var currVal_1 = i1.ɵnov(_v, 1).disabled; var currVal_2 = i1.ɵnov(_v, 1).tabIndex; var currVal_3 = i1.ɵnov(_v, 1).disabled; var currVal_4 = i1.ɵnov(_v, 1).active; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.dataService.configSettings[_v.context.$implicit].name; _ck(_v, 2, 0, currVal_6); }); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "nav", [["class", "mat-tab-nav-bar mat-tab-header"], ["mat-tab-nav-bar", ""]], [[2, "mat-tab-header-pagination-controls-enabled", null], [2, "mat-tab-header-rtl", null], [2, "mat-primary", null], [2, "mat-accent", null], [2, "mat-warn", null]], null, null, i7.View_MatTabNav_0, i7.RenderType_MatTabNav)), i1.ɵdid(2, 7520256, null, 1, i2.MatTabNav, [i1.ElementRef, [2, i8.Directionality], i1.NgZone, i1.ChangeDetectorRef, i9.ViewportRuler, [2, i3.Platform], [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { _items: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_2)), i1.ɵdid(5, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.filterTabsWithName(); _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._showPaginationControls; var currVal_1 = (i1.ɵnov(_v, 2)._getLayoutDirection() == "rtl"); var currVal_2 = ((i1.ɵnov(_v, 2).color !== "warn") && (i1.ɵnov(_v, 2).color !== "accent")); var currVal_3 = (i1.ɵnov(_v, 2).color === "accent"); var currVal_4 = (i1.ɵnov(_v, 2).color === "warn"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i12.FooterComponent, [i13.TranslateService, i14.DataService, i15.MatDialog, i1.ChangeDetectorRef], { version: [0, "version"], logo: [1, "logo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; var currVal_1 = ((_co.dataService.configSettings[_co.dataService.currentRoute] == null) ? null : _co.dataService.configSettings[_co.dataService.currentRoute].logo); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i16.RouterOutlet, [i16.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideNavTabs; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = _co.dataService.configSettings; _ck(_v, 7, 0, currVal_1); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "popstate"], ["window", "beforeunload"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("window:popstate" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onPopState($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:beforeunload" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).beforeunloadHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onUserInteraction($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i17.AppComponent, [i15.MatDialog, i18.SessionService, i19.UrlParameterService, i14.DataService, i20.HttpClient, i1.ChangeDetectorRef, "Window"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("app-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
