<div>
  <strong>
    {{translate.instant('word.destination')}}
  </strong>
</div>

<mat-radio-group [disabled]="inputDisabled" [(ngModel)]="serverPostLocation" (change)="changed($event)">
  <mat-radio-button color="accent" value="PACS" *ngIf="config.pacs" [disabled]="displayOnly">
    {{translate.instant('radio.pacs')}}
  </mat-radio-button>

  <mat-radio-button color="accent" value="Nuance" *ngIf="config.nuance" [disabled]="displayOnly">
    {{translate.instant('radio.powershare')}}
  </mat-radio-button>

  <mat-radio-button color="accent" value="XDSb" *ngIf="config.xdsb" [disabled]="displayOnly">
    {{translate.instant('radio.xdsb')}}
  </mat-radio-button>
</mat-radio-group>

<div *ngIf="serverPostLocation === 'Nuance'">
  <mat-form-field>
    <mat-select 
      [disabled]="inputDisabled" 
      [placeholder]="translate.instant('word.destination')" 
      [(ngModel)]="selectedDestination" 
      (selectionChange)="destinationChange($event)"
    >
      <mat-option *ngFor="let dest of nuanceDestinations" [value]="dest.id">
        {{ dest.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-accordion *ngIf="destination.studyTags.studyTag.length > 0">
    <mat-expansion-panel>
      
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{translate.instant('phrase.studyTag')}}
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <div class="selection-body">
        <mat-checkbox 
          *ngFor="let dest of destination.studyTags.studyTag" 
          [value]="dest.id" 
          [checked]="dest.checked" 
          (change)="selectTag($event, dest)"
        >
          {{dest.name}}
        </mat-checkbox>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  
</div>