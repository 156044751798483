import { debounceTime, filter, tap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { DataService } from '../services/data.service';
import { TranslateService } from '../translation/translate.service';
import { StudyType } from '../classes/study-type';
import { Patient } from '../classes/patient';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CancelModalityComponent } from '../modals/cancel-modality/cancel-modality.component';
import { DateFormatService } from '../services/date-format.service';
import { ManifestService } from '../services/manifest.service';
import { GuidService } from '../services/guid.service';
import { SessionService } from '../services/session.service';
import { Provider, ProviderSearch } from '../classes/provider';
import { ConfigurationService } from '../services/configuration.service';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OrderTable, ModalityService } from '../services/modality.service';
import fecha from 'fecha';
import { ProviderUtilities } from '../utilities/provider-utilities';

@Component({
  selector: 'app-modality',
  templateUrl: './modality.component.html',
  styleUrls: ['./modality.component.scss']
})
export class ModalityComponent implements OnInit, OnDestroy {

  private CURRENT_TAB = 'modality';

  qrCodeData: string;
  postData;
  loading: boolean;
  disabled: boolean;
  captureInProgress: boolean;
  manifest: any = '';
  getCount = 0;
  studies: Array<StudyType> = [];
  customerConfigs;
  template = {};
  params = {} as any;
  selectedStudy: string;
  selectedStudyObject: StudyType = {} as StudyType;
  suggestedStudies: Array<StudyType>;
  notSuggestedStudies: Array<StudyType>;
  studyEnabled: boolean;
  currentlyUpdating = false;
  errorStatus: string;
  manifestPostData;
  manifestRoute;
  reasonForStudy = '';
  version;
  accessionNumber;
  manifestId;
  patient: Patient = {} as any;
  cancelModality: MatDialogRef<CancelModalityComponent>;
  stop = null;
  ordersListener = null;
  providers: Array<Provider> = [];
  filteredProviders: BehaviorSubject<Provider[]>;
  selectedProvider: Provider = {} as Provider;
  providersSelect = new FormControl();
  providersFilter = new FormControl();

  rendering = true;
  showOrdersTable = true;
  ordersTable = new MatTableDataSource<OrderTable>();
  displayedColumns = ['accessionId', 'timestamp', 'studyDescription', 'authProvider', 'status', 'actions'];

  constructor(
    private dateFormatService: DateFormatService,
    private dialogService: MatDialog,
    private guidService: GuidService,
    private manifestService: ManifestService,
    private sessionService: SessionService,
    private configurationService: ConfigurationService,
    private modalityService: ModalityService,
    public dataService: DataService,
    public translate: TranslateService,
    @Inject('Document') private document: Document) { }

  ngOnInit() {
    this.params = this.dataService.setupData(this.params);
    this.translate.use(this.params.lang);

    this.setup();
  }

  async setup() {
    this.params = this.dataService.setupData(this.params);
    this.customerConfigs = this.dataService.configSettings.modality;
    this.document.body.classList.add(this.customerConfigs.theme);

    if (this.params.hideNavTabs) {
      DataService.hideNavTabs.next(this.params.hideNavTabs);
    } else {
      DataService.hideNavTabs.next(!this.customerConfigs.name);
    }

    if (!this.dataService.hasMinimumPatientInformation) {
      this.errorStatus = this.translate.instant('error.session');
      this.disabled = true;
      this.providersSelect.disable();
      return;
    }

    this.studies = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, this.CURRENT_TAB);

    const result = this.dataService.searchStudies(this.studies, this.params, 'modalityQuickOrder');
    this.studies = result.studiesFilter;
    this.selectedStudyObject = result.selectedStudy;
    this.suggestedStudies = result.suggested;
    this.notSuggestedStudies = result.notSuggested;

    this.patient = this.dataService.patient;

    const providers = await this.configurationService.getProviders();
    // Process provider.json file to ensure that all names are properly formatted, remove invalid providers
    this.providers = providers.map(ProviderUtilities.validateProviderListItems).filter(provider => provider);

    this.filteredProviders = new BehaviorSubject<Provider[]>(
      this.providers
        .sort((a, b) => {
          if (a.name.lastName === b.name.lastName) {
            if (a.name.firstName === b.name.firstName) {
              return 0;
            }

            return a.name.firstName > b.name.firstName ? 1 : -1;
          }

          return a.name.lastName > b.name.lastName ? 1 : -1;
        })
        .slice(0, 20));

    

    await this.setupOrdersTable();
    this.showOrdersTable = this.ordersTable.data
      .filter((order) => order.status === 'Pending').length > 0;
    this.rendering = false;

    this.providersFilter.valueChanges
      .pipe(
        debounceTime(333),
        filter(input => input.length > 2),
      )
      .subscribe((value) => {
        const providers = this.providers
          .map(ProviderUtilities.compareProviderName(value))
          .filter((p: Provider & ProviderSearch) => p.search.firstNameScore > 0.75 || p.search.lastNameScore > 0.75)
          .sort(ProviderUtilities.sortProviders(value));

        this.filteredProviders.next(providers);
      });

    this.providersSelect.valueChanges
      .subscribe(async (provider) => {
        this.selectedProvider = provider;
      });

    this.setupInitialStudyConfig();
    await this.setupOrdersListener();
  }

  async setupOrdersListener() {
    this.ordersListener = setInterval(async () => {
      await this.setupOrdersTable();
    }, 3000);
  }

  async setupOrdersTable() {
    try {
      const url = `${this.sessionService.getUrlPath(this.params.unittest)}`;
      const orders = (await this.modalityService.getOpenOrders(this.patient.mrn, url)).map((order) => {
        order.authProvider = order.authProvider.replace('^', ',');
        const date = new Date(order.timestamp);
        order.timestampDisplay = fecha.format(date, 'MMM D, YYYY HH:mma');
        return order;
      });
      orders.sort((a, b) => {
        try {
          if ((new Date(a.timestamp)) < (new Date(b.timestamp))) {
            return 1;
          }
        } catch (error) {
          console.log(error);
        }

        return -1;
      });

      this.ordersTable = new MatTableDataSource<OrderTable>(orders);
    } catch (error) {
      console.log(error);
    }
  }

  setupInitialStudyConfig() {
    const foundStudy = this.dataService.findStudy(this.studies, this.params.studyDescription);
    const suggestedStudy = this.suggestedStudies.length ? this.suggestedStudies[0] : null;
    const defaultStudy = suggestedStudy ? suggestedStudy : this.dataService.findStudy(this.studies, this.dataService.configSettings.modalityQuickOrder.defaultStudyDescription);
    const study = foundStudy.value ? foundStudy : defaultStudy;

    this.studyEnabled = !this.params.studyDescription;
    if (this.params.studyDescription) {
      this.selectedStudy = this.params.studyDescription;
      this.selectedStudyObject = {
        code: '-1',
        studyDescription: this.params.studyDescription
      } as any;
    } else {
      this.selectedStudy = this.selectedStudyObject && this.selectedStudyObject.value ? this.selectedStudyObject.value : study.value;
      this.selectedStudyObject = study.value ? study : undefined;
    }
  }

  async togglePageView() {
    if (!this.showOrdersTable) {
      await this.setupOrdersTable();
    } else {
      this.reset();
    }
    this.showOrdersTable = !this.showOrdersTable;
  }

  updateReasonForStudy($event) {
    this.reasonForStudy = $event.target.value;
  }

  async submit() {
    this.loading = true;
    this.captureInProgress = true;
    this.dataService.showNavigationWarning = true;
    this.providersSelect.disable();

    try {
      let encounterProvider = {};
      if (this.selectedProvider) {
        encounterProvider = {
          'encounterProviderId': this.selectedProvider.id,
          'encounterProviderName': `${this.selectedProvider.name.lastName}, ${this.selectedProvider.name.firstName}`
        };
      }

      const sessionOptions = {
        appName: 'modality',
        appAction: 'startExam',
        prov: this.params.prov,
        op: this.params.op,
        demo: this.params.demo,
        timeoutMinutes: this.dataService.configSettings.modalityQuickOrder.sessionTimeoutPeriod,
        ...encounterProvider
      };

      const session = await this.sessionService.createSession(this.params, sessionOptions);
      this.manifestRoute = session.manifests + '/';
      this.version = session.version;

      const modality = _.first(this.selectedStudyObject.modality);

      this.manifestPostData = {
        'session': session.key,
        'patient.lastName': this.params.lastName,
        'patient.firstName': this.params.firstName,
        'patient.middleName': this.params.middleName,
        'patient.title': this.params.title,
        'patient.suffix': this.params.suffix,
        'patient.externalId': this.params.mrn,
        'patient.dateOfBirth': this.dateFormatService.formatDate(this.params.dob),
        'patient.gender': this.params.gender,
        'batch.size': 0,
        'batch.sizeRemaining': 0,
        'examCode': this.selectedStudyObject.code === '-1' ? '' : this.selectedStudyObject.code,
        'studyDescription': this.selectedStudyObject.studyDescription,
        'modality': this.params.modality ? this.params.modality : modality,
        'sendToPACS': this.params.sendToPACS,
        'sendToNuance': this.params.sendToNuance,
        'sendToXDSb': this.params.sendToXDSb,
        'reasonForStudy': this.reasonForStudy,
        'xdsPatientId': this.params.xdsPatientId,
        'xdsSourcePatientId': this.params.xdsSourcePatientId
      };

      this.manifest = await this.manifestService.createManifest(this.manifestRoute, this.manifestPostData);
      const response = await this.singleFileUpload(this.manifest.files);
      this.accessionNumber = response.accessionNumber;

      this.qrCodeData = this.generateQrCode();

      this.startStatus(this.manifest);

    } catch (error) {
      this.currentlyUpdating = false;
      this.errorStatus = this.translate.instant('error.manifest');
      throw error;
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.loading = false;
    this.captureInProgress = false;
    this.manifestId = undefined;
    this.accessionNumber = undefined;
    this.reasonForStudy = '';
    this.providersSelect.enable();
    this.stopStatus();
  }

  async end() {
    this.loading = true;
    try {
      await this.sessionService.updateAction('endExam');

      await this.singleFileUpload(this.manifest.files);
    } catch (error) {
      throw error;
    } finally {
      this.reset();
    }
  }

  async endOpenExam(row: OrderTable) {
    this.loading = true;
    try {
      const url = `${this.sessionService.getUrlPath(this.params.unittest)}sessions/${row.sessionKey}`;

      if (row.appName === 'Modality') {
        await this.sessionService.updateAction('endExam', url);
        await this.singleFileUpload(`${url}/manifests/${row.manifestId}/files`);
      } else {
        const baseUrl = `${this.sessionService.getUrlPath(this.params.unittest)}`;
        await this.modalityService.endExam(baseUrl, row.sessionKey, row.manifestId);
      }

      await this.setupOrdersTable();
    } catch (error) {
      throw error;
    } finally {
      this.reset();
    }
  }

  async cancel() {
    this.loading = true;
    try {
      await this.sessionService.updateAction('cancelExam');

      await this.singleFileUpload(this.manifest.files);
    } catch (error) {
      throw error;
    } finally {
      this.reset();
    }
  }

  async promptCancel() {
    this.cancelModality = this.dialogService.open(CancelModalityComponent, {
      minWidth: 300,
      disableClose: true
    });

    const response = await this.cancelModality.afterClosed().toPromise();

    if (response.status) {
      this.cancel();
    }
  }

  async promptCancelOpenOrder(row: OrderTable) {
    this.cancelModality = this.dialogService.open(CancelModalityComponent, {
      minWidth: 300,
      disableClose: true
    });

    const response = await this.cancelModality.afterClosed().toPromise();

    if (response.status) {
      this.cancelOpenOrder(row);
    }
  }

  async cancelOpenOrder(order: OrderTable) {
    this.loading = true;
    try {
      const url = `${this.sessionService.getUrlPath(this.params.unittest)}sessions/${order.sessionKey}`;

      if (order.appName === 'Modality') {
        await this.sessionService.updateAction('cancelExam', url);
        await this.singleFileUpload(`${url}/manifests/${order.manifestId}/files`);
      } else {
        const baseUrl = `${this.sessionService.getUrlPath(this.params.unittest)}`;
        await this.modalityService.cancelExam(baseUrl, order.sessionKey, order.manifestId);
      }

      await this.setupOrdersTable();
    } catch (error) {
      throw error;
    } finally {
      this.reset();
    }
  }

  stopStatus() {
    clearInterval(this.stop);
    this.stop = undefined;
  }

  startStatus(manifest) {
    // Don't start a new interval if we are already have one, this prevents memory leaks.
    this.checkStatus();
    const pollingInterval = 3000;
    this.stop = setInterval(this.checkStatus, pollingInterval);
  }

  checkStatus = async () => {
    if (this.currentlyUpdating !== false) {
      return;
    }
    // Set to prevent queuing of calls.
    this.currentlyUpdating = true;
    try {
      const response: any = await this.manifestService.getStatusManifest(this.manifestRoute);
      // Set to allow calls to resume.
      this.currentlyUpdating = false;
      if (response.status !== 404) {
        this.parseResponse(response);
      } else {
        // This handles the typical 404 error message after timeout or a hard failure somewhere like server reboot. Server should try 3 times to make this call before it gets caught in the catch below.
        this.getCount = this.getCount + 1;
        if (this.getCount >= 3) {
          this.clearAndShowError('error.timeout');
        }
      }

    } catch (error) {
      // If for some reason we get a JS error this handles stopping anything.
      this.currentlyUpdating = false;
      this.clearAndShowError('error.timeout');
      console.log(error);
    }
  }

  async parseResponse(response: Array<any>) {
    const hasError = response
      .some(i => i.transmissionStatusSummaryHL7 && i.transmissionStatusSummaryHL7.ERROR > 0);

    if (hasError) {
      this.clearAndShowError('error.hl7');
    }
  }

  clearAndShowError(error: string = 'error.general') {
    this.errorStatus = this.translate.instant(error);
  }

  singleFileUpload(files) {
    const formData = {
      'type': 'NONE',
      'diskPath': 'unknown',
      'clientDateCreated': '', // "yyyy-MM-dd'T'HH:mm:ss'Z'"
      'fileContent': '',
      'newlockKey': this.guidService.generateGuid()
    };

    return this.dataService.sendImage(files, formData, 0);
  }

  updateStudyDescription(selected: StudyType) {
    this.selectedStudyObject = selected;
    this.selectedStudy = this.selectedStudyObject.value;
  }

  async updateDescription(id: string, displayValue: string) {
    const queryUrl = this.manifestRoute + id;

    try {
      await this.dataService.updateStudyDescription(queryUrl, displayValue, this.selectedStudy);
    } catch (error) {
      throw error;
    }
  }

  // 'MRN|Last|First|Middle|Title|Suffix|DOBYear|DOBMonth|DOBDay|Operator|Accession'
  generateQrCode() {
    const dobProvided = !!this.params.dob;
    return `${this.params.mrn}|${this.params.lastName}|${this.params.firstName}|${this.params.middleName}|` +
      `${this.params.title}|${this.params.suffix}|${dobProvided ? this.params.dob.substring(0, 4) : ''}|` +
      `${dobProvided ? this.params.dob.substring(4, 6) : ''}|${dobProvided ? this.params.dob.substring(6, 8) : ''}|` +
      `${this.params.operator}|${this.accessionNumber}`;
  }

  showInstructions() {
    return this.customerConfigs.hasOwnProperty('show_instructions') ? this.customerConfigs.show_instructions : true;
  }

  hasCustomInstructions() {
    return this.customerConfigs.custom_instructions && this.customerConfigs.custom_instructions.length > 0;
  }

  getCustomInstructions() {
    return this.customerConfigs.custom_instructions;
  }

  ngOnDestroy() {
    this.reset();
    this.document.body.classList.remove(this.customerConfigs.theme);
    this.dataService.showNavigationWarning = false;

    clearInterval(this.ordersListener);
    this.ordersListener = null;
  }

  
}
