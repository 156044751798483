"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var translate_service_1 = require("../../translation/translate.service");
var data_service_1 = require("../../services/data.service");
var PatientDataConfirmationComponent = /** @class */ (function () {
    function PatientDataConfirmationComponent(dialogRef, dataService, data, translate) {
        this.dialogRef = dialogRef;
        this.dataService = dataService;
        this.data = data;
        this.translate = translate;
    }
    PatientDataConfirmationComponent.prototype.ngOnInit = function () {
        this.params = this.dataService.setupData(this.params);
        this.patientInfoConfig = [
            { label: 'patient.name', key: 'name', altKey: 'patientName' },
            { label: 'patient.dob', key: 'dobFormat', altKey: 'dob' },
            { label: 'patient.sex', key: 'gender', altKey: 'gender' },
            { label: 'patient.mrn', key: 'mrn', altKey: 'patientID' }
        ];
        this.studies = this.data.studies;
        this.params = this.data.params;
        this.patient = this.data.patient;
        this.manifestList = this.data.manifestList;
        this.validation = this.data.validation;
        this.providers = this.data.providers;
        this.specialties = this.data.specialties;
        this.showProviders = this.providers && this.providers.length > 0 && this.specialties && this.specialties.length > 0 && this.manifestList.some(function (i) { return i.overread; });
        this.submitDisabled = this.manifestList.some(function (m) { return !m.studyDescriptionObject; });
        if (this.providers) {
            this.setProvider();
        }
    };
    PatientDataConfirmationComponent.prototype.setProvider = function () {
        var _this = this;
        this.selectedProvider = this.providers.find(function (i) { return i.id === _this.params.prov; });
        if (this.selectedProvider) {
            this.filterProviders({ value: this.selectedProvider.specialties[0] });
        }
        this.selectedSpecialty = this.selectedProvider ? this.selectedProvider.specialties[0] : undefined;
    };
    PatientDataConfirmationComponent.prototype.filterProviders = function ($event) {
        this.providers = this.data.providers.filter(function (i) { return i.specialties.some(function (j) { return j === $event.value; }); });
        this.selectedProvider = this.providers.length > 0 ? this.providers[0] : undefined;
    };
    PatientDataConfirmationComponent.prototype.getPatientTooltip = function (patientInfoConfig) {
        var patientMessage = this.validation[patientInfoConfig.key + 'Message'];
        var altPatientMessage = this.validation[patientInfoConfig.altKey + 'Message'];
        return patientMessage ? patientMessage : altPatientMessage;
    };
    PatientDataConfirmationComponent.prototype.getPatientClass = function (patientInfoConfig) {
        var patientClass = this.validation[patientInfoConfig.key];
        var altPatientClass = this.validation[patientInfoConfig.altKey];
        return patientClass ? patientClass : altPatientClass;
    };
    PatientDataConfirmationComponent.prototype.getOverreadTooltip = function (manifest) {
        return manifest.overread ? this.translate.instant('validation.overread') + " \"" + manifest.reasonForStudy + "\"" : '';
    };
    PatientDataConfirmationComponent.prototype.isInPatientInfoConfig = function (patientInfoConfig) {
        return this.validation[patientInfoConfig.key] === 'validation-error' || this.validation[patientInfoConfig.altKey] === 'validation-error';
    };
    PatientDataConfirmationComponent.prototype.studyChange = function ($event, manifest) {
        manifest.studyDescription = $event.label;
        manifest.studyDescriptionValue = $event.value;
        manifest.studyDescriptionObject = $event;
        this.submitDisabled = this.manifestList.some(function (m) { return !m.studyDescriptionObject; });
    };
    PatientDataConfirmationComponent.prototype.submit = function () {
        this.dialogRef.close({
            submit: true,
            manifestList: this.manifestList,
            provider: this.selectedProvider
        });
    };
    PatientDataConfirmationComponent.prototype.cancel = function () {
        this.dialogRef.close({
            submit: false
        });
    };
    return PatientDataConfirmationComponent;
}());
exports.PatientDataConfirmationComponent = PatientDataConfirmationComponent;
