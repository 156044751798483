<mat-card>
  <header>
    <app-logo [logo]="customerConfigs.logo"></app-logo>
  </header>
  <section>
    <aside>
      <div>
        <strong>
          {{translate.instant('phrase.siteSelector')}}
        </strong>
      </div>
      <div>
        <mat-form-field>
          <mat-select [formControl]="reposSelect" [multiple]="true" #select>
            <mat-option class="search-sites">
              <ngx-mat-select-search [placeholderLabel]="translate.instant('button.search')" noEntriesFoundLabel=""
                [formControl]="reposFilter"></ngx-mat-select-search>
              <button mat-raised-button color='primary' class="search-sites__search-btn"
                [disabled]="loadingPatients || !reposSelect.value || reposSelect.value?.length <= 0"
                (click)="search()">{{translate.instant('phrase.searchSites')}}</button>
            </mat-option>
            <mat-option class="select-all-option" [value]="'Select All'">Select All</mat-option>
            <mat-option *ngFor="let repo of filteredRepos | async;" [value]="repo" class="site-list">
              {{repo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="site-section" *ngIf="repos?.data?.length > 1">
        <button mat-raised-button color='primary'
          (click)="search()">{{translate.instant('phrase.searchSites')}}</button>
      </div>
      <div>
        <app-destination-selector [inputDisabled]="true" [config]="destinationConfigs" serverPostLocation="PACS">
        </app-destination-selector>
      </div>
    </aside>

    <main>
      <div class="ehr-information" *ngIf="!disabled">
        <app-patient-information (changeLastName)="patientUpdated($event)" [patient]="patient"
          [allowEditLastName]="false">
        </app-patient-information>
      </div>

      <div *ngIf="errorStatus" class="error">
        {{errorStatus}}
      </div>
    </main>

    <mat-tab-group>
      <mat-tab label="Patients">
        <section class="messages">
          <div [class.finished]="status === UploadState.SUCCESS" id="finished">
            <span>
              {{translate.instant('word.success')}}
            </span>
          </div>
        </section>
        <div class="loading-patients" [class.resolving]="loadingPatients">
          <mat-progress-spinner [diameter]="25" color="accent" mode="indeterminate">
          </mat-progress-spinner>
        </div>
        <mat-accordion [class.unresolved]="loadingPatients" [multi]="true">
          <mat-expansion-panel #panel *ngFor="let patient of displayPatients;" (closed)="patientClosed(patient)"
            (opened)="patient?.studies?.length > 0 || patientOpened(patient)" [expanded]="true">
            <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
              <mat-panel-title>
                <table class="patient-header">
                  <thead>
                    <tr>
                      <th class="patient-name">{{translate.instant('table.patientname')}}</th>
                      <th class="patient-id">{{translate.instant('table.patientid')}}</th>
                      <th class="patient-site">{{translate.instant('table.site')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="patient-name" [class.has-mismatch]="hasMismatchName(patient)">
                        <span>{{patient.patientNameDisplay}}</span>
                      </td>
                      <td class="patient-id">
                        <span>{{patient.patientId}}</span>
                      </td>
                      <td class="patient-site">
                        <span>{{patient.siteName}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="match" *ngIf="patient?.studies?.length === 0">
                  {{patient.error}}
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <main>
              <div class="loading-studies" [class.resolving]="patient.loadingStudies">
                <mat-progress-spinner [diameter]="25" color="accent" mode="indeterminate">
                </mat-progress-spinner>
              </div>

              <ng-container *ngIf="patient?.studies?.length || patient.loadingStudies; else notFound">
                <div class="row top">
                  <div class="checkbox-column"></div>
                  <div class="description-column">{{translate.instant('table.study')}}</div>
                  <div class="modality-column">{{translate.instant('table.modalities')}}</div>
                  <div class="date-column">{{translate.instant('table.date')}}</div>
                  <div class="files-column">{{translate.instant('table.files')}}</div>
                  <div class="physician-name-column">{{translate.instant('table.referringphysician')}}</div>
                  <div class="overread-column">{{translate.instant('table.overread')}}</div>
                  <div class="counts-column"></div>
                </div>

                <div class="select" *ngFor="let study of patient.studies;">
                  <div class="row">
                    <div class="checkbox-column">
                      <mat-checkbox color="primary" [disabled]="captureInProgress"
                        [checked]="study.selected"
                        (change)="selectStudy($event, patient, study, existsTooltip)">
                      </mat-checkbox>
                    </div>
                    <div class="description-column" [matTooltip]="study.studyDescription" matTooltipPosition="above">
                      <div class="text">
                        {{study.studyDescription}}
                      </div>
                      <div class="loading-study" [class.resolving]="study.loading">
                        <mat-progress-spinner [diameter]="25" color="accent" mode="indeterminate">
                        </mat-progress-spinner>
                      </div>
                      <div class="study-exists" [class.resolved]="study.studyExists" [matTooltip]="study.studyExists ? translate.instant('phrase.study-exists') : ''" #existsTooltip="matTooltip">
                        <div class="mat-icon notranslate material-icons mat-icon-no-color">check</div>
                      </div>
                    </div>
                    <div class="modality-column">{{study.modalitiesInStudyDisplay}}</div>
                    <div class="date-column">{{study.studyDateDisplay}}</div>
                    <div class="files-column files-column--data">{{study.numberOfStudyRelatedInstances}}</div>
                    <div class="physician-name-column" [matTooltip]="study.referringPhysician"
                      matTooltipPosition="above">
                      <div class="text">
                        {{study.referringPhysician}}
                      </div>
                    </div>
                    <div class="overread-column">
                      <mat-checkbox (change)="selectOverread($event, study)" [(ngModel)]="study.overread"
                        color="primary">
                      </mat-checkbox>
                    </div>
                    <div class="counts-column" *ngIf="study.counts && !study.uploadFailed">
                      <span class="file-success" [matTooltip]="translate.instant('phrase.filesendsuccess')">
                        {{study.counts?.SUCCESS}}
                      </span>
                      <span class="file-processing" [matTooltip]="translate.instant('phrase.fileprocessing')">
                        {{study.counts?.PROCESSING}}
                      </span>
                      <span class="file-error" [matTooltip]="translate.instant('phrase.filesenderror')">
                        {{study.counts?.ERROR}}
                      </span>
                      <span class="file-total" [matTooltip]="translate.instant('phrase.filesprocessed')">
                        {{study.counts?.filesReceived}}&nbsp;/&nbsp;{{study.counts?.totalFiles}}
                      </span>
                    </div>
                    <div class="upload-failed-column" *ngIf="study.uploadFailed">
                      {{translate.instant('phrase.uploadFailed')}}
                    </div>
                  </div>
                  <mat-progress-bar [color]='study.progressColor' [mode]='study.progressMode'
                    [value]="study.progressPercentage" [bufferValue]="study.bufferPercentage"></mat-progress-bar>
                </div>
              </ng-container>
              <ng-template #notFound>
                <div>
                  {{translate.instant('patient.studyNotFound')}}
                </div>
              </ng-template>
            </main>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="form-footer">
          <button mat-raised-button color="accent" (click)="submit()"
            [disabled]="!anySelected() || loadingPatients || captureInProgress">{{translate.instant('button.retrieve')}}</button>
        </div>
      </mat-tab>
      <mat-tab label="Log Messages" [disabled]="!this.logData.data?.length">
        <div class="table-header">
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)"
              [placeholder]="translate.instant('phrase.filterlogs')" autocomplete="off">
          </mat-form-field>
        </div>
        <mat-table #table [dataSource]="logData">
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>{{translate.instant('table.status')}}</mat-header-cell>
            <mat-cell *matCellDef="let el;"> {{el.status}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="message">
            <mat-header-cell *matHeaderCellDef>{{translate.instant('table.message')}}</mat-header-cell>
            <mat-cell *matCellDef="let el;"> {{el.message}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="logColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: logColumns;"></mat-row>

        </mat-table>
        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]">
        </mat-paginator>
      </mat-tab>
    </mat-tab-group>

  </section>
  <footer>

  </footer>
</mat-card>