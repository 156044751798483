
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable, empty } from 'rxjs';

import { ConfigurationService } from '../services/configuration.service';
import { DataService } from '../services/data.service';

@Injectable()
export class StudyDescriptionResolver implements Resolve<Observable<any>> {
  constructor(
    private dataService: DataService,
    private configurationService: ConfigurationService) {}

  async resolve() {
    if (this.dataService.studyDescriptionSettings) {
      return this.dataService.studyDescriptionSettings;
    }

    const result = this.configurationService.getStudyDescription().catch(reason => {
      console.log('Study Descriptions not available:', reason);
      return empty();
    });

    this.dataService.studyDescriptionSettings = await result;
    return result;
  }

}
