"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANG_NL_NAME = 'nl';
exports.LANG_NL_TRANSLATIONS = {
    'word.processing': 'Verwerken',
    'word.cancelled': 'Geannul',
    'word.failure': 'Mislukt',
    'word.success': 'Succes',
    'word.connected': 'Verbonden',
    'word.files': 'Bestanden',
    'word.destination': 'Bestemming',
    'word.current': 'Huidig',
    'word.updated': 'Bijgewerkt',
    'word.instructions': 'Instructies',
    'word.study-match': 'Gesugg',
    'word.specialties': 'Specialiteiten',
    'word.providers': 'Providers',
    'word.duration': 'Duur',
    'word.hours': 'uur',
    'word.results': 'Resultaten',
    'word.done': 'Klaar',
    'word.sensitive': 'Gevoelig',
    'word.selected': 'Geselecteerd',
    'word.apply': 'Toepassen',
    'word.anterior': 'Voorste',
    'word.posterior': 'Achterste',
    'word.name': 'naam',
    'word.size': 'grootte',
    'word.bytes': 'bytes',
    'word.date': 'datum',
    'phrase.disconnected': 'Apparaat losgekoppeld',
    'phrase.install': 'De mobiele app installeren?',
    'phrase.uploadFailed': 'Uploaden mislukt!',
    'phrase.scanPartOne': 'Scan de QR-code met uw apparaat, zoek voor ',
    'phrase.scanPartTwo': ' in de app-winkel, of navigeer naar ',
    'phrase.scanPartThree': ' van uw telefoon.',
    'phrase.overreadnotavailable': 'Overreads zijn alleen beschikbaar wanneer verzonden naar het Lokale archief (PACS/VNA}',
    'phrase.study': 'Onderzoek:',
    'phrase.selectall': 'Alles selecteren',
    'phrase.selectdir': 'Kies een map',
    'phrase.selectfiles': 'Bestanden selecteren',
    'phrase.folder': 'Map selectie',
    'phrase.file': 'Selectie meerdere bestanden',
    'phrase.ehr': 'EHR-patiëntinformatie',
    'phrase.ehrDoctor': '',
    'phrase.navigation': 'Waarschuwing: U staat op het punt om deze toepassing te verlaten',
    'phrase.patientinfo': 'Patiëntinformatie',
    'phrase.accession': 'Uw toegangsnummer is',
    'phrase.cancelExam': 'Waarschuwing: U staat op het punt om dit onderzoek te annuleren',
    'phrase.pinCode': 'Pincode',
    'phrase.selectSites': 'Selecteer sites',
    'phrase.searchSites': 'Zoek sites',
    'phrase.siteSelector': 'Selectie sites',
    'phrase.op': '',
    'phrase.selectedStudy': 'Geselecteerd',
    'phrase.newStudy': 'Nieuw onderzoek',
    'phrase.reasonOverread': 'Redenen voor overread',
    'phrase.filesendsuccess': 'Bestand met succes verstuurd',
    'phrase.fileprocessing': 'Bestand wordt verwerkt',
    'phrase.filesenderror': 'Bestand verzenden mislukt',
    'phrase.filesprocessed': 'Aantal bestanden verwerkt',
    'phrase.filterlogs': 'Filter logboek',
    'phrase.authorizingprovider': 'Aanbieder autoriseren',
    'phrase.transferstatus': 'Status overdracht:',
    'phrase.studyTag': 'Nuance onderzoekslabels',
    'phrase.search': 'Zoekparameters',
    'phrase.study-exists': 'Onderzoek bestaat al in uw archief.',
    'phrase.addToExistingStudy': 'Voeg toe aan bestaand onderzoek',
    'phrase.selectExistingStudy': 'Selecteer bestaand onderzoek',
    'phrase.filenotinwhitelist': 'Sommige onderzoeken bevatten SOP-classen die buiten de "witte" lijst vallen',
    'phrase.fileinblacklist': 'Sommige onderzoeken bevatten SOP-klassen die op de "zwarte" lijst staan.',
    'patient.name': 'Naam:',
    'patient.firstName': 'Voornaam:',
    'patient.lastName': 'Achternaam:',
    'patient.dob': 'Geboortedatum:',
    'patient.sex': 'Geslacht:',
    'patient.mrn': 'Medisch dossiernr.:',
    'patient.unknown': 'Onbekende patiënt',
    'patient.alternateLastName': 'Alternatieve achternaam:',
    'patient.notFound': 'Patiënt niet gevonden',
    'patient.studyNotFound': 'Patiëntonderzoeken niet gevonden.',
    'button.submit': 'Bestanden indienen',
    'button.stop': 'Upload stoppen',
    'button.cancel': 'Annuleren',
    'button.begin': 'Upload beginnen',
    'button.nevermind': 'Laat maar',
    'button.continue': 'Doorgaan',
    'button.confirm': 'Bevestigen en doorgaan',
    'button.capture': 'Voorbereiden om vast te leggen',
    'button.endexam': 'Onderzoek eindigen',
    'button.confirmReason': 'Bevestig reden voor onderzoek',
    'button.reason': 'Reden voor het examen',
    'button.cancelExam': 'Onderzoek annuleren',
    'button.close': 'Afsluiten',
    'button.generate': 'Genereren',
    'button.pinCode': 'Pincode',
    'button.search': 'Zoeken',
    'button.retrieve': 'Opvragen en opslaan',
    'button.refresh': 'Vernieuwen',
    'button.createNewOrder': 'Maak Nieuwe bestelling aan',
    'button.backToOrders': 'Terug naar bestellingen',
    'button.reset': 'Reset',
    'table.filename': 'Bestandsnaam',
    'table.message': 'Bericht',
    'table.filesize': 'Bestandsgrootte in bytes',
    'table.filedate': 'Bestandsdatum',
    'table.patientname': 'Naam patiënt',
    'table.patientid': 'ID patiënt',
    'table.patientsex': 'Geslacht:',
    'table.patientdob': 'Geboortedatum patiënt',
    'table.patientage': 'Leeftijd',
    'table.site': 'Site',
    'table.study': 'Beschrijving onderzoek',
    'table.facility': 'Faciliteit',
    'table.studyInstanceUID': 'Onderzoekgeval UID',
    'table.seriesInstanceUID': 'Seriegeval UID',
    'table.series': 'Seriebeschrijving',
    'table.physician': 'Arts',
    'table.referringphysician': 'Verwijzende arts',
    'table.archive': 'Archief',
    'table.status': 'Status',
    'table.accessionNumber': 'Toegangsnummer',
    'table.modality': 'Modaliteit',
    'table.modalities': 'Modaliteiten',
    'table.date': 'Datum onderzoek',
    'table.seriesDate': 'Seriedatum',
    'table.files': '# bestanden',
    'table.overread': 'Overread',
    'table.bodyPart': 'Lichaamsdeel',
    'table.notSpecified': '[Niet gespecificeerd]',
    'about.dialogPartOne': 'De mobiele app is niet bedoeld voor het beheren',
    'about.dialogPartTwo': 'van of het diagnostisch weergeven van beelden',
    'about.link': 'Over',
    'about.build_date': 'Bouwdatum: ',
    'about.help_text': 'Deze app wordt gebruikt om beelden en video\'s te importeren in een beeldvormingssysteem voor ziekenhuizen Bekijk meer op www.imagemovermd.com.\n\nDe ImageMover mobiele app is niet bedoeld voor het beheren van of de  diagnostische weergave van beelden.\n\n',
    'about.non_diagnostic': 'is niet bestemd voor diagnostische, bewakings- of behandelingsdoeleinden, of op enige andere wijze voor de reguliere medische praktijk.',
    'about.rights': 'Alle rechten voorbehouden.',
    'about.copyright': 'Reproductie of transmissie, geheel of gedeeltelijk, in welke vorm of op welke manier dan ook, elektronisch, mechanisch of anderszins, is verboden zonder de voorafgaande schriftelijke toestemming van de eigenaar van het auteursrecht.',
    'about.sw': 'Copyrights en alle overige eigendomsrechten in alle software en bijbehorende documenatie ("Software") die u ter beschikking is gesteld, blijven het exclusieve eigendom van ImageMoverMD of haar licentiegevers. Geen enkele aanspraak of eigendom in de Software wordt aan u overgedragen. Gebruik van de Software is onderhevig aan de voorwaarden van de eindgebruikerslicentie, die op verzoek verkrijgbaar is.',
    'about.engineering': 'Het is u niet toegestaan de software of enig onderdeel daarvan te onderwerpen aan decompilatie of reverse engineering, tenzij anders door de wet bepaald.',
    'error.timeout': 'Er is een time-out opgetreden voor uw verbinding of de verbinding werd gereset Druk op F5 om de pagina te vernieuwen, of klik op de knop herladen van uw browser om door te gaan. Als u problemen blijft ondervinden, neem dan contact op met de ondersteuning.',
    'error.manifest': 'Uw manifest kon niet worden aangemaakt; als u deze pagina vanuit uw toepassing begon, probeer het dan opnieuw.',
    'error.session': 'Uw sessie kon niet worden gestart: als u deze pagina vanuit uw toepassing begon, probeer het dan opnieuw.',
    'error.badcancelled': 'Uw upload is gestopt.',
    'error.general': 'Het spijt ons, er is een fout opgetreden.',
    'error.hl7': 'HL7-bericht overdracht mislukt',
    'instruction.confirmation': 'Patiëntinformatie over de geselecteerde onderzoeken om te importeren zullen worden bijgewerkt zoals hierboven getoond, zodat deze overeenkomt met de patiëntinformatie van de EHR Door op de knop Bevestigen en Doorgaan te klikken, bevestigt u dat de geïmporteerde onderzoeken  deze van de patiënt horen zijn.',
    'instruction.navigation': 'De toepassing zal opnieuw worden gereset als u overschakelt naar een andere toepassing. Een voltooide activiteit wordt niet beïnvloed maar een nieuwe activiteit zal worden gestopt. Klik op Doorgaan om de toepassing te veranderen, of klik op Laat maar om in de huidige toepassing te blijven.',
    'instruction.patients': 'Onderzoeken van meerdere patiënten in een patiëntdossier uploaden is niet toegestaan. Kies slechts één patiënt voordat u verder gaat.',
    'instruction.modalityPartOne': '1 Kies de geschikte onderzoeksbeschrijving.',
    'instruction.modalityPartTwo': '2 Klik de knop \'Klaarmaken voor Vastleggen\'',
    'instruction.capturePartOne': 'Wijs uw iOS of Android app naar deze',
    'instruction.capturePartTwo': 'QR-code om de beelden vast te leggen.',
    'instruction.roundsPartOne': 'Wijs uw',
    'instruction.roundsPartTwo': 'Wijs uw  iOS of Android app naar deze QR-code om de beelden vast te leggen.',
    'instruction.pinCode': 'Klik op de knop onderaan om een code te generen om te delen met uw externe gebruiker.',
    'instruction.updateManifest': '',
    'instruction.modalityPartThree': 'Er wordt een order aangemaakt en naar de EHR gestuurd.',
    'instruction.modalityPartFour': 'Selecteer de order uit de werklijst op uw modaliteit en begin met uw onderzoek.',
    'instruction.accession': 'Klik \'Onderzoek beëindigen\' wanneer de beeldverwerving voltooid is.',
    'validation.namematch': 'Achternaam is een exacte overeenkomst.',
    'validation.namepartial': 'Achternaam is een mogelijke overeenkomst.',
    'validation.namemismatch': 'Achternaam heeft geen overeenkomst.',
    'validation.gendermatch': 'Geslacht is een exacte match.',
    'validation.genderpartial': 'Geslacht is niet inbegrepen of genoemd als ander.',
    'validation.gendermismatch': 'Geen overeenkomst met geslacht.',
    'validation.dobmatch': 'Geboortedatum is een exacte overeenkomst.',
    'validation.dobpartial': 'Geboortedatum is niet inbegrepen of werd geanonimiseerd.',
    'validation.dobmismatch': 'Geboortedatum heeft geen overeenkomst.',
    'validation.overread': 'Onderzoek is gepland voor een overread om een reden',
    'validation.failedOverread': 'Geen beschrijvingen voor overread gevonden.',
    'validation.failedModality': 'Geen beschrijvingen voor modaliteit gevonden.',
    'validation.noModalities': 'Geen modaliteiten geselecteerd',
    'validation.required': 'Vereist veld',
    'dicom.location132': 'Vereist voorvoegsel niet gevonden op locatie 132; dit is geen DICOM-bestand.',
    'dicom.toosmall': 'Bestand is te klein om een geldig DICOM-bestand te zijn.',
    'dicom.invalidresponse': 'Veld x00 100020 bevatte een ongeldig antwoord en werd overgeslagen.',
    'dicom.skipped': 'bestandtype wordt beschouwd als een systeembestand en werd overgeslagen.',
    'dicom.enabled': 'is momenteel niet ingeschakeld en werd overgeslagen.',
    'dicom.pinTitle': 'Pincode voor het uploaden van een directory',
    'dicom.syncDesc': 'Met de pincode voor het uploaden van een directory kunt u uw sessie en de patiënteninformatie veilig overdragen naar een andere browser.',
    'dicom.notIE': 'Momenteel ondersteunt Microsoft Internet Explorer niet de selectie van directories of mappen van het Windows mappensysteem.  Dit vereist dat in Internet Explorer bestanden individueel worden geselecteerd of door middel van toetsenbordsneltoetsen (zoals Shift+Click of Ctrl+A)',
    'dicom.useOther': 'ImageMoverMD ondersteunt de capaciteit om hele directories/mappen te verwerken wanneer ze in combinatie met internetbrowsers worden gebruikt die deze functionaliteit ondersteunen.  Populaire browsers die deze functionaliteit ondersteunen zijn onder andere Google Chrome en Mozilla Firefox.',
    'dicom.pinNavigate': 'Om toegang te krijgen tot uw huidige sessie en patiënteninformatie in een ondersteunde browser, opent u de browser en navigeert u naar',
    'dicom.enterPin': 'Daar wordt u gevraagd om de 4-cijferige pincode voor het uploaden van directories (die ook hieronder wordt vermeld).   Voer gewoon de pincode in en de browser haalt uw sessie op, en dan kunt u beginnen met het uploaden van uw directories/mappen.',
    'dicom.copyURL': 'Kopieer URL naar klipbord',
    'dicom.copyURLConfirm': 'URL gekopieerd naar het klipboard',
    'dicom.refreshMessage': 'Uw pincode is vervallen en moet worden vernieuwd.  Om een nieuwe pincode te krijgen, klik op ',
    'dicom.startOver': 'Opnieuw beginnen',
    'dicom.goBackStartOver': 'Er is een fout opgetreden bij het uploaden van uw bestanden. Klik op Klaar en voer een nieuwe sync code in om het opnieuw te proberen.',
    'dicom.uploadSuccessMsg': 'Uploads geslaagd! Klik op Klaar om met uw volgende upload te starten',
    'dicom.successUpload': 'Geslaagde uploads',
    'dicom.failedUpload': 'Uploads mislukt',
    'dicom.filesUploaded': 'Bestanden zijn verwerkt',
    'media.allSensitive': 'Alle instellen als gevoelig',
    'media.sensitiveStudy': 'Gevoelig onderzoek',
    'media.change': 'verandering',
    'media.apply-to-unspecified': 'van toepassing op niet gespecificeerd',
    'media.not-anatomical': 'Niet anatomisch',
    'media.select-different-body-part': 'Select A Different Body Part',
    'media.body-part-required': 'Lichaamsdeel vereist voor elk bestand',
    'media.not-applied-study-description': 'Niet toegepast op studiebeschrijving',
    'media.tooltip.generating-thumbnail': 'Miniatuur genereren',
    'media.tooltip.error-loading-thumbnail': 'Fout bij het laden van afbeeldingsminiatuur',
    'transfer.title': 'Enter Session Pin Code',
    'transfer.wait': 'Retrieving session information, please wait',
    'transfer.error': 'Invalid Session Pin Code.  Please check the code listed in the uploader and try again',
    'sync.title': 'Voer de pincode van uw sessie in ',
    'sync.loading': 'Bezig met ophalen van sessie-informatie, even wachten a.u.b.',
    'sync.invalid': 'Ongeldige pincode voor sessie  Controleer de code die vermeld is in de uploader en probeer het opnieuw',
    'sync.nextUploadTooltip': 'Klik op Klaar om nieuwe upload te starten',
    'dicom.available': 'DICOM-bestanden beschikbaar om te importeren.',
    'dicom.nonconformant': 'niet conforme DICOM bestanden.',
    'dicom.mediaskipped': 'mediabestanden overgeslagen.',
    'dicom.mediaready': 'ediabestanden beschikbaar om te importeren en voor conversie.',
    'dicom.fileskipped': 'bestanden overgeslagen',
    'dicom.notmedia': 'Bestand overgeslagen omdat het geen media is.',
    'dicom.nomedia': 'Mediabestand overgeslagen',
    'radio.pacs': 'Lokaal archief (PACS/VNA)',
    'radio.powershare': 'PowerShare Network',
    'radio.xdsb': 'XDS.b repository',
};
