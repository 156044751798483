<div>
  {{translate.instant('phrase.cancelExam')}}
</div>
<div class="form-footer">
  <button mat-button color='primary' (click)="close(false)">
    {{translate.instant('button.close')}}
  </button>
  <button mat-raised-button color='accent' (click)="close(true)">
    {{translate.instant('button.cancelExam')}}
  </button>
</div>