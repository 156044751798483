import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

  constructor() { }

  createQrCode(manifest: any, isManifest: boolean = true): string {
    const location = isManifest ? 'manifest' : 'session'
    return `http://www.imagemovermd.com/?${location}=${manifest.self}#install`;
  }
}
