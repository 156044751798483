import { SessionService } from './session.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export interface OrderTable {
  timestamp: string;
  timestampDisplay?: string;
  accessionId: string;
  studyDescription: string;
  reasonForStudy: string;
  encDept: string;
  authProvider: string;
  status: 'Sending' | 'Pending' | 'Cancelled' | 'Completed' | 'Failure';
  manifestId: number;
  sessionKey: number;
  appName: 'Modality' | 'Mobile Quick Order';
}

@Injectable({
  providedIn: 'root'
})
export class ModalityService {

  baseUrl: string;

  constructor(
    private http: HttpClient) {}

  enableMocksLocally(): Boolean {
    return !environment.production;
  }

  endExam(baseUrl, sessionKey, manifestId) {
    return this.doModalityAction(baseUrl, sessionKey, manifestId, 'endExam');
  }

  cancelExam(baseUrl, sessionKey, manifestId) {
    return this.doModalityAction(baseUrl, sessionKey, manifestId, 'cancelExam');
  }

  getOpenOrders(mrn, baseUrl): Promise<Array<OrderTable>> {
    if (false && this.enableMocksLocally()) {
      const data: Array<OrderTable> = [
        {
          accessionId: 'MJC999000000128',
          appName: 'Modality',
          authProvider: 'Jeffrey Englewood MD',
          encDept: 'Emergency',
          reasonForStudy: 'Lacerations of the ankle',
          timestamp: '2020-03-16T11:38:04.562-05:00',
          status: 'Pending',
          studyDescription: 'Patient Photo',
          manifestId: 1,
          sessionKey: 1
        },
        {
          accessionId: 'MJC999000000127',
          appName: 'Modality',
          authProvider: 'Alex Smith MD',
          manifestId: 1,
          reasonForStudy: 'POC stuff',
          sessionKey: 2,
          timestamp: '2020-03-16T11:39:04.562-05:00',
          studyDescription: 'POC ULTRASOUND',
          encDept: 'Dermatology',
          status: 'Completed',
        },
        {
          accessionId: 'MJC999000000126',
          appName: 'Modality',
          authProvider: 'First^Last',
          manifestId: 1,
          reasonForStudy: 'derm issues',
          sessionKey: 3,
          timestamp: '2020-03-16T11:40:04.562-05:00',
          studyDescription: 'DERM PHOTO',
          encDept: 'ICU',
          status: 'Failure'
        },
        {
          accessionId: 'MJC999000000134',
          appName: 'Modality',
          authProvider: 'First^Last',
          encDept: 'Derm',
          manifestId: 1,
          reasonForStudy: 'asdf',
          sessionKey: 1,
          status: 'Pending',
          studyDescription: 'DERM PHOTO',
          timestamp: '2020-03-16T11:41:04.562-05:00',
        },
        {
          'accessionId': 'MJC999000000137',
          'appName': 'Modality',
          'authProvider': 'First^Last',
          'encDept': 'Derm',
          'manifestId': 2,
          'reasonForStudy': 'asdf',
          'sessionKey': 2,
          'status': 'Failure',
          'studyDescription': 'DERM PHOTO',
          timestamp: '2020-03-16T11:49:04.562-05:00',
      },
      {
          'accessionId': 'MJC999000000136',
          'appName': 'Modality',
          'authProvider': 'Test^Test',
          'encDept': 'Derm',
          'manifestId': 1,
          'reasonForStudy': 'asdf',
          'sessionKey': 1,
          'status': 'Pending',
          'studyDescription': 'DERM PHOTO',
          timestamp: '2020-03-16T12:38:04.562-05:00',
      }
      ];
      return Promise.resolve(data);
    }

    return this.http.get(`${baseUrl}patient/getOrdersPerMRN/${mrn}`).toPromise() as Promise<Array<OrderTable>>;
  }

  private doModalityAction(baseUrl, sessionKey, manifestId, action) {
    const url = `${baseUrl}patient/${action}/${sessionKey}/${manifestId}`;
    return this.http.post(url, {}).toPromise();
  }

  private toQueryString(paramsObject) {
    return Object
      .keys(paramsObject)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
      .join('&');
  }

}
