"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./transfer-confirm-modal.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/a11y");
var i5 = require("@angular/platform-browser/animations");
var i6 = require("@angular/material/dialog");
var i7 = require("@angular/common");
var i8 = require("./transfer-confirm-modal.component");
var i9 = require("@angular/material/snack-bar");
var i10 = require("../../translation/translate.service");
var styles_TransferConfirmModalComponent = [i0.styles];
var RenderType_TransferConfirmModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TransferConfirmModalComponent, data: {} });
exports.RenderType_TransferConfirmModalComponent = RenderType_TransferConfirmModalComponent;
function View_TransferConfirmModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "red-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translate.instant("dicom.refreshMessage"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.translate.instant("button.refresh"); _ck(_v, 3, 0, currVal_1); }); }
function View_TransferConfirmModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["color", "warn"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(2, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_4 = "warn"; _ck(_v, 1, 0, currVal_4); var currVal_5 = "refresh"; _ck(_v, 2, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 2).type; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = _co.translate.instant("button.refresh"); _ck(_v, 3, 0, currVal_6); }); }
function View_TransferConfirmModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransferConfirmModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "actions mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i6.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""], ["mat-outline", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(10, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], null, null), (_l()(), i1.ɵted(11, 0, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransferConfirmModalComponent_2)), i1.ɵdid(13, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(16, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.data == null) ? null : _co.data.tokenExpired); _ck(_v, 5, 0, currVal_1); var currVal_6 = "primary"; _ck(_v, 9, 0, currVal_6); _ck(_v, 10, 0); var currVal_8 = ((_co.data == null) ? null : _co.data.tokenExpired); _ck(_v, 13, 0, currVal_8); var currVal_11 = "primary"; _ck(_v, 15, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userMessage; _ck(_v, 3, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = _co.translate.instant("button.close"); _ck(_v, 11, 0, currVal_7); var currVal_9 = (i1.ɵnov(_v, 15).disabled || null); var currVal_10 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_9, currVal_10); var currVal_12 = _co.translate.instant("dicom.copyURL"); _ck(_v, 16, 0, currVal_12); }); }
exports.View_TransferConfirmModalComponent_0 = View_TransferConfirmModalComponent_0;
function View_TransferConfirmModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-transfer-confirm-modal", [], null, null, null, View_TransferConfirmModalComponent_0, RenderType_TransferConfirmModalComponent)), i1.ɵdid(1, 49152, null, 0, i8.TransferConfirmModalComponent, [i6.MAT_DIALOG_DATA, i9.MatSnackBar, i10.TranslateService], null, null)], null, null); }
exports.View_TransferConfirmModalComponent_Host_0 = View_TransferConfirmModalComponent_Host_0;
var TransferConfirmModalComponentNgFactory = i1.ɵccf("app-transfer-confirm-modal", i8.TransferConfirmModalComponent, View_TransferConfirmModalComponent_Host_0, {}, {}, []);
exports.TransferConfirmModalComponentNgFactory = TransferConfirmModalComponentNgFactory;
