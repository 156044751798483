import { FooterComponent } from './footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutDialogComponent } from '../../modals/about-dialog/about-dialog.component';
import { TranslateService } from '../../translation/translate.service';
import { TRANSLATION_PROVIDERS } from '../../translation/translations';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DataService } from '../../services/data.service';
import { LogoModule } from '../logo/logo.module';


@NgModule({
  declarations: [
    FooterComponent,
    AboutDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    PdfViewerModule,
    LogoModule
  ],
  providers: [
    TranslateService,
    DataService,
    TRANSLATION_PROVIDERS,
  ],
  exports: [FooterComponent],
  entryComponents: [
    AboutDialogComponent
  ]
})
export class FooterModule { }
