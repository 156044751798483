import { Patient } from "../classes/patient";
import { Dicom } from '../classes/dicom-file';

/**
 * Collection of tools to work with DICOM files
 */
export class DicomUtils {

  // "Default Date" of 01/01/0001
  static DEFAULT_DATE = '00010101';

  /**
   * Mapping of the human readable strings to DICOM codes and how strict the ingestion should be
   * This is based off of the file parse service logic in pre-v4.8 which was directly checking 
   * individual keys from the DICOM file.  Since that logic was repeated, it made sense to
   * make a helper function which consumes the field and a configuration object and produces a
   * response instead.  This also centralized the list of DICOM fields that IM uses in our application
   * and allows making changes in one place to add/remove fields rather than multiple
   * 
   * Source for flags: https://github.com/WITS-MD/ImageMover-Html-v2/blob/e46a1889e63d0954d83439e48ad5f12c59104b30/src/app/services/file-parse.service.ts#L357
   * 
   * 
   *  Example: 
   *   -- Old Way:
   *    if ((typeof response.x0020000e !== 'object' && response.x0020000e !== null) !== false) {
   *       file.seriesUID = response.x0020000e;
   *    }
   * 
   *   -- New Way
   *    if (this.isValidField('seriesUID')) {
   *       file.seriesUID = this.getDICOMField('seriesUID');
   *    }
   */
  public static USED_DICOM_TAGS = new Map<string, IDicomFieldConfig>([
    ['patientID', { code: 'x00100020', allowObject: false, allowNull: false, allowUndefined: true }], 
    ['patientName', { code: 'x00100010', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['dob', { code: 'x00100030', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['gender', { code: 'x00100040', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['age', { code: 'x00101010', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['studyUID', { code: 'x0020000d', allowObject: false, allowNull: false, allowUndefined: true }], 
    ['seriesUID', { code: 'x0020000e', allowObject: false, allowNull: false, allowUndefined: true }], 
    ['uid', { code: 'x00080018', allowObject: false, allowNull: false, allowUndefined: true }], 
    ['sopClass', { code: 'x00080016', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['studyDate', { code: 'x00080020', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['modality', { code: 'x00080060', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['studyDescription', { code: 'x00081030', allowObject: false, allowNull: false, allowUndefined: false }], 
    ['studyAccession', { code: 'x00080050', allowObject: false, allowNull: false, allowUndefined: false}]
  ]);

  /**
   * Extracts DICOM field's from a DICOM file that are listed in the DicomUtils.USED_DICOM_TAGS 
   * @param dataSet DICOM data set
   * @returns 
   */
  public static extractDicomData(dataSet: { [key: string]: any }): { [key: string]: string } {
    return Array.from(this.USED_DICOM_TAGS.values()).reduce((output, current) => {
      // Check if the dataset has the DICOM field.  Ex: x00080050 => Accession #
      if (dataSet.elements[current.code]) {
        const element = dataSet.elements[current.code];
        const hasItemsOrFragments = element.items && element.fragments;

        if (!hasItemsOrFragments && element.length < 256) {
          const value = dataSet.string(current.code);
          const stringIsASCII = /^[\x00-\x7F]*$/.test(value);
          
          if (stringIsASCII && value) {
            output[current.code] = value;
          } 
        }
      }

      return output;
    }, {});
  }

  /**
   * Updates a DICOM file object with details stored in the dicom fields.  This is in
   * turn used by the application for both displaying details about the file and for
   * submission to Pronghorn
   * @param file File object which is used by the application
   * @param parsedDICOM Internal DICOM of that file
   * @param invalidDICOMCode Code to provide IF the file is not DICOM
   */
  public static parseDicomFile(file: any, parsedDICOM: any, invalidDICOMCode: number) {

    if (this.isObject(this.getDICOMField(file, 'patientID'))) {
      throw new DicomFileError('Invalid patient ID field in file', invalidDICOMCode); 
    }

    file.mediaType = 'DICOM';
    file.label = parsedDICOM.name;
    file.selected = true;
    file.sopClass = this.getDICOMField(parsedDICOM, 'sopClass');

    if (this.isValidField(parsedDICOM, 'uid')) {
      file.UID = this.getDICOMField(parsedDICOM, 'uid');
      
      // If the fileUID has a period in it we should set that as the file id as well,
      // otherwise use patientId as the file id
      if (file.UID.indexOf('.') > 0) {
        file.id = `u${file.UID.replace(/\./, '')}`
      } else {
        file.id = `u${parsedDICOM.patientId}`
      }
    }

    if (this.isValidField(parsedDICOM, 'studyDate')) {
      file.studyDate =  this.parseDICOMDate(parsedDICOM, 'studyDate');
    }

    if (this.isValidField(parsedDICOM, 'modality')) {
      file.modality = this.getDICOMField(parsedDICOM, 'modality');
    }

    if (this.isValidField(parsedDICOM, 'studyDescription')) {
      file.studyDescription = this.getDICOMField(parsedDICOM, 'studyDescription');
    }

    if (this.isValidField(parsedDICOM, 'patientName')) {
      file.patientNameObject = Patient.parseName(this.getDICOMField(parsedDICOM, 'patientName'))
      file.patientName = file.patientNameObject.firstName === ''
        ? file.patientNameObject.lastName
        : `${file.patientNameObject.lastName}, ${file.patientNameObject.firstName}`; 
    } else {
      file.patientNameObject = Patient.parseName(this.getDICOMField(parsedDICOM, 'patientName'))
      file.patientName = 'Unknown Patient'
    }

    if (this.isValidField(parsedDICOM, 'patientID')) {
      file.patientID = this.getDICOMField(parsedDICOM, 'patientID') || 'Unknown.';
    }

    if (this.isValidField(parsedDICOM, 'dob')) {
      file.dob = this.parseDICOMDate(parsedDICOM, 'dob') || 'Unknown';
    }

    if (this.isValidField(parsedDICOM, 'gender')) {
      file.gender = this.getDICOMField(parsedDICOM, 'gender') || ' ';
    }

    if (this.isValidField(parsedDICOM, 'age')) {
      file.age = this.getDICOMField(parsedDICOM, 'age') || ' ';
    }

    if (this.isValidField(parsedDICOM, 'studyUID')) {
      file.studyUID = this.getDICOMField(parsedDICOM, 'studyUID') || 'Unknown.';
    }

    if (this.isValidField(parsedDICOM, 'seriesUID')) {
      file.seriesUID = this.getDICOMField(parsedDICOM, 'seriesUID');
    }

    if (this.isValidField(parsedDICOM, 'studyAccession')) {
      file.studyAccession = this.getDICOMField(parsedDICOM, 'studyAccession') || '';
    }
  }

  private static getDICOMField(file, fieldName: string) {
    return file[this.USED_DICOM_TAGS.get(fieldName).code];
  }

  private static isObject(input: any) {
    return typeof input === 'object';
  }

  private static isNotNull(input: any) {
    return input !== null;
  }

  private static isNotUndefined(input: any) {
    return input !== undefined;
  }

  /**
   * Finds a specific field in the DICOM file and then reviews the configs to determine
   * if the value meets the configured requirements for that field.  This will be checking
   * if the field is an object or primitive, if the field is null, and if the field is undefined.
   * This ensures that other falsy values that JS recognizes are not incorrectly identified as invalid
   * @param dicom JSON version fo the DICOM file with the allowed fields
   * @param field String version of the JSON field.  @see USED_DICOM_TAGS
   * @returns True if the field exists in the file and passes the field validation
   */
  public static isValidField(dicom: any, fieldName: string): boolean {
    // Get field from the map
    const field = this.USED_DICOM_TAGS.get(fieldName)
    
    // Avoid undefined property access errors we should return early
    // as false if there is no matching field.
    if (!field) {
      return false;
    }
    
    const value = dicom[field.code];

    // These checks ensure that the 
    const isNotObject = !field.allowObject ? !this.isObject(value) : true;
    const isNotNull = !field.allowNull ? this.isNotNull(value) : true;
    const isNotUndefined = !field.allowUndefined ? this.isNotUndefined(value) : true;

    return isNotObject && isNotUndefined && isNotNull;
  }

  private static parseDICOMDate(dicom: any, fieldName: string) {
    const value = this.getDICOMField(dicom, fieldName);

    return value === this.DEFAULT_DATE
      ? this.DEFAULT_DATE
      : Patient.parseDate(value);
  }

}

interface IDicomFieldConfig {
  code: string;
  allowObject: boolean;
  allowNull: boolean;
  allowUndefined: boolean;
}

class DicomFileError extends Error {
  code: number;

  constructor(message: string, code: number) {
    super(message);
    this.name = 'DicomFileError';

    this.code = code;
  }
}