import { MatRadioChange } from '@angular/material/radio';
import { NuanceService } from '../services/nuance.service';


export default abstract class AbstractUploaderPage {
  protected abstract nuanceService: NuanceService;

  protected abstract customerConfigs: any;
  protected abstract params: any;

  public nuanceDestinations: any[] = [];

  /**
   * Gets the list of nuance repositories from pronghorn on request IF the configs.json has nuance enabled
   * for the specific application
   */
  async getNuanceDestinations(): Promise<void> {
    if (this.customerConfigs.nuance && this.nuanceDestinations.length === 0) {
      this.nuanceDestinations = await this.nuanceService.destinations();
    }
  }

  /**
   * Triggered by a radio button change, will update the dicom destination
   * for the studies/images being sent from the browser.
   * @param $event Change event triggered by the radio button collection
   */
  async setServerPostLocation($event: MatRadioChange): Promise<void> {
    this.params.sendToNuance = $event.value === 'Nuance';
    this.params.sendToXDSb = $event.value === 'XDSb';
    this.params.sendToPACS = $event.value === 'PACS';

    if ($event.value === 'Nuance') {
      this.getNuanceDestinations();
    }
  }
}