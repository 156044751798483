import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '../../translation/translate.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-destination-selector',
  templateUrl: './destination-selector.component.html',
  styleUrls: ['./destination-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestinationSelectorComponent implements OnInit {

  @Input() serverPostLocation;
  @Input() config;
  @Input() inputDisabled;
  @Input() nuanceDestinations;
  @Input() selectedDestination;
  @Input() nuanceStudyTagIds;
  @Output() change = new EventEmitter();
  @Output() serverPostLocationChange = new EventEmitter();
  @Output() selectedDestinationChange = new EventEmitter();
  @Output() nuanceStudyTagIdsChange = new EventEmitter();

  displayOnly: boolean;

  destination = {
    studyTags: {
      studyTag: []
    }
  };

  constructor(
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.displayOnly = [this.config.pacs, this.config.nuance, this.config.xdsb].filter(i => i).length < 2;
  }

  changed($event: MatRadioChange) {
    this.serverPostLocationChange.emit(this.serverPostLocation);
    this.change.emit($event);
    this.cdr.markForCheck();
  }

  destinationChange($event: MatSelectChange) {
    this.selectedDestinationChange.emit($event.value);
    const destination = this.nuanceDestinations.find(i => i.id === $event.value);
    if (destination.studyTags) {
      this.destination = destination;
    } else {
      this.destination = {
        studyTags: {
          studyTag: []
        }
      };
    }
    this.cdr.markForCheck();
  }

  selectTag($event: MatCheckboxChange, destination) {
    destination.checked = $event.checked;

    this.nuanceStudyTagIds = this.destination.studyTags.studyTag.filter(i => i.checked).map(i => i.id).join(',');
    this.nuanceStudyTagIdsChange.emit(this.nuanceStudyTagIds);
  }

}
