import { DataService } from './../../services/data.service';
import { Patient } from './../../classes/patient';
import { Component, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '../../translation/translate.service';

@Component({
  selector: 'app-search-patients',
  templateUrl: './search-patients.component.html',
  styleUrls: ['./search-patients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPatientsComponent implements OnInit {

  formState = {
    hasFirstName: true,
    hasLastName: true,
    hasMRN: true,
    hasSex: true,
    hasBirthDate: true
  };

  patient: Patient;

  constructor(
    public dialogRef: MatDialogRef<SearchPatientsComponent>,
    public translate: TranslateService,
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: Patient) { }

  ngOnInit() {
    const params = this.dataService.setupData({});
    this.patient = { ...this.data } as Patient;
    this.cdr.markForCheck();
  }

  updateFormState($event: MatCheckboxChange, key: string) {
    this.formState[key] = $event.checked;

    this.cdr.markForCheck();
  }

  updateFormData($event, key: string) {
    this.patient[key] = $event.target.value;

    this.cdr.markForCheck();
  }

  cancel() {
    this.dialogRef.close(false);
    this.cdr.markForCheck();
  }

  close() {
    const query = [];
    if (this.formState.hasFirstName) {
      query.push(`firstName=${this.patient.firstName}`);
    }
    if (this.formState.hasLastName) {
      query.push(`lastName=${this.patient.lastName}`);
    }
    if (this.formState.hasMRN) {
      query.push(`mrn=${this.patient.mrn}`);
    }
    if (this.formState.hasSex) {
      query.push(`sex=${this.patient.gender}`);
    }
    if (this.formState.hasBirthDate) {
      query.push(`birthDate=${this.patient.dob}`);
    }

    this.dialogRef.close(query.join('&'));
    this.cdr.markForCheck();
  }

}
