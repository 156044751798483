"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var translate_service_1 = require("../../translation/translate.service");
var forms_1 = require("@angular/forms");
var PatientInformationComponent = /** @class */ (function () {
    function PatientInformationComponent(translate) {
        this.translate = translate;
        this.patient = {};
        this.doctor = {};
        this.enableDoctorFacing = false;
        this.allowEditLastName = false;
        this.patientInfoConfig = [
            { label: 'patient.name', key: 'name' },
            { label: 'patient.dob', key: 'dobFormat' },
            { label: 'patient.sex', key: 'gender' },
            { label: 'patient.mrn', key: 'mrn' }
        ];
        this.doctorInfoConfig = [
            { label: 'phrase.op', key: 'name' },
            { label: 'phrase.providerId', key: 'id' }
        ];
        this.lastName = new forms_1.FormControl();
        this.changeLastName = new core_1.EventEmitter();
    }
    PatientInformationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lastName.valueChanges
            .subscribe(function (value) {
            _this.patient.alternateLastName = value;
            _this.changeLastName.emit(_this.patient);
        });
    };
    return PatientInformationComponent;
}());
exports.PatientInformationComponent = PatientInformationComponent;
