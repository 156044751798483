"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var date_format_service_1 = require("./date-format.service");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./date-format.service");
var SessionService = /** @class */ (function () {
    function SessionService(http, dateFormatService) {
        this.http = http;
        this.dateFormatService = dateFormatService;
    }
    SessionService.prototype.createSession = function (params, customOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var local, sessionObject, SESSION_URL, session, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        local = params.unittest;
                        sessionObject = _.assign({
                            'externalId': params.acc,
                            'date': this.dateFormatService.formatDate(params.date),
                            'time': params.time,
                            'mdname': params.mdname,
                            'mode': params.mode,
                            'encDept': params.encDept,
                            'encDeptID': params.encDeptID,
                            'sourceSite': params.sourceSite,
                            'customData': params.customData
                        }, customOptions);
                        SESSION_URL = 'sessions/';
                        this.baseUrl = this.getUrlPath(local);
                        session = this.http.post(this.baseUrl + SESSION_URL, sessionObject, {}).toPromise();
                        _a = this;
                        return [4 /*yield*/, session];
                    case 1:
                        _a.session = _b.sent();
                        return [2 /*return*/, session];
                }
            });
        });
    };
    SessionService.prototype.updateSession = function (expectedFiles) {
        return this.http.put(this.session.self, expectedFiles).toPromise();
    };
    SessionService.prototype.updateAction = function (action, url) {
        if (url === void 0) { url = this.session.self; }
        return this.http.put(url, { 'appAction': action }).toPromise();
    };
    SessionService.prototype.getUrlPath = function (local) {
        var QA = 'https://dev.imagemovermd.com';
        var SERVICE_URL = '/pronghorn/v1/';
        this.baseUrl = '';
        // Check to see if using local or remote path
        if ((typeof local !== 'undefined' && local !== null)) {
            if (local === 'y') {
                this.baseUrl = QA + SERVICE_URL;
            }
            else {
                this.baseUrl = decodeURIComponent(local) + SERVICE_URL;
            }
        }
        else {
            this.baseUrl = this.baseUrl + SERVICE_URL;
        }
        return this.baseUrl;
    };
    SessionService.prototype.getSession = function () {
        return this.session;
    };
    SessionService.prototype.setSession = function (session) {
        this.session = session;
    };
    SessionService.prototype.transferSession = function (operator, location, query) {
        var data = new FormData();
        data.append('key', operator);
        data.append('value', JSON.stringify({ location: location, query: query, session: this.session }));
        return this.http.post(this.baseUrl + "sessions/" + this.session.key + "/transfer/", data).toPromise();
    };
    SessionService.prototype.retrieveTransfer = function (operator) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.baseUrl + "sessions/active/" + operator).toPromise()];
            });
        });
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DateFormatService)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
exports.SessionService = SessionService;
