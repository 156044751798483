"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var date_format_service_1 = require("../../services/date-format.service");
var modality_worklist_service_1 = require("../../services/modality-worklist.service");
var translate_service_1 = require("../../translation/translate.service");
var ViewStates;
(function (ViewStates) {
    ViewStates[ViewStates["Loading"] = 0] = "Loading";
    ViewStates[ViewStates["Ready"] = 1] = "Ready";
    ViewStates[ViewStates["Error"] = 2] = "Error";
})(ViewStates || (ViewStates = {}));
var SelectExamComponent = /** @class */ (function () {
    function SelectExamComponent(cdr, mwlService, translate, dateService, dialogRef, data) {
        this.cdr = cdr;
        this.mwlService = mwlService;
        this.translate = translate;
        this.dateService = dateService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.allowRefresh = true;
        this.displayedColumns = ['accessionNumber', 'studyDescription', 'modality', 'startDate', 'startTime', 'performingPhysician', 'status'];
        this.VIEW_STATES = ViewStates;
        this.examList = [];
        this.noExam = {
            accessionNumber: '',
            startDate: '',
            startTime: '',
            displayDate: this.dateService.convertDateToMediumDate(new Date()),
            studyDescription: 'New Order',
            performingPhysician: ''
        };
        this.studiesTable = new material_1.MatTableDataSource();
    }
    SelectExamComponent.prototype.ngOnInit = function () {
        if (!this.data.mrn) {
            this.viewState = ViewStates.Error;
            this.allowRefresh = false;
            this.cdr.markForCheck();
            return;
        }
        else {
            this.allowRefresh = true;
        }
        if (this.data.exams.length === 0) {
            this.refresh();
        }
        else {
            this.viewState = ViewStates.Ready;
            this.generateOrderList(this.data.exams);
        }
    };
    SelectExamComponent.prototype.generateOrderList = function (orders) {
        var _this = this;
        this.examList = orders.slice();
        this.examList.forEach(function (exam) {
            var date = _this.dateService.stringToDateTransformer(exam.startDate, 'YYYYMMDD');
            exam.displayDate = _this.dateService.convertDateToMediumDate(date);
        });
        if (this.data.allowNew && !this.examList.find(function (exam) { return exam.accessionNumber === ''; })) {
            this.examList.unshift(this.noExam);
        }
        this.updateList(this.examList);
    };
    SelectExamComponent.prototype.cancel = function () {
        this.dialogRef.close({ cancel: true });
    };
    SelectExamComponent.prototype.select = function (exam) {
        if (!exam.accessionNumber) {
            return this.dialogRef.close({ cancel: false, list: this.examList, exam: undefined });
        }
        this.dialogRef.close({ cancel: false, exam: exam, list: this.examList });
    };
    SelectExamComponent.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.examList = [];
                        this.viewState = ViewStates.Loading;
                        this.cdr.markForCheck();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.mwlService.queryModalityWorklist({ patientId: this.data.mrn })];
                    case 2:
                        result = _a.sent();
                        if (result.data.length >= 1) {
                            this.generateOrderList(result.data[0].exams);
                        }
                        else {
                            this.generateOrderList([]);
                        }
                        this.viewState = ViewStates.Ready;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.viewState = ViewStates.Error;
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    SelectExamComponent.prototype.updateList = function (exams) {
        this.studiesTable = new material_1.MatTableDataSource(exams);
        this.studiesTable.paginator = this.paginator;
    };
    return SelectExamComponent;
}());
exports.SelectExamComponent = SelectExamComponent;
