"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var translate_service_1 = require("../../translation/translate.service");
var PdfPreviewModalComponent = /** @class */ (function () {
    function PdfPreviewModalComponent(translate, materialDialogRefererence, data) {
        var _this = this;
        this.translate = translate;
        this.materialDialogRefererence = materialDialogRefererence;
        this.data = data;
        this.isLoading = true;
        this.pdfTitle = data.file.name;
        // TODO TDK 06/29/2021
        // When user selects a non PDF file (See: WITS-6429) then request a resized image
        // from pronghorn to display as a preview
        // Read file into memory so that it can be viewed by the PDF viewer
        var fileReader = new FileReader();
        fileReader.onload = function (event) {
            _this.pdfFile = _this.convertBase64ToUInt8({ dataUrl: event.target.result });
        };
        fileReader.readAsDataURL(data.file);
    }
    PdfPreviewModalComponent.prototype.closeDialog = function () {
        this.materialDialogRefererence.close();
    };
    PdfPreviewModalComponent.prototype.pdfDoneLoading = function (pdf) {
        this.isLoading = false;
    };
    PdfPreviewModalComponent.prototype.convertBase64ToUInt8 = function (pdfFile) {
        var BASE64_MARKER = ';base64,';
        var base64Index = pdfFile.dataUrl.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = pdfFile.dataUrl.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));
        for (var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    };
    return PdfPreviewModalComponent;
}());
exports.PdfPreviewModalComponent = PdfPreviewModalComponent;
