<h3>
  {{translate.instant('phrase.patientinfo')}}
</h3>

<p>
  {{translate.instant('instruction.confirmation')}}
</p>
<mat-dialog-content>
  <section>
    <div class="column-left row-one">
      <div>
        <strong>
          {{translate.instant('word.current')}}
        </strong>
      </div>
      <div *ngFor="let i of patientInfoConfig" class="display">
        <div *ngIf="patient[i.altKey]">
          <span>
            {{translate.instant([i.label])}}
          </span>

          <span [class]="getPatientClass(i)" [matTooltip]="getPatientTooltip(i)">
            {{patient[i.altKey]}}
          </span>
        </div>
      </div>
    </div>
    <div class="column-right row-one">
      <div>
        <strong>
          {{translate.instant('word.updated')}}
        </strong>
      </div>
      <div *ngFor="let i of patientInfoConfig" class="display">
        <div *ngIf="params[i.key]">
          <span>
            {{params[i.key]}}
          </span>
        </div>
      </div>
    </div>
  </section>
  <div *ngFor="let manifest of manifestList" class="manifest-compare">
    <div class="column-left display">
      <div>
        <span>{{translate.instant('phrase.study')}}</span>
        <span class="study-display">{{manifest.study}}</span>
      </div>
      <div *ngIf="manifest.accessionNumber">
        <span class="study-display--accession--label">{{translate.instant('word.accession')}}</span>
        <span class="study-display">{{ manifest.studyAccessionNumber }}</span>
      </div>
    </div>
    <div class="column-right" [class.overread]="manifest.overread">
      <app-study-selection
        *ngIf="!manifest.accessionNumber"
        [disabled]="manifest.accessionNumber"
        [matTooltip]="getOverreadTooltip(manifest)"
        [notSuggestedStudies]="manifest.studies"
        [(selectedStudy)]="manifest.studyDescriptionObject" 
        (selectionChange)="studyChange($event, manifest)" 
        [suggestedLabel]="translate.instant('word.study-match')">
      </app-study-selection>
      <mat-form-field *ngIf="manifest.accessionNumber">
        <mat-label>{{ translate.instant('table.study') }}</mat-label>
        <input matInput type="text" value="{{ manifest.studyDescription }}" disabled />
      </mat-form-field>
      <mat-form-field *ngIf="manifest.accessionNumber">
        <mat-label>{{ translate.instant('dicom.examAccessionNumber') }}</mat-label>
        <input matInput type="text" value="{{ manifest.accessionNumber }}" disabled />
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="showProviders">
    <div class="authorizing-provider">
      <div class="border"></div>
      <strong>
        {{translate.instant('phrase.authorizingprovider')}}
      </strong>
    </div>
    <div class="provider-selection">
      <mat-form-field>
        <mat-select [placeholder]="translate.instant('word.specialties')" (selectionChange)="filterProviders($event)"
          [(ngModel)]="selectedSpecialty">
          <mat-option *ngFor="let s of specialties" [value]="s">
            {{ s }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select [placeholder]="translate.instant('word.providers')" [(ngModel)]="selectedProvider">
          <mat-option *ngFor="let p of providers" [value]="p">
            {{ p.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">
    {{translate.instant('button.cancel')}}
  </button>
  <button mat-raised-button color="accent" (click)="submit()" [disabled]="submitDisabled">
    {{translate.instant('button.confirm')}}
  </button>
</mat-dialog-actions>