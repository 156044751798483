"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".about[_ngcontent-%COMP%]{width:60vw;height:60vh;min-height:25vh;display:flex}.about__barcode[_ngcontent-%COMP%]{width:10rem}app-logo[_ngcontent-%COMP%]{width:30rem}.logo[_ngcontent-%COMP%]{display:flex;justify-content:center;margin-bottom:1rem}.title[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:center}.title[_ngcontent-%COMP%]   h4[_ngcontent-%COMP%]{font-size:2rem;margin-bottom:.5rem}.title[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-size:1.5rem}.build[_ngcontent-%COMP%], .company[_ngcontent-%COMP%], .ref[_ngcontent-%COMP%]{display:grid;grid-template-columns:10rem 1fr}.build[_ngcontent-%COMP%]   img[_ngcontent-%COMP%], .company[_ngcontent-%COMP%]   img[_ngcontent-%COMP%]{height:5.5rem}.address[_ngcontent-%COMP%]{padding:1rem 0}.address[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{margin:0}.ref[_ngcontent-%COMP%] > img[_ngcontent-%COMP%]{height:3.5rem}.date[_ngcontent-%COMP%], .product-number[_ngcontent-%COMP%]{padding:1rem 0}.copyright[_ngcontent-%COMP%] > strong[_ngcontent-%COMP%]{font-size:1.1rem}"];
exports.styles = styles;
