import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Media } from '../../classes/media-file';
import { DataService } from '../../services/data.service';
import fecha from 'fecha';
import { GuidService } from '../../services/guid.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, take, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private readonly FILE_ICON_MAP = {
    'photo_library': /tiff.*/,
    'picture': /image.*/,
    'picture_as_pdf': /pdf.*/,
    'video_library': /video.*/
  }

  private readonly DEFAULT_FILE_ICON = 'insert_drive_file'

  constructor(
    private dataService: DataService,
    private guidService: GuidService,
    private http: HttpClient
  ) { }

  setFileIcons(files: Media.MediaFile[]) {
    files
      .map(i => i.children)
      .reduce( (a, b) => a.concat(b), [])
      .forEach( (file: Media.Child) => {
        file.icon = this.getFileIcon(file);
        
        if (file.icon !== 'video_library' && !file.$dataUrl) {
          file.$dataUrl = this.getThumbnail(file);
        } else {
          file.$dataUrl = new Subject();
        }
        return;
      })
  }

  /**
   * Upload a single image to pronghorn
   * @param file {File} File to be uploaded
   * @param files {string} URL of where in pronghorn to upload the file to
   * @returns HTTP response object from request
   */
  uploadSingleFile(file: any, files: string, sensitive = false, seriesDescription: string) {
    let lastModified = new Date(file.lastModified);

    if (!lastModified) {
      lastModified = new Date(file.lastModifiedDate);
    }

    const formData: any = {
      'seriesDescription': seriesDescription,
      'type': file.mediaType,
      'diskPath': file.webkitRelativePath,
      'clientDateCreated': fecha.format(lastModified, 'YYYY-MM-DD\'T\'HH:mm:ss\'Z\''),
      'newlockKey': this.guidService.generateGuid(),
    };

    const fileThumbnail = file.thumbnail ? file.thumbnail.getValue() : '';
    if (!fileThumbnail || fileThumbnail === 'data:image/png;base64,error') {
      formData.fileName = file.name
      formData.fileContent = file;
    } else {
      formData.fileName = fileThumbnail;
    }
    
    // If a body part is selected, then append the body part details to the file
    if (file.bodyPart.bodyPart) {
      formData.bodyRegionOrientation = file.bodyPart.orientation;
      formData.bodyRegionCode = file.bodyPart.bodyPart.code;
    }

    return this.dataService.sendImage(files, formData, 0);
  }

  deleteRemoteThumbnailSource(filename: string | string[]) {
    const fileNames = Array.isArray(filename) ? filename : [ filename ];
    const body = { fileNames };

    return this
      .http.request('post', 'pronghorn/v1/image/delete', { body })
      .toPromise();
  }

  private getFileIcon(file: Media.Child): string {
    const mapElements = _.entries(this.FILE_ICON_MAP);
    const entryValue = _.find(mapElements, (mapElement) => file.type.match(mapElement[1]));
    return _.head(entryValue as any) || this.DEFAULT_FILE_ICON;
  }

  public setBodyPart(file: Media.Child, bodyPart: Media.BodyPart) {
    file.bodyPart = bodyPart;
    return file;
  }

  /**
   * Utility function that will either retrieve the file from the instance or using the 
   * thumbnail filename request the thumbnail from pronghorn
   * @param file Media file requesting an image
   */
  public getThumbnail(file: Media.Child): Subject<string> {
    file.$dataUrl = file.$dataUrl || new Subject<string>();
    file.imageUrl = '';

    file.thumbnail.subscribe((thumbnailFilename) => {
      file.imageUrl = '';
      const query = new URLSearchParams();
  
      query.append('imageToRescale', thumbnailFilename);
      query.append('imageQuality', 'L');
      query.append('outputWidth', '40');

      
      if (!thumbnailFilename) {
        file.imageUrl = 'data:image/png;base64,pending';
        return;
      }
      

      if (thumbnailFilename === 'data:image/png;base64,error') {
        file.imageUrl = 'data:image/png;base64,error';
        return;
      }

      this.http
        .get(`/pronghorn/v1/image/rescale?${query.toString()}`)
        .pipe(
          map((json: any) => {
            return `data:image/png;base64,${json.data.base64String}`
          }),
          catchError((error) => {
            console.error('Error getting image');
            return of('data:image/png;base64,error');
          }),

          take(1)
        )
        .subscribe(result => {
          file.$dataUrl.next(result);

          file.imageUrl = result;
        });
    });

    return file.$dataUrl;
  }
}
