"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var data_service_1 = require("./services/data.service");
var dialog_1 = require("@angular/material/dialog");
var navigation_warning_component_1 = require("./modals/navigation-warning/navigation-warning.component");
var environment_1 = require("../environments/environment");
var url_parameter_service_1 = require("./services/url-parameter.service");
var session_service_1 = require("./services/session.service");
var http_1 = require("@angular/common/http");
var inter_app_communication_util_1 = require("./utilities/inter-app-communication.util");
var AppComponent = /** @class */ (function () {
    function AppComponent(dialogService, sessionService, urlParameterService, dataService, http, cdr, window) {
        this.dialogService = dialogService;
        this.sessionService = sessionService;
        this.urlParameterService = urlParameterService;
        this.dataService = dataService;
        this.http = http;
        this.cdr = cdr;
        this.window = window;
        this.version = environment_1.environment.VERSION;
        this.hideNavTabs = true;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.currentRoute = this.window.location.pathname.replace('/', '');
        this.sessionService.getUrlPath(this.urlParameterService.getUrlParameter('unittest'));
        data_service_1.DataService.hideNavTabs.subscribe(function (data) {
            requestAnimationFrame(function () {
                _this.hideNavTabs = data;
            });
        });
        // DataService.hideNavTabs.next(this.urlParameterService.getUrlParameter('hideNavTabs') || this.dataService.currentRoute === '/sync')
        this.refreshFhir();
    };
    AppComponent.prototype.onPopState = function () {
        this.dataService.currentRoute = this.window.location.pathname.replace('/', '');
    };
    AppComponent.prototype.beforeunloadHandler = function (event) {
        // todo review this for use in a true post workflow
        // if (_.isUndefined((window as any).postData)) {
        //   return;
        // }
        // event.preventDefault();
        // event.returnValue = 'prompt';
        // return 'prompt';
    };
    AppComponent.prototype.onUserInteraction = function () {
        var location = window.location;
        var wrapperOrigin = location.protocol + "//" + location.hostname + ":8443";
        window.parent.postMessage('user-action', wrapperOrigin);
    };
    AppComponent.prototype.refreshFhir = function () {
        var _this = this;
        var params = this.urlParameterService.getAllParametersViaUrl();
        if (!params.state && !params.code) {
            return;
        }
        var INTERVAL = 1000 * 60 * 2;
        var timer = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http.get("/fhir/refresh?code=" + params.code + "&state=" + params.state).toPromise()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.log(error_1);
                        clearInterval(timer);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); }, INTERVAL);
    };
    AppComponent.prototype.navigate = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.dataService.showNavigationWarning) return [3 /*break*/, 2];
                        this.navigationWarning = this.dialogService.open(navigation_warning_component_1.NavigationWarningComponent, {
                            minWidth: 200,
                            disableClose: true,
                            maxWidth: '768px'
                        });
                        return [4 /*yield*/, this.navigationWarning.afterClosed().toPromise()];
                    case 1:
                        response = _a.sent();
                        if (response.rejectNavigation) {
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        this.dataService.navigate(route);
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.filterTabsWithName = function () {
        var _this = this;
        var params = this.dataService.setupData({});
        var order = [];
        var filterWithConfig = function (tab) { return !!_this.dataService.configSettings[tab].name; };
        var hasNavigationConfiguration = this.dataService.configSettings && this.dataService.configSettings.navigation && this.dataService.configSettings.navigation.order;
        var hasStandaloneConfiguration = this.dataService.configSettings && this.dataService.configSettings.navigation && this.dataService.configSettings.navigation.standalone;
        if (params.requestSource == inter_app_communication_util_1.RequestSource.Standalone && hasStandaloneConfiguration) {
            order = this.dataService.configSettings.navigation.standalone.filter(filterWithConfig);
        }
        else if (hasNavigationConfiguration) {
            order = this.dataService.configSettings.navigation.order.filter(filterWithConfig);
        }
        return order;
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
