import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '../../translation/translate.service';

@Component({
  selector: 'app-overread-confirmation',
  templateUrl: './overread-confirmation.component.html',
  styleUrls: ['./overread-confirmation.component.scss']
})
export class OverreadConfirmationComponent {

  text: string;

  constructor(
    private dialogRef: MatDialogRef<OverreadConfirmationComponent>,
    public translate: TranslateService) { }

  submit() {
    this.dialogRef.close({
      submit: true,
      text: this.text
    });
  }

  cancel() {
    this.dialogRef.close({
      submit: false
    });
  }

}
