"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var data_service_1 = require("./../../services/data.service");
var patient_1 = require("./../../classes/patient");
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var translate_service_1 = require("../../translation/translate.service");
var SearchPatientsComponent = /** @class */ (function () {
    function SearchPatientsComponent(dialogRef, translate, dataService, cdr, data) {
        this.dialogRef = dialogRef;
        this.translate = translate;
        this.dataService = dataService;
        this.cdr = cdr;
        this.data = data;
        this.formState = {
            hasFirstName: true,
            hasLastName: true,
            hasMRN: true,
            hasSex: true,
            hasBirthDate: true
        };
    }
    SearchPatientsComponent.prototype.ngOnInit = function () {
        var params = this.dataService.setupData({});
        this.patient = __assign({}, this.data);
        this.cdr.markForCheck();
    };
    SearchPatientsComponent.prototype.updateFormState = function ($event, key) {
        this.formState[key] = $event.checked;
        this.cdr.markForCheck();
    };
    SearchPatientsComponent.prototype.updateFormData = function ($event, key) {
        this.patient[key] = $event.target.value;
        this.cdr.markForCheck();
    };
    SearchPatientsComponent.prototype.cancel = function () {
        this.dialogRef.close(false);
        this.cdr.markForCheck();
    };
    SearchPatientsComponent.prototype.close = function () {
        var query = [];
        if (this.formState.hasFirstName) {
            query.push("firstName=" + this.patient.firstName);
        }
        if (this.formState.hasLastName) {
            query.push("lastName=" + this.patient.lastName);
        }
        if (this.formState.hasMRN) {
            query.push("mrn=" + this.patient.mrn);
        }
        if (this.formState.hasSex) {
            query.push("sex=" + this.patient.gender);
        }
        if (this.formState.hasBirthDate) {
            query.push("birthDate=" + this.patient.dob);
        }
        this.dialogRef.close(query.join('&'));
        this.cdr.markForCheck();
    };
    return SearchPatientsComponent;
}());
exports.SearchPatientsComponent = SearchPatientsComponent;
