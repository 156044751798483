"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fecha_1 = require("fecha");
var i0 = require("@angular/core");
var DateFormatService = /** @class */ (function () {
    function DateFormatService() {
    }
    DateFormatService.prototype.convertDateToMediumDate = function (date) {
        return fecha_1.default.format(date, 'mediumDate').replace(',', '');
    };
    DateFormatService.prototype.formatDate = function (date) {
        if ((typeof date !== 'undefined' && date !== null) !== false) {
            return date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2);
        }
    };
    DateFormatService.prototype.prettyPrint = function (date) {
        var formatted = this.formatDate(date);
        var pretty = fecha_1.default.format(new Date(formatted + " 00:00:00"), 'mediumDate');
        return pretty;
    };
    /**
     * Consumes a date as a string and returns a new Date object
     * @param {string} date Date to be transformed
     * @param {string} inputFormat The format code of the date string where `Y` is year, `M` is month, and `D` is date
     * @returns {Date} a new Date object
     *
     * @example
     *
     *  const myDate = stringToDateTransformer('20210502', 'YYYYMMDD')
     *
     *  # Expected Output: 5/2/2021 <Date>
     */
    DateFormatService.prototype.stringToDateTransformer = function (date, format) {
        if (format === void 0) { format = 'YYYYMMDD'; }
        return fecha_1.default.parse(date, format);
    };
    DateFormatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateFormatService_Factory() { return new DateFormatService(); }, token: DateFormatService, providedIn: "root" });
    return DateFormatService;
}());
exports.DateFormatService = DateFormatService;
