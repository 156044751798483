"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var data_service_1 = require("../services/data.service");
var core_1 = require("@angular/core");
var translate_service_1 = require("../translation/translate.service");
var nuance_service_1 = require("../services/nuance.service");
var date_format_service_1 = require("../services/date-format.service");
var manifest_service_1 = require("../services/manifest.service");
var qr_code_service_1 = require("../services/qr-code.service");
var session_service_1 = require("../services/session.service");
var url_parameter_service_1 = require("../services/url-parameter.service");
var material_1 = require("@angular/material");
var configuration_service_1 = require("../services/configuration.service");
var abstract_uploader_page_component_1 = require("../shared/abstract-uploader-page.component");
var QrcodeComponent = /** @class */ (function (_super) {
    __extends(QrcodeComponent, _super);
    function QrcodeComponent(dateFormatService, manifestService, nuanceService, qrCodeService, sessionService, urlParamService, snackbar, dataService, translate, configService, document) {
        var _this = _super.call(this) || this;
        _this.dateFormatService = dateFormatService;
        _this.manifestService = manifestService;
        _this.nuanceService = nuanceService;
        _this.qrCodeService = qrCodeService;
        _this.sessionService = sessionService;
        _this.urlParamService = urlParamService;
        _this.snackbar = snackbar;
        _this.dataService = dataService;
        _this.translate = translate;
        _this.configService = configService;
        _this.document = document;
        _this.CURRENT_TAB = 'qrcode';
        _this.min = 1;
        _this.max = 24;
        _this.multiplier = 3;
        _this.MINUTES_PER_HOUR = 60;
        _this.qrCodeData = null;
        _this.studyEnabled = true;
        _this.patient = {};
        _this.deviceStatusList = [];
        _this.nuanceStudyTagIds = '';
        _this.cloudServer = 'https://pwa.imagemovermd.com/pin-code/';
        _this.currentlyUpdating = false;
        _this.intervalReference = null;
        _this.params = {};
        _this.manifestPostData = {};
        _this.sessions = [];
        return _this;
    }
    QrcodeComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.dataService.showNavigationWarning = false;
        this.params = this.dataService.setupData(this.params);
        this.translate.use(this.params.lang);
        this.customerConfigs = this.dataService.configSettings.qrcode;
        this.setDefaultServerPostLocation();
        this.setup();
    };
    QrcodeComponent.prototype.ngOnDestroy = function () {
        this.stopStatus();
        this.dataService.showNavigationWarning = false;
        this.document.body.classList.remove(this.customerConfigs.theme);
    };
    QrcodeComponent.prototype.formatLabel = function (value) {
        return value + " " + this.translate.instant('word.hours');
    };
    QrcodeComponent.prototype.setDefaultServerPostLocation = function () {
        this.serverPostLocation = this.customerConfigs.sendToServerDefault;
        this.setServerPostLocation({ value: this.serverPostLocation });
    };
    QrcodeComponent.prototype.setServerPostLocation = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _super.prototype.setServerPostLocation.call(this, $event);
                        if (!this.manifest) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.manifestService.updateManifestByObj({
                                'sendToPACS': this.params.sendToPACS,
                                'sendToNuance': this.params.sendToNuance,
                                'sendToXDSb': this.params.sendToXDSb
                            }, this.manifest)];
                    case 1:
                        _a.sent();
                        if (!($event.value === 'Nuance')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getNuanceDestinations()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.updateNuancePostData()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    QrcodeComponent.prototype.updateNuancePostData = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manifestService.updateManifestByObj({
                            'nuanceRepoId': this.selectedDestination,
                            'nuanceRepoStudyTagIds': this.nuanceStudyTagIds
                        }, this.manifest)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QrcodeComponent.prototype.trackDeviceStatusBy = function (__index, device) {
        return device.id;
    };
    QrcodeComponent.prototype.getPinCode = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.manifestService.updateManifestByObj({
                                genPinCode: true
                            }, this.manifest)];
                    case 1:
                        _a.manifest = _b.sent();
                        this.server = this.manifest.serverId;
                        this.pin = this.manifest.pinCode;
                        return [2 /*return*/];
                }
            });
        });
    };
    QrcodeComponent.prototype.copyPinUrl = function ($event, input) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText("" + this.cloudServer + this.server + this.pin);
                }
                else {
                    input.select();
                    this.document.execCommand('copy');
                }
                this.snackbar.open(this.translate.instant('dicom.copyURLConfirm'), null, {
                    duration: 2000
                });
                return [2 /*return*/];
            });
        });
    };
    // ====== PRIVATE ===== //
    QrcodeComponent.prototype.setup = function () {
        return __awaiter(this, void 0, void 0, function () {
            var getCloudServer, sessionOptions, newSession, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configService.getCloudServerURL()];
                    case 1:
                        getCloudServer = _a.sent();
                        this.cloudServer = getCloudServer.url;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        this.document.body.classList.add(this.customerConfigs.theme);
                        if (this.params.hideNavTabs) {
                            data_service_1.DataService.hideNavTabs.next(this.params.hideNavTabs);
                        }
                        else {
                            data_service_1.DataService.hideNavTabs.next(!this.customerConfigs.name);
                        }
                        if (!this.dataService.hasMinimumPatientInformation) {
                            this.clearIntervalAndShowError('error.session');
                            this.loading = false;
                            return [2 /*return*/];
                        }
                        this.pin = '';
                        sessionOptions = {
                            appName: 'qrcode',
                            op: this.params.op,
                            prov: this.params.prov,
                            demo: this.params.demo
                        };
                        return [4 /*yield*/, this.sessionService.createSession(this.params, sessionOptions)];
                    case 3:
                        newSession = _a.sent();
                        this.sessions.push(newSession);
                        this.nuanceDestinations = [];
                        this.selectedDestination = this.nuanceDestinations.length > 0 ? this.nuanceDestinations[0].id : undefined;
                        this.patient = this.dataService.patient;
                        return [4 /*yield*/, this.createManifest(newSession)];
                    case 4:
                        _a.sent();
                        this.qrCodeData = this.qrCodeService.createQrCode(this.manifest);
                        this.startStatus(newSession);
                        this.loading = false;
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        this.stopStatus();
                        throw error_1;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    QrcodeComponent.prototype.createManifest = function (session) {
        return __awaiter(this, void 0, void 0, function () {
            var nuanceRepoId, _a, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        session.manifestRoute = session.manifests + '/';
                        nuanceRepoId = this.selectedDestination && this.params.sendToNuance ? this.selectedDestination : undefined;
                        this.manifestPostData = {
                            'session': session.key,
                            'patient.lastName': this.params.lastName,
                            'patient.firstName': this.params.firstName,
                            'patient.middleName': this.params.middleName,
                            'patient.title': this.params.title,
                            'patient.suffix': this.params.suffix,
                            'patient.externalId': this.params.mrn,
                            'patient.dateOfBirth': this.dateFormatService.formatDate(this.params.dob),
                            'patient.gender': this.params.gender,
                            'batch.size': 0,
                            'batch.sizeRemaining': 0,
                            'sendToPACS': this.params.sendToPACS,
                            'sendToNuance': this.params.sendToNuance,
                            'sendToXDSb': this.params.sendToXDSb,
                            'modality': this.urlParamService.getDefaultModality(this.params),
                            'studyDescription': this.params.studyDescription,
                            'genPinCode': false,
                            'nuanceRepoId': nuanceRepoId,
                            'nuanceRepoStudyTagIds': this.nuanceStudyTagIds,
                            'xdsPatientId': this.params.xdsPatientId,
                            'xdsSourcePatientId': this.params.xdsSourcePatientId
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, this.manifestService.createManifest(session.manifestRoute, this.manifestPostData)];
                    case 2:
                        _a.manifest = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _b.sent();
                        console.log(error_2);
                        this.clearIntervalAndShowError('error.timeout');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    QrcodeComponent.prototype.startStatus = function (session) {
        var _this = this;
        this.checkStatus(session);
        var pollingInterval = 3000;
        this.intervalReference = setInterval(function () {
            _this.checkStatus(session);
        }, pollingInterval);
    };
    QrcodeComponent.prototype.stopStatus = function () {
        clearInterval(this.intervalReference);
        this.intervalReference = undefined;
    };
    QrcodeComponent.prototype.checkStatus = function (session) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (session.status === 404) {
                            console.log('session expired: ', session);
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.manifestService.getStatusManifest(session.manifestRoute)];
                    case 2:
                        response = _a.sent();
                        this.parseResponse(response);
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        session.status = error_3.status;
                        if (error_3.status === 404 && this.sessions.every(function (s) { return s.status === 404; })) {
                            this.clearIntervalAndShowError('error.timeout');
                        }
                        console.log(error_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    QrcodeComponent.prototype.parseResponse = function (response) {
        return __awaiter(this, void 0, void 0, function () {
            var parsedResponse;
            var _this = this;
            return __generator(this, function (_a) {
                parsedResponse = response
                    .map(function (element) {
                    return {
                        result: _this.parseNote(element),
                        original: element
                    };
                });
                this.deviceStatusList = this.deviceStatusList.map(function (device) {
                    if (device.id === response[0].id) {
                        device = _this.parseDeviceStatus(parsedResponse[0]);
                    }
                    return device;
                });
                if (this.manifest.id === response[0].id && this.manifest.notes !== response[0].notes) {
                    this.deviceStatusList.push(this.parseDeviceStatus(parsedResponse[0]));
                    this.setup();
                }
                return [2 /*return*/];
            });
        });
    };
    QrcodeComponent.prototype.parseDeviceStatus = function (element) {
        this.manifestStatus = true;
        var deviceStatus = {
            connectionNotes: '',
            progressMode: 'determinate',
            progressUpload: 0,
            id: element.original.id,
            counts: element.result
        };
        if (element.original.expectedFiles > 0) {
            deviceStatus.progressMode = 'determinate';
            deviceStatus.progressUpload = element.result.percentComplete;
        }
        if (element.result.phoneName === this.translate.instant('phrase.disconnected')) {
            deviceStatus.progressMode = 'determinate';
            deviceStatus.connectionNotes = "" + element.result.phoneName;
        }
        else {
            deviceStatus.connectionNotes = element.result.phoneName + " " + this.translate.instant('word.connected');
        }
        return deviceStatus;
    };
    QrcodeComponent.prototype.parseNote = function (note) {
        var phoneName = '';
        var noteTotal = 0;
        if (!(note && note.notes)) {
            return null;
        }
        var notes = note.notes.split('|');
        if (notes.length >= 6 && notes[5].length > 0) {
            phoneName = notes[5] + ' ';
        }
        if (notes[0] === 'D') {
            phoneName = this.translate.instant('phrase.disconnected');
        }
        if (note.transmissionStatusSummary.ERROR !== 0) {
            noteTotal += note.transmissionStatusSummary.ERROR;
        }
        if (note.transmissionStatusSummary.SUCCESS !== 0) {
            noteTotal += note.transmissionStatusSummary.SUCCESS;
        }
        var object = {
            phoneName: phoneName.trim(),
            processingTotal: note.transmissionStatusSummary.PROCESSING,
            canceledTotal: note.transmissionStatusSummary.CANCELLED,
            failureTotal: note.transmissionStatusSummary.ERROR,
            successTotal: note.transmissionStatusSummary.SUCCESS,
            percentComplete: (noteTotal / note.expectedFiles) * 100,
            filesReceived: noteTotal,
            totalFiles: note.expectedFiles
        };
        return object;
    };
    QrcodeComponent.prototype.clearIntervalAndShowError = function (error) {
        if (error === void 0) { error = 'error.general'; }
        this.stopStatus();
        this.qrCodeData = '';
        this.patient = {
            name: '---',
            dobFormat: '---',
            gender: '---',
            mrn: '---'
        };
        this.pin = undefined;
        this.server = undefined;
        this.errorStatus = this.translate.instant(error);
    };
    return QrcodeComponent;
}(abstract_uploader_page_component_1.default));
exports.QrcodeComponent = QrcodeComponent;
