import AbstractUploaderPage from '../shared/abstract-uploader-page.component';
import { Component, OnInit, OnDestroy, ViewChild, Inject, ElementRef, HostListener } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import {TooltipPosition} from '@angular/material/tooltip';
import { DataService } from '../services/data.service';
import { FileParseService } from '../services/file-parse.service';
import { TranslateService } from '../translation/translate.service';
import { Media } from '../classes/media-file';
import { StudyType } from '../classes/study-type';
import { NuanceService } from '../services/nuance.service';
import { PdfPreviewModalComponent } from '../modals/pdf-preview-modal/pdf-preview-modal.component';
import { FileService } from './services/file-service.service';
import { DateFormatService } from '../services/date-format.service';
import { ManifestService } from '../services/manifest.service';
import { SessionService } from '../services/session.service';
import { UrlParameterService } from '../services/url-parameter.service';
import { SelectStudyDialogComponent } from '../modals/select-study-dialog/select-study-dialog.component';
import { BodyMapDialogComponent } from '../modals/body-map-dialog/body-map-dialog.component';
import { ConfigurationService } from '../services/configuration.service';

import { cloneDeep } from 'lodash';
import { Dicom } from '../classes/dicom-file';
import { RequestSource } from '../utilities/inter-app-communication.util';

interface ISelectedStudy {
  new?: boolean;
  studyDescription: string;
  modalities: string;
  time: string;
}

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent extends AbstractUploaderPage implements OnInit, OnDestroy {

  protected NotAnatomical = {
    name: 'Not Anatomical',
    modifiers: [],
    view: '0',
    orientation: 'ANTERIOR',
    relatedList: [],
    bodyPart: {
      bpe: 'NA',
      code: 'NOT ANATOMICAL',
      id: '-1',
      modifierTables: [],
      localizedStrings: {
        en: { label: 'Not Anatomical' },
        de: { label: 'Not Anatomical' },
        fr: { label: 'Not Anatomical' },
        it: { label: 'Not Anatomical' },
        nl: { label: 'Not Anatomical' },
        fr_ca: { label: 'Not Anatomical' },
        es: { label: 'Not Anatomical' },
      }
    }
  };
  protected NotSpecified = { name: this.translate.instant('table.notSpecified'), code: 'NOT SPECIFIED' }
  protected NewStudy = {
    new: true,
    studyDescription: 'New Study',
    modalities: '',
    time: ''
  };

  requestSource: RequestSource;
  localization = 'en';

  allFilesSelected = true;
  customerConfigs;
  errorStatus = '';
  files: Array<Media.MediaFile> = [];
  finished: boolean;
  isPdfModalOpen = false;
  logColumns = ['name', 'message'];
  logData = new MatTableDataSource<Element>();
  over = false;

  uploaderDisabled = true;
  cancelDisabled = true;
  resetDisabled = true;

  bodyPartEnabled = false;
  bodyPartRequired = false;
  // UI flag that sets "[Not Specified]" body parts to red if the body part is required and the user attempted to submit.
  //  This emulates traditional form controls and the transition from black to red draws the users eyes
  invalidFields: boolean = false;

  inputDisabled: boolean;
  nuanceDestinations = [];
  nuanceStudyTagIds = '';
  selectedDestination;
  serverPostLocation;

  notSuggestedStudies: Array<StudyType>;
  patient;
  selectedStudyObject: StudyType;
  selectedStudy;
  suggestedStudies: Array<StudyType>;
  exams: Dicom.Exam[] = [];

  selectStudyDialog: MatDialogRef<SelectStudyDialogComponent>;
  existingStudy: ISelectedStudy | Dicom.Exam = this.NewStudy;
  selectionType: 'study' | 'exam' = 'study';

  isSensitive = false;
  studyInstanceUID: string;
  accessionNumber: string;

  private readonly CURRENT_TAB = 'media';
  private readonly BATCH_SIZE = 1;
  private readonly WARNING_TOOLTIP_POSITION: TooltipPosition = 'above';

  private continueChain = true;
  private currentlyUpdating = false;
  private manifestFetchRetryCounter = 0;
  private manifestList = [];
  private manifestRoute: string;
  protected params: any = {};
  private promiseList = [];
  private stopInterval;
  private studies;
  private bodyPartList = '';

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('mediaFileSelect', { static: false }) fileInput: ElementRef;
  bodyParts: any;
  rollups: any;
  codeArray: any[];
  nameArray: any[];
  matches: any[];
  finalNameArray: any[];
  studyDescriptionString: any;
  studyDescriptionFormats: any;
  seriesDescriptionString: any;
  seriesDescriptionArray: any[];
  currentBodyPart: Media.BodyPart;

  constructor(
    private dateFormateService: DateFormatService,
    private fileService: FileService,
    private dialogService: MatDialog,
    private manifestService: ManifestService,
    private materialDialog: MatDialog,
    protected nuanceService: NuanceService,
    private sessionService: SessionService,
    private urlParamService: UrlParameterService,
    public dataService: DataService,
    public fileParseService: FileParseService,
    public translate: TranslateService,
    private configService: ConfigurationService,
    @Inject('Document') private document: Document,
    @Inject('Window') private window: Window) { super() }

  // -- PUBLIC -- //
  ngOnInit() {
    this.params = this.dataService.setupData(this.params);
    this.translate.use(this.params.lang);

    this.localization = this.params.lang.slice(0, 2) || this.localization;

    this.requestSource = RequestSource.Integrated;
    if (this.params.requestSource) {
      this.requestSource = this.params.requestSource;
    }

    this.setup();
    this.cleanup();
  }

  async ngOnDestroy() {
    const thumbnailFiles = Media.getMediaChildren(this.files)
                                .map(child => child.thumbnail.getValue() || '')
                                .filter(Boolean);

    this.fileService.deleteRemoteThumbnailSource(thumbnailFiles);

    this.resetPage();
    this.document.body.classList.remove(this.customerConfigs.theme);
  }
  
  @HostListener('window:beforeunload', ['$event'])
  async beforeunloadHandler(event) {
    const thumbnailFiles = [];
    this.files.forEach(file => {
      file.children.forEach(child => {
        thumbnailFiles.push(child.thumbnail.getValue());
      })
    });

    this.window.localStorage.setItem('old_thumbnails', thumbnailFiles.join(','));
  }

  trackByFile(__index, file) {
    return file.patientID;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.logData.filter = filterValue;
  }

  async openPdf(file) {
    if (this.isPdfModalOpen) {
      return;
    }

    this.isPdfModalOpen = true;

    await this.materialDialog.open(PdfPreviewModalComponent, {
      minWidth: '55vw',
      maxWidth: '100vw',
      maxHeight: '80vh',
      panelClass: 'pdf-dialog',
      data: {
        file: file
      }
    }).afterClosed().toPromise();

    this.isPdfModalOpen = false;
  }

  selectFile($event: MatCheckboxChange, file, child) {
    child.selected = $event.checked;
    this.updateSelectChildrenStatus(file);
    this.updateSelectAllStatus();
    this.setUploaderEnabledStatus();
  }

  selectAllChildren($event: MatCheckboxChange, file) {
    file.selected = $event.checked;
    file.children.forEach(i => i.selected = $event.checked);
    this.updateSelectAllStatus();
    this.setUploaderEnabledStatus();
  }

  /**
   * Mark the global sensitivity property.  This can be used to mark an entire study as sensitive
   * @param {MatCheckboxChange} $event Event emitted by the checkbox
   */
  updateSensitive($event: MatCheckboxChange) {
    this.isSensitive = $event.checked;
  }

  /**
   * Mark all files of a specific type as sensitive, should be used with a material checkbox.  This can be used to mark an individual manifest as sensitive
   * @param {MatCheckboxChange} $event Event emitted by the checkbox
   * @param {MediaFile} file
   */
  markChildrenAsSensitive($event: MatCheckboxChange, file) {
    file.sensitive = $event.checked;
  }

  selectAllFiles($event: MatCheckboxChange) {
    this.allFilesSelected = $event.checked;
    this.files.forEach(file => {
      this.selectAllChildren($event, file);
    });
  }

  async fileChange($event) {
    // run a destroy of old state
    this.finished = false;
    this.dataService.showNavigationWarning = true;

    await this.fileParseService.parseMediaFolder($event);
    this.files = this.fileParseService.parsedFiles.files;
    this.fileService.setFileIcons(this.files);

    this.files.forEach( file => 
      file.children.forEach( child =>
        this.fileService.setBodyPart(child, this.NotSpecified)
    ));

    this.setUploaderEnabledStatus();
    this.resetDisabled = false;

    this.logData = new MatTableDataSource<Element>(this.fileParseService.parsedFiles.log);
    this.logData.paginator = this.paginator;
    this.fileInput.nativeElement.value = '';
  }

  dragover($event) {
    this.over = true;
    this.stopAndPrevent($event);
  }

  dragleave($event) {
    this.over = false;
    this.stopAndPrevent($event);
  }

  drop($event) {
    this.over = false;
    this.fileChange($event.dataTransfer.files);
    this.stopAndPrevent($event);
  }

  async cancelUpload() {
    this.continueChain = false;
    this.cancelDisabled = true;
    this.inputDisabled = true;
    this.resetDisabled = false;
    // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
    window.parent.postMessage('enable-inputs', `${location.protocol}//${location.hostname}:8443`);

    // Only allow user to reset page via the reset btn IF there is no error.  Otherwise error will instruct user on how to proceed
    //  Ex: Unable to connect to pronghorn will instruct user to refresh page/relaunch from EHR
    if (!this.errorStatus) {
      this.resetDisabled = false;
    }

    const updateObject = { expectedFiles: 0 };
    const len = this.manifestList.length;
    for (let j = 0; j < len; j++) {
      try {
        await this.manifestService.updateManifestByRoute(this.manifestList[j].self, updateObject);
      } catch (error) {
        console.log(error);
      } finally {
        this.stopStatus();
      }
    }
  }

  async selectStudy() {
    this.selectStudyDialog = this.dialogService.open(SelectStudyDialogComponent, {
      minWidth: '75vw',
      maxWidth: '600px',
      position: { top: '0' },
      panelClass: 'select-study-modal',
      disableClose: true,
      data: {
        allowNew: this.requestSource === RequestSource.Integrated,
        mode: this.selectionType,
        selection: this.existingStudy,
        exams: this.exams
      }
    });

    const { study, list, type, cancel } = await this.selectStudyDialog.afterClosed().toPromise();
    
    // User selected to close the modal
    if (cancel) {
      return;
    }
    
    this.studyInstanceUID = '';
    this.accessionNumber = '';

    this.selectionType = type;

    if (list) {
      this.exams = list;
    }

    if (study) {
      this.studyInstanceUID = study.studyInstanceUID;
      this.accessionNumber = study.accessionNumber;

      this.selectedStudyObject = this.dataService.findStudy(this.studies, study.studyDescription, 'label');
      if (!this.selectedStudyObject.studyDescription) {
        this.selectedStudyObject = { studyDescription: study.studyDescription } as StudyType;
      }

      this.existingStudy = {
        studyDescription: study.studyDescription,
        modalities: study.studyModalities,
        time: study.studyTime
      }
    }

    if (!study) {
      this.existingStudy = this.NewStudy;
      this.selectionType = 'study';
    }
  }

  async submitTrigger() {
    // Check for Disabled
    if (this.uploaderDisabled) {
      return;
    }

    if (!this.validateBodyPartRequired()) {
      this.errorStatus = this.translate.instant('media.body-part-required');
      this.invalidFields = true;

      setTimeout(() => this.errorStatus = '', 5000);
      return;
    }

    if (this.requestSource == RequestSource.Standalone && !this.accessionNumber) {
      this.errorStatus = this.translate.instant('media.study-required');
      this.invalidFields = true;

      setTimeout(() => this.errorStatus = '', 5000);
      return;
    }

    this.manifestList = [];
    try {
      this.files
        .filter(file => file.selected !== false)
        .forEach((file) => {


          this.manifestList.push({
            'studyid': file.UID,
            'patientid': file.patientID,
            'imageModifiers': this.isSensitive ? 'S' : '',
            accessionNumber: this.accessionNumber ? this.accessionNumber : '',
            useAccessionNumberProvided: this.selectionType === 'exam'
          });


          this.promiseList[file.UID] = [];

          file.children
            .filter(child => child.selected !== false)
            .forEach(child => {
              const _child = child;

              // Check if body part is Not specified or Not anatomical and send empty string if it is, else send body part name
              _child.bodyPart = 
                _child.bodyPart.code === this.NotSpecified.code || 
                _child.bodyPart.bodyPart.code === this.NotAnatomical.bodyPart.code ? 
                    { name: '' } :
                    _child.bodyPart; 
              
              this.promiseList[_child.studyUID].push(_child);

              if (this.bodyPartList) {
                this.bodyPartList += _child.bodyPart.name ? `, ${_child.bodyPart.name}` : ''; // Append body parts to list for Session study description
              } else {
                this.bodyPartList = _child.bodyPart.name;
              }
            });
        });

      this.folderUpload();
    } catch (error) {
      this.currentlyUpdating = false;
      this.clearAndShowError('error.general', error.message);

      throw error;
    }
  }

  changeSelectedStudy($event) {
    this.selectedStudyObject = this.dataService.findStudy(this.studies, $event.value, 'value');
  }

  // -- PRIVATE -- //
  /**
   * Clean up any items leftover from previous sessions.  This includes but is not limited
   * to cleaning up thumbnail files that were not uploaded to pronghorn if the user refreshes the page
   */
  private async cleanup() {
    const oldThumbnails = this.window.localStorage.getItem('old_thumbnails');

    if (oldThumbnails) {
      this.window.localStorage.removeItem('old_thumbnails');

      const thumbnails = oldThumbnails.split(',');
      this.fileService.deleteRemoteThumbnailSource(thumbnails);
    }
  }


  private async setup() {
    this.params.batchSize = this.params.batchSize > 0 ? this.params.batchSize : 3;
    this.patient = this.dataService.patient;

    this.customerConfigs = this.dataService.configSettings.media;
    this.configureFileParser();

    const photoSettings = this.dataService.configSettings.photo || { bodyMapEnabled: true, bodyPartRequired: false };
    this.bodyPartEnabled = photoSettings.bodyMapEnabled;
    this.bodyPartRequired = photoSettings.bodyPartRequired;

    this.document.body.classList.add(this.customerConfigs.theme);

    if (this.params.hideNavTabs) {
      DataService.hideNavTabs.next(this.params.hideNavTabs);
    } else {
      DataService.hideNavTabs.next(!this.customerConfigs.name);
    }

    if (!this.dataService.hasMinimumPatientInformation) {
      this.clearAndShowError('error.session');
      this.inputDisabled = true;
      return;
    }

    this.setDefaultServerPostLocation();
    this.studies = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, this.CURRENT_TAB);
    const result = this.dataService.searchStudies(this.studies, this.params, this.CURRENT_TAB);

    this.studies = result.studiesFilter;
    this.suggestedStudies = result.suggested;
    this.notSuggestedStudies = result.notSuggested;

    this.setupInitialStudyConfig();

    this.selectedDestination = this.nuanceDestinations.length > 0 ? this.nuanceDestinations[0].id : undefined;

    const { bodyMappings } = await this.configService.getBodyParts();
    this.bodyParts = bodyMappings;
    this.rollups = bodyMappings.rollups || [];
  }

  private setupInitialStudyConfig() {
    const foundStudy = this.dataService.findStudy(this.studies, this.params.studyDescription);
    const suggestedStudy = this.suggestedStudies.length ? this.suggestedStudies[0] : null;
    const defaultStudy = suggestedStudy ? suggestedStudy : this.dataService.findStudy(this.studies, this.customerConfigs.defaultStudyDescription);
    const fallbackStudy = defaultStudy.value ? defaultStudy : this.studies[0];
    const study = foundStudy.value ? foundStudy : fallbackStudy;

    if (this.params.studyDescription) {
      this.selectedStudy = this.params.studyDescription;
      this.selectedStudyObject = {
        code: '-1',
        studyDescription: this.params.studyDescription
      } as any;
    } else {
      this.selectedStudy = this.selectedStudyObject && this.selectedStudyObject.value ? this.selectedStudyObject.value : study.value;
      this.selectedStudyObject = study;
    }
  }

  private configureFileParser() {
    this.fileParseService.params = this.params;
    this.fileParseService.customerConfigs = this.dataService.configSettings.media;
    this.fileParseService.parsedUpdate.progressPercentage = 0;
    this.fileParseService.parsedFiles = {
      logType: [0, 0, 0],
      log: [],
      files: []
    };
  }

  resetPage() {
    // Capture attempt to reset when reset is disabled
    if (this.resetDisabled) {
      return;
    }

    this.errorStatus = '';

    this.cancelDisabled = true;
    this.finished = false;
    this.uploaderDisabled = true;
    this.inputDisabled = false;
    this.resetDisabled = true;
    this.invalidFields = false;
    this.dataService.showNavigationWarning = false;
    this.configureFileParser();
    this.files = [];
    this.exams = []; 
    this.studyInstanceUID = '';
    this.accessionNumber = '';
    this.existingStudy = this.NewStudy;
    this.bodyPartList = '';
    this.stopStatus();

    // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
    window.parent.postMessage('enable-inputs', `${location.protocol}//${location.hostname}:8443`);
  }

  private setUploaderEnabledStatus() {
    // strict compare to false because null denotes indeterminate
    let selectedFiles = this.files.filter(file => file.selected !== false);

    this.uploaderDisabled = !(selectedFiles.length > 0);

    selectedFiles = [];
  }

  private updateSelectChildrenStatus(file) {
    const allSelected = file.children.every(i => i.selected);
    const anySelected = file.children.some(i => i.selected);
    const isIndeterminate = !allSelected && anySelected;

    file.selected = isIndeterminate ? null : allSelected;
  }

  private updateSelectAllStatus() {
    const allSelected = this.files.every(i => i.selected);
    const anySelected = this.files.some(i => i.selected !== false);
    const isIndeterminate = !allSelected && anySelected;

    this.allFilesSelected = isIndeterminate ? null : allSelected;
  }

  private stopAndPrevent($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  private async batchTransmit(tasks, batch_size, files) {
    if (this.continueChain) {
      this.currentlyUpdating = false;
      // Finished?
      if (!tasks || tasks.length === 0) {
        return;
      }
      // Call the function batch_size times to get the promises
      const promise_array = [];
      for (let i = 0; (i < batch_size) && (i < tasks.length); i++) {
        try {
          const task = tasks[i];
          const seriesDescription = this.seriesDescriptionArray.shift();
          promise_array.push(
            this.fileService.uploadSingleFile(task, files, this.isSensitive, seriesDescription));
        } catch (error) {
          console.log(error);
        }
      }

      // Execute the batch_size, then go again
      try {
        const promise_batch = Promise.all(promise_array);
        await promise_batch;
        tasks.splice(0, batch_size);
        await this.batchTransmit(tasks, batch_size, files);
      } catch (error) {
        tasks.splice(0, batch_size);
        await this.batchTransmit(tasks, batch_size, files);
        console.log(error);
      }
    }
  }

  /**
   * Upload selected files to the current session.
   */
  private async folderUpload() {
    // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
    window.parent.postMessage('disable-inputs', `${location.protocol}//${location.hostname}:8443`);

    this.inputDisabled = true;
    this.uploaderDisabled = true;
    this.cancelDisabled = false;
    this.resetDisabled = true;

    const allowCoerce: boolean =
      this.params.demo;

    if (allowCoerce) {
      this.params.coerceUIDs = true;
    }

    const sessionSettings = {
      appName: 'media',
      coerce: allowCoerce,
      coerceUIDs: this.params.coerceUIDs,
      sourceSite: this.params.sourceSite,
      prov: this.params.prov,
      op: this.params.op,
      demo: this.params.demo
    };

    // Create Session, Manifest and QrCode
    try {
      const session = await this.sessionService.createSession(this.params, sessionSettings);

      this.manifestRoute = session.manifests + '/';

      const nuanceRepoId = this.selectedDestination && this.params.sendToNuance ? this.selectedDestination : undefined;

      // studyDescriptionFormats.json file parsing
      this.studyDescriptionFormats = await this.configService.getStudyDescriptionFormats();
      if (this.studyDescriptionFormats) {  // if there's a studyDescriptionFormats.json file
        const studyDescriptionOptions = this.studyDescriptionFormats.studyDescriptionOverrides.find(desc => desc.studyDescription === this.selectedStudyObject.studyDescription);
        let studyBlock;
        let seriesBlock;
        if (studyDescriptionOptions) {  // if there's an override
          studyBlock = studyDescriptionOptions.studyDescriptionFormat;
          seriesBlock = studyDescriptionOptions.seriesDescriptionFormat;
        } else {  // default
          studyBlock = this.studyDescriptionFormats.studyDescriptionFormat;
          seriesBlock = this.studyDescriptionFormats.seriesDescriptionFormat;
        }

        const isSensitive = this.isSensitive;
        // Work through Study Descriptions tags
        for (let i = 0; i < studyBlock.tokens.length; i++) {
          const token = studyBlock.tokens[i];
          const tag = token.tag;
          const prefix = token.prefix || '';
          const suffix = token.suffix || '';
          const literal = token.literal;
          const ifSensitive = token.ifSensitive;
          const ifEqual = token.ifEqual;
          const ifExists = token.ifExists;

          if (i === 0) {
            this.studyDescriptionString = '';  // initialize
          }

          if (literal) {
            if (ifSensitive && !isSensitive) {
              this.studyDescriptionString = '';  // ignore Sensitive string if not actually sensitive
            } else {
              this.studyDescriptionString += literal;  // special case - no prefix, suffix, ifEqual, etc
            }
          } else {  // tags instead of literals
            const tagString = this.getTagString(tag);
            if (tagString && tagString !== '' && ((ifEqual === this.studyDescriptionString) || (ifExists === tag) || (ifEqual !== '' && !ifExists))) {
              this.studyDescriptionString += prefix + tagString + suffix;
              this.studyDescriptionString = this.studyDescriptionString.replace('  ', ' ');
            }
          }
        }
        // Work through Series Descriptions tags
        this.seriesDescriptionArray = [];
        const numFileTypes = this.files.length;  // could be multiple file types, like PNGs and JPGs
        for (let m = 0; m < numFileTypes; m++) {
          const filesOfCurrentType = this.files[m];
          for (let j = 0; j < filesOfCurrentType.children.length; j++) {
            this.seriesDescriptionString = '';  // initialize
            const curFile = filesOfCurrentType.children[j];
            // WITS-4594: Ensure that we are only processing files the user has selected
            if (!curFile.selected) {
              continue;
            }

            for (let i = 0; i < seriesBlock.tokens.length; i++) {
              const token = seriesBlock.tokens[i];
              const tag = token.tag;
              const prefix = token.prefix || '';
              const suffix = token.suffix || '';
              const literal = token.literal;
              const ifSensitive = token.ifSensitive;
              const ifEqual = token.ifEqual;
              const ifExists = token.ifExists;

              if (literal) {
                if (ifSensitive && !isSensitive) {
                  this.seriesDescriptionString = '';  // ignore Sensitive string if not actually sensitive
                } else {
                  this.seriesDescriptionString += literal;  // special case - no prefix, suffix, ifEqual, etc
                }
              } else {  // tags instead of literals
                let tagString = '';
                this.currentBodyPart = curFile.bodyPart;
                tagString = this.getTagString(tag);
                if (tagString && tagString !== '' && ((ifEqual === this.seriesDescriptionString) || (ifExists === tag) || (ifEqual !== '' && !ifExists))) {
                  this.seriesDescriptionString += prefix + tagString + suffix;
                  this.seriesDescriptionString = this.seriesDescriptionString.replace('  ', ' ');
                }
              }
            }
            this.seriesDescriptionArray.push(this.seriesDescriptionString);
          }
        }
      } else {  // default if there's no StudyDescriptionFormats.json file
        this.seriesDescriptionArray = [];  // initialize
        this.studyDescriptionString = `${this.isSensitive ? 'SENSITIVE ' : ''}${this.selectedStudyObject.studyDescription} ${this.bodyPartList.trim()}`;
        this.studyDescriptionString = this.studyDescriptionString.replace('  ', ' ');
        const numFileTypes = this.files.length;  // could be multiple file types, like PNGs and JPGs
        for (let i = 0; i < numFileTypes; i++) {
          const filesOfCurrentType = this.files[i];
          const numChildFiles = filesOfCurrentType.children.length;  // number of actual image files in current file type (e.g. PNG)
          for (let j = 0; j < numChildFiles; j++) {
            const curFile = filesOfCurrentType.children[j];

            // WITS-4594: Ensure that we are only processing files the user has selected
            if (!curFile.selected) {
              continue;
            }
            let seriesDescriptionStringForFile = (this.isSensitive ? 'SENSITIVE' : '') + (curFile.bodyPart.name ? curFile.bodyPart.name : '');
            seriesDescriptionStringForFile = seriesDescriptionStringForFile.replace('  ', ' ');
            this.seriesDescriptionArray.push(seriesDescriptionStringForFile);
          }
        }
      }

      let modality = this.urlParamService.getDefaultModality(this.params);
      if (this.selectedStudy.type === 'exam') {
        modality = this.selectedStudy.studyModalities;
      }

      const postData = {
        'session': session.key,
        'patient.lastName': this.params.lastName,
        'patient.firstName': this.params.firstName,
        'patient.middleName': this.params.middleName,
        'patient.title': this.params.title,
        'patient.suffix': this.params.suffix,
        'patient.externalId': this.params.mrn,
        'patient.dateOfBirth': this.dateFormateService.formatDate(this.params.dob),
        'patient.gender': this.params.gender,
        'batchSize': this.BATCH_SIZE,
        'batch.size': this.BATCH_SIZE,
        'sendToPACS': this.params.sendToPACS,
        'sendToNuance': this.params.sendToNuance,
        'sendToXDSb': this.params.sendToXDSb,
        'examCode': this.selectedStudyObject.code === '-1' ? '' : this.selectedStudyObject.code,
        'studyDescription': this.studyDescriptionString,
        'expectedFiles': 0,
        'studyStringID': '',
        'isOverread': false,
        'reasonForStudy': '',
        'nuanceRepoId': nuanceRepoId,
        'nuanceRepoStudyTagIds': this.nuanceStudyTagIds,
        'xdsPatientId': this.params.xdsPatientId,
        'xdsSourcePatientId': this.params.xdsSourcePatientId,
        'imageModifiers': this.isSensitive ? 'S' : '',
        'studyInstanceUID': this.studyInstanceUID,
        'accessionNumber': this.accessionNumber,
        modality,
      };
      await this.createManifest(postData, this.promiseList, 0);
      this.startStatus();
    } catch (error) {
      this.errorStatus = this.translate.instant('error.session');
      throw error;
    }

  }

  private getTagString(tag) {
    let tagString;
    let lang = this.localization

    switch (tag) {
      case 'DEPARTMENT':
        const dept = this.params.encDept;
        if (dept && this.studyDescriptionFormats.departmentNameRemapping) {
          const depMatch = this.studyDescriptionFormats.departmentNameRemapping.filter(
            group => group.names.find(match => match === dept)
          );
          if (depMatch[0]) {
            tagString = depMatch[0].mapTo; // use first match if for some reason there are multiple matches
          } else {
            tagString = dept;
          }
        } else {
          tagString = dept;
        }
        break;
      case 'SPECIALTY':
        tagString = this.params.specialty; // not actually supported yet
        break;
      case 'STUDY_DESCRIPTION':
        tagString = this.selectedStudyObject.studyDescription;
        break;
      case 'BODY_PART':  // this tag only applies to Series Description
        if (this.currentBodyPart && this.currentBodyPart.bodyPart) {
          let tagStringPreText = '';
            if (this.currentBodyPart.relatedList && this.currentBodyPart.relatedList.parent && this.currentBodyPart.relatedList.parent[0].prependLabelToSubParts === true) {
              tagStringPreText = this.currentBodyPart.relatedList.parent[0].localizedStrings[lang].label + ' ';
            }
            tagString = tagStringPreText + this.currentBodyPart.bodyPart.localizedStrings[lang].label;
        } else {
          tagString = '';
        }
        break;
      case 'MODIFIERS':  // this tag only applies to Series Description
        if (this.currentBodyPart && this.currentBodyPart.modifiers && this.currentBodyPart.modifiers.length !== 0) {
          const modifierArray = this.currentBodyPart.modifiers.filter(mod => mod.selected !== '');
          if (modifierArray) {
            const tagStringArray = [];
            for (let k = 0; k < modifierArray.length; k++) {
              const modifier = modifierArray[k];
              const selectedString = modifier.selected;
              const selectedCode = modifier.value.find(line => line.code === selectedString);
              if (selectedCode) {
                tagStringArray.push(selectedCode.localizedStrings[lang].label);
              }
            }
            if (tagStringArray.length === 1) {
              tagString = tagStringArray[0];
            } else {
              tagString = tagStringArray.join(' ');
            }
          }
        } else {
          tagString = '';
        }
        break;
      case 'BODY_PART_ROLLUP': // this tag only applies to Study Description
        const numFileTypes = this.files.length;  // could be multiple file types, like PNGs and JPGs
        this.codeArray = [];
        this.nameArray = [];
        this.finalNameArray = [];
        for (let n = 0; n < numFileTypes; n++) {
          const filesOfCurrentType = this.files[n];
          const numChildFiles = filesOfCurrentType.children.length;  // number of actual image files in current file type (e.g. PNG)
          if (numFileTypes === 1 && numChildFiles === 1) {  // no rollup logic needed if only one file
            let tagStringPreText = '';
            if (filesOfCurrentType.children[0].bodyPart.relatedList && filesOfCurrentType.children[0].bodyPart.relatedList.parent && filesOfCurrentType.children[0].bodyPart.relatedList.parent[0].prependLabelToSubParts === true) {
              tagStringPreText = filesOfCurrentType.children[0].bodyPart.relatedList.parent[0].localizedStrings[lang].label + ' ';
            }
            if (filesOfCurrentType.children[0].bodyPart.name === '') {
              tagString = '';
            } else {
              tagString = tagStringPreText + filesOfCurrentType.children[0].bodyPart.bodyPart.localizedStrings[lang].label;
            }
            return tagString;
          } else {
            for (let i = 0; i < numChildFiles; i++) {
              if (filesOfCurrentType.children[i].bodyPart.name !== '' && filesOfCurrentType.children[i].selected) {
                this.codeArray.push(filesOfCurrentType.children[i].bodyPart.bodyPart.code);
                let tagStringPreText = '';
                if (filesOfCurrentType.children[i].bodyPart.relatedList && filesOfCurrentType.children[i].bodyPart.relatedList.parent && filesOfCurrentType.children[i].bodyPart.relatedList.parent[0].prependLabelToSubParts === true) {
                  tagStringPreText = filesOfCurrentType.children[i].bodyPart.relatedList.parent[0].localizedStrings[lang].label + ' ';
                }
                this.nameArray.push(tagStringPreText + filesOfCurrentType.children[i].bodyPart.bodyPart.localizedStrings[lang].label);
              }
            }
          }
        }
        if (this.codeArray.length !== 0 && this.nameArray.length !== 0) {
          for (let j = 0; j < this.rollups.length; j++) {
            const codeBlock = this.rollups[j].codes;
            this.matches = []; // clear between each set of rollups
            for (let k = 0; k < this.codeArray.length; k++) {
              const code = this.codeArray[k];
              if (codeBlock.find(line => line === code)) {
                this.matches.push(code);
              }
            }
            if (this.matches.length > 1 && this.rollups[j] && !(this.matches.every((val, i, arr) => val === arr[0]))) {
              const rollupLabel = this.rollups[j].localizedStrings[lang].label;
              for (let m = 0; m < this.matches.length; m++) {
                const match = this.matches[m];
                const index = this.codeArray.indexOf(match);
                this.nameArray.splice(index, 1);
                this.codeArray.splice(index, 1);
                if (m === this.matches.length - 1) {
                  this.finalNameArray.push(rollupLabel);
                }
              }
            }
          }
        }

        // remove duplicates
        const nameArrayUnique = this.nameArray.filter((name, index, array) => {
          const firstIndex = array.findIndex((item) => item === name);
          return index === firstIndex;
        });
        const finalNameArrayUnique = this.finalNameArray.filter((name, index, array) => {
          const firstIndex = array.findIndex((item) => item === name);
          return index === firstIndex;
        });

        const allParts = finalNameArrayUnique.concat(nameArrayUnique).sort(); // merge any non-rolled up entries
        tagString = allParts.join(', ');
        break;
    }
    return tagString;
  }

  private async createManifest(postData, promiseList, count) {
    const current = this.manifestList[count];

    if (current && this.continueChain) {
      postData.expectedFiles = promiseList[current.studyid].length;
      postData.studyStringID = current.studyid;
      postData.modality = current.modality ? current.modality : postData.modality;
      postData.reasonForStudy = current.reasonForStudy;
      postData.imageModifiers = current.imageModifiers;
      postData.useAccessionNumberProvided = current.useAccessionNumberProvided;

      const list = this.manifestList[count];
      try {
        const manifest = await this.manifestService.createManifest(this.manifestRoute, postData);
        list.manifestId = manifest.id;
        list.self = manifest.self;
        current.files = manifest.files;
        current.expectedFiles = postData.expectedFiles;
        current.count = {};
        count++;

        try {
          this.batchTransmit(promiseList[current.studyid], this.params.batchSize, current.files);
          await this.createManifest(postData, promiseList, count);
        } catch (error) {
          console.log(error);
          await this.createManifest(postData, promiseList, count);
        }
      } catch (error) {
        this.errorStatus = this.translate.instant('error.manifest');
        throw error;
      }
    } else if (this.continueChain) {
      let totalExpected = 0;
      for (let j = 0; j < this.manifestList.length; j++) {
        totalExpected = totalExpected + this.manifestList[j].expectedFiles;
      }

      try {
        await this.sessionService.updateSession(totalExpected);
      } catch (error) {
        this.errorStatus = this.translate.instant('error.session');
        throw error;
      }
    }
  }

  private parseResponse(response) {
    response.forEach(status => {
      const countObject = status.transmissionStatusSummary as Media.Counts;
      const fileGroup = this.files.find(file => file.UID === status.studyStringID);

      countObject.totalFiles = status.expectedFiles > 0 ? status.expectedFiles : fileGroup.counts.totalFiles;
      countObject.filesReceived = status.filesReceived;

      fileGroup.counts = countObject;
      fileGroup.errors = status.transmissionStatusErrorReasons ? status.transmissionStatusErrorReasons : [];
      fileGroup.progressPercentage = Math.round(((countObject.filesReceived - countObject.PROCESSING) / countObject.totalFiles) * 100);
      fileGroup.bufferPercentage = Math.round((countObject.filesReceived / countObject.totalFiles) * 100);
      fileGroup.progressMode = fileGroup.bufferPercentage > 0 ? 'buffer' : 'determinate';
    });

    if (this.files.filter(file => file.selected !== false).every(file => file.progressPercentage === 100 && file.counts.SUCCESS === file.counts.totalFiles)) {
      this.inputDisabled = true;
      this.uploaderDisabled = true;
      this.finished = true;
      this.cancelDisabled = true;
      this.resetDisabled = false;
      this.dataService.showNavigationWarning = false;
      this.stopStatus();

      // TODO This needs to be moved to the AbstractUploaderPage from WITS-3702 once these two features are together in master
      window.parent.postMessage('enable-inputs', `${location.protocol}//${location.hostname}:8443`);
    }

    this.addErrorsToLogs();
  }

  addErrorsToLogs() {
    if (!this.files) {
      return;
    }

    const logs = this.files.map(i => {
      if (!i.errors) {
        i.errors = [];
      }

      return i.errors.map(j => {
        return {
          name: 'Error',
          message: j
        };
      });
    }).reduce((a, b) => a.concat(b), []).filter(i => !this.fileParseService.parsedFiles.log.includes(i));

    this.logData = new MatTableDataSource<Element>(this.fileParseService.parsedFiles.log.concat(logs));
    this.logData.paginator = this.paginator;
  }

  /**
   * Attempts to fetch the thumbnail file again in the event that an error occurred
   * @param file 
   */
  retryThumbnailRequest(file: Media.Child) {
    const thumbnailFilename = file.thumbnail.getValue();

    console.dir(file);

    if (!thumbnailFilename || thumbnailFilename === 'data:image/png;base64,error') {
      const type = file.type.split('/');
      file.thumbnail.complete();
      file.thumbnail = this.fileParseService.saveImage((file as any), type[1]);
      this.fileService.getThumbnail(file);
    } else {
      file.thumbnail.next(thumbnailFilename);
    }

  }

  private clearAndShowError(error: string = 'error.general', details: string = '') {
    this.stopStatus();
    this.errorStatus = `${this.translate.instant(error)} - ${details}`;
  }

  private startStatus() {
    // Don't start a new interval if we are already have one, this prevents memory leaks.
    this.checkStatus();
    const pollingInterval = 3000;
    this.stopInterval = setInterval(this.checkStatus.bind(this), pollingInterval);
  }

  private stopStatus() {
    clearInterval(this.stopInterval);
    this.stopInterval = undefined;
  }

  private async checkStatus() {
    if (this.currentlyUpdating) {
      return;
    }

    // Set to prevent queuing of calls.
    this.currentlyUpdating = true;
    try {
      const response: any = await this.manifestService.getStatusManifest(this.manifestRoute);
      // Set to allow calls to resume.
      this.currentlyUpdating = false;
      if (response.status !== 404) {
        this.parseResponse(response);
      } else {
        // This handles the typical 404 error message after timeout or a hard failure somewhere like server reboot. Server should try 3 times to make this call before it gets caught in the catch below.
        this.manifestFetchRetryCounter = this.manifestFetchRetryCounter + 1;
        if (this.manifestFetchRetryCounter >= 3) {
          this.clearAndShowError('error.timeout');
        }
      }

    } catch (error) {
      // If for some reason we get a JS error this handles stopping anything.
      console.log(error);
      this.currentlyUpdating = false;
      this.clearAndShowError('error.timeout');
    }
  }

  private setDefaultServerPostLocation() {
    this.serverPostLocation = this.customerConfigs.sendToServerDefault;
    this.setServerPostLocation({ value: this.serverPostLocation } as MatRadioChange);
  }

  private openBodyMap(file: Media.MediaFile, childIndex: number) {
    const bodyDialog = this.dialogService.open(BodyMapDialogComponent, {
      maxHeight: 'unset',
      maxWidth: 'unset',
      position: { top: '0' },
      panelClass: 'body-map-modal',
      data: { file: this.bodyParts ,bodyPart: file.children[childIndex].bodyPart, fileCount: this.countFilesWithUnspecified(), lang: this.params.lang }
    });

    bodyDialog.afterClosed().subscribe( (result: { bodyPart: Media.BodyPart, applyToUnspecified?: boolean }) => {
      if (!result) {
        return;
      }

      if (result.bodyPart) {
        file.children[childIndex].bodyPart = result.bodyPart;
      }

      if (result.applyToUnspecified) {
        this.setUnspecified(file, childIndex);
      }
    });
  }

  private setNotAnatomical(file: Media.MediaFile, childIndex: number) {
    file.children[childIndex].bodyPart = this.NotAnatomical;
    file.children[childIndex].bodyPart.name = this.translate.instant('media.not-anatomical');
  }

  private setUnspecified(inputFile: Media.MediaFile, childIndex: number) {
    const bodyPart = inputFile.children[childIndex].bodyPart;

    // Loop over file types
    this.files.forEach( fileTypes => {
      // Loop over files
      fileTypes.children.forEach( file => {

        if (file.bodyPart.name === '[Not specified]') {
          file.bodyPart = cloneDeep(bodyPart);
        }
      });
    });
  }

  /**
   * Validates that all images have a body part if the server requires it
   * @returns {Boolean} If the validation is successful
   */
  private validateBodyPartRequired(): boolean {
    if (!this.bodyPartRequired) {
      return true;
    }
    
    return this.files.every(fileTypes =>
      fileTypes
        .children
        .filter( file => file.selected )
        .every(file =>
          file.bodyPart.code !== this.NotSpecified.code
        )
    );
  }

  private countFilesWithUnspecified(): number {
    return this.files
      .map((file: Media.MediaFile) => file.children.filter((child) => child.bodyPart.code === this.NotSpecified.code).length)
      .reduce((acc, cur) => acc + cur, 0);
  }

  private generateUnspecifiedTooltip(childBodyPart: string): string {
    return `Apply ${childBodyPart} to ${this.countFilesWithUnspecified()} files`;
  }
}
