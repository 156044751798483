"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var translate_service_1 = require("../../translation/translate.service");
var NavigationWarningComponent = /** @class */ (function () {
    function NavigationWarningComponent(dialogRef, translate) {
        this.dialogRef = dialogRef;
        this.translate = translate;
    }
    NavigationWarningComponent.prototype.submit = function () {
        this.dialogRef.close({
            rejectNavigation: false
        });
    };
    NavigationWarningComponent.prototype.cancel = function () {
        this.dialogRef.close({
            rejectNavigation: true
        });
    };
    return NavigationWarningComponent;
}());
exports.NavigationWarningComponent = NavigationWarningComponent;
