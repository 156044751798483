export const LANG_FRCA_NAME = 'frca';
export const LANG_FRCA_TRANSLATIONS = {
  'word.processing': 'En cours de traitement',
  'word.cancelled': 'Annulé',
  'word.failure': 'Échec',
  'word.success': 'Réussite',
  'word.connected': 'connecté',
  'word.files': 'Fichiers',
  'word.destination': 'Destination',
  'word.current': 'En cours',
  'word.updated': 'Mis à jour',
  'word.instructions': 'Instructions',
  'word.study-match': 'Suggéré',
  'word.specialties': 'Spécialités',
  'word.providers': 'Fournisseurs',
  'word.duration': 'Durée',
  'word.hours': 'heures',
  'word.results': 'Résultats',
  'word.done': 'Terminé',
  'word.sensitive': 'Confidentiel',
  'word.selected': 'Sélectionné',
  'word.apply': 'Appliquer',          // Google Translated: 12/15/2020
  'word.anterior': 'Antérieur',  // Google Translated: 12/15/2020
  'word.posterior': 'Postérieur', // Google Translated: 12/15/2020
  'word.name': 'nom', // Google Translated: 12/16/2020
  'word.size': 'taille', // Google Translated: 12/16/2020
  'word.bytes': 'bytes', // Google Translated: 12/16/2020
  'word.date': 'date', // Google Translated: 12/16/2020
  'phrase.disconnected': 'Appareil déconnecté',
  'phrase.install': 'Besoin d’installer l\'application mobile?',
  'phrase.uploadFailed': 'Échec du téléchargement!',
  'phrase.scanPartOne': 'Numérisez le code QR avec votre appareil, recherchez ',
  'phrase.scanPartTwo': ' dans la boutique d\'applications ou allez dans ',
  'phrase.scanPartThree': ' depuis votre téléphone.',
  'phrase.overreadnotavailable': 'Les deuxièmes avis sont uniquement disponibles lors de l’envoi vers l’archive locale (PACS/VNA)',
  'phrase.study': 'Étude :',
  'phrase.selectall': 'Sélectionner tout',
  'phrase.selectdir': 'Sélectionnez un dossier',
  'phrase.selectfiles': 'Sélectionner des fichiers',
  'phrase.folder': 'Sélection de dossier',
  'phrase.file': 'Sélection de plusieurs fichiers',
  'phrase.ehr': 'Informations relatives au patient du DME',
  'phrase.ehrDoctor': '',
  'phrase.navigation': 'Avertissement : Vous allez quitter cette application',
  'phrase.patientinfo': 'Informations relatives au patient',
  'phrase.accession': 'Votre numéro d’inscription est le ',
  'phrase.cancelExam': 'Avertissement : Vous allez annuler cet examen',
  'phrase.pinCode': 'Code NIP',
  'phrase.selectSites': 'Sélectionner les sites',
  'phrase.searchSites': 'Rechercher les sites',
  'phrase.siteSelector': 'Sélection du site',
  'phrase.op': '',
  'phrase.selectedStudy': 'Sélectionné',
  'phrase.newStudy': 'Nouvelle étude',
  'phrase.reasonOverread': 'Motif du deuxième avis',
  'phrase.filesendsuccess': 'Fichier bien envoyé',
  'phrase.fileprocessing': 'Traitement du fichier en cours',
  'phrase.filesenderror': 'Échec d’envoi du fichier',
  'phrase.filesprocessed': 'Total des fichiers traités',
  'phrase.filterlogs': 'Journaux des filtres',
  'phrase.authorizingprovider': 'Autorisation du fournisseur',
  'phrase.transferstatus': 'Statut du transfert :',
  'phrase.studyTag': 'Balises d\'étude de nuance',
  'phrase.search': 'Paramètres de recherche',
  'phrase.study-exists': 'L\'étude existe déjà dans vos archives.',
  'phrase.addToExistingStudy': 'Ajouter à l’étude existante',
  'phrase.selectExistingStudy': 'Sélectionner une étude existante',
  'phrase.filenotinwhitelist': 'Certaines études contiennent des classes SOP qui sont en dehors de la liste blanche',
  'phrase.fileinblacklist': 'Certaines études contiennent des classes SOP qui sont sur la liste noire.',
  'patient.name': 'Nom :',
  'patient.firstName': 'Prénom :',
  'patient.lastName': 'Nom :',
  'patient.dob': 'DDN :',
  'patient.sex': 'Sexe :',
  'patient.mrn': 'NDM :',
  'patient.unknown': 'Patient inconnu',
  'patient.alternateLastName': 'Autre nom de famille :',
  'patient.notFound': 'Patient non trouvé',
  'patient.studyNotFound': 'Études de patient non trouvées.',
  'button.submit': 'Soumettre les fichiers',
  'button.stop': 'Arrêter le téléchargement',
  'button.cancel': 'Annuler',
  'button.begin': 'Commencer le téléchargement',
  'button.nevermind': 'Peu importe',
  'button.continue': 'Continuer',
  'button.confirm': 'Confirmer et poursuivre',
  'button.capture': 'Préparer à capturer',
  'button.endexam': 'Terminer l’examen',
  'button.confirmReason': 'Confirmer le motif d’examen',
  'button.reason': 'Raison de l\'examen',
  'button.cancelExam': 'Annuler l’examen',
  'button.close': 'Fermer',
  'button.generate': 'Générer',
  'button.pinCode': 'Code NIP',
  'button.search': 'Rechercher',
  'button.retrieve': 'Récupérer et stocker',
  'button.refresh': 'Actualiser',
  'button.createNewOrder': 'Créer une nouvelle commande',
  'button.backToOrders': 'Retour aux commandes',
  'button.reset': 'Réinitialiser', // Google Translated: 1/4/2021
  'table.filename': 'Nom du fichier',
  'table.message': 'Message',
  'table.filesize': 'Taille du fichier en octets',
  'table.filedate': 'Date du fichier',
  'table.patientname': 'Nom du patient',
  'table.patientid': 'ID du patient',
  'table.patientsex': 'Sexe',
  'table.patientdob': 'DDN du patient',
  'table.patientage': 'Âge',
  'table.site': 'Site',
  'table.study': 'Description de l’étude',
  'table.facility': 'Établissement',
  'table.studyInstanceUID': 'Étude Instance UID',
  'table.seriesInstanceUID': 'Série Instance UID',
  'table.series': 'Description de la série',
  'table.physician': 'Médecin',
  'table.referringphysician': 'Médecin traitant',
  'table.archive': 'Archives',
  'table.status': 'Statut',
  'table.accessionNumber': 'Numéro d’inscription',
  'table.modality': 'Modalité',
  'table.modalities': 'Modalités',
  'table.date': 'Date de l’étude',
  'table.seriesDate': 'Date de la série',
  'table.files': 'Nombre de fichiers',
  'table.overread': 'Deuxième avis',
  'table.bodyPart': 'Partie du corps',
  'table.notSpecified': '[Non spécifié]',
  'about.dialogPartOne': 'L’application mobile n’est pas destinée à un affichage',
  'about.dialogPartTwo': 'des images à des fins de gestion ou de diagnostic.',
  'about.link': 'À propos',
  'about.build_date': 'Date de construction: ',
  'about.help_text': 'Cette application est utilisée pour importer des images et des vidéos dans un système d’imagerie hospitalier. Consultez www.imagemovermd.com pour en savoir plus.\n\nL’application mobile ImageMover n’est pas destinée à un affichage des images à des fins de gestion ou de diagnostic.\n\n',
  'about.non_diagnostic': 'n\'est pas destiné à des fins diagnostiques, thérapeutiques ou de surveillance, ni d\'aucune autre manière pour une pratique médicale standard.',
  'about.rights': 'Tous droits réservés.',
  'about.copyright': 'Toute reproduction ou transmission totale ou partielle, sous quelque forme et par quelque moyen que ce soit, électronique, mécanique ou autre, est interdite sans le consentement préalable par écrit du propriétaire du copyright.',
  'about.sw': 'Les droits de reproduction et tous les autres droits de propriété relatifs au logiciel et à la documentation associée (le "Logiciel") mis à votre disposition restent la propriété exclusive de ImageMoverMD ou de ses concédants. Aucun titre ni propriété du Logiciel ne vous est conféré. L\'utilisation du Logiciel est soumise aux conditions de la licence d\'utilisateur final, disponibles sur demande.',
  'about.engineering': 'Vous n’êtes pas autorisé à décompiler et/ou à inverser l’ingénierie de tout ou partie du logiciel, au-delà des limites strictes autorisées par la loi.',
  'error.timeout': 'Votre connexion a expiré ou a été réinitialisée. Appuyez sur F5 pour actualiser cette page, ou cliquez sur le bouton de rafraîchissement de votre navigateur pour continuer. Si vous continuez à rencontrer des problèmes, veuillez contacter le support.',
  'error.manifest': 'Votre manifeste n\'a pas pu être créé, si vous avez lancé cette page depuis votre application, veuillez réessayer.',
  'error.session': 'Votre session n’a pas pu être démarrée, si vous avez lancé cette page depuis votre application, veuillez réessayer.',
  'error.badcancelled': 'Votre téléchargement s’est arrêté.',
  'error.general': 'Nous sommes désolés, une erreur s’est produite.',
  'error.hl7': 'Échec de transmission de message HL7.',
  'instruction.confirmation': 'Les informations relatives au patient sur les études à importer sélectionnées seront mises à jour tel qu’indiqué ci-dessus pour correspondre aux informations relatives au patient du DME. En cliquant sur le bouton Confirmer et poursuivre, vous confirmez que les études importées concernent le patient.',
  'instruction.navigation': 'L’application se réinitialisera si vous basculez vers une autre application. Aucune activité terminée ne sera perturbée, mais les nouvelles activités s\'arrêteront. Cliquez sur Continuer pour changer d’applications, ou cliquez sur Peu importe pour rester sur l’application actuelle.',
  'instruction.patients': 'Le téléchargement d’études de plusieurs patients dans un dossier de patient n’est pas autorisé. Veuillez sélectionner un seul patient avant de continuer.',
  'instruction.modalityPartOne': '1. Sélectionnez la Description de l’étude appropriée.',
  'instruction.modalityPartTwo': '2. Cliquez sur le bouton « Préparer à capturer ».',
  'instruction.capturePartOne': 'Pointez votre application iOS ou Android ',
  'instruction.capturePartTwo': 'sur ce code QR pour capturer des images.',
  'instruction.roundsPartOne': 'Pointez votre',
  'instruction.roundsPartTwo': 'application iOS ou Android sur ce code QR pour capturer des images.',
  'instruction.pinCode': 'Cliquez sur le bouton ci-dessous pour générer un code à communiquer à votre utilisateur distant.',
  'instruction.updateManifest': 'Scannez le code QR pour utiliser votre nouvelle sélection.',
  'instruction.modalityPartThree': 'Une commande sera créée et envoyée au DME.',
  'instruction.modalityPartFour': 'Sur votre modalité, sélectionnez la commande dans la liste des travaux et commencez votre examen.',
  'instruction.accession': 'Cliquez sur « Terminer l’examen » lorsque l’acquisition de l’image est terminée.',
  'validation.namematch': 'Nom est une correspondance exacte.',
  'validation.namepartial': 'Nom est une correspondance possible.',
  'validation.namemismatch': 'Nom ne correspond pas.',
  'validation.gendermatch': 'Sexe est une correspondance exacte.',
  'validation.genderpartial': 'Sexe n’est pas inclus ou est répertorié comme Autre.',
  'validation.gendermismatch': 'Sexe ne correspond pas.',
  'validation.dobmatch': 'Date de naissance est une correspondance exacte.',
  'validation.dobpartial': 'Date de naissance n’est pas inclue ou a été anonymisée.',
  'validation.dobmismatch': 'Date de naissance ne correspond pas.',
  'validation.overread': 'L’étude est programmée pour un deuxième avis pour une certaine raison',
  'validation.failedOverread': 'Aucune description d’un deuxième avis n’a été trouvée.',
  'validation.failedModality': 'Aucune description de modalité n’a été trouvée.',
  'validation.noModalities': 'Aucune modalité sélectionnée.',
  'validation.required': 'Champ requis',
  'dicom.location132': 'Préfixe requis introuvable à l’emplacement 132, il ne s’agit pas d’un fichier DICOM.',
  'dicom.toosmall': 'Le fichier est trop petit pour être un fichier DICOM valide.',
  'dicom.invalidresponse': 'Le champ x00100020 comprenait une réponse non valide et a été ignoré.',
  'dicom.skipped': '(type de fichier) est considéré comme un fichier système et a été ignoré.',
  'dicom.enabled': 'n’est actuellement pas activé et a été ignoré.',
  'dicom.pinTitle': 'Code PIN de transfert du dossier',
  'dicom.syncDesc': 'Le code PIN de transfert du dossier vous permet de transférer votre session et les informations de vos patients vers un autre navigateur en toute sécurité.',
  'dicom.notIE': 'Pour l’instant, Microsoft Internet Explorer ne prend pas en charge la sélection de dossiers à partir du système de fichiers de Windows.  Dans Internet Explorer, vous devez sélectionner les fichiers individuellement ou utiliser des raccourcis clavier (comme Maj+Clic ou Ctrl+A)',
  'dicom.useOther': 'ImageMoverMD n’est pas capable de traiter des dossiers entiers s’il est utilisé avec des navigateurs internet fonctionnant ainsi.  Google Chrome et Mozilla Firefox figurent parmi les navigateurs populaires fonctionnant de cette façon.',
  'dicom.pinNavigate': 'Pour accéder à votre session en cours et à vos informations patient dans un navigateur compatible, ouvrez le navigateur et naviguez vers',
  'dicom.enterPin': 'Il vous sera alors demandé de saisir un code PIN de transfert de dossier à 4 chiffres (figurant également ci-dessous).  Saisissez simplement le code PIN et le navigateur récupèrera votre session, ce qui vous permettra de transférer vos dossiers.',
  'dicom.copyURL': 'Copier l’URL dans le presse-papier',
  'dicom.copyURLConfirm': 'URL copiée dans le presse-papier',
  'dicom.refreshMessage': 'Votre code PIN a expiré et doit être actualisé.  Pour obtenir un nouveau code PIN, cliquez sur ',
  'dicom.startOver': 'Recommencer',
  'dicom.goBackStartOver': 'Une erreur s\'est produite lors du téléchargement de vos fichiers, veuillez cliquer sur Terminer et saisir un nouveau code de synchronisation pour réessayer',
  'dicom.uploadSuccessMsg': 'Téléchargement réussi! Cliquez sur Terminer pour démarrer votre prochain téléchargement',
  'dicom.successUpload': 'Téléchargements réussis',
  'dicom.failedUpload': 'Échec des téléchargements',
  'dicom.filesUploaded': 'Traitement du fichier effectué',
  'media.allSensitive': 'Régler tous sur Confidentiel',
  'media.sensitiveStudy': 'Étude confidentielle;',
  'media.change': 'changement',                             // Google Translated: 12/11/2020
  'media.apply-to-unspecified': 'appliquer à non spécifié', // Google Translated: 12/11/2020
  'media.not-anatomical': 'Pas anatomique',                 // Google Translated: 12/11/2020
  'media.select-different-body-part': 'Select A Different Body Part',    // Google Translated: 12/15/2020
  'media.body-part-required': 'Partie du corps requise pour chaque fichier',  // Google Translated: 12/16/2020
  'media.not-applied-study-description': 'Non appliqué à la description de l\'étude', // Google Translated: 12/17/2020
  'media.tooltip.generating-thumbnail': 'Génération de vignette',  // Google Translated: 08/02/2021
  'media.tooltip.error-loading-thumbnail': 'Erreur lors du chargement de la miniature de l\'image', // Google Translated: 08/02/2021  
  'transfer.title': 'Saisissez le code PIN de la session',
  'transfer.wait': 'Récupération des informations de la session en cours, veuillez patienter',
  'transfer.error': 'Code PIN de la session invalide.  Veuillez vérifier le code figurant dans le gestionnaire de transfert et réessayez',
  'sync.title': 'Saisissez le code PIN de la session',
  'sync.loading': 'Récupération des informations de la session en cours, veuillez patienter',
  'sync.invalid': 'Code PIN de la session invalide.  Veuillez vérifier le code figurant dans le gestionnaire de transfert et réessayez',
  'sync.nextUploadTooltip': 'Cliquez sur Terminer pour démarrer votre prochain téléchargement',
  'dicom.available': 'fichiers DICOM disponibles à l’importation.',
  'dicom.nonconformant': 'fichiers DICOM non conformes.',
  'dicom.mediaskipped': 'fichiers multimédias ignorés.',
  'dicom.mediaready': 'fichiers multimédias disponibles pour l\'importation et la conversion.',
  'dicom.fileskipped': 'fichiers ignorés.',
  'dicom.notmedia': 'Fichier ignoré car il ne s’agit pas d\'un fichier multimédia.',
  'dicom.nomedia': 'Fichiers multimédias ignorés.',
  'radio.pacs': 'Archive locale (PACS/VNA)',
  'radio.powershare': 'PowerShare Network',
  'radio.xdsb': 'XDS.b repository',

};
