"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var session_service_1 = require("./session.service");
var i0 = require("@angular/core");
var i1 = require("./session.service");
var i2 = require("@angular/common/http");
var NuanceService = /** @class */ (function () {
    function NuanceService(sessionService, http) {
        this.sessionService = sessionService;
        this.http = http;
        this.nuance = 'nuance';
    }
    NuanceService.prototype.destinations = function () {
        return this.http.get("" + this.sessionService.baseUrl + this.nuance + "/repos").toPromise();
    };
    NuanceService.prototype.patients = function (query) {
        return this.http.get("" + this.sessionService.baseUrl + this.nuance + "/patients?" + query).toPromise();
    };
    NuanceService.prototype.study = function (studyUID) {
        return this.http.get("" + this.sessionService.baseUrl + this.nuance + "/study?studyUID=" + studyUID).toPromise();
    };
    NuanceService.prototype.dicomStudy = function (studyUID) {
        return this.http.get("" + this.sessionService.baseUrl + this.nuance + "/dicomStudy?studyUID=" + studyUID).toPromise();
    };
    NuanceService.prototype.dicomImage = function (studyUID, imageUID) {
        return this.http.get("" + this.sessionService.baseUrl + this.nuance + "/dicomImage?studyUID=" + studyUID + "&imageUID=" + imageUID).toPromise();
    };
    NuanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NuanceService_Factory() { return new NuanceService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.HttpClient)); }, token: NuanceService, providedIn: "root" });
    return NuanceService;
}());
exports.NuanceService = NuanceService;
