import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GuidService {

  constructor() { }

    // TODO: This is not how GUIDs are generated. This has potential for collision.
  generateGuid() {
    const s4 = this.generateSubstring;
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  private generateSubstring() {
    return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
  }
}
