<div *ngIf="!hideNavTabs">
  <nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let route of filterTabsWithName()" (click)="navigate(route)"
      [active]="route === dataService.currentRoute">
      {{dataService.configSettings[route].name}}
    </a>
  </nav>
</div>
<div>
  <router-outlet></router-outlet>
  <footer>
    <app-footer *ngIf="dataService.configSettings" [logo]="dataService.configSettings[dataService.currentRoute]?.logo"
      [version]="version"></app-footer>
  </footer>
</div>