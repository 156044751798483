"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var DicomUtils = /** @class */ (function () {
    function DicomUtils() {
        this.exams = [];
    }
    DicomUtils.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DicomUtils_Factory() { return new DicomUtils(); }, token: DicomUtils, providedIn: "root" });
    return DicomUtils;
}());
exports.DicomUtils = DicomUtils;
