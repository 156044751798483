"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Dicom;
(function (Dicom) {
    var Study = /** @class */ (function () {
        function Study() {
        }
        Study.diffDate = function (date) {
            var now = new Date();
            var studyDate = new Date(date);
            var timeDiff = Math.abs(studyDate.getTime() - now.getTime());
            return Math.ceil(timeDiff / (1000 * 3600 * 24));
        };
        return Study;
    }());
    Dicom.Study = Study;
    var Counts = /** @class */ (function () {
        function Counts() {
            this.SUCCESS = 0;
            this.ERROR = 0;
            this.PROCESSING = 0;
            this.CANCELLED = 0;
            this.totalFiles = 0;
            this.filesReceived = 0;
        }
        return Counts;
    }());
    Dicom.Counts = Counts;
})(Dicom = exports.Dicom || (exports.Dicom = {}));
