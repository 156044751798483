import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DataService } from '../services/data.service';
import { TranslateService } from '../translation/translate.service';
import { DicomQueryRetrieveService } from '../services/dicom-query-retrieve.service';
import * as _ from 'lodash';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { OverreadConfirmationComponent } from '../modals/overread-confirmation/overread-confirmation.component';
import { Patient } from '../classes/patient';
import { Dicom } from '../classes/dicom-file';
import { ArrayResponse, Patient as PossiblePatient, Study } from '../classes/image-exchange-types';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { PatientDataConfirmationComponent } from '../modals/patient-data-confirmation/patient-data-confirmation.component';
import { FileParseService } from '../services/file-parse.service';
import { NameParseService } from '../services/name-parse.service';
import { DateFormatService } from '../services/date-format.service';

enum UploadState {
  STARTUP,
  IN_PROGRESS,
  FAILED,
  SUCCESS,
  CANCELLED
}

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientHistoryComponent implements OnInit, OnDestroy {

  private CURRENT_TAB = 'dicom-image-exchange';
  customerConfigs;
  destinationConfigs = {
    pacs: true,
    nuance: false,
    xdsb: false
  };
  patientInfoConfig;
  captureInProgress: boolean;
  params = {} as any;
  errorStatus;
  disabled: boolean;
  patient: Patient;
  patients: ArrayResponse<ArrayResponse<PossiblePatient>>;
  displayPatients: PossiblePatient[];
  repos: ArrayResponse<string>;
  filteredRepos: BehaviorSubject<string[]>;
  selectedRepos: string[];
  counts = {} as Dicom.Counts;
  reposSelect = new FormControl();
  reposFilter = new FormControl();
  overreadConfirmation: MatDialogRef<OverreadConfirmationComponent>;
  patientDataConfirmation: MatDialogRef<PatientDataConfirmationComponent>;
  manifestList: any[];
  loadingPatients: boolean;
  progressMode;
  bufferPercentage;
  progressPercentage;
  stop = {};
  totalFiles: number;
  polling: boolean;
  status: UploadState = UploadState.IN_PROGRESS;
  UploadState = UploadState;
  studies: Study[];
  studyDescriptions: any[];
  logData = new MatTableDataSource<any>();
  logColumns = ['status', 'message'];
  @ViewChild('select', { static: false }) select: MatSelect;
  @ViewChild('table', { static: false }) logTable: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogService: MatDialog,
    public dataService: DataService,
    public translate: TranslateService,
    private dicomQueryRetrieveService: DicomQueryRetrieveService,
    private fileParseService: FileParseService,
    private nameParseService: NameParseService,
    private dateFormatService: DateFormatService,
    @Inject('Document') private document: Document) { }

  ngOnInit() {
    this.params = this.dataService.setupData(this.params);
    this.translate.use(this.params.lang);
    this.patientInfoConfig = [
      { label: this.translate.instant('patient.name'), key: 'name' },
      { label: this.translate.instant('patient.dob'), key: 'dobFormat' },
      { label: this.translate.instant('patient.sex'), key: 'gender' },
      { label: this.translate.instant('patient.mrn'), key: 'mrn' }
    ];

    this.setup();
  }

  async setup() {
    this.params = this.dataService.setupData(this.params);
    this.fileParseService.params = this.params;
    this.customerConfigs = this.dataService.configSettings[this.CURRENT_TAB];
    this.document.body.classList.add(this.customerConfigs.theme);

    if (this.params.hideNavTabs) {
      DataService.hideNavTabs.next(this.params.hideNavTabs);
    } else {
      DataService.hideNavTabs.next(!this.customerConfigs.name);
    }

    this.studyDescriptions = this.dataService.filterAndMapStudies(this.dataService.studyDescriptionSettings, 'dicom');

    if (!this.dataService.hasMinimumPatientInformation) {
      this.errorStatus = this.translate.instant('error.session');
      this.disabled = true;
      return;
    }

    this.patient = this.dataService.patient;

    this.repos = await this.dicomQueryRetrieveService.sites();

    this.filteredRepos = new BehaviorSubject<string[]>(this.repos.data);

    this.reposFilter.valueChanges
      .subscribe((value) => {
        const repos = this.repos.data.filter(repo => repo === '' || repo.toLowerCase().includes(value));
        this.filteredRepos.next(repos);
        this.cdr.markForCheck();
      });

    this.reposSelect.valueChanges
      .subscribe(async (repos) => {
        const previousState = this.selectedRepos ? [...this.selectedRepos] : [];
        this.selectedRepos = repos;

        const userSelectedAll = repos[0] === 'Select All' && previousState[0] !== 'Select All';
        const userSelectedEach = repos.length === this.filteredRepos.getValue().length && previousState[0] !== 'Select All';
        const userSelectedNone = previousState[0] === 'Select All' && repos[0] !== 'Select All';

        if (userSelectedAll || userSelectedEach) {
          this.selectedRepos = [ 'Select All', ...this.filteredRepos.getValue() ];
          this.reposSelect.setValue(this.selectedRepos, { emitEvent: false });
        } else if (userSelectedNone) {
          this.selectedRepos = [];
          this.reposSelect.setValue([], { emitEvent: false });
        } else {
          if (repos[0] === 'Select All') {
            repos.shift();
          }

          this.selectedRepos = repos;
          this.reposSelect.setValue(this.selectedRepos, { emitEvent: false });
        }

        this.cdr.markForCheck();
      });

    if (this.repos.data.length < 2) {
      this.reposSelect.setValue(this.repos.data);
      this.reposSelect.disable();

      await this.search();
    }

    this.logData.paginator = this.paginator;
    this.logData.filter = '';

    this.cdr.markForCheck();
  }

  hasMismatchPatient(patient, field: 'patientId' | 'patientNameDisplay'): Boolean {
    return this.patients.data.some(i => i.data.some(p => p.site === patient.site && p[field] !== patient[field]));
  }

  hasMismatchName(patient): Boolean {
    return this.patient.name !== patient.patientNameDisplay;
  }

  async search() {
    if (!this.selectedRepos || this.selectedRepos.length === 0) {
      this.select.open();
    } else {
      this.loadingPatients = true;
      this.select.close();
      this.cdr.markForCheck();

      const repos = this.selectedRepos;
      if (repos[0] === 'Select All') {
        repos.shift();
      }

      this.patients = await this.dicomQueryRetrieveService.patients({
        mrn: this.patient.mrn,
        firstName: this.patient.firstName,
        middleName: this.patient.middleName,
        lastName: this.patient.lastName,
        alternateLastName: this.patient.alternateLastName,
        sex: this.patient.gender,
        birthDate: this.patient.dob,
        sites: repos
      });

      this.displayPatients = _.chain(this.patients.data)
        .map(patient => {
          if (patient.data.length === 0) {
            patient.data.push({
              patientNameDisplay: this.patient.name,
              siteName: patient.message.replace('No patients found at ', '').replace('.', ''),
              error: '',
              studies: []
            } as PossiblePatient);
          }

          return patient.data;
        })
        .flatten()
        .sortBy((patient) => this.hasMismatchName(patient))
        .value();

      this.loadingPatients = false;
    }

    this.cdr.markForCheck();
  }

  async selectStudy($event, patient, study, existsTooltip: MatTooltip) {
    study.selected = $event.checked;

    if (study.selected) {
      // should set a boolean here to manage status of existence in pacs
      const { data: existsResponse } = await this.dicomQueryRetrieveService.studyExists(study);

      study.studyExists = existsResponse.studyExists;
      this.cdr.detectChanges();

      if (study.studyExists) {
        existsTooltip.show(400);
        setTimeout(() => {
          existsTooltip.hide();
        }, 12000);
      }
    } else {
      study.overread = false;
    }

    this.cdr.markForCheck();
  }

  async patientOpened(patient: PossiblePatient) {
    if (!patient.archive) {
      return;
    }
    const STUDY_TIMEOUT = 60000;

    patient.loadingStudies = true;
    this.cdr.markForCheck();
    try {
      const delayPromise = this.delay(STUDY_TIMEOUT, null);
      const responsePromise = this.dicomQueryRetrieveService.studies({
        ...patient,
        repos: this.selectedRepos
      });

      const response = await Promise.race<any>([responsePromise, delayPromise]);
      if (response) {
        patient.studies = response.data;

        patient.studies.forEach((study) => {
          study.loading = true;
          this.cdr.detectChanges();
          this.dicomQueryRetrieveService.studyExists(study).then((res) => {
            study.studyExists = res.data.studyExists;
            study.loading = false;
            this.cdr.detectChanges();
          }).catch(console.log);
        });

      } else {
        throw new Error('study fetch timeout');
      }
    } catch (error) {
      patient.studies = [];
      console.log(error);
    }

    patient.loadingStudies = false;
    this.cdr.markForCheck();
  }

  delay(t: number, v: any) {
    return new Promise((resolve) => {
      setTimeout(resolve.bind(null, v), t);
    });
  }

  async patientClosed(patient: any) {
    // patient.studies = [];
    // this.cdr.markForCheck();
  }

  async patientUpdated(patient: any) {
    this.patient = patient;
    this.cdr.markForCheck();
  }

  async selectOverread($event: MatCheckboxChange, study: Study) {
    if (!$event.checked) {
      study.reasonForStudy = undefined;
      study.overread = false;
      return;
    }

    this.overreadConfirmation = this.dialogService.open(OverreadConfirmationComponent, {
      minWidth: 300,
      disableClose: true,
    });

    const response = await this.overreadConfirmation.afterClosed().toPromise();

    study.reasonForStudy = response.text;
    study.overread = response.submit;

    study.selected = true;

    this.cdr.markForCheck();
  }

  anySelected(): Boolean {
    return this.patients && this.patients.data.some(i => i.data.some(p => Array.isArray(p.studies) ? p.studies.some(s => s.selected) : p.selected));
  }

  async submit() {
    this.studies = _.chain(this.patients.data)
      .map(patient => patient.data)
      .flatten()
      .filter(patient => patient.studies && patient.studies.length > 0)
      .map(patient => patient.studies)
      .flatten()
      .filter((study: any) => study.selected)
      .value();

    this.totalFiles = this.studies.map(study => study.numberOfStudyRelatedInstances).reduce((a, b) => a + b);

    this.buildManifestList();
    const manifestList = await this.openPatientDataConfirmationModal();
    if (manifestList.length === 0) {
      return;
    }

    this.captureInProgress = true;
    this.status = UploadState.IN_PROGRESS;

    this.studies.forEach(study => {
      study.progressMode = 'indeterminate';
      study.progressColor = 'accent';
    });
    this.cdr.markForCheck();

    const pollingInterval = 3000;

    this.studies.forEach((study: Study) => {
      const manifest = manifestList.find(eachManifest => eachManifest.studyid === study.studyInstanceUID);

      this.dicomQueryRetrieveService.retrieve([study], {
        mrn: this.patient.mrn,
        firstName: this.patient.firstName,
        middleName: this.patient.middleName,
        lastName: this.patient.lastName,
        alternateLastName: this.patient.alternateLastName,
        sex: this.patient.gender,
        birthDate: this.patient.dob,
        studyDescription: manifest.studyDescriptionObject.label,
        examCode: manifest.studyDescriptionObject.value,
        reasonForStudy: manifest.overreadText,
        overread: manifest.overread,
        demo: this.params.demo,
        externalId: this.params.acc
      }).then((response) => {
        this.cdr.markForCheck();
        study.manifest = response.data[0].manifest;

        this.checkStatus(response);

        this.stop[Study.getUID(study)] = setInterval(() => {
          this.checkStatus(response);
        }, pollingInterval);
      }).catch(error => this.studyFailed(study, error.status, error.message));
    });
  }

  private buildManifestList() {
    this.manifestList = [];
    let overreadText;

    this.studies
      .forEach((file) => {
        let modalityFilter;
        let studyDescription;
        let overreadFilter;

        if (!file.modalitiesInStudy) {
          modalityFilter = this.studyDescriptions;
        } else {
          modalityFilter = this.dataService.filterAndMapStudies(this.studyDescriptions, file.modalitiesInStudy, 'modality');
        }

        if (modalityFilter.length > 0) {
          overreadFilter = this.dataService.filterAndMapStudies(modalityFilter, (!!file.overread).toString(), 'overread');
          if (overreadFilter.length > 0) {

            for (let o = 0; o < overreadFilter.length; o++) {
              const current = overreadFilter[o];
              current.searchTerms.forEach(function (element, index, array) {
                if (studyDescription === undefined && file.studyDescription) {
                  if (file.studyDescription.toLowerCase().indexOf(element.toLowerCase()) > -1) {
                    studyDescription = current;
                  }
                }
              });
            }

            // Catch to match first item if nothing else matches
            if (studyDescription === undefined) {
              studyDescription = overreadFilter[0];
            }

          } else {
            // Overread Returned 0 results use Modality Filter
            overreadFilter = modalityFilter;
            studyDescription = overreadFilter[0];
            overreadText = this.translate.instant('validation.failedOverread');
          }
        } else {
          // No Study Description Valid Select Value from Dicom File
          overreadFilter = [{ 'value': '', 'overread': false, 'label': file.studyDescription }];
          studyDescription = overreadFilter[0];
          overreadText = this.translate.instant('validation.failedModality');
        }

        if (file.overread) {
          overreadText = this.translate.instant('validation.overread');
        }

        studyDescription = this.customerConfigs.studyDescriptionRequired ? null : studyDescription;

        if (overreadFilter.some(o => o.label === file.studyDescription)) {
          studyDescription = overreadFilter.find(o => o.label === file.studyDescription);
        }
        this.manifestList.push({
          'studyid': file.studyInstanceUID,
          'patientid': file.patientId,
          'study': file.studyDescription,
          'modality': file.modalitiesInStudy,
          'studies': overreadFilter,
          'studyDescriptionValue': studyDescription ? studyDescription.value : '',
          'studyDescription': studyDescription ? studyDescription.label : '',
          'studyDescriptionObject': studyDescription,
          'overread': this.params.sendToPACS ? file.overread : false,
          'overreadText': this.params.sendToPACS ? overreadText : ''
        });
      });
  }

  private async openPatientDataConfirmationModal() {
    const study = this.studies[0];
    const file = {
      patientName: study.patientNameDisplay,
      gender: study.sex,
      dob: this.dateFormatService.prettyPrint(study.birthDate),
      patientID: study.patientId,
      patientNameObject: this.nameParseService.parseName(study.patientNameDisplay)
    };

    const patientObj = this.fileParseService.createPatientObject(file);
    this.patientDataConfirmation = this.dialogService.open(PatientDataConfirmationComponent, {
      minWidth: 300,
      disableClose: true,
      data: {
        studies: this.studies,
        params: this.params,
        patient: file,
        manifestList: this.manifestList,
        validation: patientObj.validation,
        imageExchange: true
      }
    });

    const $event = await this.patientDataConfirmation.afterClosed().toPromise();

    if ($event.submit) {
      return $event.manifestList;
    }

    return [];
  }

  private studyFailed(study: Study, status: number, message: string) {
    study.progressMode = 'determinate';
    study.uploadFailed = true;
    study.progressPercentage = 100;
    study.progressColor = 'warn';
    this.log(status, message);
    if (study.manifest && study.manifest.id) {
      this.stopStatus(Study.getUID(study));
    }
  }

  private log(status: number, message: string) {
    const row = { status, message };
    this.logData.data.push(row);
    this.logTable.renderRows();
    this.logData._updateChangeSubscription();
    this.cdr.markForCheck();
  }

  checkStatus = async (response: ArrayResponse<Study>) => {

    this.polling = true;
    for (const each of response.data) {
      await (async study => {
        const patientStudy = this.studies.find(i => i.studyInstanceUID === study.studyInstanceUID);
        const countResponse = await this.dicomQueryRetrieveService.status(study.manifest.id);
        const count = countResponse.data;

        count.filesReceived = count.SUCCESS + count.PROCESSING + count.ERROR + count.CANCELLED;
        count.totalFiles = +patientStudy.numberOfStudyRelatedInstances;
        patientStudy.counts = count;

        if (count.ERROR === count.totalFiles) {
          this.studyFailed(patientStudy, countResponse.status, countResponse.message);
          return;
        }

        patientStudy.progressPercentage = Math.round(((count.filesReceived - count.PROCESSING) / count.totalFiles) * 100);
        if (count.filesReceived > 0) {
          patientStudy.bufferPercentage = Math.round((count.filesReceived / count.totalFiles) * 100);
          patientStudy.progressMode = patientStudy.bufferPercentage > 0 ? 'buffer' : 'determinate';
          patientStudy.progressColor = 'primary';
        }

        if (patientStudy.progressPercentage === 100) {
          this.stopStatus(Study.getUID(study));
        }
      })(each);
    }

    if (this.studies.every(study => study.progressPercentage === 100)) {
      this.captureInProgress = false;
      this.dataService.showNavigationWarning = false;

      if (this.studies.every( study => study.counts.SUCCESS === study.counts.totalFiles )) {
        this.status = UploadState.SUCCESS;
      } else {
        this.status = UploadState.FAILED;
      }
    }
    this.polling = false;

    this.cdr.markForCheck();
  }

  private stopStatus(studyInstanceUID: string) {
    clearInterval(this.stop[studyInstanceUID]);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.logData.filter = filterValue;
  }

  ngOnDestroy() {
    this.captureInProgress = false;
    this.document.body.classList.remove(this.customerConfigs.theme);
    this.dataService.showNavigationWarning = false;
    this.cdr.markForCheck();
  }
}
