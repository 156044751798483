import { Provider } from "../classes/provider";
import { ProviderSearch } from "../classes/provider";
import JaroWinkler = require('jaro-winkler');

type ProviderSort = Provider & ProviderSearch;


export class ProviderUtilities {
  
  public static validateProviderListItems(provider: Provider) {
    // Old Name Format: <last name>, <first name> <middle name>, <title>
    const stringNameRegex = /([^,\n\s]+),\s?([^,\n]+),?\s?([^\n]+)?/g;
    const _provider = Object.assign({}, provider);
    
    if (typeof provider.name === 'string') {
      const isOldFormat = stringNameRegex.test(provider.name);

      if (isOldFormat) {
        const [ lastName, givenName, title] = (provider.name as string).split(/,\s?/);
        const [ firstName, middleName, surname ] = givenName.split(/\s/);

        const name: any = {
          lastName,
          firstName,
        }

        if (title) {
          name.title = title;
        }

        if (middleName) {
          name.middleName = middleName;
        }

        if (surname) {
          name.surname = surname;
        }
        _provider.name = name;
      } else {
        console.warn(`"${provider.name}" is not a valid provider name`)
        return undefined;
      }
    }

    return _provider;
  }
  
  /**
   * Compare the first and last name of a provider to the comparator and generate a score based on the JaroWinkler
   * algorithm.  Used with an Array<Provider>().map
   * @param {string} comparator The string the providers name should be compared against
   * @returns {(Provider) => Provider & ProviderSearch} A function that is consumed by an Array.map method
   */
   public static compareProviderName(comparator: string) {
    return (provider: any ) => {
      provider.search = {
        firstNameScore: JaroWinkler(provider.name.firstName, comparator, { caseSensitive : false }),
        lastNameScore: JaroWinkler(provider.name.lastName, comparator, { caseSensitive : false })
      }

      return provider;
    }
  }

  /**
   * Checks the name to see if it starts with the comparator string.  Names that start with the
   * comparator should be given more weight (end up higher on the list) than ones where the comparator
   * is located in the middle of the list
   * @param name Name to be checked
   * @param comparator The sub-string to check for at the start of the name string
   * @returns { number } Weight applied to this specific name
   */
  public static weighProviderNames(name: string, comparator: string): number {
    return name.toLowerCase().startsWith(comparator.toLowerCase()) ? 0.5 : 0;
  }

  // Sorts a list of providers by closest match to name 
  public static sortProviders(comparator: string) {
    return (providerA: ProviderSort, providerB: ProviderSort): number => {
      // Calculate name scores
      const aScores = [
        providerA.search.firstNameScore + this.weighProviderNames(providerA.name.firstName, comparator),
        providerA.search.lastNameScore + this.weighProviderNames(providerA.name.lastName, comparator)
      ];
      
      const bScores = [
        providerB.search.firstNameScore + this.weighProviderNames(providerB.name.firstName, comparator),
        providerB.search.lastNameScore + this.weighProviderNames(providerB.name.lastName, comparator)
      ];
      
      // Aggregator Object manages what the highest JaroWinkler score is and if it is a first name (0) or last name (1)
      const highestScore = { 
        scoreIndex: 0,
        score: 0,
        compare: function(scores: number[]) {
          scores.forEach((score: number, index: number) => {
            if (score > this.score) {
              this.score = score;
              this.scoreIndex = index;
            }
          })
          
        }
      };
      
      // Provide arrays of scores to aggregator
      highestScore.compare(aScores)
      highestScore.compare(bScores)

      let sortDirection = 1;

      // Get the values to be compared.  The scores are multiplied by -1 so that the sorting direction
      // is the same when sorting by score or by the secondary item.
      let aScoreComparator: any = aScores[highestScore.scoreIndex] * -1;
      let bScoreComparator: any = bScores[highestScore.scoreIndex] * -1;
      
      if (aScoreComparator === bScoreComparator) {
        // When the scores are equal (such as 2 people with the same last name) grab the other
        // name and sort based on that instead
        const secondaryIndex = highestScore.scoreIndex === 0 ? 'lastName' : 'firstName';

        // If this is true then you have two exact same names
        if (providerA.name[secondaryIndex] === providerB.name[secondaryIndex]) {
          return 0;
        } else {
          aScoreComparator = providerA.search[secondaryIndex];
          bScoreComparator = providerB.search[secondaryIndex];
          sortDirection = -1;
        }
      }
      
      // Sort based on highest score so closest match is at the top
      return aScoreComparator > bScoreComparator ? sortDirection : sortDirection * -1;
    }
  }
}