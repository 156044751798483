"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var platform_browser_1 = require("@angular/platform-browser");
var patient_1 = require("../classes/patient");
var url_parameter_service_1 = require("./url-parameter.service");
var _ = require("lodash");
var rxjs_1 = require("rxjs");
/**
 * NOTICE: This class is deprecated, please DO NOT add to it. Currently it
 * does too many things and needs to be split out to more and more subservices.
 */
var DataService = /** @class */ (function () {
    function DataService(http, router, title, urlParameterService, window) {
        this.http = http;
        this.router = router;
        this.title = title;
        this.urlParameterService = urlParameterService;
        this.window = window;
        this.copyrightYear = environment_1.environment.COPYRIGHT;
        this.patient = {};
        this.qrCode = '';
        this.headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };
    }
    DataService.prototype.updateStudyDescription = function (queryURL, studyDescription, examCode) {
        return this.http.put(queryURL, { 'studyDescription': studyDescription, 'examCode': examCode }).toPromise();
    };
    // -- sending image -- //
    DataService.prototype.sendImage = function (files, formData, count) {
        var _this = this;
        var workingFormData = new FormData();
        for (var _i = 0, _a = Object.keys(formData); _i < _a.length; _i++) {
            var key = _a[_i];
            workingFormData.append(key, formData[key]);
        }
        var action = this.http.post(files, workingFormData)
            .toPromise()
            .then(function (response) {
            return response;
        }).catch(function () {
            if (count < 3) {
                count++;
                _this.sendImage(files, formData, count);
            }
        });
        return action;
    };
    // -- Data initialization -- //
    DataService.prototype.initializeData = function () {
        var parameters = this.urlParameterService.buildParameterContainer(this.configSettings);
        this.initializePatient(parameters);
        return parameters;
    };
    DataService.prototype.initializePatient = function (parameters) {
        this.patient = new patient_1.Patient(parameters);
        this.hasMinimumPatientInformation = this.patient.mrn !== undefined || this.standalone;
    };
    DataService.prototype.setupData = function (params) {
        return _.assign(params, this.initializeData());
    };
    DataService.prototype.findStudy = function (studies, compare, prop) {
        if (prop === void 0) { prop = 'label'; }
        var study = studies.find(function (i) {
            return i[prop] === compare;
        });
        return study ? study : {};
    };
    DataService.prototype.filterAndMapStudies = function (arrayToFilter, filterValue, studyProperty) {
        if (studyProperty === void 0) { studyProperty = 'tabs'; }
        return arrayToFilter
            .filter(function (i) {
            if (typeof i[studyProperty] !== 'object') {
                return i[studyProperty].toString().includes(filterValue);
            }
            var val = i[studyProperty].length === 0
                || i[studyProperty].some(function (j) { return j === filterValue; });
            return val;
        })
            .map(function (i) {
            return {
                value: i.code,
                label: i.studyDescription,
                tabs: i.tabs,
                mode: i.mode,
                modality: i.modality,
                overread: i.overread,
                searchTerms: i.searchTerms,
                code: i.code,
                studyDescription: i.studyDescription,
            };
        })
            .sort(this.studyTypeAlphabeticalSort);
    };
    DataService.prototype.studyTypeAlphabeticalSort = function (a, b) {
        if (a.studyDescription < b.studyDescription) {
            return -1;
        }
        if (a.studyDescription > b.studyDescription) {
            return 1;
        }
        return 0;
    };
    DataService.prototype.searchStudies = function (studies, params, tab) {
        var _this = this;
        var filteringType = this.configSettings[tab].studyDescriptionFiltering;
        var studiesFilter = studies;
        var selectedStudy;
        if (params.encDept) {
            selectedStudy = studies.find(function (study) { return _this.findStudyPredicate(study, params.encDept); });
        }
        if (filteringType === 'first' && selectedStudy) {
            studiesFilter = [selectedStudy];
        }
        else if (filteringType === 'subset' && selectedStudy) {
            studiesFilter = studies.filter(function (study) { return _this.findStudyPredicate(study, params.encDept); });
        }
        else {
            this.foundSearchPriorityAlphabeticalSort(studiesFilter, params.encDept);
            selectedStudy = studies.find(function (study) { return study.studyDescription === _this.configSettings[tab].defaultStudyDescription; });
        }
        var splitStudies = this.splitStudiesSuggested(studiesFilter);
        return {
            studiesFilter: studiesFilter,
            selectedStudy: selectedStudy,
            suggested: splitStudies.suggested,
            notSuggested: splitStudies.notSuggested
        };
    };
    DataService.prototype.foundSearchPriorityAlphabeticalSort = function (studiesFilter, search) {
        var _this = this;
        studiesFilter.sort(function (a, b) {
            var aExists = _this.findStudyPredicate(a, search);
            var bExists = _this.findStudyPredicate(b, search);
            var aIsLower = a.studyDescription < b.studyDescription;
            if (aExists && !bExists) {
                return -1;
            }
            if (!aExists && bExists) {
                return 1;
            }
            return aIsLower ? -1 : 1;
        });
    };
    DataService.prototype.findStudyPredicate = function (study, encDept) {
        var searchTerms = study.searchTerms ? study.searchTerms : [];
        var isInSearch = searchTerms.some(function (term) { return encDept && encDept.toLowerCase().indexOf(term.toLowerCase()) > -1; });
        study.isInSearch = isInSearch;
        return isInSearch;
    };
    DataService.prototype.navigate = function (route) {
        this.currentRoute = route;
        var params = this.setupData({});
        var config = this.configSettings[route];
        delete params.app;
        this.title.setTitle(config.title);
        var isGetRequest = _.isUndefined(this.window.postData);
        if (isGetRequest) {
            this.router.navigate([config.url], {
                queryParams: params,
                queryParamsHandling: 'merge'
            });
        }
        else {
            this.router.navigate([config.url], {
                queryParams: { code: params.code, state: params.state },
                queryParamsHandling: 'merge'
            });
        }
    };
    DataService.prototype.splitStudiesSuggested = function (studies) {
        return {
            suggested: studies.filter(function (study) { return study.isInSearch; }),
            notSuggested: studies.filter(function (study) { return !study.isInSearch; })
        };
    };
    DataService.hideNavTabs = new rxjs_1.Subject();
    return DataService;
}());
exports.DataService = DataService;
